import { useBrandTFunction } from '@dap-admin/i18n';
import { Dataowner, UpdateDataownerSchema } from '@dap-admin/types';
import { getOrgNumberNameFromCountry } from '@dap-admin/utils';
import { useAppTFunction } from '@dap-common/i18n';
import { FlexColumn, FlexRow, InputDatePicker, SaveOrCancelEdit } from '@dap-common/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { Dialog, SelectInputField, TextInputField } from '@shared/components';
import { themeV2 } from '@shared/styles';
import { Country, CountryCode } from '@shared/types';
import { capitalizeFirstLetter } from '@shared/utils';
import { formatISO } from 'date-fns';
import { useController, useForm } from 'react-hook-form';
import {
  EditDataownerDetailsSchema,
  useEditDataownerDetailsSchema,
} from './editDataownerDetailsSchema';

interface Props {
  open: boolean;
  dataowner: Dataowner;
  cancelEdit: () => void;
  brandKey: string;
  updateDataowner: (input: UpdateDataownerSchema) => void;
  isUpdatingDataowner: boolean;
  isSuperuser: boolean;
}

export type DataownerEditDetails = typeof editDetailsInit;

const editDetailsInit = {
  name: false,
  orgNoAndCountry: false,
  domains: false,
  apps: false,
  isMember: false,
  isEditingMemberToFrom: false,
};

const EditDataownerDetails = ({
  open,
  dataowner,
  cancelEdit,
  brandKey,
  updateDataowner,
  isUpdatingDataowner,
  isSuperuser,
}: Props) => {
  const tBrand = useBrandTFunction(brandKey);
  const t = useAppTFunction();

  const { handleSubmit, control, watch } = useForm<EditDataownerDetailsSchema>({
    defaultValues: useEditDataownerDetailsSchema(dataowner).getDefault(),
    resolver: yupResolver(useEditDataownerDetailsSchema(dataowner)),
  });

  const nameController = useController({ name: 'name', control });
  const orgNrController = useController({ name: 'orgNr', control });
  const memberFromController = useController({ name: 'memberFrom', control });
  const memberToController = useController({ name: 'memberTo', control });
  const notMemberController = useController({ name: 'notMember', control });
  const countryController = useController({ name: 'country', control });

  const country = watch('country');
  const notMember = watch('notMember');

  const handleSave = (formData: EditDataownerDetailsSchema) => {
    const data = {
      name: formData.name,
      country: formData.country as Country,
      orgNr: formData.orgNr,
      memberFrom:
        formData.memberFrom && !formData.notMember
          ? formatISO(formData.memberFrom, { representation: 'date' })
          : undefined,
      memberTo:
        formData.memberTo && !formData.notMember
          ? formatISO(formData.memberTo, { representation: 'date' })
          : undefined,
    };

    updateDataowner(data);
  };

  return (
    <ThemeProvider theme={themeV2}>
      <Dialog open={open} maxWidth="sm" onClose={cancelEdit}>
        <DialogTitle>
          {t('pagesHomeDataowner.actionMenu.editDetails', {
            dataowner: tBrand('brand.dataowner.name.singular'),
          })}
        </DialogTitle>

        <DialogContent>
          <TextInputField
            {...nameController.field}
            error={nameController.fieldState.error?.message}
            label={t('pagesHomeDataowner.form.name')}
            required
            fullWidth
          />

          <SelectInputField
            {...countryController.field}
            error={countryController.fieldState.error?.message}
            placeholder={t('common.country.title')}
            label={t('common.country.title')}
            menuItems={[
              { value: CountryCode.Norway, name: t('common.countries.NO') },
              { value: CountryCode.Sweden, name: t('common.countries.SE') },
              { value: CountryCode.Finland, name: t('common.countries.FI') },
            ]}
            required
            margin="normal"
            fullWidth
          />

          <TextInputField
            {...orgNrController.field}
            error={orgNrController.fieldState.error?.message}
            label={getOrgNumberNameFromCountry(country, t)}
            required
            fullWidth
          />

          {isSuperuser && (
            <FlexColumn width={'fit-content'} gap={0.5} mt={2} mb={1}>
              <FlexRow>
                <Typography component={'label'} fontWeight={700}>
                  {t('dataowner.membership.nonMember')}
                </Typography>
                <Checkbox
                  {...notMemberController.field}
                  checked={notMemberController.field.value}
                />
              </FlexRow>
            </FlexColumn>
          )}

          {!notMember && (
            <Stack direction="row" gap={3}>
              <Stack flex={'50%'}>
                <InputDatePicker
                  {...memberFromController.field}
                  error={memberFromController.fieldState.error?.message}
                  required
                  label={capitalizeFirstLetter(
                    t('pagesHomeDataowner.actionMenu.memberFrom', {
                      memberType: tBrand('brand.dataowner.name.singular'),
                    })
                  )}
                  margin="dense"
                />
              </Stack>
              <Stack flex={'50%'}>
                <InputDatePicker
                  {...memberToController.field}
                  error={memberToController.fieldState.error?.message}
                  required
                  label={capitalizeFirstLetter(
                    t('pagesHomeDataowner.actionMenu.memberTo', {
                      memberType: tBrand('brand.dataowner.name.singular'),
                    })
                  )}
                  margin="dense"
                />
              </Stack>
            </Stack>
          )}
        </DialogContent>

        <DialogActions>
          <SaveOrCancelEdit
            save={handleSubmit(handleSave)}
            cancel={cancelEdit}
            loading={isUpdatingDataowner}
          />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default EditDataownerDetails;
