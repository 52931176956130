import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useAppTFunction } from '@dap-common/i18n';
import { useEffect, useState } from 'react';
import { ContainedButton, Dialog, TextInputField } from '@shared/components';
import { themeV2 } from '@shared/styles';

interface DialogProps {
  entityName: string;
  title: string;
  content: string;
  handleCancel: () => void;
  handleConfirm: () => void;
  open?: boolean;
  loading: boolean;
  error?: string;
}

const FormDialog = ({
  entityName,
  title,
  content,
  handleConfirm,
  handleCancel,
  loading,
  error,
  open = true,
}: DialogProps) => {
  const t = useAppTFunction();
  const [inputValue, setInputValue] = useState<string>('');
  const [notCorrentInput, setNotCorrectInput] = useState<boolean>(false);

  const inputMatchesEntityName = inputValue === entityName;

  useEffect(() => {
    if (inputValue === '') {
      setNotCorrectInput(false);
    }
  }, [inputValue]);

  const checkInput = () => {
    if (inputMatchesEntityName) {
      handleConfirm();
    } else {
      setNotCorrectInput(true);
    }
  };

  return (
    <ThemeProvider theme={themeV2}>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{title}</DialogTitle>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <DialogContent>
          <Typography>{content}</Typography>
          <TextInputField
            name="confirm"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            error={notCorrentInput ? t('brandadmin.deleteEntity.wrongName') : ''}
            margin="normal"
            label={t('brandadmin.deleteEntity.label')}
            disabled={loading}
            size="small"
          />
        </DialogContent>

        <DialogActions>
          <Button variant="text" size="large" color="grey" onClick={handleCancel} disableElevation>
            {t('common.cancel')}
          </Button>
          <ContainedButton
            onClick={checkInput}
            disabled={!inputMatchesEntityName}
            loading={loading}
            color="warning"
          >
            {t('brandadmin.deleteEntity.confirmButton')}
          </ContainedButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default FormDialog;
