import { useBrandadminTFunction } from '@dap-admin/i18n';
import { EmployeeBasicV2, EmployeeStatus } from '@dap-admin/types';
import { FlexRow } from '@dap-common/ui';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  styled,
  useTheme,
  Box,
} from '@mui/material';
import { Chip, LabelValueTable } from '@shared/components';
import { AlarmIcon } from '@shared/custom-icons';
import { EmployeeAlertTooltip } from '../Employee';
import { RolesList } from '../List';
import EmployeeActionsMenu, { EmployeeActionsMenuActions } from '../Employee/EmployeeActionsMenu';

interface Props {
  employee: EmployeeBasicV2;
  isAdmin: boolean;
  isSuperuser: boolean;
  menuActions: EmployeeActionsMenuActions;
}

export default function EmployeeCard({ employee, isAdmin, isSuperuser, menuActions }: Props) {
  const { palette } = useTheme();
  const tAdmin = useBrandadminTFunction();

  const hasWarnings =
    employee.status === EmployeeStatus.UNVERIFIED || employee.status === EmployeeStatus.TEMP;
  const employeeIsAdmin = employee.roles?.some((role) => role.role.isAdmin);

  return (
    <StyledCard data-testid="EmployeeCard" variant="outlined">
      <CardHeader
        title={
          (employee.status === EmployeeStatus.DISABLED || employeeIsAdmin) && (
            <Chip
              label={
                employee.status === EmployeeStatus.DISABLED
                  ? tAdmin('employee.details.deactivated')
                  : tAdmin('employee.details.administrator')
              }
              backgroundColor={
                employee.status === EmployeeStatus.DISABLED
                  ? palette.warning.main
                  : palette.gradient.secondary
              }
            />
          )
        }
        action={
          <FlexRow gap={1}>
            {hasWarnings && (
              <EmployeeAlertTooltip
                unverfied={employee.status === EmployeeStatus.UNVERIFIED}
                blocked={employee.status === EmployeeStatus.BLOCKED}
                tempAccount={employee.status === EmployeeStatus.TEMP}
              >
                <IconButton
                  component="span"
                  edge="end"
                  style={{ marginLeft: 'auto' }}
                  onClick={(event) => event.stopPropagation()}
                >
                  <AlarmIcon />
                </IconButton>
              </EmployeeAlertTooltip>
            )}

            {isAdmin && (
              <div onClick={(event) => event.stopPropagation()}>
                <EmployeeActionsMenu
                  actions={menuActions}
                  employee={employee}
                  isSuperuser={isSuperuser}
                />
              </div>
            )}
          </FlexRow>
        }
      />

      <CardContent>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Typography variant="h6">{employee.name}</Typography>
          <Typography variant="body2" component="p" fontWeight={600} mb={1} minHeight="21px">
            {employee.jobTitle}
          </Typography>

          <LabelValueTable
            typography="body2"
            items={[
              { label: tAdmin('employee.details.phone'), value: employee.phoneNumber },
              {
                label: tAdmin('employee.details.email'),
                value: <span style={{ wordBreak: 'break-all' }}>{employee.email}</span>,
              },
              {
                label: tAdmin('employee.details.roles'),
                value: employee.roles?.length ? <RolesList roles={employee.roles} max={3} /> : '–',
              },
            ]}
          />
        </Box>
      </CardContent>
    </StyledCard>
  );
}

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderWidth: '2px',
  backgroundColor: theme.palette.background.paper,

  '.MuiCardContent-root': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));
