import { IconButton, IconButtonProps, TooltipProps } from '@mui/material';
import { AlertTooltip } from '@shared/components';
import { AlarmIcon } from '@shared/custom-icons';

interface Props {
  iconSize?: 'small' | 'medium' | 'large';
  tooltipTitle?: TooltipProps['title'];
  tooltipPlacement?: TooltipProps['placement'];
  edge?: IconButtonProps['edge'];
}

export default function CTATooltip({
  iconSize = 'medium',
  tooltipTitle,
  tooltipPlacement = 'bottom',
  edge = false,
}: Props) {
  return (
    <AlertTooltip title={tooltipTitle} placement={tooltipPlacement}>
      {/* children of tooltip cannot functional components, must hold a ref */}
      <IconButton component="div" edge={edge} sx={{ my: '-12px' }}>
        <AlarmIcon fontSize={iconSize} />
      </IconButton>
    </AlertTooltip>
  );
}
