import { ChipProps, useTheme } from '@mui/material';
import { Chip } from '@shared/components';

export default function FilterChip({ label, onDelete }: Pick<ChipProps, 'label' | 'onDelete'>) {
  const { palette } = useTheme();
  return (
    <Chip
      rounded
      label={label}
      onDelete={onDelete}
      backgroundColor={palette.primary.A100}
      textColor={palette.primary.main}
    />
  );
}
