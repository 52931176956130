import { alpha, Card, Link, Stack, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import PreviewSanityImage from '../previewContent/PreviewSanityImage';
import { previewCardHeight, previewCardImageHeight } from './eventConstants';
import { messages } from '@dap-common/i18n';
import { SanityEventPreview } from '@dap-sanity/types';
import { maxLinesSx } from '@shared/utils';

interface Props {
  event: SanityEventPreview;
  onClick?: () => void;
}

export default function CalendarEventPreviewCardContent({ event, onClick }: Props) {
  const { palette } = useTheme();
  const { t } = useTranslation(['events']);
  const date = new Date(event.eventInformation.dateTimeRange.startDate);

  return (
    <Card onClick={onClick} sx={cardStyle}>
      <Stack justifyContent="center" alignItems="center" spacing={1} width="40px">
        <Typography variant="body3" color={alpha(palette.common.black, 0.4)}>
          {format(date, 'EEE')}
        </Typography>
        <Typography
          variant="h1"
          sx={{
            color: palette.text.primary,
            textTransform: 'capitalize',
          }}
        >
          {format(date, 'd')}
        </Typography>
      </Stack>
      {event.image && (
        <PreviewSanityImage height={previewCardImageHeight} image={event.image} imageWidth="94px" />
      )}
      <Stack spacing={1} flex="1 1 0">
        <Typography variant="h5" sx={max4LinesTextSx}>
          {event.title}
        </Typography>
        {(event.eventInformation.registration?.registrationUrl ||
          event.eventInformation.registration?.registrationEmail) && (
          <Link
            onClick={(event) => event.stopPropagation()}
            href={
              event.eventInformation.registration.registrationUrl
                ? event.eventInformation.registration.registrationUrl
                : `mailto:${event.eventInformation.registration.registrationEmail}`
            }
            variant="body4"
            color="primary.main"
            underline="none"
            target="_blank"
          >
            {t(messages.events.registration)}
          </Link>
        )}
      </Stack>
    </Card>
  );
}

const max4LinesTextSx = maxLinesSx(4);

const cardStyle: SxProps<Theme> = ({ palette }) => ({
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
  px: '10px',
  py: 0,
  textDecoration: 'none',
  backgroundColor: 'transparent',
  boxShadow: 0,
  border: 0,
  height: `${previewCardHeight}px`,
  columnGap: 2,
  alignItems: 'center',
  ':hover': {
    border: `1px solid ${palette.border.darkPurple}`,
    borderLeft: 'none',
    borderRight: 'none',
    boxShadow: ({ palette }) => `0 0 10px ${alpha(palette.common.black, 0.1)}`,
  },
});
