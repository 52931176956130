import { messages } from '@dap-common/i18n';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const useBrandNameSchema = (currentBrandName: string) => {
  const { t } = useTranslation('validation');
  const brandNameSchema = useMemo(
    () =>
      yup
        .object()
        .shape({
          name: yup
            .string()
            .required(t(messages.validation.required))
            .default(currentBrandName || ''),
        })
        .defined(),
    [t, currentBrandName]
  );
  return brandNameSchema;
};

export type BrandNameSchema = yup.InferType<ReturnType<typeof useBrandNameSchema>>;
