import { messages } from '@dap-common/i18n';
import { FunctionComponent, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { cardInputProps } from './cardInputProps';
import { ErpInfo, ErpType, LocationErpInfoSchema } from '@dap-admin/types';
import {
  EditCard,
  InputSelect,
  LabelValue,
  SaveOrCancelEdit,
  TextInput,
  UniversalFormProvider,
} from '@dap-common/ui';
import { Stack } from '@mui/material';
import { pruneObject } from '@shared/utils';

const erpInfoSchema = yup.object().shape({
  brandId: yup.string(),
  fileGroup: yup.string(),
  erpType: yup.string(),
  warehouseId: yup.string(),
});

interface Props {
  erpInfo?: ErpInfo;
  save: (updatedErpInfo: LocationErpInfoSchema) => void;
  saving: boolean;
  isBrandAdmin: boolean;
}

interface FormProps extends Omit<Props, 'isBrandAdmin' | 'save'> {
  cancel: () => void;
  save: (updatedErpInfo: ErpInfo) => void;
}

const ErpInfoForm: FunctionComponent<FormProps> = ({ save, cancel, saving }) => {
  const { t } = useTranslation('pagesHomeLocation');
  const { handleSubmit, control } = useFormContext<ErpInfo>();

  const onSubmit = handleSubmit((erpInfo) => {
    save(erpInfo);
  });

  const erpTypeController = useController({ name: 'erpType', control });

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={1.5}>
        <TextInput
          name="brandId"
          label={t(messages.pagesHomeLocation.erp.brandId)}
          {...cardInputProps}
        />
        <TextInput
          name="fileGroup"
          label={t(messages.pagesHomeLocation.erp.fileGroup)}
          {...cardInputProps}
        />
        <InputSelect
          {...erpTypeController.field}
          placeholder={t(messages.pagesHomeLocation.erp.erpType)}
          label={t(messages.pagesHomeLocation.erp.erpType)}
          menuItems={Object.keys(ErpType).map((erpType) => ({
            name: erpType,
            value: erpType,
          }))}
        />
        <TextInput
          name="warehouseId"
          label={t(messages.pagesHomeLocation.erp.warehouseId)}
          {...cardInputProps}
        />
        <SaveOrCancelEdit save={onSubmit} cancel={cancel} align="right" loading={saving} />
      </Stack>
    </form>
  );
};

const ErpInfoCard: FunctionComponent<Props> = ({ erpInfo, save, saving, isBrandAdmin }) => {
  const { t } = useTranslation('pagesHomeLocation');
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const saveFn = (updated: ErpInfo) => {
    save({ erpInfo: pruneObject(updated, null) });
    setIsEditing(false);
  };

  return (
    <EditCard
      header={t(messages.pagesHomeLocation.erp.name)}
      isEditing={isEditing}
      edit={() => setIsEditing(true)}
      cancel={() => setIsEditing(false)}
      isEditable={isBrandAdmin}
      titleTypographyVariant="h6"
      fullHeight
    >
      {isEditing ? (
        <UniversalFormProvider defaultValues={erpInfo} schema={erpInfoSchema}>
          <ErpInfoForm save={saveFn} cancel={() => setIsEditing(false)} saving={saving} />
        </UniversalFormProvider>
      ) : (
        <Stack spacing={1}>
          <LabelValue
            label={t(messages.pagesHomeLocation.erp.brandId)}
            value={erpInfo?.brandId || '-'}
          />
          <LabelValue
            label={t(messages.pagesHomeLocation.erp.fileGroup)}
            value={erpInfo?.fileGroup || '-'}
          />
          <LabelValue
            label={t(messages.pagesHomeLocation.erp.erpType)}
            value={erpInfo?.erpType || '-'}
          />
          <LabelValue
            label={t(messages.pagesHomeLocation.erp.warehouseId)}
            value={erpInfo?.warehouseId || '-'}
          />
        </Stack>
      )}
    </EditCard>
  );
};

export default ErpInfoCard;
