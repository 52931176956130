import { Card as MuiCard, CardProps, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

interface Props extends CardProps {
  color: string;
  shadow?: string;
}

const StyledCard = styled(MuiCard, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'shadow',
})<Pick<Props, 'color' | 'shadow'>>(({ shadow, color }) => ({
  background: color,
  boxShadow: shadow ? shadow : 'none',
}));

export default function Card({ color, shadow, children, ...rest }: PropsWithChildren<Props>) {
  return (
    <StyledCard color={color} shadow={shadow} {...rest}>
      {children}
    </StyledCard>
  );
}
