import { Typography } from '@mui/material';
import { messages } from '@dap-common/i18n';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BrandConfigCard from '../BrandConfigCard/BrandConfigCard';
import EditBrandNameForm from './EditBrandNameForm';
import { BrandNameSchema, useBrandNameSchema } from './brandNameSchema';
import { UniversalFormProvider } from '@dap-common/ui';

interface Props {
  brandName: string;
  handleSave: (input: BrandNameSchema) => void;
  isUpdating: boolean;
}

export default function BrandNameEditCard({ brandName, handleSave, isUpdating }: Props) {
  const { t } = useTranslation('brandDetailsPage');
  const [isEditing, setIsEditing] = useState(false);
  const brandNameSchema = useBrandNameSchema(brandName);

  const updateName = (input: BrandNameSchema) => {
    handleSave(input);
    setIsEditing(false);
  };

  return (
    <BrandConfigCard
      header={t(messages.brandDetailsPage.brandName.title)}
      isEditing={isEditing}
      handleEdit={() => setIsEditing(true)}
      handleCancelEdit={() => setIsEditing(false)}
      isLoading={isUpdating}
      hasAdminRights={true}
    >
      {isEditing ? (
        <UniversalFormProvider
          schema={brandNameSchema}
          defaultValues={brandNameSchema.getDefault()}
        >
          <EditBrandNameForm onCancel={() => setIsEditing(false)} onSave={updateName} />
        </UniversalFormProvider>
      ) : (
        <Typography variant="body2">{brandName ? brandName : '–'}</Typography>
      )}
    </BrandConfigCard>
  );
}
