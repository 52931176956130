import { AppBasic, AppDetailed } from '@dap-admin/types';
import { mapAppToCompleteApp } from '@dap-admin/utils';
import { messages } from '@dap-common/i18n';
import { AppsList } from '@dap-common/ui';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BrandConfigCard from '../BrandConfigCard/BrandConfigCard';

interface Props {
  currentApps: AppDetailed[];
  availableApps: AppBasic[];
  hasAdminRights: boolean;
  handleEdit: () => void;
}

function BrandApps({ currentApps, availableApps, hasAdminRights, handleEdit }: Props) {
  const { t } = useTranslation('brandDetailsPage');
  const [isEditing, setIsEditing] = useState(false);

  return (
    <BrandConfigCard
      header={t(messages.brandDetailsPage.availableApps.title)}
      isEditing={isEditing}
      handleEdit={handleEdit}
      handleCancelEdit={() => setIsEditing(false)}
      hasAdminRights={hasAdminRights}
    >
      {availableApps && availableApps.length > 0 ? (
        <>
          <Typography variant="body2" gutterBottom>
            {t(messages.brandDetailsPage.availableApps.defaultInfo)}
          </Typography>
          <AppsList apps={currentApps.map(mapAppToCompleteApp)} highlightDefault />
        </>
      ) : (
        <Typography variant="body2">{t(messages.brandDetailsPage.availableApps.empty)}</Typography>
      )}
    </BrandConfigCard>
  );
}

export default BrandApps;
