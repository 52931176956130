import { EmployeeBasicV2, EmployeeOverview, EmployeeDetail, UserLanguage } from '@dap-admin/types';
import {
  EmployeeBasicDTO,
  EmployeeOverviewDTO,
  EmployeeDetailDTO,
  CreateManagedEmployee,
} from '@generated-types';
import {
  appBasicMapper,
  dataownerBasicMapper,
  employeeStatusMapper,
  roleBasicMapper,
} from './mappers';
import { locationBasicMapper } from './locationMappers';
import { brandBasicMapper } from './brandMappers';

export function employeeBasicMapper(employeeBasicDto: EmployeeBasicDTO): EmployeeBasicV2 {
  const employeeBasic: EmployeeBasicV2 = {
    name: employeeBasicDto.name,
    userId: employeeBasicDto.userId,
    email: employeeBasicDto.email ?? null,
    status: employeeStatusMapper(employeeBasicDto.status),
    givenName: employeeBasicDto.givenName ?? null,
    familyName: employeeBasicDto.familyName ?? null,
    phoneNumber: employeeBasicDto.phoneNumber ?? null,
    jobTitle: employeeBasicDto.jobTitle ?? null,
    superuser: employeeBasicDto.superuser,
    apps: appBasicMapper(employeeBasicDto.apps),
    locationNames: employeeBasicDto.locationNames ?? [],
    roles: roleBasicMapper(employeeBasicDto.roles),
    mgEmployee: employeeBasicDto.mgEmployee,
  };

  return employeeBasic;
}

export function employeeOverviewMapper(employeeOverViewDto: EmployeeOverviewDTO): EmployeeOverview {
  const employeeOverview: EmployeeOverview = {
    activeEmployees: employeeOverViewDto.activeEmployees.map(employeeBasicMapper),
    inactiveEmployees: employeeOverViewDto.inactiveEmployees.map(employeeBasicMapper),
  };
  return employeeOverview;
}

export function createEmployeeMapper(createEmployee: CreateManagedEmployee): CreateManagedEmployee {
  const createEmployeeDto: CreateManagedEmployee = {
    email: createEmployee.email,
    familyName: createEmployee.familyName,
    givenName: createEmployee.givenName,
    jobTitle: createEmployee.jobTitle,
    phoneNumber: createEmployee.phoneNumber,
    apps: createEmployee.apps ?? [],
    brands: createEmployee.brands ?? [],
    dataowners: createEmployee.dataowners ?? [],
    locations: createEmployee.locations ?? [],
    roles: createEmployee.roles ?? [],
    emailVerified: false,
    verifyEmail: false,
    passwordResetEmail: false,
  };
  return createEmployeeDto;
}

export function employeeDetailsMapper(employeeDetailsDto: EmployeeDetailDTO): EmployeeDetail {
  const employeeDetail: EmployeeDetail = {
    userId: employeeDetailsDto.userId,
    email: employeeDetailsDto.email ?? '',
    status: employeeDetailsDto.status.map(employeeStatusMapper),
    name: employeeDetailsDto.givenName + ' ' + employeeDetailsDto.familyName ?? '',
    familyName: employeeDetailsDto.familyName ?? '',
    givenName: employeeDetailsDto.givenName ?? '',
    phoneNumber: employeeDetailsDto.phoneNumber ?? null,
    dataowners: employeeDetailsDto.dataowners?.map(dataownerBasicMapper) ?? [],
    locations: employeeDetailsDto.locations
      ? locationBasicMapper(employeeDetailsDto.locations)
      : [],
    brands: employeeDetailsDto.brands.map(brandBasicMapper) ?? [],
    apps: employeeDetailsDto.apps ? appBasicMapper(employeeDetailsDto.apps) : [],
    metadata: {
      verifyEmailSent: employeeDetailsDto.metadata.verifyEmailSent ?? null,
      superuser: employeeDetailsDto.metadata.superuser ?? false,
      preferredLanguage: employeeDetailsDto.metadata.preferredLanguage as UserLanguage,
      createdAt: employeeDetailsDto.metadata.createdAt ?? null,
      updatedAt: employeeDetailsDto.metadata.updatedAt ?? null,
      updatedBy: employeeDetailsDto.metadata.updatedBy ?? null,
      disabledAt: employeeDetailsDto.metadata.disabledAt ?? null,
    },
    jobTitle: employeeDetailsDto.jobTitle ?? null,
    roles: employeeDetailsDto.roles ? roleBasicMapper(employeeDetailsDto.roles) : [],
    mgEmployee: employeeDetailsDto.mgEmployee,
  };

  return employeeDetail;
}
