import { Box, useTheme } from '@mui/material';

interface BlackOverlayProp {
  height: number;
  width: number;
  borderRadius?: string;
  zIndex: number;
  fullHeight?: boolean;
}

export default function BlackOverlay({
  height,
  width,
  borderRadius = '7px',
  zIndex,
  fullHeight = true,
}: BlackOverlayProp) {
  const { shadows } = useTheme();

  const hoverStyle = {
    ':hover': {
      boxShadow: shadows[1],
    },
  };

  return (
    <Box
      position="absolute"
      zIndex={zIndex}
      height={height}
      width={width}
      top={0}
      borderRadius={borderRadius}
      style={{
        background: fullHeight
          ? 'linear-gradient(180deg, rgba(35, 35, 35, 0) 0%, rgba(0, 0, 0, 0.6) 100%)'
          : 'linear-gradient(180deg, rgba(35, 35, 35, 0) 55%, rgba(0, 0, 0, 0.85) 80%)',
      }}
      sx={hoverStyle}
    ></Box>
  );
}
