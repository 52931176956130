import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum DialogType {
  MISSING_BRAND_CONFIGURATION = 'MISSING_BRAND_CONFIGURATION',
  NEW_LOCATION = 'NEW_LOCATION',
  NEW_DATAOWNER = 'NEW_DATAOWNER',
  NEW_EMPLOYEE = 'NEW_EMPLOYEE',
}

export interface DialogState {
  isOpen: boolean;
  dialog?: DialogType;
}

const dialogInitialState: DialogState = {
  isOpen: false,
  dialog: undefined,
};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState: dialogInitialState,
  reducers: {
    openDialog: (state, action: PayloadAction<DialogType>) => {
      state.isOpen = true;
      state.dialog = action.payload;
    },
    closeDialog: (state) => {
      state.isOpen = false;
      state.dialog = undefined;
    },
  },
});

export const { openDialog, closeDialog } = dialogSlice.actions;

export default dialogSlice.reducer;
