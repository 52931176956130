import { useAppTFunction } from '@dap-common/i18n';
import { useMemo } from 'react';
import * as yup from 'yup';
import yupSchema from './schemas';
import { Validations } from '@dap-common/utils';
import { AppBasic } from '@dap-admin/types';

export const useLocationSchema = () => {
  const tApp = useAppTFunction();
  const basicLocationSchema = useBasicLocationSchema();
  const addressSchema = useLocationAddressSchema();

  const schema = useMemo(
    () =>
      yup
        .object({
          gln: yup
            .string()
            .default('')
            .test('nonOrLength13', (value, { createError }) => {
              if (!value) {
                return true;
              } else if (value.length !== 13) {
                return createError({ message: tApp('validation.gln.format') });
              }
              return true;
            }),

          apps: yup.array(yup.string().default('')).default([]),
        })
        .concat(basicLocationSchema)
        .concat(addressSchema),
    [addressSchema, basicLocationSchema, tApp]
  );

  return yup.object({ location: schema });
};

export const useBasicLocationSchema = () => {
  const tApp = useAppTFunction();

  const schema = useMemo(
    () =>
      yup.object({
        ...yupSchema.name(tApp),
        regionKey: yup.string().required(tApp('validation.required')).default(''),
      }),
    [tApp]
  );

  return schema;
};

export const useLocationAddressSchema = () => {
  const tApp = useAppTFunction();

  const schema = useMemo(
    () =>
      yup.object({
        address: yup.object(yupSchema.address(true, tApp)),
        addressAndPostalAddressEqual: yup.boolean().default(true),
        postalAddress: yup
          .object(yupSchema.address(false, tApp))
          .when('addressAndPostalAddressEqual', {
            is: false,
            then: yup.object(yupSchema.address(true, tApp)),
          }),
      }),
    [tApp]
  );

  return schema;
};

export const useLocationDetailsSchema = () => {
  const tApp = useAppTFunction();
  const basicLocationSchema = useBasicLocationSchema();

  const schema = useMemo(
    () =>
      yup
        .object({
          ...yupSchema.glns(tApp),
          contact: yup.object({
            email: yup.string().email().default(''),
            phone: Validations.phone(tApp('validation.phone.format')),
            homepage: yup.string().default(''),
          }),
        })
        .concat(basicLocationSchema),
    [basicLocationSchema, tApp]
  );

  return schema;
};

export const useLocationServicesSchema = (services: Array<AppBasic>) => {
  const schema = useMemo(
    () =>
      yup.object({
        apps: yup.array(yup.string().default('')).default(services.map(({ key }) => key)),
      }),
    [services]
  );

  return schema;
};

export const useLocationEditSchema = (services: Array<AppBasic>) => {
  const tApp = useAppTFunction();

  return yup.object({
    details: useLocationDetailsSchema(),
    address: useLocationAddressSchema(),
    services: useLocationServicesSchema(services),
    coordinates: yup
      .object({
        lat: yup.number().typeError(tApp('validation.coordinates.typeError')).default(undefined),
        long: yup.number().typeError(tApp('validation.coordinates.typeError')).default(undefined),
      })
      .nullable(),
  });
};

export type LocationSchema = yup.InferType<ReturnType<typeof useLocationSchema>>;
export type LocationEditSchema = yup.InferType<ReturnType<typeof useLocationEditSchema>>;
