import { useAppTFunction } from '@dap-common/i18n';
import { Collapse, Stack, Typography } from '@mui/material';
import { ContainedButton, Paper, TextButton } from '@shared/components';
import { LoadingErrorComponent } from '../Wrappers';
import { CustomError, QueryError } from '@dap-admin/types';
import { isCustomError } from '@dap-common/utils';
import { CenterElement } from '../Layout';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { ChevronLeft, NoAccountsOutlined } from '@mui/icons-material';
import { useDisclosure } from '@shared/hooks';
import { useNavigate } from 'react-router-dom';
import React from 'react';

interface Props {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  actionButtonLabel?: string;
  onActionButtonClick?: () => void;
  errorMessage: string;
}

const ErrorTemplate = ({
  icon,
  title,
  subtitle,
  actionButtonLabel,
  onActionButtonClick,
  errorMessage,
}: Props) => {
  const t = useAppTFunction();
  const showMore = useDisclosure();

  return (
    <CenterElement flexDirection={'column'}>
      <Stack
        height="128px"
        width="128px"
        borderRadius="50%"
        bgcolor={({ palette }) => palette.warning[100]}
        alignItems="center"
        justifyContent="center"
        fontSize="48px"
        marginBottom={4}
        sx={{ '> svg': { fontSize: '64px', color: ({ palette }) => palette.warning[800] } }}
      >
        {icon}
      </Stack>

      <Typography variant="h1" gutterBottom>
        {title}
      </Typography>

      <Typography paragraph>{subtitle}</Typography>

      {actionButtonLabel && onActionButtonClick && (
        <Stack my={2}>
          <ContainedButton onClick={onActionButtonClick}>{actionButtonLabel}</ContainedButton>
        </Stack>
      )}

      <Stack my={2}>
        <TextButton
          size="small"
          onClick={() => showMore.onToggle()}
          endIcon={
            <ChevronLeft
              sx={{
                transform: showMore.isOpen ? 'rotate(90deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s ease-in-out',
              }}
            />
          }
        >
          {t('order.order.showMore')}
        </TextButton>
      </Stack>

      <Collapse in={showMore.isOpen}>
        <Paper sx={{ py: 3, px: 4, backgroundColor: ({ palette }) => palette.primary.A100 }}>
          <Typography variant="body2">{errorMessage}</Typography>
        </Paper>
      </Collapse>
    </CenterElement>
  );
};

const CustomErrorPage: LoadingErrorComponent<CustomError | QueryError> = ({ error }) => {
  const t = useAppTFunction();
  const navigate = useNavigate();

  if (error?.status === 403) {
    return (
      <Paper sx={{ height: '100%' }}>
        <ErrorTemplate
          icon={<NoAccountsOutlined />}
          title={t('common.error.forbidden.title')}
          subtitle={t('common.error.forbidden.description')}
          onActionButtonClick={() => navigate('/')}
          errorMessage={`${error.status} ${isCustomError(error) ? error.data.message : ''}`}
        />
      </Paper>
    );
  }

  if (error?.status === 404) {
    return (
      <Paper sx={{ height: '100%' }}>
        <ErrorTemplate
          icon={<PriorityHighIcon />}
          title={t('error.notFoundPage.title')}
          subtitle={t('error.notFoundPage.subtitle')}
          actionButtonLabel={t('error.notFoundPage.goHome')}
          onActionButtonClick={() => navigate('/')}
          errorMessage={`${error.status} ${isCustomError(error) ? error.data.message : ''}`}
        />
      </Paper>
    );
  }

  return (
    <Paper sx={{ height: '100%' }}>
      <ErrorTemplate
        icon={<PriorityHighIcon />}
        title={t('error.generic')}
        subtitle={t('error.contact')}
        onActionButtonClick={() => navigate('/')}
        errorMessage={`${error?.status} ${isCustomError(error) ? error.data.message : ''}`}
      />
    </Paper>
  );
};

export default CustomErrorPage;
