import * as yup from 'yup';
import { useController, useFormContext } from 'react-hook-form';
import { CheckboxWithLabel, ErrorCard, SaveOrCancelEdit } from '@dap-common/ui';

export const singleLocationFieldConfigSchema = yup.object().shape({
  show: yup.boolean(),
});

export interface SingleLocationFieldConfigSchema {
  show: boolean;
}

interface Props {
  cancelEdit: () => void;
  save: (schema: SingleLocationFieldConfigSchema) => void;
  label: string;
  loading?: boolean;
  error?: boolean;
}

function SingleLocationFieldConfigForm({ save, label, cancelEdit, loading = false, error }: Props) {
  const { handleSubmit, control } = useFormContext<SingleLocationFieldConfigSchema>();
  const onSubmit = handleSubmit(save);

  const showController = useController({
    name: 'show',
    control,
  });

  return (
    <form onSubmit={onSubmit}>
      <CheckboxWithLabel {...showController.field} label={label} disabled={loading} margin="none" />
      <SaveOrCancelEdit save={onSubmit} cancel={cancelEdit} align="right" />
      {error && <ErrorCard />}
    </form>
  );
}

export default SingleLocationFieldConfigForm;
