import { useBrandadminTFunction } from '@dap-admin/i18n';
import { EmployeeBasicV2, EmployeeDetail } from '@dap-admin/types';
import { Avatar, Chip, LimitedList } from '@shared/components';
import { appLogos } from '@assets/appLogos';

interface Props {
  apps: EmployeeBasicV2['apps'] | EmployeeDetail['apps'];
  max?: number;
  itemWidth?: string;
  size?: 'small' | 'medium';
}

export default function AppsList({ apps, max = 1, itemWidth = 'auto', size = 'small' }: Props) {
  const tAdmin = useBrandadminTFunction();

  if (!apps) return null;

  return (
    <LimitedList
      title={tAdmin('employee.filter.app')}
      items={apps.map(({ key, name }) => (
        <Chip
          avatar={<Avatar name={name} imgSrc={appLogos[key]} />}
          key={key}
          label={name}
          size={size}
          rounded
        />
      ))}
      max={max}
      itemWidth={itemWidth}
      disablePadding
      dense
      row
    />
  );
}
