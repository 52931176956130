import { useAppTFunction } from '@dap-common/i18n';
import { SaveOrCancelEdit } from '@dap-common/ui';
import { Stack } from '@mui/material';
import { TextInputField } from '@shared/components';
import { useController, useFormContext } from 'react-hook-form';
import { RegionSchema } from './RegionSchema';

interface Props {
  save: (regions: RegionSchema) => void;
  cancel: () => void;
  inCreateMode: boolean;
}

function RegionsForm({ save, cancel, inCreateMode }: Props) {
  const {
    handleSubmit,
    formState: { isDirty },
    control,
  } = useFormContext<RegionSchema>();
  const t = useAppTFunction();

  const keyController = useController({ name: 'key', control });
  const nameController = useController({ name: 'name', control });

  return (
    <>
      <Stack spacing={1.5}>
        {inCreateMode && (
          <TextInputField
            {...keyController.field}
            error={keyController.fieldState.error?.message}
            label={t('brandDetailsPage.regions.form.key')}
            required
            fullWidth
          />
        )}
        <TextInputField
          {...nameController.field}
          error={nameController.fieldState.error?.message}
          label={t('brandDetailsPage.regions.form.name')}
          required
          fullWidth
        />
      </Stack>

      <SaveOrCancelEdit
        save={handleSubmit(save)}
        saveText={inCreateMode ? t('brandDetailsPage.regions.create') : undefined}
        cancel={cancel}
        disabled={!isDirty}
        align="right"
      />
    </>
  );
}

export default RegionsForm;
