import { BrandadminTFunction } from '@dap-admin/i18n';
import { LocationOverview } from '@dap-admin/types';
import { Theme } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { Chip } from '@shared/components';
import { FilterOperatorSelect } from '../components';

export const locationColumnDefs = (
  theme: Theme,
  tBrandadmin: BrandadminTFunction
): Array<GridColDef> => [
  {
    field: 'name',
    headerName: tBrandadmin('brandadmin.orgView.locationHeaders.location'),
    minWidth: 300,
    hideable: false,
    disableReorder: true,
  },
  {
    field: 'dataownerName',
    headerName: tBrandadmin('brandadmin.orgView.locationHeaders.dataowner'),
    minWidth: 300,
  },
  {
    field: 'countTotalEmployees',
    headerName: tBrandadmin('brandadmin.orgView.locationHeaders.employees'),
  },
  {
    field: 'regionName',
    headerName: tBrandadmin('brandadmin.orgView.locationHeaders.region'),
    minWidth: 200,
  },
  {
    field: 'enabled',
    headerName: tBrandadmin('brandadmin.orgView.locationHeaders.status'),
    minWidth: 200,
    renderCell: ({ row: location }: GridRenderCellParams<LocationOverview>) =>
      location.enabled ? (
        <Chip label={tBrandadmin('brandadmin.filter.status.active')} />
      ) : (
        <Chip
          label={tBrandadmin('brandadmin.filter.status.deactivated')}
          backgroundColor={theme.palette.warning.main}
        />
      ),
    filterOperators: [
      {
        getApplyFilterFn: (filterItem) => {
          if (
            !filterItem.columnField ||
            !filterItem.operatorValue ||
            filterItem.value === undefined // initially the filter value is set to undefined / user has not selected anything yet. If so, must return null to show all items
          ) {
            return null;
          }
          return ({ value }) => {
            // Convert value to boolean and check if it matches the filter criteria
            return Boolean(value) === filterItem.value;
          };
        },
        value: 'is',
        InputComponent: FilterOperatorSelect,
        InputComponentProps: { type: 'boolean' },
      },
    ],
  },
  {
    field: 'street',
    headerName: tBrandadmin('brandadmin.orgView.locationHeaders.street'),
    minWidth: 350,
    valueGetter: ({ row }: GridValueGetterParams<LocationOverview>) =>
      row['contactInfo'].address.street,
  },
  {
    field: 'postalCode',
    headerName: tBrandadmin('brandadmin.orgView.locationHeaders.postalCode'),
    minWidth: 150,
    valueGetter: ({ row }: GridValueGetterParams<LocationOverview>) =>
      row['contactInfo'].address.postalCode,
  },
  {
    field: 'city',
    headerName: tBrandadmin('brandadmin.orgView.locationHeaders.city'),
    minWidth: 150,
    valueGetter: ({ row }: GridValueGetterParams<LocationOverview>) =>
      row['contactInfo'].address.city,
  },
  {
    field: 'country',
    headerName: tBrandadmin('brandadmin.orgView.locationHeaders.country'),
    minWidth: 150,
    valueGetter: ({ row }: GridValueGetterParams<LocationOverview>) =>
      row['contactInfo'].address.country,
  },
  {
    field: 'contactEmail',
    headerName: tBrandadmin('brandadmin.orgView.locationHeaders.contactEmail'),
    minWidth: 250,
    valueGetter: ({ row }: GridValueGetterParams<LocationOverview>) =>
      row['contactInfo'].contact.email,
  },
  {
    field: 'contactPhone',
    headerName: tBrandadmin('brandadmin.orgView.locationHeaders.contactPhone'),
    minWidth: 150,
    valueGetter: ({ row }: GridValueGetterParams<LocationOverview>) =>
      row['contactInfo'].contact.phone,
  },
  {
    field: 'contactHomepage',
    headerName: tBrandadmin('brandadmin.orgView.locationHeaders.contactHomepage'),
    minWidth: 250,
    valueGetter: ({ row }: GridValueGetterParams<LocationOverview>) =>
      row['contactInfo'].contact.homepage,
  },
];
