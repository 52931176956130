import { appLogos } from '@assets/appLogos';
import { LocationEditSchema } from '@dap-admin/schemas';
import { AppBasic } from '@dap-admin/types';
import { getIfDefaultApp } from '@dap-admin/utils';
import { useAppTFunction } from '@dap-common/i18n';
import { FlexRow, useCheckboxGroup } from '@dap-common/ui';
import {
  Checkbox,
  FormControl,
  FormGroup,
  List,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { Avatar, InfoTooltip } from '@shared/components';
import { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { OutlinedListItem } from '../../List';

interface Props {
  availableServices: Array<AppBasic>;
}

export default function EditServices({ availableServices }: Props) {
  const t = useAppTFunction();

  const { control } = useFormContext<LocationEditSchema>();
  const { field: appsControllerField } = useController({
    name: 'services.apps',
    control,
  });

  const defaultServices = useMemo(
    () => availableServices.filter((service) => getIfDefaultApp(service)),
    [availableServices]
  );

  const filteredServices = useMemo(
    () => availableServices.filter((service) => !getIfDefaultApp(service)),
    [availableServices]
  );

  const { handleChange } = useCheckboxGroup({
    onChange: appsControllerField.onChange,
    value: appsControllerField.value,
  });

  const renderCheckbox = (service: AppBasic) => {
    return (
      <OutlinedListItem
        key={service.key}
        marginBottom="none"
        borderRadius={0}
        secondaryAction={
          <Checkbox
            edge={'end'}
            checked={appsControllerField.value.includes(service.key)}
            value={service.key}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
              handleChange(event, checked, availableServices, false)
            }
            onBlur={appsControllerField.onBlur}
          />
        }
      >
        <ListItemIcon>
          <Avatar
            name={service.name}
            imgSrc={appLogos[service.key]}
            sx={{ width: 25, height: 25 }}
          />
        </ListItemIcon>
        <ListItemText>{service.name}</ListItemText>
      </OutlinedListItem>
    );
  };

  return (
    <FormControl component={'fieldset'} fullWidth>
      <FormGroup>
        <Stack mt={2} mb={4}>
          <Typography variant="body1" fontWeight={700}>
            {t('location.detail.services.availableTitle')}
          </Typography>
          {filteredServices.length > 0 ? (
            <List>{filteredServices.map((service) => renderCheckbox(service))}</List>
          ) : (
            <Typography>{t('brandadmin.services.empty')}</Typography>
          )}
        </Stack>

        <Stack>
          <FlexRow alignItems={'center'}>
            <Typography variant="body1" fontWeight={700}>
              {t('location.detail.services.default.title')}
            </Typography>
            <InfoTooltip tooltipText={t('location.detail.services.default.description')} />
          </FlexRow>
          {defaultServices.length > 0 ? (
            <List>{defaultServices.map((service) => renderCheckbox(service))}</List>
          ) : (
            <Typography>{t('brandadmin.services.empty')}</Typography>
          )}
        </Stack>
      </FormGroup>
    </FormControl>
  );
}
