import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { NonBrandNamespaces } from '../resources';
import { Format, formatMap } from '../utils/formatting';

export const fallbackNS = 'default';

type TWithOptionsFunction = (
  translationKey: string,
  brandOptions: Record<string, string>,
  format?: Format
) => string;

const nsWithFallback = (ns: string, translationKey: string) => [
  `${ns}:${translationKey}`,
  `${fallbackNS}:${translationKey}`,
];

const tWithBrandOptions: (t: TFunction, brandNS: string) => TWithOptionsFunction =
  (t, brandNS) => (translationKey, brandOptions, format) => {
    const optionsWithFallback = Object.entries(brandOptions)
      .map(([key, val]) => ({
        [key]: t(nsWithFallback(brandNS, val)),
      }))
      .reduce((tmp, option) => ({ ...tmp, ...option }), {});

    const text = t(translationKey, optionsWithFallback);
    return format ? formatMap[format](text) : text;
  };

export const useTranslationWithBrandOptions = (ns: NonBrandNamespaces, brandNameSpace: string) => {
  const translation = useTranslation([ns, brandNameSpace]);
  const { t } = translation;

  return { ...translation, t: tWithBrandOptions(t, brandNameSpace) };
};

export const useBrandTranslation = (brandNameSpace: string) => {
  const translation = useTranslation(brandNameSpace);

  const brandT = (translationKey: string, format?: Format) => {
    const text = translation.t(nsWithFallback(brandNameSpace, translationKey));

    return format ? formatMap[format](text) : text;
  };

  return { ...translation, t: brandT };
};
