import {
  alpha,
  Stack,
  styled,
  SxProps,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Category } from '@dap-sanity/types';
import { useSanityContextActions } from '@dap-sanity/utils';

interface Props {
  tabs: Array<Category>;
  tabId: string;
  tabPanelId: string;
  arialLabel: string;
  tabsSx?: SxProps;
  tabSx?: SxProps;
  fremdriftUrl: string;
  currentTab: string;
}

const a11yProps = (index: number, tabId: string, tabPanelId: string) => ({
  id: `${tabId}-tab-${index}`,
  'aria-controls': `${tabPanelId}-${index}`,
});

export default function FremdriftTabsComponent({
  tabs,
  tabId,
  tabPanelId,
  arialLabel,
  tabsSx,
  tabSx,
  fremdriftUrl,
  currentTab,
}: Props) {
  const { breakpoints } = useTheme();
  const isXs = useMediaQuery(breakpoints.only('xs'));
  const { getImageUrlBuilder } = useSanityContextActions();

  const tabValue = (category: string, index: number) => {
    return index === 0 ? fremdriftUrl : `${fremdriftUrl}/${category}`;
  };

  return (
    <StyledTabs
      value={currentTab}
      arial-label={arialLabel}
      TabIndicatorProps={{
        style: {
          display: 'none',
        },
      }}
      variant={isXs ? 'scrollable' : 'fullWidth'}
      allowScrollButtonsMobile
      sx={tabsSx ? tabsSx : undefined}
    >
      {tabs.map((tab, index) => (
        <StyledTab
          {...a11yProps(index, tabId, tabPanelId)}
          label={
            <Stack direction="row" spacing={2}>
              {tab.image && (
                <img
                  src={getImageUrlBuilder(tab.image)
                    .height(10 * 2)
                    .url()}
                  alt=""
                />
              )}
              <Typography variant="h3" style={{ marginLeft: '7px' }}>
                {tab.title}
              </Typography>
            </Stack>
          }
          aria-label={tab.title}
          sx={tabSx ? tabSx : undefined}
          wrapped
          key={tabPanelId + `-${tab.title}`}
          component={Link}
          to={tabValue(tab.slug.toLowerCase(), index)}
          value={tabValue(tab.slug.toLowerCase(), index)}
        />
      ))}
    </StyledTabs>
  );
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    height: '100%',
    justifyContent: 'space-between',
  },
  '& .MuiTabScrollButton-root': {
    borderBottom: `1px solid ${alpha(theme.palette.primary.dark, 0.1)}`,
    '&.Mui-disabled': {
      opacity: 0.3,
    },
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  padding: '0.75rem',
  fontSize: '18px',
  '&.MuiButtonBase-root': {
    alignItems: 'center',
    borderBottom: `1px solid ${alpha(theme.palette.primary.dark, 0.1)}`,
    borderRadius: '8px 8px 0 0',
    '&.Mui-selected': {
      backgroundColor: theme.palette.orange[50],
      border: `1px solid ${alpha(theme.palette.primary.dark, 0.1)}`,
      borderBottom: 'none',
      padding: 'calc(0.75rem - 1px)',
      paddingBottom: 'calc(0.75rem + 1px)',
    },
  },
})) as typeof Tab;
