import { useBrandTFunction } from '@dap-admin/i18n';
import { Location, RegionV2 } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { FieldArray, FieldArrayItem, SelectInputField, TextInputField } from '@shared/components';
import { Controller, useController, useFieldArray, useFormContext } from 'react-hook-form';
import { LocationEditSchema } from '@dap-admin/schemas';
import { Typography } from '@mui/material';

interface Props {
  location: Location;
  availableRegions: RegionV2[];
  isDataownerAdmin: boolean;
}

export default function EditDetailsTab({ location, availableRegions, isDataownerAdmin }: Props) {
  const t = useAppTFunction();
  const tBrand = useBrandTFunction(location.dataowner.brand.key);

  const { control } = useFormContext<LocationEditSchema>();
  const nameController = useController({ name: 'details.name', control });
  const regionKeyController = useController({ name: 'details.regionKey', control });
  const emailController = useController({ name: 'details.contact.email', control });
  const phoneController = useController({ name: 'details.contact.phone', control });
  const homepageController = useController({ name: 'details.contact.homepage', control });
  const glnsController = useController({ name: 'details.glns', control });

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'details.glns',
  });

  return (
    <>
      <TextInputField
        {...nameController.field}
        error={nameController.fieldState.error?.message}
        required
        label={t('location.detail.nameOfLocation', {
          location: tBrand('brand.location.name.singular'),
        })}
        size="medium"
        fullWidth
      />

      <SelectInputField
        {...regionKeyController.field}
        label={t('location.region.name')}
        menuItems={availableRegions.map((region) => ({ value: region.key, name: region.name }))}
        error={regionKeyController.fieldState.error?.message}
        required
        fullWidth
        noOptionsText={t('location.region.empty')}
      />

      {isDataownerAdmin && (
        <FieldArray
          onAdd={() => {
            append({ id: '' });
          }}
          label={t('location.detail.gln')}
          addButtonLabel={t('common.add')}
          name={glnsController.field.name}
          margin="normal"
          fullWidth
          error={glnsController.fieldState.error?.message}
        >
          {fields.map((field, index) => (
            <FieldArrayItem
              key={field.id}
              onRemove={() => remove(index)}
              removeButtonLabel={t('common.remove')}
              inputSize="medium"
              inputMargin="none"
            >
              <Controller
                name={`details.glns.${index}.id`}
                control={control}
                render={(props) => (
                  <TextInputField
                    label={''}
                    {...props.field}
                    size="medium"
                    error={props.fieldState.error?.message}
                    margin="none"
                    fullWidth
                  />
                )}
              />
            </FieldArrayItem>
          ))}
        </FieldArray>
      )}

      <Typography variant="h5" mt={2}>
        {t('location.detail.contactInfo')}
      </Typography>
      <TextInputField
        {...emailController.field}
        error={emailController.fieldState.error?.message}
        label={t('location.detail.email')}
        fullWidth
      />
      <TextInputField
        {...phoneController.field}
        error={phoneController.fieldState.error?.message}
        label={t('location.detail.phone')}
        fullWidth
      />
      <TextInputField
        {...homepageController.field}
        error={homepageController.fieldState.error?.message}
        label={t('location.detail.homepage')}
        fullWidth
      />
    </>
  );
}
