import { InformationModelType, Role, UserData } from '@dap-admin/types';
import { useEmployeeRouteOutletContext } from '@dap-admin/utils';
import { getDataownerRoute, getLocationRoute } from '@dap-common/consts';
import { messages, useBrandTranslation } from '@dap-common/i18n';
import { brandStylesConfig } from '@dap-common/styles';
import { RecognizedBrands } from '@dap-common/types';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { Chip, LabelValueTable, LimitedList } from '@shared/components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BrandButton } from '../Brand';
import { AppsList } from '../List';
import { mapOrgUnitRolesToOldRoles } from '@dap-common/utils';

interface Props {
  brandKey: string;
  selectBrand: (brandKey: string) => void;
  isSuperuser: boolean;
  userBrands: UserData['brands'];
}

export default function EmployeeRelations({
  brandKey,
  selectBrand,
  isSuperuser,
  userBrands,
}: Props) {
  const { t } = useTranslation(['employeePage']);
  const { selectedEmployee: employeeData } = useEmployeeRouteOutletContext();
  const { t: tBrand } = useBrandTranslation(brandKey);

  const getLocationUrl = (locationKey: string) => {
    return getLocationRoute({ locationKey });
  };

  const getDataownerUrl = (dataownerKey: string) => {
    if (brandKey) {
      return getDataownerRoute({ dataownerKey });
    }
    return '';
  };

  const { roles } = employeeData;
  const brandEmployements = employeeData.brands || [];
  const locationEmployments = useMemo(
    () =>
      employeeData.locations?.filter((location) =>
        employeeData.dataowners?.map((dataowner) => dataowner.key).includes(location.dataownerKey)
      ) || [],
    [employeeData.dataowners, employeeData.locations]
  );

  const dataownerEmployments = useMemo(
    () => employeeData.dataowners?.filter((dataowner) => dataowner.brand.key === brandKey) || [],
    [brandKey, employeeData.dataowners]
  );

  const locationRoles = mapOrgUnitRolesToOldRoles(roles || []).filter(
    (role) =>
      role['@type'] === InformationModelType.Location &&
      locationEmployments.map(({ key }) => key).includes(role.key)
  );

  const dataownerRoles = mapOrgUnitRolesToOldRoles(roles || [])?.filter(
    (role) =>
      role['@type'] === InformationModelType.Dataowner &&
      dataownerEmployments.map(({ key }) => key).includes(role.key)
  );

  const brandRoles = mapOrgUnitRolesToOldRoles(roles || []).filter(
    (role) =>
      role['@type'] === InformationModelType.Brand &&
      brandEmployements.map(({ key }) => key).includes(role.key)
  );

  return (
    <Stack gap={3} divider={<Divider />}>
      <Stack>
        <Typography variant="h6" gutterBottom>
          {t(messages.employeePage.apps.title)}
        </Typography>
        {employeeData.apps?.length ? (
          <AppsList apps={employeeData.apps} max={employeeData.apps.length} size="medium" />
        ) : (
          <Typography variant="body1">{t(messages.employeePage.apps.noApps)}</Typography>
        )}
      </Stack>

      <Stack>
        <Typography variant="h6" gutterBottom>
          {tBrand(messages.brand.location.name.plural, 'capitalizeFirstLetter')}
        </Typography>

        {locationEmployments.length ? (
          <LabelValueTable
            items={locationEmployments.map(({ key, name }) => ({
              label: (
                <Button
                  component={Link}
                  to={getLocationUrl(key)}
                  role="link"
                  key={key}
                  variant="outlined"
                  color="grey"
                  sx={{
                    minWidth: '6rem',
                    height: '3rem',
                  }}
                >
                  <Typography variant="body1" fontWeight="500" noWrap>
                    {name}
                  </Typography>
                </Button>
              ),
              value: renderRoleChips(
                locationRoles.filter(({ key: orgUnitKey }) => orgUnitKey === key),
                t(messages.employeePage.roles.title)
              ),
            }))}
          />
        ) : (
          <Typography variant="body1">{t(messages.employeePage.employment.noRelations)}</Typography>
        )}
      </Stack>

      <Stack>
        <Typography variant="h6" gutterBottom>
          {tBrand(messages.brand.dataowner.name.plural, 'capitalizeFirstLetter')}
        </Typography>

        {dataownerEmployments.length ? (
          <LabelValueTable
            items={dataownerEmployments.map(({ key, name }) => ({
              label: (
                <Button
                  component={Link}
                  to={getDataownerUrl(key)}
                  role="link"
                  key={key}
                  variant="outlined"
                  color="grey"
                  sx={{
                    minWidth: '6rem',
                    height: '3rem',
                  }}
                >
                  <Typography variant="body1" fontWeight="500" noWrap>
                    {name}
                  </Typography>
                </Button>
              ),
              value: renderRoleChips(
                dataownerRoles.filter(({ key: orgUnitKey }) => orgUnitKey === key),
                t(messages.employeePage.roles.title)
              ),
            }))}
          />
        ) : (
          <Typography variant="body1">{t(messages.employeePage.employment.noRelations)}</Typography>
        )}
      </Stack>

      <Stack>
        <Typography variant="h6" gutterBottom>
          {t(messages.employeePage.employment.brandEmployments)}
        </Typography>

        {brandEmployements.length ? (
          <LabelValueTable
            items={brandEmployements.map(({ key, name }) => ({
              label: (
                <BrandButton
                  onClick={() => selectBrand(key)}
                  brandStyle={
                    brandStylesConfig[key as RecognizedBrands] || brandStylesConfig.default
                  }
                  key={key}
                  disabled={!isSuperuser && !userBrands?.map(({ key }) => key)?.includes(key)}
                >
                  {name}
                </BrandButton>
              ),
              value: renderRoleChips(
                brandRoles.filter(({ key: orgUnitKey }) => orgUnitKey === key),
                t(messages.employeePage.roles.title)
              ),
            }))}
          />
        ) : (
          <Typography variant="body1">{t(messages.employeePage.employment.noRelations)}</Typography>
        )}
      </Stack>
    </Stack>
  );
}

const renderRoleChips = (roles: Role[], title: string) => {
  return (
    <div style={{ display: 'inline-block' }}>
      <LimitedList
        row
        max={roles.length}
        title={title}
        items={roles.map((role) => (
          <Chip key={role.key + '_' + role.genericTitle} label={role.genericTitle} />
        ))}
      />
    </div>
  );
};
