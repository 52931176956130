import { MapIllustration } from '@assets/images';
import { useBrandadminTFunction } from '@dap-admin/i18n';
import { Grid, Typography } from '@mui/material';
import { Paper } from '@shared/components';

export default function WelcomeBlock() {
  const t = useBrandadminTFunction();

  return (
    <Paper sx={{ height: '100%' }}>
      <Grid
        container
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={'space-between'}
        spacing={{ xs: 2, md: 1 }}
        textAlign={{ xs: 'center', md: 'left' }}
      >
        <Grid item md={9} order={{ xs: 2, md: 1 }}>
          <Typography paddingBottom={{ xs: 0, md: 2 }} variant="h5">
            {t('brandadmin.welcome.title')}
          </Typography>

          <Typography component="p" variant="h6" fontWeight={500} color="text.secondary">
            {t('brandadmin.welcome.description')}
          </Typography>
        </Grid>
        <Grid
          item
          md={'auto'}
          display={'flex'}
          justifyContent={{ xs: 'center', md: 'flex-end' }}
          order={{ xs: 1, md: 2 }}
        >
          <img src={MapIllustration} height="120" alt="" />
        </Grid>
      </Grid>
    </Paper>
  );
}
