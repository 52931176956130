import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  SxProps,
} from '@mui/material';
import { forwardRef } from 'react';

interface Props
  extends Pick<
    CheckboxProps,
    'onChange' | 'onBlur' | 'name' | 'value' | 'color' | 'checked' | 'disabled'
  > {
  error?: string;
  label: string;
  required?: boolean;
  margin?: 'normal' | 'dense' | 'none';
  fullWidth?: boolean;
}

const CheckboxWithLabel = forwardRef(
  (
    {
      name,
      label,
      error,
      required,
      margin = 'normal',
      fullWidth,
      onBlur,
      onChange,
      value,
      color = 'default',
      checked,
      disabled,
    }: Props,
    ref
  ) => {
    const inputId = `${name}-id`;
    const formLabelId = label && `${name}-label`;
    const helperTextId = error && `${name}-helperText`;

    return (
      <FormControl fullWidth={fullWidth} margin={margin} error={!!error} required={required}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              name={name}
              checked={checked || !!value}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              id={inputId}
              color={color}
              disabled={disabled}
              required={required}
              style={{ marginLeft: '2px' }} // Fix for negative margins when used with FormControlLabel
            />
          }
          label={
            <FormLabel sx={labelSx} htmlFor={inputId} id={formLabelId}>
              {label}
            </FormLabel>
          }
          componentsProps={{ typography: { variant: 'body2' } }}
          ref={ref}
          id={formLabelId}
        />
        {error && (
          <FormHelperText id={helperTextId} sx={formHelperTextSx}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default CheckboxWithLabel;

const labelSx: SxProps = {
  '&.MuiFormLabel-root': {
    typography: 'body2',
  },
};

const formHelperTextSx: SxProps = {
  m: 0,
  typography: 'body3',
};
