import { styled, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

export default function EntityChart({ children }: PropsWithChildren) {
  return (
    <Stack
      data-testid="entity-chart"
      alignItems={'center'}
      divider={
        <Connector>
          <ConnectorLine />
        </Connector>
      }
    >
      {children}
    </Stack>
  );
}

const Connector = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
});

const ConnectorLine = styled('span')(({ theme }) => ({
  borderLeft: `3px solid ${theme.palette.grey[200]}`,
  minHeight: '24px',
}));
