import { BrandBasic } from '@dap-admin/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { alpha, IconButton, Menu, MenuItem, SxProps, Theme } from '@mui/material';
import { useState } from 'react';

interface Props {
  availableBrands: BrandBasic[];
  selectBrand: (brandKey: string) => void;
  brandKey: string;
}

function BrandSelectorMenu({ availableBrands, selectBrand, brandKey }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleSelect = (value: string) => {
    selectBrand(value);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        color="inherit"
        size="small"
        edge="end"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <ExpandMoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={menuSx}
      >
        {availableBrands.map((brand) => (
          <MenuItem
            key={'brand-select-' + brand.key}
            value={brand.key}
            onClick={() => handleSelect(brand.key)}
            selected={brand.key === brandKey}
          >
            {brand.name.toUpperCase() || brand.key.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default BrandSelectorMenu;

const menuSx: SxProps<Theme> = (theme) => ({
  '& .MuiPaper-root': {
    borderRadius: '7px',
    marginTop: theme.spacing(1),
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: alpha(theme.palette.primary.dark, 0.1),
    '& .MuiMenuItem-root': {
      ...theme.typography.body2,
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      },
      '&.Mui-selected': {
        background: theme.palette.purple[100],
      },
    },
  },
});
