import { List } from '@mui/material';
import AppChip from './AppChip';
import { CompleteApp } from '@dap-admin/types';

interface Props {
  apps: CompleteApp[];
  noBorder?: boolean;
  highlightDefault?: boolean;
}

export default function AppsList({ apps, noBorder = false, highlightDefault = false }: Props) {
  return (
    <List data-testid="services-list" sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {apps.map((app) => (
        <AppChip
          key={app.key}
          appKey={app.key}
          name={app.name}
          noBorder={noBorder}
          defaultApp={highlightDefault && app.default}
        />
      ))}
    </List>
  );
}
