import { KeyInfoType, ModuleType } from '@dap-sanity/types';
import { groqQuery } from '@shared/utils';

export interface GetModuleArticleQueryProps {
  moduleId: string;
  categorySlug: string;
  brandKey: string;
  slug: string;
}

export interface GetStandardArticleQueryProps {
  slug: string;
  moduleType: ModuleType;
}

// Used by Fremdrift- and Proff articles
export const getModuleArticleQuery = ({
  moduleId,
  categorySlug,
  brandKey,
  slug,
}: GetModuleArticleQueryProps) =>
  groqQuery({
    filters: [
      `references("${moduleId}")`,
      `"${brandKey}" in brand`,
      `_id in *[category_slug.current == "${categorySlug}"].articles[]._ref`,
      `url.current == "${slug}"`,
    ],
    projections: [
      '"id": _id',
      '"url": url.current',
      'intro',
      '"image": image.asset->',
      'title',
      `"body": textBlock[]`,
      `attachments[]{
        label,
        "url": asset->url
      }`,
      `"keyInfo": {
        "email": keyInfo.fields[_type match "${KeyInfoType.email}"],
        "link": keyInfo.fields[_type match "${KeyInfoType.link}"],
        "text": keyInfo.fields[_type match "${KeyInfoType.text}"],
      }`,
      `"categoryTitle": *[references(^._id) && defined(title)][0].title`,
    ],
    slice: { start: 0 }, // Only one result
  });

// Get standard article for a specific module and brand
export const getStandardArticleQuery = ({ slug, moduleType }: GetStandardArticleQueryProps) =>
  groqQuery({
    filters: [`url.current == "${slug}"`, `module == "${moduleType}"`],
    projections: [
      `
      "id":_id,
      title,
      "url": url.current,
      brand,
      publishAt,
      "image": image.asset->url,
      intro,
      "body": textBlock,
      "keyInfo": {
        "email": keyInfo.fields[_type match "${KeyInfoType.email}"],
        "link": keyInfo.fields[_type match "${KeyInfoType.link}"],
        "text": keyInfo.fields[_type match "${KeyInfoType.text}"],
      },
      attachments[]{
        label,
        "url": asset->url
      },
      author[]->,
      tags,
      module
    `,
    ],
    slice: { start: 0 }, // Only one result
    ordering: [{ fieldname: '_createdAt', order: 'desc' }],
  });
