export const capitalizeFirstLetter = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const getFileExtension = (url: string) => {
  const splits = url.split('.');
  if (!splits.length) {
    return null;
  }
  return splits[splits.length - 1].toLowerCase();
};

export function replaceLastOccurrence(str: string, search: string, replacement: string | number) {
  const lastIndex = str.lastIndexOf(search);
  if (lastIndex === -1) {
    return str;
  }
  const before = str.slice(0, lastIndex);
  const after = str.slice(lastIndex + search.length);
  return before + replacement + after;
}

export const NorwegianKroner = new Intl.NumberFormat('no-NO', {
  style: 'currency',
  currency: 'NOK',
  currencyDisplay: 'code',
});
