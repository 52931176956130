import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { Attachment, SanityBlockContent } from '../common/commonFieldTypes';
import { SanityModuleConfigBase } from '../modules/moduleTypes';
import { Person } from '../person/personTypes';

export interface MosaicModuleConfigBase extends SanityModuleConfigBase {
  header: string;
  slug: string;
}

export interface MosaicModuleConfig extends MosaicModuleConfigBase {
  contact: Person;
  mosaicBuilder: MosaicItem[];
  textBlock?: SanityBlockContent[];
  callToAction?: CallToAction;
  attachments: Attachment[];
  image: SanityImageSource;
}

type CallToAction = {
  linkText: string;
  internalUrl?: { _type: string; slug: string };
  externalUrl?: string;
} & (
  | {
      internalUrl: { _type: string; slug: string };
    }
  | {
      externalUrl: string;
    }
);

export interface MosaicItem {
  image: SanityImageSource;
  isSizeLarge: boolean;
  linkText: string;
  url: string;
  size: MosaicSize;
  title: string;
  subtitle: string;
  _type: MosaicType;
  _key: string;
}

export const MosaicSize = {
  Small: 'small',
  Medium: 'medium',
  Large: 'large',
} as const;
export type MosaicSize = (typeof MosaicSize)[keyof typeof MosaicSize];

export const MosaicType = {
  WithCta: 'mosaicCTALink',
  Standard: 'mosaicPictureLink',
} as const;
export type MosaicType = (typeof MosaicType)[keyof typeof MosaicType];
