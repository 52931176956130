import { useBrandadminTFunction, useBrandTFunction } from '@dap-admin/i18n';
import { Dataowner } from '@dap-admin/types';
import { getOrgNumberNameFromCountry } from '@dap-admin/utils';
import { useAppTFunction } from '@dap-common/i18n';
import { CopyValueToClipboard, FlexColumn, FlexRow, LabelValue } from '@dap-common/ui';
import { Divider, Stack, useTheme } from '@mui/material';
import { Breadcrumbs, Chip, Flag, LabelValueTable, Paper, TagHeading } from '@shared/components';
import { FinnishFlagRoundedIcon } from '@shared/custom-icons';
import { CountryCode } from '@shared/types';
import { formatOrgNo } from '@shared/utils';
import { format } from 'date-fns';

interface Props {
  dataowner: Dataowner;
}

export default function DataownerDetailsBlock({ dataowner }: Props) {
  const t = useBrandadminTFunction();
  const tApp = useAppTFunction();
  const tBrand = useBrandTFunction(dataowner.brand.key);
  const { palette } = useTheme();

  return (
    <Paper sx={{ height: '100%' }} data-testid="dataowner-details-block">
      <TagHeading marginBottom={3} alignItems="flex-start" color="primary">
        <FlexRow justifyContent="space-between" alignItems="flex-start" flexWrap={'wrap'}>
          <FlexColumn>
            <Breadcrumbs
              items={[{ href: '../..', label: tApp('common.home') }, { label: dataowner.name }]}
              fontSize="sm"
            />
            <LabelValue
              label={t('location.id')}
              sx={{ padding: '4px 0' }}
              value={
                <FlexRow flexWrap={'nowrap'}>
                  <Chip label={dataowner.key} backgroundColor={palette.info.main} />
                  <CopyValueToClipboard value={dataowner.key} />
                </FlexRow>
              }
              labelTypographyProps={{
                color: 'text.secondary',
                variant: 'body2',
                fontWeight: 400,
              }}
            />
          </FlexColumn>

          {!dataowner.enabled && (
            <Chip
              label={t('dataowner.deactivated')}
              backgroundColor={palette.warning.main}
              sx={{ marginRight: -2 }}
            /> // to counter for gap in the TagHeading
          )}
        </FlexRow>
      </TagHeading>

      <LabelValueTable
        items={[
          {
            label: getOrgNumberNameFromCountry(dataowner.country, t),
            value: (
              <FlexRow flexWrap={'nowrap'}>
                <FlexRow gap={1} flexWrap={'nowrap'}>
                  {formatOrgNo(dataowner.orgNr)}
                  {(dataowner.country as CountryCode) === CountryCode.Finland ? (
                    <FinnishFlagRoundedIcon sx={{ height: '18px', width: '18px' }} />
                  ) : (
                    <Flag country={dataowner.country as CountryCode} />
                  )}
                </FlexRow>
                <CopyValueToClipboard value={dataowner.orgNr} />
              </FlexRow>
            ),
          },
          {
            label: t('dataowner.gln.title'),
            value: dataowner.glns.length && (
              <Stack
                direction="row"
                flexWrap="wrap"
                rowGap={1.5}
                columnGap={1}
                divider={<Divider orientation="vertical" flexItem />}
              >
                {dataowner.glns.map(({ id }) => (
                  <FlexRow key={id} flexWrap={'nowrap'}>
                    {id}
                    <CopyValueToClipboard value={id} />
                  </FlexRow>
                ))}
              </Stack>
            ),
          },
          {
            label: t('dataowner.membership.nonMember'),
            value: dataowner.isMember ? tApp('common.no') : tApp('common.yes'),
          },
          {
            label: t('dataowner.membership.memberSinceTo', {
              memberType: tBrand('brand.dataowner.name.singular', 'capitalize'),
            }),
            value: `${
              dataowner.memberFrom ? format(new Date(dataowner.memberFrom), 'dd/MM/yyyy') : ''
            } – ${dataowner.memberTo ? format(new Date(dataowner.memberTo), 'dd/MM/yyyy') : ''}`,
          },
        ]}
      />
    </Paper>
  );
}
