import { Tab, TabProps, Tabs, TabsProps } from '@mui/material';
import { TabsClass } from '@shared/styles';
import { a11yProps } from '@shared/utils';

/* Use the same id as TabPanel */
interface Props {
  id: string;
  tabs: Array<Pick<TabProps, 'label' | 'value' | 'icon'>>;
  currentTab: string | number;
  onChange: TabsProps['onChange'];
}

const className = TabsClass.underlined;

export default function UnderlinedTabs({ id, tabs, currentTab, onChange }: Props) {
  return (
    <Tabs value={currentTab} onChange={onChange} className={className}>
      {tabs.map(({ label, value, icon }, index) => (
        <Tab
          key={`${id}-${value || index}`}
          label={label}
          value={value || index}
          icon={icon}
          {...a11yProps(id, value || index)}
          className={className}
        />
      ))}
    </Tabs>
  );
}
