import { Paper, Typography } from '@mui/material';
import { FlexRow } from '@dap-common/ui';
import { brandStylesConfig } from '@dap-common/styles';
import { useAppTFunction } from '@dap-common/i18n';
import { RecognizedBrands } from '@dap-common/types';
import { capitalizeFirstLetter } from '@shared/utils';
import { EntityButton, EntityChart } from '../../Chart';

interface Props {
  brandName: string;
  brandKey: string;
}

export default function BrandChartBlock({ brandName, brandKey }: Props) {
  const tApp = useAppTFunction();

  const brandStyle = brandStylesConfig[brandKey as RecognizedBrands] || brandStylesConfig.default;

  return (
    <Paper sx={{ height: '100%', p: 4 }}>
      <FlexRow
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'nowrap'}
        gap={3}
        mb={3}
      >
        <Typography variant="h6" noWrap>
          {capitalizeFirstLetter(tApp('brandadmin.brand.title.singular'))}
        </Typography>
        {brandStyle.icon && (
          <img
            src={brandStyle.icon}
            alt={`${brandName} logo`}
            height="24px"
            width="24px"
            style={{ objectFit: 'contain' }}
          />
        )}
      </FlexRow>

      <EntityChart>
        <EntityButton color="success" active>
          <Typography component="span" fontWeight="bold" noWrap>
            {brandName}
          </Typography>
          <Typography component="span" variant="body2" color="text.secondary" noWrap>
            {capitalizeFirstLetter(tApp('brandadmin.brand.title.singular'))}
          </Typography>
        </EntityButton>
      </EntityChart>
    </Paper>
  );
}
