import { List as MuiList, ListItem as MuiListItem, styled } from '@mui/material';

export const BrregList = styled(MuiList)(({ theme }) => ({
  border: `1px solid ${theme.palette['divider']}`,
  borderRadius: theme.shape.borderRadius * 2,
  maxHeight: '275px',
  overflow: 'auto',
}));

export const BrregListItem = styled(MuiListItem)(({ theme }) => ({
  paddingRight: '100px',
  ':nth-of-type(odd)': {
    backgroundColor: theme.palette['primary'].A100,

    ':first-of-type': {
      borderTopLeftRadius: theme.shape.borderRadius * 2,
      borderTopRightRadius: theme.shape.borderRadius * 2,
    },

    ':last-of-type': {
      borderBottomLeftRadius: theme.shape.borderRadius * 2,
      borderBottomRightRadius: theme.shape.borderRadius * 2,
    },
  },

  ':nth-of-type(even)': {
    backgroundColor: 'inherit',
  },
}));
