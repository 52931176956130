interface DatahubConfig {
  url: string;
}

type Env = 'dev' | 'stage' | 'prod' | 'local';

interface EnvConfigs {
  dev: DatahubConfig;
  stage: DatahubConfig;
  prod: DatahubConfig;
}

const envConfigs: EnvConfigs = {
  dev: { url: 'https://explorer.datahub-dev.mestergruppen.cloud' },
  stage: { url: 'https://explorer.datahub-stage.mestergruppen.cloud' },
  prod: { url: 'https://explorer.datahub-prod.mestergruppen.cloud' },
};

const env = (process.env['NX_REACT_APP_ENV'] || 'local') as Env;

export const datahubConfig = env === 'local' ? envConfigs.dev : envConfigs[env];
