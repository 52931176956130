import { Person } from '@dap-sanity/types';
import { Stack, Typography } from '@mui/material';
import { messages } from '@dap-common/i18n';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

interface Props {
  author?: Person;
  publishedAt: string | undefined;
}

export default function PublisherInfo({ author, publishedAt }: Props) {
  const { t } = useTranslation(['newsArticles']);

  return (
    <Stack py={1}>
      {author && (
        <Stack direction="row" spacing={0.5}>
          <Typography variant="body3">{t(messages.newsArticles.meta.author)}</Typography>
          <Typography variant="body3" fontWeight="bold">
            {author.name}
          </Typography>
        </Stack>
      )}
      {publishedAt && (
        <Stack direction="row" spacing={0.5}>
          <Typography variant="body3">{t(messages.newsArticles.meta.published)}</Typography>
          <Typography variant="body3" fontWeight="bold">
            {format(new Date(publishedAt), 'dd.MM.yyyy')}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
