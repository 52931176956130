import { Stack } from '@mui/material';
import { OverflowingText } from '@shared/components';
import { CTATooltip } from '../Tooltip';

interface Props {
  title: string;
  CTAText?: string;
}

export default function SidebarListItemPrimaryTitle({ title, CTAText }: Props) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <OverflowingText tooltipText={title}>{title}</OverflowingText>
      {CTAText && <CTATooltip tooltipTitle={CTAText} tooltipPlacement="top-end" edge="end" />}
    </Stack>
  );
}
