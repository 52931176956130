import { Stack, Typography } from '@mui/material';
import { messages } from '@dap-common/i18n';
import { useTranslation } from 'react-i18next';

export default function SanityImageErrorComponent() {
  const { t } = useTranslation(['common']);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height="3rem"
      bgcolor={({ palette }) => palette.cardBackgrounds.lightPurple}
      my={2}
    >
      <Typography variant="body2">{t(messages.common.error.sanityImage)}</Typography>
    </Stack>
  );
}
