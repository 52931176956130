import { Breadcrumbs, Link, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  breadcrumbs: {
    text: string;
    to: string;
    leftIcon?: ReactElement;
    relativePath?: boolean;
  }[];
  header?: string;
}

export default function SanityPageHeader({ breadcrumbs, header }: Props) {
  return (
    <>
      <Breadcrumbs>
        {breadcrumbs.map(({ to, text, leftIcon, relativePath }) => (
          <Link
            component={RouterLink}
            to={to}
            key={to}
            sx={{ display: 'flex', alignItems: 'center' }}
            {...(relativePath && { relative: 'path' })}
          >
            {leftIcon}
            {text}
          </Link>
        ))}
      </Breadcrumbs>
      {!!header && (
        <Typography variant="h1" sx={{ mt: 3 }}>
          {header}
        </Typography>
      )}
    </>
  );
}
