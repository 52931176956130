import { Country } from '@shared/types';
import { AppBasic, LocationProperty, OrgUnitRole, Property } from '../commonTypes';
import { BrandBasic } from './brandTypes';
import { GLN, LocationBasic } from './locationTypes';

export interface LocationDataOwnerView {
  enabled: boolean;
  key: string;
  name: string;
  region: string;
  locationProperties?: LocationProperty[];
  memberFrom?: string;
  memberTo?: string;
}

export interface DataownerBasic {
  uuid: string;
  name: string;
  key: string;
  orgNr: string;
  brand: BrandBasic;
  properties: Array<Property>;
  countEnabledLocations: number;
  hasUnverifiedEmployees: boolean;
}

export interface Dataowner {
  uuid: string;
  name: string;
  key: string;
  memberFrom: string;
  memberTo: string;
  enabled: boolean;
  country: Country;
  orgNr: string;
  createdAt: string;
  updatedAt: string;
  domains: string[];
  brand: BrandBasic;
  locations: LocationBasic[];
  isAdmin: boolean;
  glns: Array<GLN>;
  properties: Array<Property>;
  apps: Array<AppBasic>;
  availableRoles: Array<OrgUnitRole>;
  isMember: boolean;
}

export interface CreateDataownerDTO {
  brandKey: string;
  enabled?: boolean;
  member?: boolean; //if the dataowner is owned by MG, this value should be false (but the checkbox will return true)
  name: string;
  country: Country;
  orgNr: string;
  domains?: string[];
  apps?: string[];
  glns?: string[];
}

export interface RemoveEmployeesFromDataowner {
  dataownerKey: string;
  userIds: string[];
}

export type DataownerEdit = typeof dataownerEditInit;

export const dataownerEditInit = {
  details: false,
  name: false,
  orgNo: false,
  domains: false,
  apps: false,
  employeeApps: false,
  roles: false,
  removeEmployee: false,
  isMember: false,
  enabled: false,
  glns: false,
};

export interface DataownerSearchResult {
  uuid: string | null;
  name: string;
  enabled: boolean;
  key: string;
  orgNr: string | null;
  glns: Array<GLN>;
  brand: BrandBasic;
  isMember: boolean;
  countEnabledLocations: number;
  hasUnverifiedEmployees: boolean;
  country: Country;
}

export interface DataownerSearchArgs {
  filters: DataownerSearchFilters;
  operator: 'AND' | 'OR';
}

type DataownerSearchFilters = {
  query?: string;
  dataownerKeys?: Array<string>;
  appFilter?: Array<string>;
  enabledStatusFilter?: boolean;
  brandFilter?: Array<string>;
  orgNrFilter?: Array<string>;
  glnFilter?: Array<string>;
};

export interface PatchDataownerDTO
  extends Pick<
    Dataowner,
    'name' | 'memberFrom' | 'memberTo' | 'enabled' | 'orgNr' | 'domains' | 'country'
  > {
  apps?: Array<string>;
  glns?: Array<string>;
}

export interface UpdateDataownerDetailsSchema {
  country?: Dataowner['country'];
  name?: Dataowner['name'];
  orgNr?: Dataowner['orgNr'];
  memberFrom?: Dataowner['memberFrom'];
  memberTo?: Dataowner['memberTo'];
  glns?: string[];
  apps?: Array<string>;
}

export interface UpdateDataownerEnabledSchema {
  enabled?: boolean;
}

export type UpdateDataownerSchema = UpdateDataownerDetailsSchema | UpdateDataownerEnabledSchema;
