import { getHighestAdminRole, isAdmin } from '@dap-admin/utils';
import {
  getBrandadminRoute,
  getModuleRoute,
  getMosaicModuleRoute,
  getOrderRoute,
  routes,
} from '@dap-common/consts';
import {
  selectBrandKey,
  useAppDispatch,
  useAppSelector,
  useGetBrandQuery,
  useSelectedBrandKey,
  useUser,
} from '@dap-common/data-access';
import { messages } from '@dap-common/i18n';
import { sidebarCollapsedWidth, sidebarWidth } from '@dap-common/styles';
import { RecognizedBrands } from '@dap-common/types';
import { useGetBrandConfig } from '@dap-sanity/data-access';
import { ModuleType } from '@dap-sanity/types';
import {
  AdminPanelSettings,
  BusinessCenter,
  ChevronLeft,
  ChevronRight,
  Sell,
  Settings,
  SupervisedUserCircle,
  TableViewOutlined,
} from '@mui/icons-material';
import AutoAwesomeMosaicTwoToneIcon from '@mui/icons-material/AutoAwesomeMosaicTwoTone';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import {
  Box,
  CSSObject,
  Divider,
  Drawer,
  IconButton,
  Stack,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import {
  CalendarIcon,
  FremdriftIcon,
  HelpCentreIcon,
  NewsIcon,
  ProffConceptsIcon,
  ServiceCatalogIcon,
} from '@shared/custom-icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { AppMenu, Navigation, SidebarHeader, UserMenu } from './components';
import { NavRoute } from './components/NavLinkItem';

interface SidebarProps {
  openSidebar: boolean;
  toggleSidebar: () => void;
  logoutCallback: () => void;
}

const SANITY_BRANDS = ['systemhus', 'nordbohus', 'blinkhus', 'mesterhus'];
const SANITY_ROLE_KEYS = ['sanity-admin-hus', 'sanity-redaktor-hus'];

function Sidebar({ openSidebar, toggleSidebar, logoutCallback }: SidebarProps) {
  const dispatch = useAppDispatch();
  const userData = useUser();
  const selectedBrandKey = useSelectedBrandKey();
  const { brandApps } = useGetBrandQuery(selectedBrandKey, {
    selectFromResult: ({ data }) => ({
      brandApps: data?.apps ?? [],
    }),
  });
  const userPicture = useAppSelector((state) => state.user.userPicture);
  const theme = useTheme();
  const navigate = useNavigate();
  const isBrandAdminRoute = useMatch(`${getBrandadminRoute()}/*`);
  const navRoutesConfig = useNavRoutes();

  const selectBrand = (brandKey: string) => {
    if (isBrandAdminRoute) {
      navigate(getBrandadminRoute());
    }
    dispatch(selectBrandKey(brandKey));
  };

  const username = useMemo(
    () =>
      userData && userData.name ? userData.name : `${userData?.givenName} ${userData?.familyName}`,
    [userData]
  );

  const highestAdminRole = useMemo(() => {
    return getHighestAdminRole(userData);
  }, [userData]);

  //@ts-ignore
  const drawerSx: SxProps<Theme> = (theme) => ({
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(openSidebar && openedMixin(theme)),
    ...(!openSidebar && closedMixin(theme)),
    '& .MuiDrawer-paper': {
      display: 'flex',
      flexDirection: 'row',
      padding: 0,
      border: 0,
      background: 'transparent',
      ...(openSidebar && openedMixin(theme)),
      ...(!openSidebar && closedMixin(theme)),
    },
  });

  //@ts-ignore
  const boxSx: SxProps<Theme> = (theme) => ({
    backgroundColor: theme.palette.purple[50],
    borderRight: `1px solid ${theme.palette.border.darkPurple}`,
    ...(openSidebar && openedMixin(theme)),
    ...(!openSidebar && closedMixin(theme)),
  });

  if (userData)
    return (
      <Box position="relative" height="100vh" zIndex={theme.zIndex.drawer}>
        <Drawer variant="permanent" anchor="left" sx={drawerSx}>
          <Stack sx={boxSx}>
            <SidebarHeader
              openSidebar={openSidebar}
              brandKey={selectedBrandKey as RecognizedBrands}
              availableBrands={userData.brands}
              selectBrand={selectBrand}
            />
            <Divider />
            <Navigation expandedView={openSidebar} navRoutes={navRoutesConfig} />
            <Divider />
            <AppMenu openSidebar={openSidebar} userApps={userData.apps} brandApps={brandApps} />
            <UserMenu
              brandKey={selectedBrandKey}
              username={username}
              highestAdminRole={highestAdminRole}
              handleLogout={logoutCallback}
              userPicture={userPicture}
              openSidebar={openSidebar}
            />
          </Stack>
        </Drawer>

        <Box position="absolute" left="100%" zIndex={theme.zIndex.drawer + 1}>
          <Box position="fixed" top="37px">
            <IconButton color="primary" onClick={toggleSidebar} sx={openSidebarIconSx}>
              {openSidebar ? <ChevronLeft fontSize="small" /> : <ChevronRight fontSize="small" />}
            </IconButton>
          </Box>
        </Box>
      </Box>
    );

  return null;
}

export default Sidebar;

const useNavRoutes = () => {
  const userData = useUser();
  const brandKey = useSelectedBrandKey();

  const { t } = useTranslation(['common', 'newsArticles', 'services', 'events', 'proff']);
  const { pathname } = useLocation();
  const lastPathParam = pathname.split('/').pop();

  const brandConfiguration = useGetBrandConfig({ brandKey });

  const isBrandadminPage = useMatch(routes.brandadmin);
  const isBrandDetailsPage = useMatch(`${routes.brandadmin}/${routes.brandDetails}/*`);
  const isBrandadminSuperAdminPage = useMatch(`${routes.brandadmin}/${routes.superadmin}`);
  const isBrandadminOrganizationPage = useMatch(`${routes.brandadmin}/${routes.organizationView}`);
  const isBrandadminDataownerPage = useMatch(`${routes.brandadmin}/${routes.dataowner}/*`);
  const isBrandadminLocationPage = useMatch(`${routes.brandadmin}/${routes.location}/*`);
  const isBrandadminEmployeePage = useMatch(`${routes.brandadmin}/${routes.employee}/*`);
  const isSanityPage = useMatch(`${routes.sanity}/*`);

  return useMemo(() => {
    const userHasBrandadminAccess =
      userData.metadata.superuser || userData.apps.some((app) => app.key === 'brandadmin');

    const userHasSanityAccess =
      userData.roles.some((role) => role.role.key === 'dap-sanity-editor') ||
      (userData.brands.some((brand) => SANITY_BRANDS.includes(brand.key)) &&
        userData.roles.some((role) => SANITY_ROLE_KEYS.includes(role.role.key)));

    const homeNavConfig: NavRoute = {
      title: t(messages.common.navigation.dashboard),
      icon: <HomeTwoToneIcon fontSize="small" />,
      isActive: lastPathParam === routes.home,
      to: routes.home,
    };

    const brandadminNavConfig: NavRoute | undefined = userHasBrandadminAccess
      ? {
          title: t(messages.common.navigation.brandadmin, { ns: 'common' }),
          icon: <SupervisedUserCircle fontSize="small" />,
          isActive: !isAdmin(userData, brandKey) && !!isBrandadminPage,
          to: routes.brandadmin,
          subRoutes: isAdmin(userData, brandKey)
            ? [
                {
                  title: t(messages.common.navigation.brandadmin, { ns: 'common' }),
                  icon: <SupervisedUserCircle fontSize="small" />,
                  isActive:
                    !!isBrandadminPage ||
                    !!isBrandadminDataownerPage ||
                    !!isBrandadminLocationPage ||
                    !!isBrandadminEmployeePage,
                  to: routes.brandadmin,
                },
                {
                  title: t(messages.common.navigation.orgview, { ns: 'common' }),
                  icon: <TableViewOutlined fontSize="small" />,
                  isActive: !!isBrandadminOrganizationPage,
                  to: `${routes.brandadmin}/${routes.organizationView}`,
                },
                {
                  title: t(messages.common.navigation.brandDetails),
                  icon: <Settings fontSize="small" />,
                  isActive: !!isBrandDetailsPage,
                  to: `${routes.brandadmin}/${routes.brandDetails}`,
                },
                ...(userData.metadata.superuser
                  ? [
                      {
                        title: t(messages.common.navigation.superuser),
                        icon: <AdminPanelSettings fontSize="small" />,
                        isActive: !!isBrandadminSuperAdminPage,
                        to: `${routes.brandadmin}/${routes.superadmin}`,
                      },
                    ]
                  : []),
              ]
            : undefined,
        }
      : undefined;

    const proffNavConfig: NavRoute[] | undefined = brandConfiguration?.[ModuleType.proffModule]
      ?.filter((proffModule) => proffModule.show)
      .map((proffModule) => ({
        title: proffModule.header,
        icon: <ProffConceptsIcon fontSize="small" />,
        isActive: pathname.split('/').some((pathParam) => pathParam === proffModule.slug),
        to: getModuleRoute({ moduleSlug: proffModule.slug }),
      }));

    const fremdriftNavConfig: NavRoute[] | undefined = brandConfiguration?.[
      ModuleType.fremdriftModule
    ]
      ?.filter((fremdriftModule) => fremdriftModule.show)
      .map((fremdriftModule) => ({
        title: fremdriftModule.header,
        icon: fremdriftModule.slug.includes('fremdrift') ? (
          <FremdriftIcon fontSize="small" />
        ) : (
          <BusinessCenter fontSize="small" />
        ),
        isActive: pathname.split('/').some((pathParam) => pathParam === fremdriftModule.slug),
        to: getModuleRoute({ moduleSlug: fremdriftModule.slug }),
      }))
      .sort((a, b) => (a.title < b.title ? 1 : -1));

    const mosaicNavConfig: NavRoute[] | undefined = brandConfiguration?.[ModuleType.mosaicModule]
      ?.filter((mosaicModule) => mosaicModule.show)
      .map((mosaicModule) => ({
        title: mosaicModule.header,
        icon: <AutoAwesomeMosaicTwoToneIcon fontSize="small" />,
        isActive: pathname.split('/').some((pathParam) => pathParam === mosaicModule.slug),
        to: getMosaicModuleRoute({ moduleSlug: mosaicModule.slug }),
      }));

    const orderNavConfig: NavRoute[] | undefined = brandConfiguration?.[ModuleType.orderModule]
      ?.filter((orderModule) => orderModule.show)
      .map((orderModule) => ({
        title: orderModule.header,
        icon: <Sell fontSize="small" />,
        isActive: pathname.split('/').some((pathParam) => pathParam === orderModule.slug),
        to: getOrderRoute({ moduleSlug: orderModule.slug }),
      }));

    const calendarNavConfig: NavRoute | undefined = brandConfiguration?.[ModuleType.eventsModule]
      ?.show
      ? {
          title: t(messages.events.calendar, { ns: 'events' }),
          icon: <CalendarIcon fontSize="small" />,
          isActive: pathname.split('/').some((pathParam) => pathParam === routes.events),
          to: routes.events,
        }
      : undefined;

    const newsNavConfig: NavRoute | undefined = brandConfiguration?.[ModuleType.newsModule]?.show
      ? {
          title: t(messages.newsArticles.news, { ns: 'newsArticles' }),
          icon: <NewsIcon fontSize="small" />,
          isActive: pathname.split('/').some((pathParam) => pathParam === routes.news),
          to: routes.news,
        }
      : undefined;

    const servicesNavConfig: NavRoute | undefined = brandConfiguration?.[ModuleType.servicesModule]
      .show
      ? {
          title: t(messages.services.serviceCatalogName.singular, { ns: 'services' }),
          icon: <ServiceCatalogIcon fontSize="small" />,
          isActive: pathname.split('/').some((pathParam) => pathParam === routes.services),
          to: routes.services,
        }
      : undefined;

    const helpCentreNavConfig: NavRoute | undefined = brandConfiguration?.[
      ModuleType.helpCentreModule
    ]?.show
      ? {
          title: t(messages.helpCentre.title, { ns: 'helpCentre' }),
          icon: <HelpCentreIcon fontSize="small" />,
          isActive: pathname.split('/').some((pathParam) => pathParam === routes.helpCentre),
          to: routes.helpCentre,
        }
      : undefined;

    const sanityMainNavConfig: NavRoute | undefined = userHasSanityAccess
      ? {
          title: 'Sanity',
          icon: <WysiwygIcon fontSize="small" />,
          isActive: !!isSanityPage,
          to: `${routes.sanity}`,
        }
      : undefined;

    return [
      homeNavConfig,
      brandadminNavConfig,
      ...(proffNavConfig || []),
      ...(fremdriftNavConfig || []),
      ...(mosaicNavConfig || []),
      ...(orderNavConfig || []),
      calendarNavConfig,
      newsNavConfig,
      servicesNavConfig,
      helpCentreNavConfig,
      sanityMainNavConfig,
    ].filter((navConfig) => !!navConfig) as NavRoute[];
  }, [
    brandConfiguration,
    brandKey,
    isBrandDetailsPage,
    isBrandadminDataownerPage,
    isBrandadminEmployeePage,
    isBrandadminLocationPage,
    isBrandadminOrganizationPage,
    isBrandadminPage,
    isBrandadminSuperAdminPage,
    isSanityPage,
    lastPathParam,
    pathname,
    t,
    userData,
  ]);
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: sidebarWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: sidebarCollapsedWidth,
});

const openSidebarIconSx: SxProps<Theme> = ({ palette }) => ({
  width: '22px',
  height: '22px',
  left: '-11px',
  padding: 0,
  borderRadius: '50%',
  bgcolor: palette.purple[100],
  border: `1px solid ${palette.border.darkPurple}`,
  '&:hover': {
    bgcolor: palette.common.white,
  },
});
