import {
  FormControl,
  FormHelperText,
  MenuItem,
  styled,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import { Control, Controller, DeepMap, FieldError, useFormContext } from 'react-hook-form';
import { Margin, Size } from './formTypes';

interface ISelectProps {
  name: string;
  label: string;
  menuItems: { value: any; name: string }[];
  defaultValue?: string;
  required?: boolean;
  errorKey?: any;
  disabled?: boolean;
  margin?: Margin;
  size?: Size;
  fullWidth?: boolean;
}

interface SelectRawProps extends ISelectProps {
  formMethods: {
    errors: DeepMap<Record<string, any>, FieldError>;
    control: Control<any>;
  };
}

export const SelectInputRaw: React.FC<SelectRawProps> = ({
  name,
  label,
  menuItems,
  defaultValue = '',
  required = false,
  errorKey,
  disabled,
  margin = 'none',
  size = 'small',
  formMethods,
  fullWidth,
}) => {
  const { control, errors } = formMethods;
  const errKey = errorKey || errors[name];
  const { breakpoints } = useTheme();
  const isLessThanMedium = useMediaQuery(breakpoints.down('md'));

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControl
          fullWidth={fullWidth || isLessThanMedium}
          margin={margin}
          error={!!errKey}
          disabled={disabled}
          required={required}
        >
          <TextField
            select
            {...field}
            size={size}
            id={label}
            label={label}
            SelectProps={{ labelId: `label-${name}` }}
            InputLabelProps={{ id: `label-${name}` }}
            sx={{ minWidth: '200px' }}
          >
            {menuItems.map(({ value, name }) => (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            ))}
          </TextField>
          {errKey && <StyledFormHelperText>{errKey?.message}</StyledFormHelperText>}
        </FormControl>
      )}
    />
  );
};

const SelectInput: React.FC<ISelectProps> = (props) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return <SelectInputRaw {...props} formMethods={{ control, errors }} />;
};

/**
 * @deprecated This component is deprecated, use  InputSelect  instead
 */

export default SelectInput;

const StyledFormHelperText = styled(FormHelperText)`
  margin-left: 1rem;
`;
