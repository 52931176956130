import { SvgIcon, SvgIconProps } from '@mui/material';

export function CustomChevronRight(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="8" height="14" viewBox="0 0 8 14" fill="none">
      <path d="M1.53125 0.5L7.25 6.46875C7.375 6.625 7.46875 6.8125 7.46875 7C7.46875 7.1875 7.375 7.375 7.25 7.5L1.53125 13.4688C1.25 13.7812 0.75 13.7812 0.46875 13.5C0.15625 13.2188 0.15625 12.75 0.4375 12.4375L5.6875 6.96875L0.4375 1.53125C0.15625 1.25 0.15625 0.75 0.46875 0.46875C0.75 0.1875 1.25 0.1875 1.53125 0.5Z" />
    </SvgIcon>
  );
}

export function CustomChevronLeft({ sx, ...props }: SvgIconProps) {
  return (
    <CustomChevronRight
      sx={{
        transform: 'rotate(180deg)',
        ...sx,
      }}
      {...props}
    />
  );
}
