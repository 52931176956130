import { Days, OpenHours } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { FlexRow } from '@dap-common/ui';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { LabelValueTable } from '@shared/components';
import { memo } from 'react';
import PinmetoPinTooltip from '../PinmetoPinTooltip';

interface Props {
  openingHours: OpenHours | null;
  hasPinmetoMapping?: boolean;
}

// TODO: Fix an util to check for empty opening hours. Now we wont show "No opening hours" ever because we have a default values/empty strings
function OpeningHoursBlock({ openingHours, hasPinmetoMapping }: Props) {
  const t = useAppTFunction();
  return (
    <Stack spacing={2}>
      <FlexRow justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant="h6" fontWeight={700}>
          {t('location.detail.openingHours.title')}
        </Typography>
        {hasPinmetoMapping && <PinmetoPinTooltip />}
      </FlexRow>
      {openingHours ? (
        <LabelValueTable
          labelWidth={120}
          items={Object.entries(openingHours).map(([day, openHoursDay]) => ({
            label: t(`location.detail.openingHours.weekdays.${day as Days}`),
            value:
              openHoursDay.state === 'Closed'
                ? t('location.detail.openingHours.closed')
                : openHoursDay.span
                    .map(({ open, close }) => (open && close ? `${open} - ${close}` : null))
                    .join(', '),
          }))}
        />
      ) : (
        <Typography color="text.secondary" fontWeight={400}>
          {hasPinmetoMapping
            ? t('location.detail.openingHours.noOpeningHours')
            : t('location.detail.openingHours.addOpeningHours')}
        </Typography>
      )}
    </Stack>
  );
}

export default memo(
  OpeningHoursBlock,
  (
    { openingHours: prevOpeningHours, hasPinmetoMapping: prevHasPinmeto },
    { openingHours, hasPinmetoMapping }
  ) => {
    return (
      prevOpeningHours?.mon === openingHours?.mon &&
      prevOpeningHours?.tue === openingHours?.tue &&
      prevOpeningHours?.wed === openingHours?.wed &&
      prevOpeningHours?.thu === openingHours?.thu &&
      prevOpeningHours?.fri === openingHours?.fri &&
      prevOpeningHours?.sat === openingHours?.sat &&
      prevOpeningHours?.sun === openingHours?.sun &&
      prevHasPinmeto === hasPinmetoMapping
    );
  }
);


