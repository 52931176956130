import { Paper as MuiPaper, PaperProps, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

interface Props extends Omit<PaperProps, 'variant'> {
  /**Should the padding prop not be sufficient, use paddingY = "none" and set the desired padding in the child component.
   * X-padding cannot be overrided. */
  paddingY?: 'none' | 'normal' | 'dense';
}

const StyledPaper = styled(MuiPaper, {
  shouldForwardProp: (prop) => prop !== 'paddingY',
})<Pick<Props, 'paddingY'>>(({ theme, paddingY }) => ({
  padding:
    paddingY === 'normal'
      ? theme.spacing(4)
      : paddingY === 'dense'
      ? theme.spacing(2)
      : theme.spacing(0, 4),
  boxShadow: 'none',
  flex: 1,
}));

/**
 * Use as surface in Grid items or as background for content.
 */
function OutlinedPaper({ paddingY = 'normal', children, ...rest }: PropsWithChildren<Props>) {
  return (
    <StyledPaper paddingY={paddingY} variant="outlined" {...rest}>
      {children}
    </StyledPaper>
  );
}

export default OutlinedPaper;
