import { CardMedia, SxProps } from '@mui/material';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { useSanityContextActions } from '@dap-sanity/utils';

interface Props {
  height: number;
  image: SanityImageSource;
  imageWidth?: string;
  noBorderRadius?: boolean;
}

export default function PreviewSanityImage({ height, image, imageWidth, noBorderRadius }: Props) {
  const { getImageUrlBuilder } = useSanityContextActions();

  const imageUrl = getImageUrlBuilder(image)
    .height(height * 2)
    .quality(50)
    .url();

  return (
    <CardMedia
      component="img"
      height={height}
      image={imageUrl}
      sx={{
        ...cardMediaSx,
        width: imageWidth ? imageWidth : '100%',
        borderRadius: noBorderRadius ? 0 : '7px',
      }}
    />
  );
}

const cardMediaSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  objectFit: 'cover',
  objectPosition: 'center',
};
