import {
  alpha,
  Checkbox as MUICheckbox,
  CheckboxProps,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import { CheckboxIcon, CheckboxIconChecked } from '@shared/custom-icons';
import { useState } from 'react';

interface Props extends CheckboxProps {
  htmlColor?: string;
}

const iconStyle: SxProps<Theme> = ({ palette }) => ({
  backgroundColor: palette.common.white,
  color: alpha(palette.common.black, 0.5),
});

const iconCheckedStyle: SxProps<Theme> = ({ palette }) => ({
  backgroundColor: palette.common.white,
  color: alpha(palette.common.black, 0.5),
  '.check': { color: palette.text.primary },
});

/**
 * @deprecated This component is deprecated, use MUI Checkbox instead
 */
const Checkbox = (props: Props) => {
  const { palette } = useTheme();
  const [focused, setFocused] = useState(false);
  const { htmlColor, ...rest } = props;

  return (
    <MUICheckbox
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      sx={
        focused
          ? {
              '&.Mui-focusVisible': {
                filter: 'drop-shadow(2px 2px 4px rgba(105, 105, 105, 0.4))',
              },
            }
          : {}
      }
      icon={
        <CheckboxIcon sx={iconStyle} htmlColor={htmlColor ? htmlColor : palette.common.white} />
      }
      checkedIcon={<CheckboxIconChecked sx={iconCheckedStyle} />}
      size="small"
      {...rest}
    />
  );
};

export default Checkbox;
