import { Button, FormControl, FormLabel, Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface ButtonField {
  value: string;
  label: string;
}

interface Props {
  arrayName: string;
  fields: ButtonField[];
  legend?: ReactNode;
}

export default function FormButtonsArray({ arrayName, fields, legend }: Props) {
  const { control } = useFormContext();

  return (
    <FormControl component="fieldset">
      {legend && <FormLabel component="legend">{legend}</FormLabel>}
      <Controller
        name={arrayName}
        control={control}
        render={({ field: { onChange, value: arrayValue } }) => {
          const checkedButtons = arrayValue as string[];
          return (
            <Grid container spacing={1}>
              {fields.map(({ label, value }) => (
                <FormButton
                  value={value}
                  label={label}
                  checkedButtons={checkedButtons}
                  onChange={onChange}
                  key={value}
                />
              ))}
            </Grid>
          );
        }}
      />
    </FormControl>
  );
}

interface FormButtonProps {
  value: string;
  label: string;
  checkedButtons: string[];
  onChange: (event: any) => void;
}

const FormButton = React.memo(
  ({ value, label, onChange, checkedButtons }: FormButtonProps) => {
    const isChecked = checkedButtons.some((checked) => checked === value);
    const newCheckedButtonsValue = isChecked
      ? checkedButtons.filter((buttonValue) => buttonValue !== value)
      : checkedButtons.concat(value);
    const onClick = () =>
      onChange({
        target: { value: newCheckedButtonsValue },
      });

    return (
      <Grid item key={value}>
        <Button
          color={isChecked ? 'secondary' : 'primary'}
          variant={isChecked ? 'contained' : 'outlined'}
          onClick={onClick}
        >
          {label}
        </Button>
      </Grid>
    );
  }
);
