export const headerHeight = 196;
export const headerAppBarHeight = 64;
export const orderAppBarHeight = 85;
export const leftBarWidth = 60;
export const drawerWidth = 390;
export const sidebarWidth = 250;
export const sidebarCollapsedWidth = 95;
export const sidebarPortalElementId = 'sidebar-portal';
export const subheaderPortalElementId = 'subheader-portal';
export const headerPortalElementId = 'header-portal';
export const widgetHeight = 255;
