import { ClickAwayListener, IconButton, Tooltip, TooltipProps } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import React, { PropsWithChildren, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDisclosure } from '@shared/hooks';

interface Props extends Pick<TooltipProps, 'placement'> {
  disabled?: boolean;
}

const HelpText: React.FC<PropsWithChildren<Props>> = ({
  placement = 'top',
  disabled,
  children,
}) => {
  const popoverId = useMemo(() => uuidv4(), []);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const id = isOpen ? popoverId : undefined;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={onClose}
        open={isOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={<div>{children}</div>}
        arrow
        id={id}
        placement={placement}
      >
        <IconButton
          color="primary"
          onClick={onOpen}
          disabled={disabled}
          size="small"
          aria-describedby={id}
        >
          <HelpOutline />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default HelpText;
