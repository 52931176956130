import { ButtonProps } from '@mui/material';
import LoadingButton from './LoadingButton';

interface Props extends Omit<ButtonProps, 'color'> {
  loading?: boolean;
  startIcon?: JSX.Element | null;
  borderRadius?: number;
}
export default function SecondaryButton({ ...rest }: Props) {
  return <LoadingButton color="secondary" {...rest} />;
}
