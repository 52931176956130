import { Card, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SanityAttachment from './SanityAttachment';
import { Attachment } from '@dap-sanity/types';
import { messages } from '@dap-common/i18n';

interface Props {
  attachments: Attachment[];
  onClickPdfAttachment: (attachment: Attachment) => void;
}

function AttachmentsCard({ attachments, onClickPdfAttachment }: Props) {
  const { t } = useTranslation();
  return (
    <Card color="primary" elevation={1} sx={{ px: 0, py: 2 }}>
      <Stack alignItems="start" divider={<Divider sx={{ alignSelf: 'stretch', mx: 2 }} />}>
        <Typography variant="h1" component="h2" sx={{ px: 2, pb: 1 }}>
          {t(messages.common.attachment.label, { ns: 'common' })}
        </Typography>
        {attachments.map((attachment) => (
          <SanityAttachment
            {...attachment}
            onClick={() => onClickPdfAttachment(attachment)}
            key={attachment.url}
          />
        ))}
      </Stack>
    </Card>
  );
}

export default AttachmentsCard;
