import { useBrandTFunction, useBrandadminTFunction } from '@dap-admin/i18n';
import { LocationProperty } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { CheckboxGroup } from '@dap-common/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ContainedButton, Dialog } from '@shared/components';
import { FunctionComponent } from 'react';
import { FormProvider, useController, useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';

const propertiesSchema = yup.object().shape({
  locationPropertyKeys: yup.array(yup.string()).default([]),
});

interface PropertiesSchema {
  locationPropertyKeys: Array<string>;
}

const formId = 'location-properties-form';

const LocationPropertiesForm: FunctionComponent<{
  properties: LocationProperty[];
  save: (locationPropertyKeys: PropertiesSchema) => void;
  formId: string;
}> = ({ properties, save, formId }) => {
  const t = useBrandadminTFunction();
  const { handleSubmit, control } = useFormContext<PropertiesSchema>();

  const propertiesController = useController({ name: 'locationPropertyKeys', control });
  return (
    <form id={formId} onSubmit={handleSubmit(save)}>
      <CheckboxGroup
        {...propertiesController.field}
        options={properties.map((property) => ({ value: property.key, label: property.title }))}
        noOptionsText={t('location.properties.empty')}
      />
    </form>
  );
};

interface Props {
  open: boolean;
  save: (propertyKeys: PropertiesSchema) => void;
  saving: boolean;
  close: () => void;
  locationProperties?: LocationProperty[];
  availableLocationProperties: LocationProperty[];
  brandKey: string;
}

function EditLocationPropertiesDialog({
  open,
  save,
  saving,
  close,
  locationProperties,
  availableLocationProperties,
  brandKey,
}: Props) {
  const t = useAppTFunction();
  const tBrand = useBrandTFunction(brandKey);

  const form = useForm<PropertiesSchema>({
    defaultValues: {
      locationPropertyKeys: locationProperties?.map((property) => property.key),
    },
    resolver: yupResolver(propertiesSchema),
  });
  const { handleSubmit } = form;

  return (
    <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle>
        {`${t('common.edit', { format: 'capitalize' })} ${tBrand(
          'brand.location.locationProperties.name.plural'
        )}`}
      </DialogTitle>

      <DialogContent>
        <FormProvider {...form}>
          <LocationPropertiesForm
            properties={availableLocationProperties}
            save={save}
            formId={formId}
          />
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant="text" size="large" color="grey" onClick={close} disableElevation>
          {t('common.cancel')}
        </Button>
        <ContainedButton loading={saving} onClick={handleSubmit(save)}>
          {t('common.save')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}

export default EditLocationPropertiesDialog;
