import { SpecialOpenHours } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { FlexRow } from '@dap-common/ui';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { LabelValueTable } from '@shared/components';
import PinmetoPinTooltip from '../PinmetoPinTooltip';

interface Props {
  openingHours: SpecialOpenHours;
  hasPinmetoMapping?: boolean;
}

export default function SpecialOpeningHoursBlock({ openingHours, hasPinmetoMapping }: Props) {
  const t = useAppTFunction();

  return (
    <Stack spacing={2}>
      <FlexRow justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant="h6" fontWeight={700}>
          {t('location.detail.openingHours.special.title')}
        </Typography>
        {hasPinmetoMapping && <PinmetoPinTooltip />}
      </FlexRow>

      <LabelValueTable
        labelWidth={120}
        items={openingHours.map((entry) => ({
          label: entry.label,
          value: entry.isClosed
            ? t('location.detail.openingHours.special.closed')
            : entry.openTime + ' - ' + entry.closeTime,
        }))}
      />
    </Stack>
  );
}
