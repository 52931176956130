import { Box } from '@mui/material';

interface Props {
  componentType: any;
  height: string | number;
  width: string | number;
  image: string;
}

export default function BoxBackgroundImage({
  componentType,
  height,
  width,
  image,
  ...props
}: Props) {
  return (
    <Box
      component={componentType}
      src={image}
      height={height}
      width={width}
      alignItems="center"
      position="relative"
      style={{
        aspectRatio: '1 / 1',
        objectFit: 'cover',
        objectPosition: 'center',
        backgroundImage: image && `url(${image})`,
        alignSelf: 'stretch',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '7px',
      }}
      {...props}
    />
  );
}
