import { Box, Collapse } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EcomConfigSchema } from './ecommerceConfigSchema';
import { CheckboxGroup, CheckboxWithLabel, ErrorCard, SaveOrCancelEdit } from '@dap-common/ui';
import { EcomAssortment } from '@dap-admin/types';
import { messages, useBrandTranslation, useTranslationWithBrandOptions } from '@dap-common/i18n';

interface Props {
  cancelEdit: () => void;
  save: (schema: EcomConfigSchema) => void;
  error?: 'errorPostConfig' | 'errorPatchConfig' | 'errorAssortments';
  brandKey: string;
  ecomAssortments: EcomAssortment[];
}

const formId = 'ecommerce-form';

function EcommerceConfigForm({ cancelEdit, save, error, brandKey, ecomAssortments }: Props) {
  const { handleSubmit, watch, control } = useFormContext<EcomConfigSchema>();
  const { t: brandT } = useBrandTranslation(brandKey);
  const { t } = useTranslation('brandDetailsPage');
  const { t: tOptions } = useTranslationWithBrandOptions('brandDetailsPage', brandKey);

  const showController = useController({ name: 'show', control });
  const selectedEcomAssortmentsController = useController({
    name: 'selectedEcomAssortments',
    control,
  });

  return (
    <form id={formId} onSubmit={handleSubmit(save)}>
      <CheckboxWithLabel
        {...showController.field}
        label={tOptions(messages.brandDetailsPage.ecommerceConfig.checkbox, {
          locations: messages.brand.location.name.plural,
        })}
        checked={showController.field.value}
        margin="dense"
      />
      <Collapse in={watch('show')}>
        <Box ml="1rem" mt="0.5rem">
          <CheckboxGroup
            {...selectedEcomAssortmentsController.field}
            label={brandT(messages.brand.location.availableEcomAssortments.form.label)}
            options={ecomAssortments.map((assortment) => ({
              value: assortment.key,
              label: assortment.title,
            }))}
            noOptionsText={t(messages.brandDetailsPage.ecommerceConfig.empty)}
          />
        </Box>
      </Collapse>
      <SaveOrCancelEdit formId={formId} cancel={cancelEdit} align="right" />
      {error && (
        <ErrorCard>{t(messages.brandDetailsPage.locationFieldConfig.errors[error])}</ErrorCard>
      )}
    </form>
  );
}

export default EcommerceConfigForm;
