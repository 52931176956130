import { ReactNode } from 'react';
import { CustomError, QueryError, RTKMutationError, RTKQueryError } from '@dap-admin/types';
import { SerializedError } from '@reduxjs/toolkit';
import { Alert } from '@mui/material';

export type Error = (CustomError | QueryError | SerializedError | undefined) & {
  customErrorText: string;
};

export function getErrorComponent(error: Error): ReactNode {
  if (!error) {
    return;
  }

  // custom & query error
  if ('status' in error && 'data' in error) {
    // custom error
    if ('message' in error.data) {
      return (
        <Alert key={`${error.status}-${error.data.message}`} severity="error">
          {`${error.customErrorText} (${error.status} ${error.data.message})`}
        </Alert>
      );
    }
    // query error
    return (
      <Alert key={`${error.status}-${error.data.detail}`} severity="error">
        {`${error.customErrorText} (${error.status} ${error.data.title})`}
      </Alert>
    );
  }

  // serialized error
  return (
    <Alert key={`${error.code}-${error.message}`} severity="error">
      {`${error.customErrorText} (${error.code} ${error.message})`}
    </Alert>
  );
}

export function getErrorMessage(error: RTKMutationError | RTKQueryError) {
  if (!error) {
    return;
  }

  if ('data' in error && 'message' in error.data) {
    return error.data.message;
  } else if ('data' in error && 'detail' in error.data) {
    return error.data.detail;
  } else {
    return '';
  }
}
