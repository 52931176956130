import { Box } from '@mui/material';
import { headerAppBarHeight, headerHeight, subheaderPortalElementId } from '@dap-common/styles';
import HouseIllustration from './components/HouseIllustration';
import { RecognizedBrands } from '@dap-common/types';
import { MaxWidthWrapper } from '../Layout';

interface SubheaderProps {
  brandKey: RecognizedBrands | undefined;
}

function Subheader({ brandKey }: SubheaderProps) {
  return (
    <Box
      height={`calc(${headerHeight}px - ${headerAppBarHeight}px)`}
      bgcolor={({ palette }) => palette.cardBackgrounds.lightPurple}
      borderBottom={({ palette }) => `1px solid ${palette.border.darkPurple}`}
      display="flex"
      alignItems="flex-end"
    >
      <MaxWidthWrapper
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <Box id={subheaderPortalElementId} sx={{ mb: 3 }}></Box>
        <HouseIllustration brandKey={brandKey} />
      </MaxWidthWrapper>
    </Box>
  );
}

export default Subheader;
