import { LocationBasic } from '@dap-admin/types';
import { messages } from '@dap-common/i18n';
import { FlexRow } from '@dap-common/ui';
import { Chip, Grid, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface ListCardProps {
  location: LocationBasic;
  locationRoute: string;
}

function LocationsListCard({
  location: { name, locationProperties, enabled },
  locationRoute,
}: ListCardProps) {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const properties = useMemo(() => {
    if (enabled) {
      if (locationProperties && locationProperties.length > 0) {
        return locationProperties.map(({ title }) => title);
      }
      return undefined;
    } else {
      return [t(messages.common.location.deactivated)];
    }
  }, [enabled, locationProperties, t]);

  return (
    <Grid
      container
      columnGap={1}
      component={Link}
      to={locationRoute}
      sx={{
        alignItems: 'center',
        color: ({ palette }) => palette.text.primary,
        textDecoration: 'none',
        cursor: 'pointer',
        padding: 1,
        minHeight: '42px',
        paddingLeft: '1rem',
        backgroundColor: enabled ? palette.background.default : palette.warning.light,
        border: `1px solid ${enabled ? palette.border.darkPurple : palette.warning.main}`,
        borderRadius: '7px',
        ':hover': {
          backgroundColor: enabled ? palette.cardBackgrounds.grey : palette.warning.main,
          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.12)',
        },
      }}
    >
      <Grid item xs={4}>
        <Typography
          variant="h5"
          noWrap
          textTransform={'capitalize'}
          textAlign="left"
          flexWrap={'nowrap'}
        >
          {name.toLowerCase()}
        </Typography>
      </Grid>

      <Grid item xs>
        {properties && properties.length > 0 && (
          <FlexRow gap={0.5} overflow={'hidden'}>
            {properties.map((property) => (
              <Chip
                key={property}
                label={property}
                variant="small"
                size="small"
                style={{
                  cursor: 'inherit',
                  border: `1px solid ${enabled ? palette.border.darkPurple : palette.warning.dark}`,
                  backgroundColor: enabled
                    ? palette.cardBackgrounds.lightBlue
                    : palette.warning.main,
                }}
              />
            ))}
          </FlexRow>
        )}
      </Grid>
    </Grid>
  );
}

export default LocationsListCard;
