import { Typography } from '@mui/material';
import { FillMinMax } from '@shared/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { previewCardImageHeight } from './newsConstants';
import NewsPreviewCard from './NewsPreviewCard';
import { messages } from '@dap-common/i18n';
import { SanityNewsArticlePreview } from '@dap-sanity/types';

interface Props {
  newsArticles: SanityNewsArticlePreview[];
  newsUrl: string;
}

const NewsList: React.FC<Props> = ({ newsArticles, newsUrl }) => {
  const { t } = useTranslation('newsArticles');

  if (newsArticles.length === 0) {
    return <Typography>{t(messages.newsArticles.emptyList)}</Typography>;
  }

  return (
    <FillMinMax minWidth="200px" gridGap="1em">
      {newsArticles.map(({ image, title, intro, url, id, author, publishAt }) => {
        return (
          <NewsPreviewCard
            image={image}
            imgHeight={previewCardImageHeight}
            title={title}
            intro={intro}
            url={`${newsUrl}/${url}`}
            author={author}
            publishedAt={publishAt}
            key={id}
          />
        );
      })}
    </FillMinMax>
  );
};

export default NewsList;
