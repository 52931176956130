import { alpha, Box, Stack } from '@mui/material';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { ErrorBoundary } from '@sentry/react';
import React, { useCallback, useState } from 'react';
import { useSanityContextActions } from '@dap-sanity/utils';

interface Props {
  brandImageUrl: string;
  backgroundImage?: SanityImageSource;
  children: React.ReactElement;
}

function ProffInfoBanner({ children, brandImageUrl, backgroundImage }: Props) {
  const [textHeight, setTextHeight] = useState<number>(180);
  const textRef: React.RefCallback<HTMLDivElement> = useCallback((node) => {
    if (node) {
      setTextHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const { getImageUrlBuilder } = useSanityContextActions();

  return (
    <Box
      sx={{
        minHeight: '180px',
        height: textHeight && `${textHeight}px`,
        alignSelf: 'stretch',
        position: 'relative',
        color: ({ palette }) => palette.common.white,
        borderRadius: '7px',
        zIndex: 0,
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          zIndex: 1,
          height: '100%',
          width: '100%',
          bottom: '0',
          background: ({ palette }) => alpha(palette.common.black, 0.4),
          borderRadius: '7px',
        }}
      />
      <Stack
        ref={textRef}
        direction="row"
        sx={{
          px: 3,
          zIndex: 2,
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'start',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <Box maxWidth="420px">{children}</Box>
        <Box
          component="img"
          src={brandImageUrl}
          sx={{
            mt: 2,
            height: '45px',
          }}
        />
      </Stack>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag('sanityImage', 'proffInfoBanner');
        }}
      >
        <Box
          sx={{
            position: 'relative',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            minHeight: '100%',
            height: '100%',
            backgroundImage:
              backgroundImage && `url(${getImageUrlBuilder(backgroundImage).height(600).url()})`,
          }}
        />
      </ErrorBoundary>
    </Box>
  );
}

export default ProffInfoBanner;
