import { Typography } from '@mui/material';
import { messages } from '@dap-common/i18n';
import { getHours } from 'date-fns';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { FlexColumn } from '../../Layout';

interface Props {
  name: string;
  locale: string;
}

export default function GreetingsWithName({ name, locale }: Props) {
  const { t } = useTranslation();

  const today = new Date();
  const formattedDate = dayjs(today).locale(locale).format('dddd LL');
  const currentHour = getHours(today);
  const isNight = currentHour >= 0 && currentHour < 6;
  const isMorning1 = currentHour >= 6 && currentHour < 10;
  const isMorning2 = currentHour >= 10 && currentHour < 12;
  const isAfternoon = currentHour >= 12 && currentHour < 18;
  const isEvening = currentHour >= 18 && currentHour < 24;

  const greetingMessage = () => {
    switch (true) {
      case isNight:
        return t(messages.common.goodNight, { userName: name });

      case isMorning1:
        return t(messages.common.goodMorning1, { userName: name });

      case isMorning2:
        return t(messages.common.goodMorning2, { userName: name });

      case isAfternoon:
        return t(messages.common.goodAfternoon, { userName: name });

      case isEvening:
        return t(messages.common.goodEvening, { userName: name });

      default:
        return name;
    }
  };

  return (
    <FlexColumn>
      <Typography variant="h3" color="primary.dark" paddingBottom={0.5}>
        {greetingMessage()}
      </Typography>
      <Typography
        variant="body3"
        color="text.secondary"
        paragraph
        m={0}
        sx={{ ':first-letter': { textTransform: 'capitalize' } }}
      >
        {formattedDate}
      </Typography>
    </FlexColumn>
  );
}
