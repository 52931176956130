import { ButtonBase, ButtonBaseProps, PaletteColor, styled } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';

type EntityButtonBaseProps = {
  active?: boolean;
  color?: 'primary' | 'secondary' | 'grey' | 'success';
  hoverColor?: 'primary' | 'secondary' | 'grey' | 'success';
};

const EntityButtonBase = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'color' && prop !== 'hoverColor',
})<EntityButtonBaseProps>(({ theme, active = false, color = 'grey', hoverColor }) => {
  const defaultBorderColor =
    color === 'grey' ? theme.palette.grey[200] : (theme.palette[color] as PaletteColor).main;

  const hoverColorValue = hoverColor
    ? hoverColor === 'grey'
      ? theme.palette.grey[400]
      : (theme.palette[hoverColor] as PaletteColor).main
    : defaultBorderColor;

  const borderHoverColor = hoverColor ? hoverColorValue : defaultBorderColor;

  return {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    gap: theme.spacing(0.5),
    maxWidth: active ? '100%' : '80%',
    padding: active ? theme.spacing(1.5, 4) : theme.spacing(1.5, 2),
    border: '1px solid',
    borderColor: defaultBorderColor,
    background: theme.palette.common.white,
    borderTopWidth: '8px',
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: active ? theme.shadows[5] : 'none',
    transition: theme.transitions.create(['borderColor', 'transform'], {
      duration: theme.transitions.duration.standard,
    }),

    '> * ': { width: '100%' },

    ':hover': {
      transform: 'scale(1.05)',
      borderColor: borderHoverColor,
    },
  };
}) as typeof ButtonBase;

export const EntityButton = (
  props: Omit<ButtonBaseProps, 'disabled'> & Partial<LinkProps> & EntityButtonBaseProps
) => {
  return <EntityButtonBase LinkComponent={Link} disabled={!props.to} {...props} />;
};

export default EntityButton;
