import { EcomAssortment } from '@dap-admin/types';
import { messages, useBrandTranslation } from '@dap-common/i18n';
import { Box, Chip, List, Typography } from '@mui/material';
import { alphabeticSort } from '@shared/utils';

interface Props {
  selectedEcomAssortments: EcomAssortment[];
  brandKey: string;
}

function SelectedEcomAssortments({ selectedEcomAssortments, brandKey }: Props) {
  const { t } = useBrandTranslation(brandKey);

  if (selectedEcomAssortments.length === 0) {
    return (
      <Typography variant="body2">
        {t(messages.brand.location.availableEcomAssortments.empty)}
      </Typography>
    );
  }

  return (
    <Box mt={1}>
      <Typography variant="body2" gutterBottom>
        {t(messages.brand.location.availableEcomAssortments.chosen)}:
      </Typography>
      <List disablePadding sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {[...selectedEcomAssortments]
          .sort(alphabeticSort<EcomAssortment>('title'))
          .map(({ key, title }) => (
            <li key={key}>
              <Chip variant="large" label={title} />
            </li>
          ))}
      </List>
    </Box>
  );
}

export default SelectedEcomAssortments;
