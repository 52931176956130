import { Divider, Stack, Typography } from '@mui/material';
import { FillMinMax } from '@shared/components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import EventCard from './EventCard';
import { SanityEventPreview } from '@dap-sanity/types';
import { getDateRange, getFullAddress, groupEventsByMonthYear } from '@dap-sanity/utils';

interface Props {
  events: SanityEventPreview[];
  getEventUrl: (eventId: string) => string;
}

function AllEventPreviews({ events, getEventUrl }: Props) {
  const {
    i18n: { language },
  } = useTranslation();
  const groupedEvents = useMemo(() => groupEventsByMonthYear(events), [events]);

  return (
    <Stack spacing={4} divider={<Divider sx={{ mx: '32px !important' }} />}>
      {Object.entries(groupedEvents).map(([monthYear, events]) => (
        <div key={monthYear}>
          <Typography variant="h2" textTransform="capitalize" mb={2}>
            {new Date(monthYear).toLocaleString(language, {
              month: 'long',
              year: 'numeric',
            })}
          </Typography>
          <FillMinMax minWidth="250px" gridGap="1em">
            {events.map(({ title, id, intro, url, eventInformation, image }) => (
              <EventCard
                key={id}
                title={title}
                intro={intro}
                date={eventInformation.dateTimeRange.startDate}
                href={url && `${getEventUrl(url)}`}
                registrationUrl={eventInformation.registration?.registrationUrl}
                registrationEmail={eventInformation.registration?.registrationEmail}
                place={eventInformation.address && getFullAddress(eventInformation.address)}
                time={getDateRange(eventInformation.dateTimeRange)}
                image={image}
              />
            ))}
          </FillMinMax>
        </div>
      ))}
    </Stack>
  );
}

export default AllEventPreviews;
