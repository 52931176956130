import { isWithinInterval } from 'date-fns';
import { RecognizedBrands } from '@dap-common/types';
import { brandStylesConfig } from '@dap-common/styles';
import { fallHouse, summerHouse, winterHouse } from '@assets/images';

interface HouseIllustrationProps {
  brandKey: RecognizedBrands | undefined;
}

function HouseIllustration({ brandKey }: HouseIllustrationProps) {
  const brandHouseIllustration = brandKey && brandStylesConfig[brandKey]?.houseIcon;
  const today = new Date();
  const currentYear = today.getFullYear();
  const isSummer = isWithinInterval(today, {
    start: new Date(currentYear, 5, 1),
    end: new Date(currentYear, 7, 31, 23, 59, 59),
  });
  const isFall = isWithinInterval(today, {
    start: new Date(currentYear, 8, 1),
    end: new Date(currentYear, 10, 30, 23, 59, 59),
  });
  const isWinter = isWithinInterval(today, {
    start: new Date(currentYear, 11, 1),
    end: new Date(currentYear, 11, 31, 23, 59, 59),
  });

  switch (true) {
    case isSummer:
      return (
        <img src={brandHouseIllustration?.summer || summerHouse} alt="" width="305" height="103" />
      );

    case isFall:
      return (
        <img src={brandHouseIllustration?.fall || fallHouse} alt="" width="305" height="103" />
      );

    case isWinter:
      return (
        <img src={brandHouseIllustration?.winter || winterHouse} alt="" width="305" height="131" />
      );

    default:
      return (
        <img src={brandHouseIllustration?.summer || summerHouse} alt="" width="305" height="103" />
      );
  }
}

export default HouseIllustration;
