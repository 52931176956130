import { Dataowner, DataownerEdit } from '@dap-admin/types';
import { messages, useTranslationWithBrandOptions } from '@dap-common/i18n';
import { FlexRow } from '@dap-common/ui';
import { MoreVert } from '@mui/icons-material';
import { Box, Button, Divider, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import DataownerActionMenu, { SmallScreenDataownerDialog } from './DataownerActionMenu';

interface Props {
  dataowner: Dataowner;
  handleClick: (editItem: keyof DataownerEdit) => void;
  deleteDataowner: () => void;
  brandKey: string;
  isSuperuser: boolean;
  handleAddLocation: () => void;
}

function DataownerDetails({
  dataowner,
  handleClick,
  deleteDataowner,
  brandKey,
  isSuperuser,
  handleAddLocation,
}: Props) {
  const { t: tBrand } = useTranslationWithBrandOptions('pagesHomeDataowner', brandKey);
  const { breakpoints } = useTheme();
  const isLessThanMedium = useMediaQuery(breakpoints.down('md'));

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  return (
    <>
      <Stack paddingBottom={4} divider={<Divider sx={{ margin: '30px 0' }} />}>
        <Box style={{ padding: '0', position: 'relative', flexGrow: 2 }}>
          <FlexRow justifyContent="flex-end" alignItems="flex-start">
            {isLessThanMedium
              ? dataowner.isAdmin && (
                  <Button
                    onClick={(event) => setMenuAnchor(event.currentTarget)}
                    aria-label={tBrand(messages.pagesHomeDataowner.actionMenu.title, {
                      dataowner: messages.brand.dataowner.name.singular,
                    })}
                  >
                    <MoreVert />
                  </Button>
                )
              : dataowner.isAdmin && (
                  <DataownerActionMenu
                    handleClick={handleClick}
                    deleteDataowner={deleteDataowner}
                    dataowner={dataowner}
                    brandKey={brandKey}
                    isSuperuser={isSuperuser}
                    handleAddLocation={handleAddLocation}
                  />
                )}
          </FlexRow>
        </Box>
      </Stack>
      <SmallScreenDataownerDialog
        close={() => setMenuAnchor(null)}
        menuAnchor={menuAnchor}
        handleClick={handleClick}
        deleteDataowner={deleteDataowner}
        dataowner={dataowner}
        brandKey={brandKey}
        isSuperuser={isSuperuser}
        handleAddLocation={handleAddLocation}
      />
    </>
  );
}

export default DataownerDetails;
