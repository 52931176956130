import { messages } from '@dap-common/i18n';
import { FunctionComponent, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { cardInputProps } from './cardInputProps';
import { LocationMgPrisSchema, MgPris } from '@dap-admin/types';
import {
  EditCard,
  LabelValue,
  SaveOrCancelEdit,
  TextInput,
  UniversalFormProvider,
} from '@dap-common/ui';
import { Stack } from '@mui/material';
import { pruneObject } from '@shared/utils';

const mgPrisSchema = yup.object().shape({
  departmentId: yup.string(),
  memberId: yup.string(),
});
interface Props {
  hasAdminRights: boolean;
  mgPris?: MgPris;
  save: (mgPris: LocationMgPrisSchema) => void;
  saving: boolean;
}

interface FormProps extends Omit<Props, 'hasAdminRights' | 'save'> {
  cancel: () => void;
  save: (mgPris: MgPris) => void;
}

const LocationPropertiesForm: FunctionComponent<FormProps> = ({ save, cancel, saving }) => {
  const { t } = useTranslation('pagesHomeLocation');
  const { handleSubmit } = useFormContext<MgPris>();

  const onSubmit = handleSubmit((mgPris) => {
    save(mgPris);
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={1.5}>
        <TextInput
          name="memberId"
          label={t(messages.pagesHomeLocation.mgPrice.memberId)}
          {...cardInputProps}
        />
        <TextInput
          name="departmentId"
          label={t(messages.pagesHomeLocation.mgPrice.departmentId)}
          {...cardInputProps}
        />
        <SaveOrCancelEdit save={onSubmit} cancel={cancel} align="right" loading={saving} />
      </Stack>
    </form>
  );
};

const MgPrisCard: FunctionComponent<Props> = ({ mgPris, save, saving, hasAdminRights }) => {
  const { t } = useTranslation('pagesHomeLocation');
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const saveFn = (updated: MgPris) => {
    save({ mgPrisData: pruneObject(updated, null) });
    setIsEditing(false);
  };

  return (
    <EditCard
      header={t(messages.pagesHomeLocation.mgPrice.name)}
      isEditing={isEditing}
      edit={() => setIsEditing(true)}
      cancel={() => setIsEditing(false)}
      isEditable={hasAdminRights}
      titleTypographyVariant="h6"
      fullHeight
    >
      {isEditing ? (
        <UniversalFormProvider defaultValues={mgPris} schema={mgPrisSchema}>
          <LocationPropertiesForm
            save={saveFn}
            cancel={() => setIsEditing(false)}
            saving={saving}
          />
        </UniversalFormProvider>
      ) : (
        <Stack spacing={1}>
          <LabelValue
            label={t(messages.pagesHomeLocation.mgPrice.memberId)}
            value={mgPris?.memberId || '-'}
          />
          <LabelValue
            label={t(messages.pagesHomeLocation.mgPrice.departmentId)}
            value={mgPris?.departmentId || '-'}
          />
        </Stack>
      )}
    </EditCard>
  );
};

export default MgPrisCard;
