import { useBrandadminTFunction } from '@dap-admin/i18n';
import {
  EmployeeBasicV2,
  EmployeeStatus,
  InformationModelType,
  OrgUnitRole,
} from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { FlexRow, LoadingDefaultComponent } from '@dap-common/ui';
import { EditOutlined } from '@mui/icons-material';
import { Box, IconButton, Link, Typography, useTheme } from '@mui/material';
import {
  GridActionsColDef,
  GridColDef,
  GridInitialState,
  GridRenderCellParams,
  GridRowParams,
  GridSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilter,
  GridValueGetterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { AlarmIcon } from '@shared/custom-icons';
import { DataGridClass } from '@shared/styles';
import { ReactElement, memo, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { EmployeeAlertTooltip } from '../Employee';
import { AppsList, RolesList } from '../List';
import BaseTable from './BaseTable';
import { tableHeight } from '@dap-admin/utils';
import EmployeeActionsMenu, { EmployeeActionsMenuActions } from '../Employee/EmployeeActionsMenu';
import { Chip } from '@shared/components';
import { FilterOperatorSelect } from './components';
import { LocalStorage } from '@shared/constants';

interface Props {
  employees: EmployeeBasicV2[];
  handleSelectedEmployees: (employeeIds: string[]) => void;
  enableSelectOrEdit?: boolean;
  brandKey: string;
  menuActions: EmployeeActionsMenuActions;
  customActions?: ReactElement;
  isSuperuser: boolean;
  orgUnit: InformationModelType;
}

function EmployeeTable({
  employees,
  handleSelectedEmployees,
  enableSelectOrEdit = false,
  menuActions,
  customActions,
  isSuperuser,
  orgUnit,
}: Props) {
  const { palette } = useTheme();
  const t = useAppTFunction();
  const tBrandadmin = useBrandadminTFunction();

  // Save and restore grid state from localStroage
  // https://mui.com/x/react-data-grid/state/#save-and-restore-the-state-from-external-storage
  const apiRef = useGridApiRef();
  const [initialState, setInitialState] = useState<GridInitialState>();
  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState();
      localStorage.setItem(
        `${LocalStorage.DATA_GRID_STATE}${orgUnit}`,
        JSON.stringify(currentState)
      );
    }
  }, [apiRef, orgUnit]);

  useLayoutEffect(() => {
    const stateFromLocalStorage = localStorage?.getItem(
      `${LocalStorage.DATA_GRID_STATE}${orgUnit}`
    );
    setInitialState(stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {});

    window.addEventListener('beforeunload', saveSnapshot);

    return () => {
      window.removeEventListener('beforeunload', saveSnapshot);

      saveSnapshot();
    };
  }, [orgUnit, saveSnapshot]);

  const actionMenuColumn: GridActionsColDef<EmployeeBasicV2> = useMemo(
    () => ({
      field: 'actions',
      type: 'actions',
      headerName: t('brandadmin.action.title'),
      width: 40,
      align: 'center',
      resizable: false,
      renderHeader: () => null,
      getActions: ({ row: employee }: GridRowParams<EmployeeBasicV2>) => [
        <EmployeeActionsMenu actions={menuActions} employee={employee} isSuperuser={isSuperuser} />,
      ],
      sortable: false,
      filterable: false,
      disableReorder: true,
      hideable: false,
    }),

    [t, menuActions, isSuperuser]
  );

  const columns: Array<GridColDef<EmployeeBasicV2>> = useMemo(() => {
    const baseColumns: Array<GridColDef<EmployeeBasicV2>> = [
      {
        field: 'name',
        headerName: t('employee.details.name'),
        minWidth: 250,
        cellClassName: () => DataGridClass.cellBold,
        renderCell: ({ row: employee }: GridRenderCellParams<string, EmployeeBasicV2>) =>
          enableSelectOrEdit ? (
            <FlexRow width="100%" gap={1} flexWrap="nowrap">
              <Link
                color="inherit"
                underline="none"
                component="button"
                onClick={() => menuActions.onEdit(employee)}
                className={DataGridClass.cellLink}
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                noWrap
                textAlign="left"
              >
                {employee.name}
              </Link>
              <IconButton onClick={() => menuActions.onEdit(employee)}>
                <EditOutlined color="action" fontSize="small" className={DataGridClass.cellIcon} />
              </IconButton>
            </FlexRow>
          ) : (
            <Typography
              variant="inherit"
              fontWeight={600}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              noWrap
              textAlign="left"
            >
              {employee.name}
            </Typography>
          ),
      },
      {
        field: 'emailVerified',
        headerName: t('employee.warning.actionRequired'),
        renderHeader: () => <AlarmIcon />,
        align: 'center',
        width: 80,
        type: 'boolean',
        valueGetter: ({ row: employee }: GridValueGetterParams<boolean, EmployeeBasicV2>) =>
          employee.status === EmployeeStatus.UNVERIFIED ||
          employee.status === EmployeeStatus.DISABLED ||
          employee.status === EmployeeStatus.TEMP ||
          employee.status === EmployeeStatus.BLOCKED,
        renderCell: ({
          value: hasWarnings,
          row: employee,
        }: GridRenderCellParams<boolean, EmployeeBasicV2, boolean>) =>
          hasWarnings && (
            <EmployeeAlertTooltip
              unverfied={employee.status === EmployeeStatus.UNVERIFIED}
              blocked={
                employee.status === EmployeeStatus.DISABLED ||
                employee.status === EmployeeStatus.BLOCKED
              }
              tempAccount={employee.status === EmployeeStatus.TEMP}
            >
              <IconButton>
                <AlarmIcon />
              </IconButton>
            </EmployeeAlertTooltip>
          ),
      },
      { field: 'phoneNumber', minWidth: 150, headerName: t('employee.details.phone') },
      {
        field: 'email',
        headerName: t('employee.details.email'),
        minWidth: 300,
        renderCell: ({ row: employee }: GridRenderCellParams<string, EmployeeBasicV2>) => (
          <Link
            component="button"
            onClick={() => (window.location.href = `mailto:${employee.email}`)}
            className={DataGridClass.cellLink}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            noWrap
            textAlign="left"
          >
            {employee.email}
          </Link>
        ),
      },
      {
        field: 'deactivated',
        type: 'boolean',
        minWidth: 120,
        align: 'left',
        headerAlign: 'left',
        headerName: t('brandadmin.filter.status.title'),
        valueGetter: ({ row: employee }: GridValueGetterParams<boolean, EmployeeBasicV2>) =>
          employee.status === EmployeeStatus.DISABLED,
        renderCell: ({ value: disabled }: GridRenderCellParams<boolean, EmployeeBasicV2>) =>
          disabled ? (
            <Chip
              label={t('brandadmin.filter.status.deactivated')}
              backgroundColor={palette.warning.main}
            />
          ) : (
            <Chip label={t('brandadmin.filter.status.active')} />
          ),
        filterOperators: [
          {
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField ||
                !filterItem.operatorValue ||
                filterItem.value === undefined // initially the filter value is set to undefined / user has not selected anything yet. If so, must return null to show all items
              ) {
                return null;
              }
              return ({ value }) => {
                // Convert value to boolean and check if it matches the filter criteria
                return Boolean(value) === Boolean(filterItem.value);
              };
            },
            value: 'is',
            InputComponent: FilterOperatorSelect,
            InputComponentProps: { type: 'boolean' },
          },
        ],
      },
      {
        field: 'roles',
        headerName: t('employee.details.roles'),
        minWidth: 250,
        valueGetter: ({ row: employee }: GridValueGetterParams<OrgUnitRole[], EmployeeBasicV2>) =>
          employee.roles?.map((role) => role.role.title),
        renderCell: ({
          row: employee,
        }: GridRenderCellParams<Array<OrgUnitRole[]>, EmployeeBasicV2>) => (
          <RolesList roles={employee.roles} itemWidth="100%" />
        ),
      },
      {
        field: 'apps',
        headerName: t('employee.filter.app'),
        minWidth: 230,
        valueGetter: ({
          row: employee,
        }: GridValueGetterParams<Array<EmployeeBasicV2['apps']>, EmployeeBasicV2>) =>
          employee.apps?.map((app) => app.name),
        renderCell: ({
          row: employee,
        }: GridRenderCellParams<Array<EmployeeBasicV2['apps']>, EmployeeBasicV2>) => (
          <AppsList apps={employee.apps} itemWidth="100%" />
        ),
      },
    ];

    if (enableSelectOrEdit) {
      return [...baseColumns, actionMenuColumn];
    }

    return baseColumns;
  }, [t, enableSelectOrEdit, menuActions, palette.warning.main, actionMenuColumn]);

  const handleRowSelectionModelChange = (newRowSelectionModel: GridSelectionModel) => {
    if (!enableSelectOrEdit) return;
    handleSelectedEmployees(newRowSelectionModel as string[]);
  };

  if (!initialState) {
    return <LoadingDefaultComponent />;
  }

  return (
    <Box width="100%" display="grid" height={tableHeight}>
      <BaseTable
        columns={columns}
        rows={employees}
        localeText={{
          noRowsLabel: t('employee.noEmployees'),
          toolbarQuickFilterPlaceholder: tBrandadmin('brandadmin.search.title') + '...',
        }}
        getRowId={(row: EmployeeBasicV2) => row.userId}
        checkboxSelection={enableSelectOrEdit}
        onSelectionModelChange={handleRowSelectionModelChange}
        initialState={{ ...initialGridState, ...initialState }}
        components={{
          Toolbar: (props) => CustomGridToolbar(props, customActions),
        }}
        apiRef={apiRef}
      />
    </Box>
  );
}

const CustomGridToolbar = (props: GridToolbarProps, customActions?: ReactElement) => {
  return (
    <GridToolbarContainer {...props}>
      <FlexRow justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
        <FlexRow gap={1}>
          <GridToolbarQuickFilter data-testid="EmployeeTableSearch" />
          <GridToolbarColumnsButton data-testid="EmployeeTableColumns" placeholder={''} />
          <GridToolbarFilterButton data-testid="EmployeeTableFilters" placeholder={''} />
          <GridToolbarDensitySelector data-testid="EmployeeTableDensity" placeholder={''} />
        </FlexRow>
        <FlexRow gap={1}>{customActions}</FlexRow>
      </FlexRow>
    </GridToolbarContainer>
  );
};

const initialGridState: GridInitialState = {
  pinnedColumns: { right: ['actions'] },
  filter: {
    filterModel: {
      items: [{ columnField: 'deactivated', operatorValue: 'is', value: false }],
    },
  },
};

export default memo(EmployeeTable);
