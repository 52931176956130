import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { messages } from '@dap-common/i18n';
import { InputText, SaveOrCancelEdit } from '@dap-common/ui';
import { DapClientIdSchema } from '@dap-admin/types';

interface Props {
  cancelEdit: () => void;
  handleSave: (input: any) => void;
}
function EditDapClientId({ cancelEdit, handleSave }: Props) {
  const { t } = useTranslation(['brandDetailsPage', 'common']);
  const { handleSubmit, control, setFocus } = useFormContext<DapClientIdSchema>();
  const clientIdController = useController({ name: 'employeePortalClientId', control });

  useEffect(() => {
    setFocus('employeePortalClientId');
  }, [setFocus]);

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <InputText
        {...clientIdController.field}
        error={clientIdController.fieldState.error?.message}
        label={t(messages.brandDetailsPage.dapAuth0ClientId.form.title)}
        margin="none"
      />
      <SaveOrCancelEdit save={handleSubmit(handleSave)} cancel={cancelEdit} align="right" />
    </form>
  );
}

export default EditDapClientId;
