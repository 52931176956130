import { InformationModelType } from '@dap-admin/types';
import { FindEventsArgs, FindEventsRequest, FindEventsResponse } from '@dap-datahub/types';
import { DatahubDTO } from '@generated-types';

// TODO: Move to util lib?
export function mapFindEventRequestToDTO(args: FindEventsArgs): DatahubDTO.FindRequest {
  const request: FindEventsRequest = {
    eventName:
      args.entity === InformationModelType.Employee ? 'employee-updated' : 'orgunit-updated',
    eventEntity: args.entity,
    eventVendor: 'no.mestergruppen.iam',
    eventVersion: '1',
    applicationId: 'iam',
    size: args.size ?? 5,
    ...(args.scrollId && { scrollId: args.scrollId }),
    filters: [
      {
        ...(args.entity === InformationModelType.Employee
          ? {
              field: 'eventPayload.userId',
              operator: 'EQ',
              value: args.orgUnitKey,
            }
          : {
              field: 'eventPayload.key',
              operator: 'EQ',
              value: args.orgUnitKey,
            }),
      },
      {
        field: 'eventEntity',
        operator: 'EQ',
        value: args.entity,
      },
    ],
    sorters: [
      {
        field: 'timestamp',
        sortDirection: 'DESC',
      },
    ],
  };

  return { ...request };
}

export function mapFindEventResponseFromDTO<T extends object>(
  response: DatahubDTO.EventsResponse
): FindEventsResponse<T> {
  const payload: FindEventsResponse<T> = {
    ...response,
    scrollId: response.scrollId,
    events: response.events.map((event) => ({
      ...event,
      created: event.created ?? '',
      eventEntity: event.eventEntity ?? '',
      eventEntityId: event.eventEntityId ?? '',
      eventPayloadString: event.eventPayloadString ?? '',
      eventPayload: (event.eventPayload as T) ?? {},
      updatedBy: event.eventPayload ? event.eventPayload['updatedBy'] : '',
    })),
  };
  return payload;
}
