import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { messages, useTranslationWithBrandOptions } from '@dap-common/i18n';
import { useTranslation } from 'react-i18next';
import { InformationModelType } from '@dap-admin/types';
import { AdministratorIcon, SuperAdminIcon } from '@shared/custom-icons';

interface HighestAdminRoleProps {
  highestAdminRole: InformationModelType;
  brandKey: string;
}

export default function HighestAdminRoleListItem({
  highestAdminRole,
  brandKey,
}: HighestAdminRoleProps) {
  const { t } = useTranslation();
  const { t: tBrandOptions } = useTranslationWithBrandOptions('common', brandKey);

  switch (highestAdminRole) {
    case InformationModelType.Super:
      return (
        <ListItem>
          <ListItemIcon>
            <SuperAdminIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t(messages.common.admins.superAdmin)}</ListItemText>
        </ListItem>
      );
    case InformationModelType.Brand:
      return (
        <ListItem>
          <ListItemIcon>
            <AdministratorIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t(messages.common.admins.brandAdmin)}</ListItemText>
        </ListItem>
      );
    case InformationModelType.Dataowner:
      return (
        <ListItem>
          <ListItemIcon>
            <AdministratorIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {tBrandOptions(messages.common.admins.dataownerAdmin, {
              dataowner: messages.brand.dataowner.name.singular,
            })}
          </ListItemText>
        </ListItem>
      );
    case InformationModelType.Location:
      return (
        <ListItem>
          <ListItemIcon>
            <AdministratorIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {tBrandOptions(messages.common.admins.locationAdmin, {
              location: messages.brand.location.name.singular,
            })}
          </ListItemText>
        </ListItem>
      );
    default:
      return null;
  }
}
