import { IconButton, Menu, MenuItem, Stack } from '@mui/material';
import { Flag } from '@shared/components';
import { messages } from '@dap-common/i18n';
import { getDTOLanguageKeyFromResource } from '@shared/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageCapitalLettersCode, LanguageCode } from '@shared/types';

interface Props {
  selectedLanguage: string;
  switchLanguage: (languageCode: LanguageCapitalLettersCode) => void;
}

const LanguageMenu = ({ selectedLanguage, switchLanguage }: Props) => {
  const { t } = useTranslation();
  const [country, setCountry] = useState<LanguageCapitalLettersCode>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleLanguageButtons = (newLanguage: LanguageCapitalLettersCode) => {
    switchLanguage(newLanguage);
    setAnchorEl(null);
  };

  useEffect(() => {
    setCountry(getDTOLanguageKeyFromResource(selectedLanguage as LanguageCode));
  }, [selectedLanguage]);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignContent="center"
      sx={{
        bgcolor: ({ palette }) => palette.common.white,
        width: 32,
        height: 32,
        borderRadius: 0.7,
        border: ({ palette }) => `1px solid ${palette.border.darkPurple}`,
      }}
    >
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        aria-label={t(messages.common.changeLanguage)}
        sx={{
          alignSelf: 'center',
        }}
      >
        <Flag country={country} size="1.125rem" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {Object.values(LanguageCapitalLettersCode).map((language) => (
          <MenuItem
            key={language}
            value={language}
            onClick={() => handleLanguageButtons(language)}
            sx={{
              paddingY: 1,
            }}
          >
            <Flag country={language} size="1.125rem" />
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default LanguageMenu;
