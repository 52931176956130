import { LinkProps } from '@mui/material';
import InlineLink from './InlineLink';

interface Props extends LinkProps {
  href: string;
}

function InlineExternalLink({ children, ...linkProps }: Props): JSX.Element {
  return (
    <InlineLink target="_blank" rel="noopener noreferrer" {...linkProps}>
      {children}
    </InlineLink>
  );
}

export default InlineExternalLink;
