import { Edit } from '@mui/icons-material';
import {
  alpha,
  Box,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { messages, useTranslationWithBrandOptions } from '@dap-common/i18n';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dataowner, DataownerEdit, EnabledOption } from '@dap-admin/types';
import {
  AddEntityIcon,
  ProhibitEntityIcon,
  SuperAdminIcon,
  UnlockEntityIcon,
} from '@shared/custom-icons';

interface Props {
  handleClick: (prop: keyof DataownerEdit) => void;
  handleAddLocation: () => void;
  deleteDataowner: () => void;
  dataowner: Dataowner;
  brandKey: string;
  isSuperuser: boolean;
}

function DataownerActionMenu(props: Props) {
  const { palette } = useTheme();
  const { t } = useTranslation('pagesHomeDataowner');

  return (
    <Box
      sx={{
        border: `1px solid ${palette.border.darkPurple}`,
        backgroundColor: palette.cardBackgrounds.lightPurple,
        borderRadius: '0.65rem',
        paddingY: '1.25rem',
        boxShadow: `0px 4px 4px 0px ${alpha(palette.common.black, 0.25)} `,
      }}
    >
      <Typography variant="h3" padding={'0 0 5px 15px'}>
        {t(messages.pagesHomeDataowner.actionMenu.title)}
      </Typography>
      <MenuList>
        <MenuItems {...props} />
      </MenuList>
    </Box>
  );
}

interface SmallProps extends Props {
  menuAnchor: HTMLElement | null;
  close: () => void;
}

export function SmallScreenDataownerDialog(props: SmallProps) {
  const { t } = useTranslation('pagesHomeDataowner');
  const { menuAnchor, close } = props;

  return (
    <Menu
      id={t(messages.pagesHomeDataowner.actionMenu.title)}
      anchorEl={menuAnchor}
      keepMounted
      open={!!menuAnchor}
      onClose={close}
    >
      <MenuItems {...props} />
    </Menu>
  );
}

export default DataownerActionMenu;

const MenuItems = ({
  handleClick,
  deleteDataowner,
  dataowner,
  brandKey,
  isSuperuser,
  handleAddLocation,
}: Props) => {
  const { t } = useTranslation('pagesHomeDataowner');
  const { t: tBrand } = useTranslationWithBrandOptions('pagesHomeDataowner', brandKey);
  const [openDeleteTooltip, setOpenDeleteTooltip] = useState<boolean>(false);

  const {
    editDetails,
    delete: deleteDataownerText,
    editLocations,
    deactivate,
    activate,
  } = messages.pagesHomeDataowner.actionMenu;
  const dataownerName = messages.brand.dataowner.name.singular;

  return (
    <>
      <MenuItem onClick={() => handleClick('details')}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <Typography variant="body2">
          {tBrand(editDetails, {
            dataowner: dataownerName,
          })}
        </Typography>
      </MenuItem>

      <MenuItem
        onClick={() => handleAddLocation()}
        aria-label={tBrand(editLocations, {
          locations: messages.brand.location.name.plural,
        })}
      >
        <ListItemIcon>
          <AddEntityIcon />
        </ListItemIcon>
        <Typography variant="body2">
          {tBrand(messages.pagesHomeDataowner.locations.addLocation, {
            location: messages.brand.location.name.singular,
          })}
        </Typography>
      </MenuItem>

      <MenuItem onClick={() => handleClick(EnabledOption.ENABLED)}>
        <ListItemIcon>
          {dataowner.enabled ? <ProhibitEntityIcon /> : <UnlockEntityIcon />}
        </ListItemIcon>
        <Typography variant="body2">
          {tBrand(dataowner.enabled ? deactivate : activate, { dataowner: dataownerName })}
        </Typography>
      </MenuItem>

      {isSuperuser &&
        (!dataowner.locations?.length ? (
          <MenuItem onClick={deleteDataowner} aria-label={t(deleteDataownerText)}>
            <ListItemIcon>
              <SuperAdminIcon color="error" />
            </ListItemIcon>
            <Typography variant="body2">{t(deleteDataownerText)}</Typography>
          </MenuItem>
        ) : (
          <Tooltip
            open={openDeleteTooltip}
            title={tBrand(messages.pagesHomeDataowner.actionMenu.deleteTooltip, {
              locations: messages.brand.location.name.plural,
              dataowner: messages.brand.dataowner.name.definite,
            })}
          >
            <MenuItem
              aria-label={t(deleteDataownerText)}
              disabled
              onMouseEnter={() => setOpenDeleteTooltip(true)}
              onMouseLeave={() => setOpenDeleteTooltip(false)}
              sx={{
                '&.Mui-disabled': {
                  pointerEvents: 'auto',
                  ':hover': {
                    bgcolor: 'transparent',
                  },
                },
              }}
            >
              <ListItemIcon>
                <SuperAdminIcon color="error" />
              </ListItemIcon>
              <Typography variant="body2">{t(deleteDataownerText)}</Typography>
            </MenuItem>
          </Tooltip>
        ))}
    </>
  );
};
