import { Box, useTheme } from '@mui/material';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { ErrorBoundary } from '@sentry/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSanityContextActions } from '@dap-sanity/utils';
import { ExpandableImage, SanityImageErrorComponent } from '@dap-common/ui';
import { messages } from '@dap-common/i18n';

interface Props {
  imgSrc: SanityImageSource;
}

export default function SanityImage({ imgSrc }: Props) {
  return (
    <Box sx={{ margin: '2em 0' }}>
      <ErrorBoundary
        fallback={<SanityImageErrorComponent />}
        beforeCapture={(scope) => {
          scope.setTag('sanityImage', JSON.stringify(imgSrc));
        }}
      >
        <ExpandableImageContainer imgSrc={imgSrc} />
      </ErrorBoundary>
    </Box>
  );
}

const ExpandableImageContainer = ({ imgSrc }: Props) => {
  const { t } = useTranslation();
  const { breakpoints } = useTheme();
  const { getImageUrlBuilder } = useSanityContextActions();

  const src = useMemo(
    () =>
      getImageUrlBuilder(imgSrc)
        .width(breakpoints.values.sm * 2)
        .fit('min')
        .url(),
    [breakpoints.values.sm, getImageUrlBuilder, imgSrc]
  );

  const expandedSrc = useMemo(
    () =>
      getImageUrlBuilder(imgSrc)
        .width(window.innerWidth * 2)
        .url(),
    [getImageUrlBuilder, imgSrc]
  );

  return (
    <ExpandableImage
      imgSrc={src}
      expandedImgSrc={expandedSrc}
      alt={t(messages.common.noAltText)}
      imgStyle={{ display: 'block', maxWidth: '100%' }}
    />
  );
};
