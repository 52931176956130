import { BoxProps, CircularProgress } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { CenterElement } from '../Layout';

interface Props extends BoxProps {
  loading: boolean;
}

const WithSpinner: FunctionComponent<Props> = ({ loading, children, ...props }) =>
  loading ? (
    <CenterElement {...props}>
      <CircularProgress color="primary" />
    </CenterElement>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );

export default WithSpinner;
