/* eslint-disable @typescript-eslint/no-restricted-imports */
import { Close } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, PaperProps, Stack } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  open?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  title?: string;
  content: ReactNode;
  actionButtons?: ReactNode;
  handleClose: () => void;
  closeOnBackdropClick?: boolean;
  variant?: PaperProps['variant'];
}

export default function StyledDialog({
  open = true,
  size = 'sm',
  title,
  content,
  actionButtons,
  handleClose,
  closeOnBackdropClick = true,
  variant = 'default',
}: Props) {
  const handleDialogClose = () => {
    if (!closeOnBackdropClick) {
      return;
    }
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperProps={{
        variant: variant,
      }}
      maxWidth={size}
      fullWidth
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <DialogTitle>{title}</DialogTitle>
        <IconButton onClick={handleClose}>
          <Close fontSize="small" />
        </IconButton>
      </Stack>
      <DialogContent>{content}</DialogContent>
      {actionButtons && (
        <DialogActions>
          <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
            {actionButtons}
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
}
