import { Button, styled, SxProps } from '@mui/material';
import { useDisclosure } from '@shared/hooks';
import StyledDialog from '../Dialogs/StyledDialog';

interface Props {
  imgSrc: string;
  expandedImgSrc: string;
  alt: string;
  imgStyle?: SxProps;
}

function ExpandableImage({ imgSrc, expandedImgSrc, alt, imgStyle }: Props) {
  const { isOpen: isModalOpen, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();

  return (
    <>
      <Button onClick={onOpenModal} sx={buttonSx}>
        <StyledPreviewImage src={imgSrc} alt={alt} sx={imgStyle} />
      </Button>
      <StyledDialog
        open={isModalOpen}
        size="xl"
        content={
          <Button
            onClick={onCloseModal}
            sx={{ padding: '0', border: 'none', background: 'transparent' }}
          >
            <StyledExpandedImage src={expandedImgSrc} alt={alt} />
          </Button>
        }
        handleClose={onCloseModal}
      />
    </>
  );
}

const StyledPreviewImage = styled('img')({
  cursor: 'zoom-in',
});

const StyledExpandedImage = styled('img')({
  cursor: 'zoom-out',
  maxWidth: '100%',
  maxHeight: 'calc(100vh - 64px)',
});

const buttonSx: SxProps = {
  width: '100%',
  padding: '0',
  border: 'none',
  background: 'transparent',
  '&:hover': {
    boxShadow: 'none',
    border: 'none',
  },
};

export default ExpandableImage;
