import { AppBar, Box, SxProps, Theme, Toolbar } from '@mui/material';
import LanguageMenu from './components/LanguageMenu';
import { headerPortalElementId } from '@dap-common/styles';
import { MaxWidthWrapper } from '../Layout';
import { LanguageCapitalLettersCode } from '@shared/types';
import { useMatch } from 'react-router-dom';
import { routes } from '@dap-common/consts';

interface Props {
  switchLanguage: (languageCode: LanguageCapitalLettersCode) => void;
  language: string;
}

function Header({ switchLanguage, language }: Props) {
  const isBrandAdminRoute = useMatch(`${routes.brandadmin}/*`);

  return (
    <AppBar position="sticky">
      <MaxWidthWrapper {...(isBrandAdminRoute && { ...brandadminWrapper })}>
        <Toolbar sx={toolbarSx} disableGutters>
          <HeaderPortalPlaceholder />
          <LanguageMenu selectedLanguage={language} switchLanguage={switchLanguage} />
        </Toolbar>
      </MaxWidthWrapper>
    </AppBar>
  );
}

export default Header;

function HeaderPortalPlaceholder() {
  return <Box id={headerPortalElementId} flexGrow={1}></Box>;
}

const toolbarSx: SxProps<Theme> = () => ({
  justifyContent: 'space-between',
  alignItems: 'center',
});

const brandadminWrapper = { maxWidth: 'xl', style: { maxWidth: '1824px' } };
