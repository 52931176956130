import { RoleDetailed } from '@dap-admin/types';
import { messages } from '@dap-common/i18n';
import { CheckboxGroup, ErrorCard, SaveOrCancelEdit } from '@dap-common/ui';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface RolesSchema {
  selectedRoles: string[];
}
interface Props {
  roles: RoleDetailed[];
  save: (schema: RolesSchema) => void;
  cancelEdit: () => void;
  error?: boolean;
}

function RolesForm({ roles, save, error, cancelEdit }: Props) {
  const { t } = useTranslation('brandDetailsPage');
  const { handleSubmit, control } = useFormContext<RolesSchema>();
  const onSubmit = handleSubmit(save);

  const selectedRolesController = useController({ name: 'selectedRoles', control });

  return (
    <form onSubmit={onSubmit}>
      <CheckboxGroup
        {...selectedRolesController.field}
        options={roles.map((role) => ({
          value: role.key,
          label: role.title,
        }))}
        noOptionsText={t(messages.brandDetailsPage.availableRoles.empty)}
      />
      <SaveOrCancelEdit save={onSubmit} cancel={cancelEdit} align="right" />
      {error && <ErrorCard />}
    </form>
  );
}

export default RolesForm;
