import { AppTFunction } from '@dap-common/i18n';
import { useMemo } from 'react';
import * as yup from 'yup';

export const useRegionSchema = (t: AppTFunction) => {
  const schema = useMemo(
    () =>
      yup.object({
        name: yup.string().default('').required(t('validation.required')),
        key: yup.string().default('').required(t('validation.required')),
      }),
    [t]
  );

  return schema;
};

export type RegionSchema = yup.InferType<ReturnType<typeof useRegionSchema>>;
