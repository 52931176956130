import {
  Attachment,
  EventInformation,
  KeyInfo,
  Person,
  SanityBlockContent,
} from '@dap-sanity/types';
import { Grid, Stack, Typography } from '@mui/material';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { useState } from 'react';
import { PublisherInfo, SanityBody, SanityImage } from './index';
import AttachmentsCard from './AttachmentsCard';
import EventInformationCard from './EventInformationCard';
import KeyInfoCard from './KeyInfoCard';
import PdfModal from './PdfModal';

interface Props {
  title: string;
  intro: string;
  image?: SanityImageSource;
  body?: SanityBlockContent[];
  attachments?: Attachment[];
  keyInfo?: KeyInfo;
  author?: Person;
  publishedAt?: string;
  eventInformation?: EventInformation;
}

export default function SanityArticle({
  title,
  intro,
  image,
  body,
  attachments,
  keyInfo,
  author,
  publishedAt,
  eventInformation,
}: Props): JSX.Element {
  const showKeyInfo =
    !!keyInfo?.email?.length || !!keyInfo?.link?.length || !!keyInfo?.text?.length;
  const showAttachments = !!attachments?.length;
  const showEventInformationCard = !!eventInformation;

  const [activePdfAttachment, setActivePdfAttachment] = useState<Attachment | null>(null);

  return (
    <Grid container spacing={4}>
      <Grid item lg={8}>
        <Typography variant="h1" mb={4}>
          {title}
        </Typography>
        <Typography variant="subtitle1">{intro}</Typography>
        {(author || publishedAt) && <PublisherInfo author={author} publishedAt={publishedAt} />}
        {image && <SanityImage imgSrc={image} />}
        {body && <SanityBody body={body} />}
      </Grid>
      {(showKeyInfo || showAttachments || showEventInformationCard) && (
        <Grid
          item
          xs={12}
          lg={4}
          position="sticky"
          top="4rem"
          style={{
            height: 'min-content',
          }}
        >
          <Stack spacing={2}>
            {showEventInformationCard && <EventInformationCard {...eventInformation} />}
            {showAttachments && (
              <AttachmentsCard
                attachments={attachments}
                onClickPdfAttachment={setActivePdfAttachment}
              />
            )}
            {showKeyInfo && <KeyInfoCard keyInfo={keyInfo} />}
          </Stack>
        </Grid>
      )}
      {!!activePdfAttachment && (
        <PdfModal url={activePdfAttachment.url} onClose={() => setActivePdfAttachment(null)} />
      )}
    </Grid>
  );
}
