import { useBrandTFunction } from '@dap-admin/i18n';
import { LocationProperty } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { UniversalFormProvider } from '@dap-common/ui';
import { Chip, List, Typography } from '@mui/material';
import { useState } from 'react';
import PropertiesForm from '../../Forms/PropertiesForm/PropertiesForm';
import {
  LocationPropertiesSchema,
  usePropertiesSchema,
} from '../../Forms/PropertiesForm/propertiesSchema';
import BrandConfigCard from '../BrandConfigCard/BrandConfigCard';

interface Props {
  updateLocationProperties: (locationProperties: LocationPropertiesSchema) => void;
  locationProperties?: LocationProperty[];
  brandKey: string;
  hasAdminRights: boolean;
}

function AvailableLocationProperties({
  updateLocationProperties,
  locationProperties = [],
  brandKey,
  hasAdminRights,
}: Props) {
  const t = useAppTFunction();
  const tBrand = useBrandTFunction(brandKey);
  const [isEditing, setIsEditing] = useState(false);

  const schema = usePropertiesSchema(t);

  const defaultValues: LocationPropertiesSchema = {
    properties: locationProperties.length
      ? locationProperties.map((property) => ({
          ...property,
          description: property.description ?? '',
        }))
      : [{ key: '', title: '', description: '' }],
  };

  const save = (properties: LocationPropertiesSchema) => {
    updateLocationProperties(properties);
    setIsEditing(false);
  };

  return (
    <BrandConfigCard
      header={t('brandDetailsPage.availableLocationProperties.name.plural', {
        locationProperties: tBrand('brand.location.locationProperties.name.plural'),
      })}
      isEditing={isEditing}
      handleEdit={() => setIsEditing(true)}
      handleCancelEdit={() => setIsEditing(false)}
      hasAdminRights={hasAdminRights}
    >
      {isEditing ? (
        <UniversalFormProvider
          schema={schema}
          defaultValues={defaultValues}
          shouldUnregister={true}
        >
          <PropertiesForm save={save} cancel={() => setIsEditing(false)} brandKey={brandKey} />
        </UniversalFormProvider>
      ) : locationProperties.length ? (
        <List disablePadding sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
          {locationProperties.map(({ key, title, description }) => (
            <li key={key}>
              <Chip label={title} variant="large" title={description || ''} />
            </li>
          ))}
        </List>
      ) : (
        <Typography variant="body2">
          {t('brandDetailsPage.availableLocationProperties.empty')}
        </Typography>
      )}
    </BrandConfigCard>
  );
}

export default AvailableLocationProperties;
