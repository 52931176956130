import {
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import LoadingDefaultComponent from '../Fallbacks/LoadingDefaultComponent';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Cached } from '@mui/icons-material';
import { CenterElement } from '../Layout';
import { LoadingErrorComponent } from '../Wrappers';
import { dotsSvg, wavesSvg } from '@assets/images';

interface Props {
  href: string;
  icon: React.ReactElement;
  value: number;
  title: string;
  variant?: Variant;
  color?: Color;
}

type Variant = 'default' | 'compact';
type Color = 'default' | 'purple' | 'yellow';

function ToolCard({ href, icon, value, title, variant = 'default', color = 'default' }: Props) {
  const renderContent = () => {
    switch (variant) {
      case 'compact':
        return <CompactView title={title} value={value} />;

      default:
        return <DefaultView title={title} value={value} />;
    }
  };

  const backgroundStyle = getBackgroundStyle(color);
  const hover = getHoverStyle(variant);

  return (
    <Card
      sx={[
        ...(Array.isArray(baseCardStyle) ? baseCardStyle : [baseCardStyle]),
        ...(Array.isArray(backgroundStyle) ? backgroundStyle : [backgroundStyle]),
        ...(Array.isArray(hover) ? hover : [hover]),
      ]}
    >
      <CardActionArea disabled={!href} href={href} target={'_blank'} sx={cardActionArea}>
        <CardContent sx={cardContentStyle}>
          <Stack justifyContent="space-between" spacing={1}>
            {icon}
            {renderContent()}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default ToolCard;

type ViewProps = Pick<Props, 'title' | 'value'>;

const DefaultView = ({ title, value }: ViewProps) => {
  return (
    <Stack
      direction="row"
      flexGrow={1}
      justifyContent="space-between"
      alignItems="flex-end"
      pl={0.7}
    >
      <Typography variant="body1" component="span" id="card-title">
        {title}
      </Typography>
      <Typography fontSize={50} fontWeight={500} component="span" lineHeight={1} id="card-value">
        {value}
      </Typography>
    </Stack>
  );
};

const CompactView = ({ title, value }: ViewProps) => {
  return (
    <Stack flexGrow={1} justifyContent="space-between" pl={0.7}>
      <Typography variant="body1" component="span" id="card-title">
        {value}
      </Typography>
      <Typography variant="body1" component="span" id="card-value">
        {title}
      </Typography>
    </Stack>
  );
};

export const LoadingToolCard = () => {
  return (
    <Card sx={baseCardStyle}>
      <LoadingDefaultComponent />
    </Card>
  );
};

export const ErrorToolCard: LoadingErrorComponent<FetchBaseQueryError> = ({ reload }) => {
  return (
    <Card>
      <CenterElement>
        <IconButton onClick={reload}>
          <Cached sx={{ fontSize: '62px', transform: 'scaleX(-1) rotate(45deg)' }} />
        </IconButton>
      </CenterElement>
    </Card>
  );
};

const getBackgroundStyle = (color: Color) => {
  switch (color) {
    case 'purple':
      return purpleBackgroundStyle;

    case 'yellow':
      return yellowBackgroundStyle;

    default:
      return defaultBackgroundStyle;
  }
};

const getHoverStyle = (variant: Variant) => {
  switch (variant) {
    case 'compact':
      return {}; //TODO: JCS 19.12.22 Add hover style

    default:
      return defaultHoverStyle;
  }
};

const baseCardStyle: SxProps<Theme> = () => ({
  p: 0,
  border: 0,
  height: '136px',
  borderRadius: '10px',
});

const purpleBackgroundStyle: SxProps<Theme> = ({ palette }) => ({
  color: palette.common.white,
  background: `left bottom no-repeat url(${wavesSvg}), linear-gradient(282.61deg, #512AFC 0.09%, #876BFF 98.77%)`,
  transition: 'all 600ms ease-out',

  ':hover, :focus-within': {
    background: `right bottom no-repeat url(${wavesSvg}), linear-gradient(282.61deg, #512AFC 0.09%, #876BFF 98.77%)`,
  },
});

const yellowBackgroundStyle: SxProps<Theme> = ({ palette }) => ({
  color: palette.text.primary,
  background: `right bottom no-repeat url(${dotsSvg}), linear-gradient(316.01deg, #FFAD0F 6.18%, #FEC047 98.85%)`,
  transition: 'all 600ms ease-out',

  ':hover, :focus-within': {
    background: `right center no-repeat url(${dotsSvg}), linear-gradient(316.01deg, #FFAD0F 6.18%, #FEC047 98.85%)`,
  },
});

const defaultBackgroundStyle: SxProps<Theme> = ({ palette }) => ({
  border: `1px solid ${palette.border.darkPurple}`,
  background: 'linear-gradient(119.63deg, #FFFFFF 2.22%, rgba(236, 242, 255, 0.2) 97.65%)',
});

const defaultHoverStyle: SxProps<Theme> = () => ({
  '& span#card-title': {
    paddingBottom: '0',
    transition: 'all 400ms ease-out',
  },

  '& span#card-value': {
    transform: 'none',
    transition: 'all 400ms ease-out',
  },

  ':hover, :focus-within': {
    '& span#card-title': {
      paddingBottom: '5px',
      transition: 'all 400ms ease-out',
    },
    '& span#card-value': {
      transform: 'scale(1.2)',
      transition: 'all 400ms ease-out',
    },
  },
});

const cardContentStyle: SxProps<Theme> = {
  height: '100%',
  width: '100%',
  p: '20px',

  '* > .MuiSvgIcon-root': {
    fontSize: 40,
  },
};

const cardActionArea: SxProps<Theme> = {
  height: '100%',
  width: '100%',

  '.MuiCardActionArea-focusHighlight': {
    background: 'transparent',
  },
};
