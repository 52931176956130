import { ComponentType } from 'react';
import NoAccessComponent from './NoAccessComponent';

interface Props {
  children: JSX.Element;
  CustomNoAccessComponent?: ComponentType;
  userHasAccess: boolean;
}

function RestrictAccess({
  children,
  CustomNoAccessComponent,
  userHasAccess,
}: Props): JSX.Element | null {
  if (userHasAccess) {
    return children;
  }

  return CustomNoAccessComponent ? <CustomNoAccessComponent /> : <NoAccessComponent />;
}

export default RestrictAccess;
