import { LocationBasic, LocationProperty } from '@dap-admin/types';
import { useBrandadminRouteOutletContext } from '@dap-admin/utils';
import { WithSpinner } from '@dap-common/ui';
import { Chip, LimitedList, Paper, SearchInputField, TagHeading } from '@shared/components';
import { useBrandTFunction, useBrandadminTFunction } from '@dap-admin/i18n';
import { getLocationRoute } from '@dap-common/consts';
import { Box, Typography, useTheme } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridValueGetterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { DataGridClass } from '@shared/styles';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CTATooltip } from '../Tooltip';
import BaseTable from './BaseTable';

interface Props {
  isLoading: boolean;
  locations: LocationBasic[];
}

const defaultSearchValueParser = (searchText: string) =>
  searchText.split(' ').filter((word) => word !== '');

const debounceMs = 500;

function LocationTable({ isLoading, locations }: Props) {
  const { palette } = useTheme();
  const { selectedBrand: brand } = useBrandadminRouteOutletContext();
  const t = useBrandadminTFunction();
  const tBrand = useBrandTFunction(brand.key);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const navigate = useNavigate();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('location.myLocations.name'),
        flex: 1,
        cellClassName: () => DataGridClass.cellBold,
      },
      {
        field: 'dataowner.name',
        headerName: tBrand('brand.dataowner.name.singular', 'capitalize'),
        flex: 0.85,
        valueGetter: ({ row: location }: GridValueGetterParams<string, LocationBasic>) =>
          location.dataownerName,
      },
      {
        field: 'locationProperties',
        headerName: t('location.properties.title'),
        flex: 1.5,
        renderCell: ({
          value: locationProperties,
        }: GridRenderCellParams<LocationProperty[], LocationBasic>) =>
          locationProperties && (
            <LimitedList
              items={locationProperties.map(({ key, title }: LocationProperty) => (
                <Chip
                  label={title}
                  backgroundColor={
                    key === t('location.properties.deactivated') ? palette.warning.main : undefined
                  }
                />
              ))}
              max={2}
              row
            />
          ),

        valueGetter: ({
          row: location,
        }: GridValueGetterParams<LocationProperty[], LocationBasic>) =>
          getLocationProperties(
            location.enabled,
            location.locationProperties ?? [],
            t('location.properties.deactivated')
          ),
      },
      {
        field: 'hasUnverifiedEmployees',
        headerName: t('location.myLocations.actions'),
        headerAlign: 'center',
        flex: 0.35,
        align: 'center',
        renderCell: ({
          value: hasUnverifiedEmployees,
        }: GridRenderCellParams<boolean, LocationBasic>) =>
          hasUnverifiedEmployees && (
            <CTATooltip
              tooltipTitle={t('employee.warning.unverified')}
              tooltipPlacement="top-end"
            />
          ),
      },
    ],
    [palette.warning.main, t, tBrand]
  );

  const onRowClick = (params: GridRowParams) => {
    navigate(getLocationRoute({ locationKey: params.row.key }));
  };

  const apiRef = useGridApiRef();

  // mostly from GridToolbarQuickFilter.js
  const quickFilterParser = defaultSearchValueParser;

  const updateSearchValue = useCallback(
    (newSearchValue: string) => {
      apiRef.current.setQuickFilterValues(quickFilterParser(newSearchValue));
    },
    [apiRef, quickFilterParser]
  );

  const debouncedUpdateSearchValue = useMemo(
    () => debounce(updateSearchValue, debounceMs),
    [updateSearchValue]
  );

  const handleSearchValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchQuery(event.target.value);
      debouncedUpdateSearchValue(event.target.value);
    },
    [debouncedUpdateSearchValue]
  );

  return (
    <div>
      {/** Wrapping in <div> necessary for correct margin */}
      <Paper>
        <TagHeading marginBottom={4}>
          <Typography variant="h5">
            {t('location.myLocations.title', { locations: tBrand('brand.location.name.plural') })}
          </Typography>
          <SearchInputField
            onChange={handleSearchValueChange}
            value={searchQuery}
            placeholder={t('brandadmin.search.title') + '...'}
          />
        </TagHeading>
        <WithSpinner loading={isLoading} pb={4}>
          <Box width="100%" display="grid" height="580px">
            <BaseTable
              columns={columns}
              rows={locations}
              localeText={{
                noRowsLabel: t('location.noLocations', {
                  locations: tBrand('brand.location.name.plural'),
                }),
                noResultsOverlayLabel: t('brandadmin.search.noResultsFoundOn', {
                  searchWord: searchQuery,
                }),
              }}
              getRowId={(row) => row.uuid}
              onRowClick={onRowClick}
              apiRef={apiRef}
              getRowClassName={() => DataGridClass.cellClickable}
            />
          </Box>
        </WithSpinner>
      </Paper>
    </div>
  );
}

const getLocationProperties = (
  enabled: boolean,
  properties: Array<LocationProperty>,
  deactivatedText: string
) => (enabled ? [] : [{ key: deactivatedText, title: deactivatedText }]).concat(properties);

export default LocationTable;
