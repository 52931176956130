import { DialogContentText, Typography } from '@mui/material';
import { messages } from '@dap-common/i18n';
import { useDisclosure } from '@shared/hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AppsList from './AppsList';
import EditApps from './EditApps';
import { UpdateApps } from './types';
import { PrimaryButton, SaveOrCancelEdit } from '../Buttons';
import { EditCard } from '../Cards';
import { StyledDialog } from '../Dialogs';
import {
  getApps,
  getEditableApps,
  mapAppToCompleteApp,
  mapBasicToAppDetailed,
} from '@dap-admin/utils';
import { AppBasic, AppDetailed } from '@dap-admin/types';

interface Props {
  apps: AppBasic[];
  availableApps: AppDetailed[];
  isEditable: boolean;
  save: (formData: UpdateApps) => void;
  fullHeight?: boolean;
}

const formId = 'edit-app-form';

/**
 * @deprecated will be replaced with a new edit all details modal
 */

export default function EditAppsCard({
  apps = [],
  availableApps,
  isEditable,
  save,
  fullHeight = true,
}: Props) {
  const { t } = useTranslation('pagesHomeLocation');
  const { isOpen: isEditOpen, onOpen: openEdit, onClose: closeEdit } = useDisclosure();

  const allApps = useMemo(() => getEditableApps(apps, availableApps), [apps, availableApps]);

  const saveApps = (selectedApps: string[]) => {
    const { appsToBeRemoved, appsToBeAdded } = getApps(apps, selectedApps);
    save({ appsToBeRemoved, appsToBeAdded });
    closeEdit();
  };

  return (
    <>
      <EditCard
        data-testid="services-list-block"
        header={t(messages.pagesHomeLocation.apps.title, {
          count: apps.length,
        })}
        isEditing={isEditOpen}
        edit={openEdit}
        cancel={closeEdit}
        isEditable={isEditable}
        fullHeight={fullHeight}
      >
        {apps && apps.length > 0 ? (
          <AppsList apps={apps.map((app) => mapAppToCompleteApp(mapBasicToAppDetailed(app)))} />
        ) : (
          <Typography>{t(messages.pagesHomeDataowner.apps.noApps)}</Typography>
        )}
      </EditCard>

      {isEditOpen && (
        <StyledDialog
          open={isEditOpen}
          title={t(messages.pagesHomeLocation.actionMenu.editApps)}
          content={
            availableApps.length > 0 ? (
              <EditApps
                availableApps={allApps}
                apps={apps}
                saveApps={saveApps}
                saving={!isEditOpen}
                formId={formId}
              />
            ) : (
              <DialogContentText>{t(messages.pagesHomeLocation.apps.noApps)}</DialogContentText>
            )
          }
          actionButtons={
            availableApps.length > 0 ? (
              <SaveOrCancelEdit
                formId={formId}
                cancel={closeEdit}
                loading={!isEditOpen}
                align="right"
              />
            ) : (
              <PrimaryButton variant="text" onClick={closeEdit}>
                {t(`common:${messages.common.cancel}`)}
              </PrimaryButton>
            )
          }
          handleClose={closeEdit}
          closeOnBackdropClick={false}
        />
      )}
    </>
  );
}
