import { useBrandTFunction } from '@dap-admin/i18n';
import { useAppTFunction } from '@dap-common/i18n';
import { ErrorCard, SaveOrCancelEdit } from '@dap-common/ui';
import { IconButton, ListItem, Stack, ThemeProvider, Typography } from '@mui/material';
import { FieldArray, TextInputField } from '@shared/components';
import { TrashIcon } from '@shared/custom-icons';
import { themeV2 } from '@shared/styles';
import { Controller, useController, useFieldArray, useFormContext } from 'react-hook-form';
import { LocationPropertiesSchema } from './propertiesSchema';
import { Fragment, ReactNode } from 'react';

interface Props {
  save: (properties: LocationPropertiesSchema) => void;
  cancel: () => void;
  loading?: boolean;
  error?: boolean;
  brandKey: string;
}

function PropertiesForm({ save, cancel, loading, error, brandKey }: Props) {
  const t = useAppTFunction();
  const tBrand = useBrandTFunction(brandKey);
  const { handleSubmit, control } = useFormContext<LocationPropertiesSchema>();

  const propertiesController = useController({ name: 'properties', control });
  const { fields, append, remove } = useFieldArray({ name: 'properties', control });

  return (
    <ThemeProvider theme={themeV2}>
      <FieldArray
        name={propertiesController.field.name}
        error={propertiesController.fieldState.error?.message}
        onAdd={() =>
          append({
            title: '',
            key: '',
            description: '',
          })
        }
        label={''}
        addButtonLabel={t('common.add')}
        fullWidth
      >
        {fields.map((field, index) => (
          <Fragment key={field.key}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              pt={index === 0 ? 0 : 3}
            >
              <Typography typography={'h6'} variant="body2">
                {`${tBrand('brand.location.locationProperties.name.singular', 'capitalize')} ${
                  index + 1
                }`}
              </Typography>
              <IconButton
                aria-label={`${t('common.remove')} ${field.key}`}
                onClick={() => remove(index)}
              >
                <TrashIcon />
              </IconButton>
            </Stack>
            <CustomFieldArrayItem>
              <Stack width={'100%'}>
                <Controller
                  name={`properties.${index}.title`}
                  control={control}
                  render={(props) => (
                    <TextInputField
                      label={t('brandDetailsPage.regions.form.name')}
                      {...props.field}
                      error={props.fieldState.error?.message}
                      fullWidth
                      margin={'dense'}
                    />
                  )}
                />
                <Controller
                  name={`properties.${index}.key`}
                  control={control}
                  render={(props) => (
                    <TextInputField
                      label={t('brandDetailsPage.availableLocationProperties.property.id')}
                      {...props.field}
                      error={props.fieldState.error?.message}
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name={`properties.${index}.description`}
                  control={control}
                  render={(props) => (
                    <TextInputField
                      label={t('brandDetailsPage.availableLocationProperties.property.description')}
                      {...props.field}
                      error={props.fieldState.error?.message}
                      fullWidth
                    />
                  )}
                />
              </Stack>
            </CustomFieldArrayItem>
          </Fragment>
        ))}
      </FieldArray>
      <SaveOrCancelEdit save={handleSubmit(save)} cancel={cancel} loading={loading} align="right" />
      {error && <ErrorCard>{t('common.error.generic')}</ErrorCard>}
    </ThemeProvider>
  );
}

export default PropertiesForm;

interface FieldArrayItemProps {
  children: ReactNode;
}

const CustomFieldArrayItem = ({ children }: FieldArrayItemProps) => {
  return (
    <ListItem
      disableGutters
      disablePadding
      sx={{ alignItems: 'flex-start', ':not(:last-of-type)': { mb: 1 } }}
    >
      {children}
    </ListItem>
  );
};
