import { Card, CardProps } from '@mui/material';
import { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface Props extends CardProps {
  children: ReactNode;
  to: LinkProps['to'];
}

export default function PreviewCard({ children, to, sx, ...props }: Props) {
  return (
    <Card
      component={Link}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: '12px',
        textDecoration: 'none',
        '&:hover img': {
          transform: 'scale(1.02)',
        },
        ...sx,
      }}
      {...props}
      to={to}
    >
      {children}
    </Card>
  );
}
