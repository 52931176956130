import {
  BrandBasic,
  BrandLocationFieldConfig,
  BrandV2,
  EcomAssortment,
  LocationField,
} from '@dap-admin/types';
import {
  BrandBasicDTO,
  BrandDetailDTO,
  BrandLocationFieldConfigDTO,
  EcommerceAssortmentsDTO,
} from '@generated-types';
import {
  appDetailedMapper,
  propertiesMapper,
  regionDetailMapper,
  roleDetailedMapper,
} from './mappers';
import { locationPropertyMapper } from './locationMappers';

export function brandBasicMapper(brandBasicDTO?: BrandBasicDTO): BrandBasic {
  return {
    uuid: '',
    key: '',
    name: '',
    ...brandBasicDTO,
  };
}

export function brandDetailMapper(brandDetailDTO: BrandDetailDTO): BrandV2 {
  const mapped: BrandV2 = {
    uuid: brandDetailDTO.uuid ?? null,
    name: brandDetailDTO.name,
    key: brandDetailDTO.key,
    updatedAt: brandDetailDTO.updatedAt ?? null,
    updatedBy: brandDetailDTO.updatedBy ?? null,
    locationRoles: brandDetailDTO.locationRoles?.map(roleDetailedMapper) ?? [],
    dataownerRoles: brandDetailDTO.dataownerRoles?.map(roleDetailedMapper) ?? [],
    brandRoles: brandDetailDTO.brandRoles?.map(roleDetailedMapper) ?? [],
    regions: brandDetailDTO.regions?.map(regionDetailMapper) ?? [],
    brandLocationFieldConfigs:
      brandDetailDTO.brandLocationFieldConfigs?.map(brandLocationFieldConfigMapper) ?? [],
    employeePortalClientId: brandDetailDTO.employeePortalClientId ?? null,
    locationProperties: brandDetailDTO.locationProperties?.map(locationPropertyMapper) ?? [],
    ecommerceAssortments:
      brandDetailDTO.ecommerceAssortments?.map(ecommerceAssortmentsMapper) ?? [],
    apps: brandDetailDTO.apps?.map(appDetailedMapper) ?? [],
    isAdmin: brandDetailDTO.isAdmin ?? false,
    properties: propertiesMapper(brandDetailDTO.properties),
  };
  return mapped;
}

export function brandLocationFieldConfigMapper(
  brandLocationFieldConfigDTO: BrandLocationFieldConfigDTO
): BrandLocationFieldConfig {
  const brandLocationFieldConfig: BrandLocationFieldConfig = {
    fieldName: brandLocationFieldConfigDTO.fieldName as LocationField,
    show: brandLocationFieldConfigDTO.show,
    id: brandLocationFieldConfigDTO.id,
  };
  return brandLocationFieldConfig;
}

export function ecommerceAssortmentsMapper(
  ecomAssortmentsDTO: EcommerceAssortmentsDTO
): EcomAssortment {
  const ecomAssortment: EcomAssortment = {
    key: ecomAssortmentsDTO.key,
    title: ecomAssortmentsDTO.title,
  };
  return ecomAssortment;
}
