import { ContactV2 } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { ExternalLink, FlexRow } from '@dap-common/ui';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { LabelValueTable } from '@shared/components';
import PinmetoPinTooltip from '../PinmetoPinTooltip';

interface Props {
  contact: ContactV2;
  hasPinmetoMapping?: boolean;
}

export default function ContactBlock({ contact, hasPinmetoMapping }: Props) {
  const t = useAppTFunction();

  return (
    <Stack spacing={2}>
      <FlexRow justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant="h6" fontWeight={700}>
          {t('location.detail.contactInfo')}
        </Typography>
        {hasPinmetoMapping && <PinmetoPinTooltip />}
      </FlexRow>
      <LabelValueTable
        labelWidth={120}
        items={[
          {
            label: t('location.detail.email'),
            value: contact?.email,
          },
          {
            label: t('location.detail.phone'),
            value: contact?.phone,
          },
          {
            label: t('location.detail.homepage'),
            value: contact?.homepage && <ExternalLink href={contact.homepage} />,
          },
        ]}
      />
    </Stack>
  );
}
