import { useAppTFunction } from '@dap-common/i18n';
import { Stack, Tooltip } from '@mui/material';
import { CheckIcon2 } from '@shared/custom-icons';
import PinmetoPinImage from './Pinmeto/PinmetoPinImage';

interface Props {
  title?: string;
}

function PinmetoPinTooltip({ title }: Props) {
  const t = useAppTFunction();
  return (
    <Tooltip title={title ? title : t('location.detail.pinmeto.tooltip.standard')}>
      <Stack
        direction={'row'}
        color={'success.main'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <CheckIcon2 />
        <PinmetoPinImage />
      </Stack>
    </Tooltip>
  );
}

export default PinmetoPinTooltip;
