import { List, Stack } from '@mui/material';
import { AlertTooltip } from '@shared/components';
import { useBrandadminTFunction } from '@dap-admin/i18n';

interface EmployeeAlertTooltipProps {
  unverfied: boolean;
  blocked: boolean;
  tempAccount: boolean | undefined;
  children: JSX.Element;
}

function EmployeeAlertTooltip({
  unverfied,
  blocked,
  tempAccount,
  children,
}: EmployeeAlertTooltipProps) {
  const t = useBrandadminTFunction();
  return (
    <AlertTooltip
      placement="right"
      title={
        <Stack component={List} disablePadding>
          {unverfied && <li>{t('employee.warning.notVerified')}</li>}
          {blocked && <li>{t('employee.warning.blocked')}</li>}
          {tempAccount && <li>{t('employee.warning.tempAccount')}</li>}
        </Stack>
      }
    >
      {children}
    </AlertTooltip>
  );
}

export default EmployeeAlertTooltip;
