import { useAppTFunction } from '@dap-common/i18n';
import { ThemeProvider, Typography } from '@mui/material';
import { Paper, TagHeading } from '@shared/components';
import { themeV2 } from '@shared/styles';

export default function NoAccessComponent() {
  const t = useAppTFunction();
  return (
    <ThemeProvider theme={themeV2}>
      <Paper>
        <TagHeading marginBottom={2}>
          <Typography variant="h5">{t('common.error.forbidden.title')}</Typography>
        </TagHeading>
        <Typography>{t('common.error.forbidden.description')}</Typography>
      </Paper>
    </ThemeProvider>
  );
}
