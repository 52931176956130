import { KeyboardBackspaceSharp } from '@mui/icons-material';
import { Button } from '@mui/material';
import { messages } from '@dap-common/i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

function GoBackLink() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button
      LinkComponent={Link}
      onClick={() => navigate(-1)}
      startIcon={<KeyboardBackspaceSharp />}
      variant="text"
      color="inherit"
      sx={{
        px: 0,
        pl: 0.5,
        pb: 0,
        '&:hover': {
          bgcolor: 'transparent',
        },
        fontSize: '16px',
      }}
    >
      {t(messages.common.goBack)}
    </Button>
  );
}

export default GoBackLink;
