import blinkhusEn from '../resources/en/brands/blinkhus.json';
import bolistEn from '../resources/en/brands/bolist.json';
import defaultEn from '../resources/en/brands/default.json';
import kuloerEn from '../resources/en/brands/kuloer.json';
import mestergruppenEn from '../resources/en/brands/mestergruppen.json';
import mesterhusEn from '../resources/en/brands/mesterhus.json';
import nordbohusEn from '../resources/en/brands/nordbohus.json';
import rotEn from '../resources/en/brands/rot.json';
import systemhusEn from '../resources/en/brands/systemhus.json';
import xlbyggEn from '../resources/en/brands/xlbygg.json';

import blinkhusNo from '../resources/no/brands/blinkhus.json';
import bolistNo from '../resources/no/brands/bolist.json';
import defaultNo from '../resources/no/brands/default.json';
import kuloerNo from '../resources/no/brands/kuloer.json';
import mestergruppenNo from '../resources/no/brands/mestergruppen.json';
import mesterhusNo from '../resources/no/brands/mesterhus.json';
import nordbohusNo from '../resources/no/brands/nordbohus.json';
import rotNo from '../resources/no/brands/rot.json';
import systemhusNo from '../resources/no/brands/systemhus.json';
import xlbyggNo from '../resources/no/brands/xlbygg.json';

import blinkhusSv from '../resources/sv/brands/blinkhus.json';
import bolistSv from '../resources/sv/brands/bolist.json';
import defaultSv from '../resources/sv/brands/default.json';
import kuloerSv from '../resources/sv/brands/kuloer.json';
import mestergruppenSv from '../resources/sv/brands/mestergruppen.json';
import mesterhusSv from '../resources/sv/brands/mesterhus.json';
import nordbohusSv from '../resources/sv/brands/nordbohus.json';
import rotSv from '../resources/sv/brands/rot.json';
import systemhusSv from '../resources/sv/brands/systemhus.json';
import xlbyggSv from '../resources/sv/brands/xlbygg.json';

import { BrandLanguageResources } from '../types/BrandResources';

export const brandI18nResources: BrandLanguageResources = {
  no: {
    brand: defaultNo,
    blinkhus: blinkhusNo,
    bolist: bolistNo,
    kuloer: kuloerNo,
    mestergruppen: mestergruppenNo,
    mesterhus: mesterhusNo,
    nordbohus: nordbohusNo,
    rot: rotNo,
    systemhus: systemhusNo,
    xlbygg: xlbyggNo,
  },
  sv: {
    brand: defaultSv,
    blinkhus: blinkhusSv,
    bolist: bolistSv,
    kuloer: kuloerSv,
    mestergruppen: mestergruppenSv,
    mesterhus: mesterhusSv,
    nordbohus: nordbohusSv,
    rot: rotSv,
    systemhus: systemhusSv,
    xlbygg: xlbyggSv,
  },
  en: {
    brand: defaultEn,
    blinkhus: blinkhusEn,
    bolist: bolistEn,
    kuloer: kuloerEn,
    mestergruppen: mestergruppenEn,
    mesterhus: mesterhusEn,
    nordbohus: nordbohusEn,
    rot: rotEn,
    systemhus: systemhusEn,
    xlbygg: xlbyggEn,
  },
};
