import { messages } from '@dap-common/i18n';
import { WarningLabel } from '@dap-common/ui';
import { CheckCircleRounded, NoAccountsOutlined, NotificationImportant } from '@mui/icons-material';
import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { ContainedButton, Paper, TagHeading } from '@shared/components';
import { ConnectIcon } from '@shared/custom-icons';
import dayjs from 'dayjs';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  isTempAccount: boolean;
  isUnverified: boolean;
  isDeactivated: boolean;
  isBlocked: boolean;
  sendCompleteRegistrationEmail: () => void;
  completeRegistrationEmailSentAt?: string;
}

function EmployeeWarnings({
  isTempAccount,
  isDeactivated,
  isUnverified,
  isBlocked,
  sendCompleteRegistrationEmail,
  completeRegistrationEmailSentAt,
}: Props) {
  const { t } = useTranslation(['pagesHomeEmployees', 'employeePage']);
  const canResendCompleteRegistrationEmail = useMemo(() => {
    if (!completeRegistrationEmailSentAt) {
      return true;
    }
    const now = dayjs();
    const sent = dayjs(completeRegistrationEmailSentAt);

    return now.diff(sent, 'minutes') >= 10;
  }, [completeRegistrationEmailSentAt]);

  return (
    <Paper>
      <TagHeading color="warning" marginBottom={2}>
        <Typography variant="h6">{t(messages.pagesHomeEmployees.alert.title)}</Typography>
      </TagHeading>
      {isDeactivated && (
        <Stack direction="row" spacing={2} alignItems="center" mb={1}>
          <NoAccountsOutlined color="warning" />
          <WarningLabel variant="body2">{t(messages.pagesHomeEmployees.deactivated)}</WarningLabel>
        </Stack>
      )}
      {isBlocked && (
        <Stack direction="row" spacing={2} alignItems="center" mb={1}>
          <NoAccountsOutlined color="warning" />
          <WarningLabel variant="body2">
            {t(messages.employeePage.blocked, { ns: 'employeePage' })}
          </WarningLabel>
        </Stack>
      )}
      {isTempAccount && (
        <Stack direction="row" spacing={2} alignItems="center" mb={1}>
          <ConnectIcon color="warning" />
          <WarningLabel variant="body2">
            {t(messages.pagesHomeEmployees.tempAccountConnect)}
          </WarningLabel>
        </Stack>
      )}
      {isUnverified && (
        <>
          <Stack direction="row" alignItems="center" spacing={2} mb={1}>
            <NotificationImportant color="warning" />
            <WarningLabel variant="body2">
              {t(messages.pagesHomeEmployees.notVerifiedAccount)}
            </WarningLabel>
          </Stack>
          {canResendCompleteRegistrationEmail ? (
            <ContainedButton
              color="warning"
              size="small"
              onClick={sendCompleteRegistrationEmail}
              sx={buttonSx}
            >
              {t(`employeePage:${messages.employeePage.initialPassword.send}`)}
            </ContainedButton>
          ) : (
            <Stack direction="row" spacing={2} alignItems="center">
              <CheckCircleRounded color="success" />
              <Typography variant="body2">
                {t(messages.employeePage.initialPassword.sent, { ns: 'employeePage' })}
              </Typography>
            </Stack>
          )}
        </>
      )}
    </Paper>
  );
}

const buttonSx: SxProps<Theme> = { ml: 4.75 };

export default memo(EmployeeWarnings);
