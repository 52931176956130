import { ArticleType, ModuleType } from '@dap-sanity/types';
import { groqQuery } from '@shared/utils';

export interface GetNewsArticlesPreviewQueryProps {
  brandKey: string;
  brandName: string;
  limit?: number;
}

export const getNewsArticlePreviewsQuery = ({
  brandKey,
  limit,
}: Omit<GetNewsArticlesPreviewQueryProps, 'brandName'>) => {
  return groqQuery({
    filters: [
      `"${brandKey}" in brand`,
      `_type == "${ArticleType.standard}"`,
      `module == "${ModuleType.newsModule}"`,
      `(defined(publishAt) && dateTime(publishAt) <= dateTime(now()))`,
    ],
    ordering: [{ fieldname: 'publishAt', order: 'desc' }],
    projections: [
      '"id": _id',
      '"url": url.current',
      'intro',
      '"image": image.asset->',
      'title',
      'author[]->',
      'publishAt',
    ],
    slice: limit ? { start: 0, end: limit } : undefined,
  });
};
