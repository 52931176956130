import { Card, CardActions, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { PrimaryButton, WarningButton } from '../Buttons';

interface Props {
  title: string;
  info?: string;
  handleClick: () => void;
  buttonText: string;
  warning?: boolean;
  isLoading?: boolean;
}

function ActionCard({ title, info, handleClick, buttonText, warning = false, isLoading }: Props) {
  return (
    <Card
      variant="outlined"
      sx={({ palette }) => ({
        backgroundColor: warning
          ? palette.cardBackgrounds.orange
          : palette.cardBackgrounds.lightPurple,
        borderColor: palette.border.darkPurple,
        padding: 0,
      })}
    >
      <CardHeader
        title={
          <Typography component="h4" variant="h4" fontWeight="700">
            {title}
          </Typography>
        }
      />
      <CardContent sx={{ py: 0 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2">{info}</Typography>
          <CardActions sx={{ justifyContent: 'flex-end', padding: 0 }}>
            {warning ? (
              <WarningButton
                onClick={handleClick}
                loading={isLoading}
                disabled={isLoading}
                disableElevation
              >
                {buttonText}
              </WarningButton>
            ) : (
              <PrimaryButton
                onClick={handleClick}
                loading={isLoading}
                disabled={isLoading}
                disableElevation
              >
                {buttonText}
              </PrimaryButton>
            )}
          </CardActions>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default ActionCard;
