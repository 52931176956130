import { alpha, Chip as MuiChip, ListItem, styled } from '@mui/material';
import { Avatar, OverflowingText } from '@shared/components';
import { appLogos } from '@assets/appLogos';
import { memo } from 'react';

interface AppChipProps {
  appKey: string;
  name: string;
  noBorder: boolean;
  defaultApp?: boolean;
}

function AppChip({ appKey, name, noBorder, defaultApp = false }: AppChipProps) {
  return (
    <ListItem disableGutters sx={{ width: 'unset', marginRight: '0.25rem', py: '0.25rem' }}>
      <Chip
        avatar={<Avatar name={name} imgSrc={appLogos[appKey]} sx={{ width: 25, height: 25 }} />}
        label={
          <OverflowingText tooltipText={name} variant="inherit">
            {name}
          </OverflowingText>
        }
        size="medium"
        noBorder={noBorder}
        defaultApp={defaultApp}
      />
    </ListItem>
  );
}

export default memo(AppChip);

const Chip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== 'defaultApp' && prop !== 'noBorder',
})<Pick<AppChipProps, 'defaultApp' | 'noBorder'>>(({ theme, noBorder, defaultApp }) => ({
  border: noBorder ? 'none' : `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
  backgroundColor: defaultApp ? theme.palette.success[500] : theme.palette.common.white,
  height: '35px',
  maxWidth: '200px',

  '& .MuiChip-avatar': { ml: '10px', mr: 0 },
  '& .MuiChip-label': {
    padding: theme.spacing(0.75, 1.5),
  },
}));
