import { KeyInfoUrlField } from '@dap-sanity/types';
import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { LinkIcon } from '@shared/custom-icons';
import InlineExternalLink from './InlineExternalLink';

interface Props {
  link: KeyInfoUrlField;
}

const linkIconStyle: SxProps<Theme> = { mr: 2, width: '14px', height: '14px' };

export default function KeyInfoLink({ link }: Props) {
  const href =
    link.url.startsWith('https://') || link.url.startsWith('http://')
      ? link.url
      : `https://${link.url}`;

  return (
    <Stack>
      <Typography variant="h4" component="h3">
        {link.title}
      </Typography>
      <InlineExternalLink
        variant="body2"
        href={href}
        display="flex"
        alignItems="center"
        width="100%"
      >
        <LinkIcon sx={linkIconStyle} />
        <Typography variant="body2" noWrap>
          {link.url}
        </Typography>
      </InlineExternalLink>
    </Stack>
  );
}
