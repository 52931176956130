import React, { useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  alpha,
  Button,
  CSSObject,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  SxProps,
  Theme,
  Tooltip,
} from '@mui/material';
import { Person as PersonIcon } from '@mui/icons-material';
import { messages } from '@dap-common/i18n';
import { useTranslation } from 'react-i18next';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import { Link as RouterLink } from 'react-router-dom';
import { InformationModelType } from '@dap-admin/types';
import { FancyAvatar } from '@shared/components';
import { routes } from '@dap-common/consts';
import { HighestAdminRoleListItem } from '@dap-admin/ui';

interface Props {
  username: string;
  highestAdminRole?: InformationModelType | null;
  handleLogout: () => void;
  userPicture: string;
  openSidebar: boolean;
  brandKey: string;
}

function UserMenu({
  username,
  highestAdminRole,
  handleLogout,
  userPicture,
  openSidebar,
  brandKey,
}: Props) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const avatarRef = useRef<HTMLElement>();
  const open = Boolean(anchorEl);

  const popoverId = useMemo(() => uuidv4(), []);
  const id = open ? popoverId : undefined;

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={[
        ...(Array.isArray(stackSx) ? stackSx : [stackSx]),
        (theme) => ({
          ...(openSidebar && { ...openedMixin(theme) }),
          ...(!openSidebar && { ...closedMixin(theme) }),
        }),
      ]}
    >
      {openSidebar ? (
        <FancyAvatar ref={avatarRef} username={username} userPicture={userPicture} />
      ) : (
        <IconButton
          aria-label={t(messages.common.openUserMenu)}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          sx={{ m: 0, p: 0 }}
        >
          <FancyAvatar ref={avatarRef} username={username} userPicture={userPicture} />
        </IconButton>
      )}

      <Button
        variant="text"
        color="inherit"
        aria-describedby={id}
        aria-haspopup={true}
        onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
        sx={[...(Array.isArray(buttonSx) ? buttonSx : [buttonSx]), !openSidebar && { opacity: 0 }]}
      >
        {username}
      </Button>

      <Tooltip
        title={t(messages.common.logout)}
        arrow
        placement="right"
        sx={{ ...(!openSidebar && { display: 'none' }) }}
      >
        <IconButton onClick={handleLogout} size="small" aria-label="logout" edge="end">
          <ExitToAppRoundedIcon fontSize="small" sx={{ color: 'text.primary' }} />
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={openSidebar ? anchorEl : avatarRef.current}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={popOverSx}
        elevation={0}
      >
        <List sx={listSx}>
          <ListItem>
            <ListItemButton>
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              <Link component={RouterLink} to={`./${routes.me}`} onClick={() => setAnchorEl(null)}>
                <ListItemText primary={username} primaryTypographyProps={{ variant: 'body3' }} />
              </Link>
            </ListItemButton>
          </ListItem>
          {highestAdminRole && (
            <HighestAdminRoleListItem highestAdminRole={highestAdminRole} brandKey={brandKey} />
          )}
          {!openSidebar && (
            <ListItem>
              <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                  <ExitToAppRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={t(messages.common.logout)}
                  primaryTypographyProps={{ variant: 'body3' }}
                />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Popover>
    </Stack>
  );
}

export default UserMenu;

const stackSx: SxProps = {
  margin: 'auto 15px 30px',
  padding: '10px',
  height: '80px',
  boxSizing: 'border-box',
  borderRadius: '7px',
  background: 'transparent',
  border: '1px solid transparent',
  overflowX: 'hidden',
  flexShrink: 0,
};

const openedMixin = (theme: Theme): CSSObject => ({
  background: theme.palette.common.white,
  border: `1px solid ${alpha(theme.palette.primary.dark, 0.1)}`,
  transition: theme.transitions.create(['background', 'border'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create(['background', 'border'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

const buttonSx: SxProps = {
  typography: 'body4',
  minWidth: '115px',
  px: 1,
  whiteSpace: 'normal',
  justifyContent: 'flex-start',
};

const listSx: SxProps<Theme> = ({ palette, typography, spacing }) => ({
  '.MuiListItem-root': {
    padding: spacing(0.5, 1),
  },
  '.MuiListItemButton-root': {
    padding: 0,
  },
  '.MuiListItemText-root .MuiTypography-root': {
    typography: typography.body3,
    color: alpha(palette.text.secondary, 0.8),
    paddingLeft: '15px',
  },
  '.MuiListItemIcon-root': {
    color: palette.text.primary,
    minWidth: '24px',
  },
  '.MuiListItemButton-root:hover': {
    background: 'transparent',
  },
});

const popOverSx: SxProps<Theme> = (theme) => ({
  '& .MuiPopover-paper': {
    borderRadius: '7px',
    marginTop: theme.spacing(1),
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: alpha(theme.palette.primary.dark, 0.1),
  },
});
