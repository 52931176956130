import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

function CalendarIcon(props: SvgIconProps) {
  const { palette } = useTheme();
  return (
    <SvgIcon width="14" height="14" {...props} viewBox="0 0 14 14" fill="none">
      <g clipPath="url(#clip0_3522_6719)">
        <path
          d="M5 1.75H9V0.875C9 0.391836 9.44687 0 10 0C10.5531 0 11 0.391836 11 0.875V1.75H12.5C13.3281 1.75 14 2.33762 14 3.0625V5.25H0V3.0625C0 2.33762 0.671562 1.75 1.5 1.75H3V0.875C3 0.391836 3.44687 0 4 0C4.55313 0 5 0.391836 5 0.875V1.75Z"
          fill={palette.primary.dark}
        />
        <path
          opacity="0.4"
          d="M0 5.25H14V12.6875C14 13.4121 13.3281 14 12.5 14H1.5C0.671562 14 0 13.4121 0 12.6875V5.25ZM2 8.3125C2 8.55313 2.22375 8.75 2.5 8.75H3.5C3.775 8.75 4 8.55313 4 8.3125V7.4375C4 7.19687 3.775 7 3.5 7H2.5C2.22375 7 2 7.19687 2 7.4375V8.3125ZM6.5 7C6.225 7 6 7.19687 6 7.4375V8.3125C6 8.55313 6.225 8.75 6.5 8.75H7.5C7.775 8.75 8 8.55313 8 8.3125V7.4375C8 7.19687 7.775 7 7.5 7H6.5ZM10 8.3125C10 8.55313 10.225 8.75 10.5 8.75H11.5C11.775 8.75 12 8.55313 12 8.3125V7.4375C12 7.19687 11.775 7 11.5 7H10.5C10.225 7 10 7.19687 10 7.4375V8.3125ZM2.5 10.5C2.22375 10.5 2 10.6969 2 10.9375V11.8125C2 12.0531 2.22375 12.25 2.5 12.25H3.5C3.775 12.25 4 12.0531 4 11.8125V10.9375C4 10.6969 3.775 10.5 3.5 10.5H2.5ZM6 11.8125C6 12.0531 6.225 12.25 6.5 12.25H7.5C7.775 12.25 8 12.0531 8 11.8125V10.9375C8 10.6969 7.775 10.5 7.5 10.5H6.5C6.225 10.5 6 10.6969 6 10.9375V11.8125ZM10.5 10.5C10.225 10.5 10 10.6969 10 10.9375V11.8125C10 12.0531 10.225 12.25 10.5 12.25H11.5C11.775 12.25 12 12.0531 12 11.8125V10.9375C12 10.6969 11.775 10.5 11.5 10.5H10.5Z"
          fill={palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_3522_6719">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default CalendarIcon;
