import { PatchLocation } from '@dap-admin/types';
import { Country } from '@shared/types';
import { LocationEditSchema } from './LocationSchemas';

export const mapToPatchLocationDTO = (input: LocationEditSchema): PatchLocation => ({
  ...input.details,
  glns: input.details.glns.map(({ id }) => id),
  address: { ...input.address.address, country: input.address.address.country as Country },
  postalAddress: input.address.addressAndPostalAddressEqual
    ? {
        postalStreet: input.address.address.street,
        postalPostalCode: input.address.address.postalCode,
        postalCity: input.address.address.city,
        postalCountry: input.address.address.country as Country,
      }
    : {
        postalStreet: input.address.postalAddress.street,
        postalPostalCode: input.address.postalAddress.postalCode,
        postalCity: input.address.postalAddress.city,
        postalCountry: input.address.address.country as Country,
      },
  ...input.services,
  coordinates: { ...input.coordinates },
});
