import { CustomError, Property, QueryError } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { Buttons } from '@dap-common/ui';
import { isCustomError } from '@dap-common/utils';
import {
  Alert,
  AlertTitle,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { ContainedButton, Dialog, DialogProps, TagHeading } from '@shared/components';

interface Props extends Omit<DialogProps, 'open'> {
  propertyToBeDeleted: Property;
  isDeleting: boolean;
  onDelete: () => void;
  error: CustomError | QueryError | SerializedError | undefined;
}

export default function DeletePropertyDialog({
  onClose,
  propertyToBeDeleted,
  isDeleting,
  onDelete,
  error,
}: Props) {
  const t = useAppTFunction();

  return (
    <Dialog maxWidth="xs" fullWidth onClose={onClose} open>
      <DialogTitle>
        <TagHeading color="warning">
          {`${t('common.delete')} ${t('brandadmin.properties.title').toLowerCase()}`}
        </TagHeading>
      </DialogTitle>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <AlertTitle>{t('brandadmin.properties.delete.error')}</AlertTitle>
          {isCustomError(error) && `${error.status} ${error.data.message}`}
        </Alert>
      )}

      <DialogContent>
        {t('brandadmin.properties.delete.description', {
          property: propertyToBeDeleted.key,
        })}
      </DialogContent>
      <DialogActions>
        <Buttons>
          <Button variant="text" color="grey" onClick={onClose} size="small">
            {t('common.cancel')}
          </Button>
          <ContainedButton color="warning" size="small" loading={isDeleting} onClick={onDelete}>
            {t('common.delete')}
          </ContainedButton>
        </Buttons>
      </DialogActions>
    </Dialog>
  );
}
