import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import {
  alpha,
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { memo, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useDisclosure } from '@shared/hooks';

export interface NavRoute {
  title: string;
  icon: ReactElement;
  to: string;
  isActive: boolean;
  subRoutes?: Omit<NavRoute, 'subRoutes'>[];
}

interface Props extends NavRoute {
  expandedView: boolean;
}

function NavLinkItem({ to, isActive, title, icon, subRoutes, expandedView }: Props) {
  const { isOpen: showSubRoutes, onToggle: onToggleShowSubRoutes } = useDisclosure(true);
  const toggleShowSubRoutes = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onToggleShowSubRoutes();
  };

  return (
    <>
      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          to={to}
          component={Link}
          selected={isActive}
          sx={({ palette, typography }) => ({
            justifyContent: expandedView ? 'initial' : 'center',
            height: '44px',
            borderRadius: '7px',
            border: '1px solid transparent',
            minHeight: 24,
            px: 2.5,
            '.MuiListItemIcon-root': {
              color: palette.primary.main,
              minWidth: 0,
              justifyContent: 'center',
            },
            '.MuiListItemText-root .MuiTypography-root': {
              typography: typography.body3,
              color: alpha(palette.text.secondary, 0.8),
            },

            '&.MuiListItemButton-root:hover': {
              background: 'transparent',
            },
            '&.Mui-selected, &.Mui-selected:hover': {
              background: palette.common.white,
              border: `1px solid ${alpha(palette.primary.dark, 0.1)}`,

              '.MuiListItemText-root .MuiTypography-root': {
                fontWeight: 500,
                color: alpha(palette.text.primary, 1),
              },
            },
          })}
        >
          <Tooltip title={expandedView ? '' : title} arrow placement="right">
            <ListItemIcon sx={{ mr: expandedView ? '15px' : 'auto' }}>{icon}</ListItemIcon>
          </Tooltip>
          <ListItemText primary={title} sx={{ ...(!expandedView && { display: 'none' }) }} />
          {subRoutes && (
            <Box sx={{ ...(!expandedView && { display: 'none' }) }}>
              <IconButton onClick={toggleShowSubRoutes} color="inherit" edge="end">
                {showSubRoutes ? <ExpandLessRounded /> : <ExpandMoreRounded />}
              </IconButton>
            </Box>
          )}
        </ListItemButton>
      </ListItem>
      {subRoutes && (
        <Collapse in={expandedView && showSubRoutes} timeout="auto">
          <List disablePadding sx={{ pl: 3 }}>
            {subRoutes.map((subRoute) => (
              <NavLinkItem {...subRoute} expandedView={expandedView} key={subRoute.to} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

export default memo(NavLinkItem);
