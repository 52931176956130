import { Property, PropertyValueTypes } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { Validations } from '@dap-common/utils';
import { parseISO } from 'date-fns';
import { useMemo } from 'react';
import * as yup from 'yup';

export const useCreateOrEditPropertySchema = (
  properties: Array<Property>,
  valueTypes: Array<string>,
  property?: Property
) => {
  const t = useAppTFunction();

  const schema = useMemo(
    () =>
      yup.object({
        key: yup
          .string()
          .defined()
          .default(property?.key ?? '')
          .required(t('validation.required'))
          .test(
            'isUnique',
            t('validation.existing', { item: t('brandadmin.properties.key') }),
            (inputValue): boolean => {
              if (inputValue === property?.key) return true;

              return !properties.some(({ key }) => inputValue?.toLowerCase() === key.toLowerCase());
            }
          ),
        valueType: yup
          .string()
          .oneOf(valueTypes)
          .default(property?.valueType ? property.valueType : PropertyValueTypes.string)
          .defined()
          .required(t('validation.required')),
        options: yup
          .array()
          .default(
            property?.options
              ? property.options.options.map((option) => ({ label: option }))
              : [{ label: '' }, { label: '' }]
          )
          .when('valueType', {
            is: PropertyValueTypes.enum || PropertyValueTypes.multipleChoice,
            then: yup
              .array()
              .of(yup.object({ label: yup.string().required(t('validation.required')) }))
              .min(2, t('validation.add.minimum', { count: 2 })),
            otherwise: yup.array().nullable(),
          }),
        value: yup
          .mixed()
          .nullable()
          .when('valueType', (valueType) => {
            switch (valueType) {
              case PropertyValueTypes.boolean:
                return yup.string().default(property?.value ?? 'true');
              case PropertyValueTypes.number:
                return Validations.numberString(t).default(property?.value.toLocaleString() ?? '');
              case PropertyValueTypes.date:
                return Validations.date(t).default(
                  property?.value ? parseISO(property.value) : null
                );
              default:
                return yup.string().default(property?.value ?? '');
            }
          }),
        keyGroup: yup
          .string()
          .default(property?.keyGroup ?? '')
          .defined(),
      }),
    [properties, property, t, valueTypes]
  );
  return schema;
};

export type CreateOrEditPropertySchema = yup.InferType<
  ReturnType<typeof useCreateOrEditPropertySchema>
>;
