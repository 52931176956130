import { LocationOverview, RegionV2 } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import {
  ConfirmationDialog,
  PrimaryButton,
  UniversalFormProvider,
  WarningButton,
} from '@dap-common/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Chip, Divider, List, Stack, Typography } from '@mui/material';
import { SelectInputField } from '@shared/components';
import { pruneObject } from '@shared/utils';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues, FormProvider, useController, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { RegionSchema, useRegionSchema } from '../../Forms';
import BrandConfigCard from '../BrandConfigCard/BrandConfigCard';
import RegionsForm from '../../Forms/RegionsForm/RegionsForm';
import { useDisclosure } from '@shared/hooks';

interface Props {
  regions: RegionV2[];
  handleUpdate: (id: string, input: RegionSchema) => void;
  handleCreate: (input: RegionSchema) => void;
  handleDelete: (id: string) => void;
  brandLocations: LocationOverview[];
  hasAdminRights: boolean;
}

function BrandRegions({
  regions: currentRegions,
  handleUpdate,
  handleCreate,
  handleDelete,
  brandLocations,
  hasAdminRights,
}: Props) {
  const t = useAppTFunction();
  const [isEditing, setIsEditing] = useState(false);
  const [createRegion, setCreateRegion] = useState(false);
  const deleteDialog = useDisclosure();

  const selectRegionSchema = yup.object({
    key: yup.string().default(''),
  });
  const selectForm = useForm({
    defaultValues: selectRegionSchema.getDefault(),
    resolver: yupResolver(selectRegionSchema),
  });
  const keyController = useController({ name: 'key', control: selectForm.control });
  const selectedRegion = selectForm.watch('key');

  const defaultValues = useMemo(() => {
    const region = currentRegions.find(({ uuid }) => uuid === selectedRegion);
    return {
      name: region?.name ?? '',
      key: region?.key ?? '',
    };
  }, [selectedRegion, currentRegions]);
  const updateRegionSchema = useMemo(
    () =>
      yup.object().shape({
        key: yup.string(),
        name: yup.string().required(t('validation.required')),
      }),
    [t]
  );
  const form = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(updateRegionSchema),
  });

  useEffect(() => {
    form.reset({ ...defaultValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion]);

  const locationsInSelectedRegion = useMemo(
    () => brandLocations.some(({ regionName }) => regionName === defaultValues?.name),
    [brandLocations, defaultValues?.name]
  );

  const cancelEdit = () => {
    setIsEditing(false);
    selectForm.reset();
    setCreateRegion(false);
  };

  const update = (input: FieldValues) => {
    if (selectedRegion) {
      const regionDto = pruneObject(input) as RegionSchema;
      handleUpdate(selectedRegion, regionDto);
    }
    cancelEdit();
  };

  const create = (input: FieldValues) => {
    const regionDto = pruneObject(input) as RegionSchema;
    handleCreate(regionDto);
    cancelEdit();
  };

  const handleCloseDeleteDialog = (id: string) => {
    deleteDialog.onClose();
    handleDelete(id);
    cancelEdit();
  };

  const createBrandRegionSchema = useRegionSchema(t);

  return (
    <BrandConfigCard
      header={t('brandDetailsPage.regions.title')}
      isEditing={isEditing}
      handleEdit={() => setIsEditing(true)}
      handleCancelEdit={cancelEdit}
      hasAdminRights={hasAdminRights}
    >
      {isEditing ? (
        <Stack spacing={2}>
          {!createRegion ? (
            <>
              <SelectInputField
                {...keyController.field}
                label={t('brandDetailsPage.regions.form.title')}
                menuItems={currentRegions.map((region) => ({
                  name: region.name,
                  value: region.uuid,
                }))}
                noOptionsText={t('location.region.empty')}
                fullWidth
              />
              {selectedRegion && defaultValues ? (
                <Stack spacing={2} divider={<Divider />}>
                  <FormProvider {...form}>
                    <RegionsForm save={update} cancel={cancelEdit} inCreateMode={false} />
                  </FormProvider>
                  <Stack spacing={2}>
                    {locationsInSelectedRegion && (
                      <Alert severity="info">{t('brandDetailsPage.regions.remove.info')}</Alert>
                    )}
                    <WarningButton
                      onClick={() => deleteDialog.onOpen()}
                      disabled={locationsInSelectedRegion}
                    >
                      {t('brandDetailsPage.regions.remove.button')}
                    </WarningButton>
                  </Stack>
                </Stack>
              ) : (
                <>
                  <Divider textAlign="left">{t('brandDetailsPage.regions.dividerText')}</Divider>
                  <Box>
                    <PrimaryButton onClick={() => setCreateRegion(true)}>
                      {t('brandDetailsPage.regions.create')}
                    </PrimaryButton>
                  </Box>
                </>
              )}
            </>
          ) : (
            <UniversalFormProvider schema={createBrandRegionSchema} defaultValues={defaultValues}>
              <RegionsForm save={create} cancel={cancelEdit} inCreateMode />
            </UniversalFormProvider>
          )}
        </Stack>
      ) : currentRegions.length ? (
        <List disablePadding sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
          {currentRegions.map((region) => (
            <li key={region.key}>
              <Chip label={region.name} variant="large" />
            </li>
          ))}
        </List>
      ) : (
        <Typography variant="body2">{t('brandDetailsPage.regions.empty')}</Typography>
      )}
      {deleteDialog.isOpen && (
        <ConfirmationDialog
          value={selectedRegion}
          open={deleteDialog.isOpen}
          onClose={handleCloseDeleteDialog}
          title={t('brandDetailsPage.regions.remove.dialogTitle')}
        >
          <Typography>{t('brandDetailsPage.regions.remove.confirmYes')}</Typography>
        </ConfirmationDialog>
      )}
    </BrandConfigCard>
  );
}

export default BrandRegions;
