import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { BrandNameSchema } from './brandNameSchema';
import { InputText, SaveOrCancelEdit } from '@dap-common/ui';

interface Props {
  onCancel: () => void;
  onSave: (input: BrandNameSchema) => void;
}

export default function EditBrandNameForm({ onCancel, onSave }: Props) {
  const { handleSubmit, control, setFocus } = useFormContext<BrandNameSchema>();
  const brandNameController = useController({ name: 'name', control });

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <InputText
        {...brandNameController.field}
        error={brandNameController.fieldState.error?.message}
        margin="none"
      />
      <SaveOrCancelEdit save={handleSubmit(onSave)} cancel={onCancel} align="right" />
    </form>
  );
}
