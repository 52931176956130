import { Typography } from '@mui/material';
import { FillMinMax } from '@shared/components';
import { previewCardImageHeight } from '../news/newsConstants';
import ServicePreviewCard from './ServicePreviewCard';
import { SanityServicePreview } from '@dap-sanity/types';

interface Props {
  servicePreviews: SanityServicePreview[];
  emptyListMessage: string;
}

export default function ServicePreviewsList({
  servicePreviews,
  emptyListMessage,
}: Props): JSX.Element {
  if (servicePreviews.length === 0) {
    return <Typography>{emptyListMessage}</Typography>;
  }

  return (
    <FillMinMax minWidth="200px" gridGap="1em">
      {servicePreviews.map(({ image, title, intro, url, id }) => {
        return (
          <ServicePreviewCard
            image={image}
            imgHeight={previewCardImageHeight}
            intro={intro}
            title={title}
            toUrl={`./${url}`}
            key={id}
          />
        );
      })}
    </FillMinMax>
  );
}
