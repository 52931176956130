import pureserviceImgUri from './pureservice.png';
import workplaceImgUri from './wp.png';
import motimateImgUri from './motimate.png';
import salesforceImgUri from './salesforce.png';
import prisinnsiktImgUri from './prisinnsikt.png';
import brandadminImgUri from './kjedeadmin.png';
import omniumImgUri from './omnium.png';
import apactaImgUri from './apacta.png';
import centigaImgUri from './centiga.png';
import proffnettImgUri from './proffnett.png';
import prognosesenteretImgUri from './prognosesenteret.png';
import holteImgUri from './holte.png';
import fonnImgUri from './fonn.png';
import brandmasterImgUri from './brandmaster.png';
import boligportenImgUri from './boligporten.png';
import experienceLiveImgUri from './experience_live.png';
import alreadyonImgUri from './alreadyon.png';

// Temporary logo map. Delete when we get a logo url from apps api
export const appLogos: Record<string, string> = {
  pureservice: pureserviceImgUri,
  workplace: workplaceImgUri,
  motimate: motimateImgUri,
  salesforce: salesforceImgUri,
  prisinnsikt: prisinnsiktImgUri,
  brandadmin: brandadminImgUri,
  omnium: omniumImgUri,
  apacta: apactaImgUri,
  centiga: centigaImgUri,
  proffnett: proffnettImgUri,
  prognosesenteret: prognosesenteretImgUri,
  holte: holteImgUri,
  fonn: fonnImgUri,
  brandmaster: brandmasterImgUri,
  boligporten: boligportenImgUri,
  experienceLive: experienceLiveImgUri,
  alreadyon: alreadyonImgUri,
};
