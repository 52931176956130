import { GLN, LocationGlnsSchema } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import {
  EditCard,
  LabelValue,
  SaveOrCancelEdit,
  TextInputArray,
  UniversalFormProvider,
} from '@dap-common/ui';
import { Validations } from '@dap-common/utils';
import { Stack, Typography } from '@mui/material';
import { pruneObject } from '@shared/utils';
import { FunctionComponent, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

interface GlnSchema {
  gln: {
    id: string;
  }[];
}

interface Props {
  hasAdminRights: boolean;
  glns?: GLN[];
  save: (updatedGlns: LocationGlnsSchema) => void;
  saving: boolean;
}

interface FormProps extends Omit<Props, 'hasAdminRights' | 'save'> {
  cancel: () => void;
  save: (updatedGlns: string[]) => void;
}

const mapGlnToSchema = (gln?: GLN[]): GlnSchema => {
  if (gln) {
    return { gln: gln.map(({ id }) => ({ id: id })) };
  }
  return { gln: [{ id: '' }] };
};

const mapSchemaToPatchObject = ({ gln }: GlnSchema): string[] => gln?.map(({ id }) => id) || [];

const GlnForm: FunctionComponent<FormProps> = ({ save, cancel, saving }) => {
  const tApp = useAppTFunction();
  const { handleSubmit } = useFormContext<GlnSchema>();

  const onSubmit = handleSubmit((gln) => {
    save(mapSchemaToPatchObject(gln));
  });

  return (
    <form onSubmit={onSubmit}>
      <TextInputArray
        arrayName="gln"
        fieldConfigs={[
          {
            name: 'id',
            inputLabel: `${tApp('pagesHomeLocation.gln.id')}`,
            placeholder: `${tApp('pagesHomeLocation.gln.placeholder')}`,
            required: true,
          },
        ]}
      />
      <SaveOrCancelEdit save={onSubmit} cancel={cancel} align="right" loading={saving} />
    </form>
  );
};

const GlnCard: FunctionComponent<Props> = ({ glns, save, saving, hasAdminRights }) => {
  const tApp = useAppTFunction();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const glnSchema = useMemo(
    () =>
      yup.object().shape({
        gln: yup.array().of(
          yup.object().shape({
            id: Validations.numberString(tApp).length(13, tApp('validation.gln.format')).required(),
          })
        ),
      }),
    [tApp]
  );

  const saveFn = (updated: string[]) => {
    save({ glns: pruneObject(updated, null) });
    setIsEditing(false);
  };

  const defaultValues = mapGlnToSchema(glns);

  return (
    <EditCard
      header={tApp('pagesHomeLocation.gln.name')}
      isEditing={isEditing}
      edit={() => setIsEditing(true)}
      cancel={() => setIsEditing(false)}
      isEditable={hasAdminRights}
      titleTypographyVariant="h6"
    >
      {isEditing ? (
        <UniversalFormProvider defaultValues={defaultValues} schema={glnSchema}>
          <GlnForm save={saveFn} cancel={() => setIsEditing(false)} saving={saving} />
        </UniversalFormProvider>
      ) : glns && glns.length > 0 ? (
        <Stack spacing={1}>
          {glns.map(({ id }) => (
            <LabelValue label={tApp('pagesHomeLocation.gln.id')} value={id} key={id} />
          ))}
        </Stack>
      ) : (
        <Typography variant="body3">{tApp('pagesHomeLocation.gln.noGln')}</Typography>
      )}
    </EditCard>
  );
};

export default GlnCard;
