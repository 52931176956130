import { InformationModelType, UserData } from '@dap-admin/types';

export function getHighestAdminRole(userData: UserData) {
  const isBrandAdmin = userData.roles?.some(
    (role) => role.type === InformationModelType.Brand && role.role.isAdmin
  );

  const isDataownerAdmin = userData.roles?.some(
    (role) => role.type === InformationModelType.Dataowner && role.role.isAdmin
  );

  const isLocationAdmin = userData.roles?.some(
    (role) => role.type === InformationModelType.Location && role.role.isAdmin
  );

  if (userData.metadata.superuser) {
    return InformationModelType.Super;
  } else if (isBrandAdmin) {
    return InformationModelType.Brand;
  } else if (isDataownerAdmin) {
    return InformationModelType.Dataowner;
  } else if (isLocationAdmin) {
    return InformationModelType.Location;
  } else {
    return null;
  }
}
