import { messages } from '@dap-common/i18n';
import React, { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SaveOrCancelEdit } from '../Buttons';
import StyledDialog from './StyledDialog';

export interface Props {
  value: any;
  open: boolean;
  onClose: (value?: any) => void;
  title: string;
}

function ConfirmationDialog({
  children,
  onClose,
  value: valueProp,
  open,
  title,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(valueProp);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  return (
    <StyledDialog
      open={open}
      size="xs"
      title={title}
      content={children}
      actionButtons={
        <SaveOrCancelEdit
          cancel={onClose}
          save={() => onClose(value)}
          saveText={t(messages.common.yes)}
        />
      }
      handleClose={onClose}
      closeOnBackdropClick={false}
    />
  );
}

export default ConfirmationDialog;
