import {
  Step,
  StepConnector,
  StepLabel,
  Stepper as MUIStepper,
  StepperProps,
  SxProps,
  Theme,
} from '@mui/material';
import React from 'react';

interface Props extends StepperProps {
  steps: Array<string>;
  skippedSteps?: Set<number>;
}

/**
 * @deprecated This component is deprecated, use Stepper from '@shared'
 */
export default function Stepper({ steps, activeStep, skippedSteps }: Props) {
  const isStepSkipped = (step: number) => {
    return skippedSteps?.has(step);
  };

  return (
    <MUIStepper
      activeStep={activeStep}
      alternativeLabel
      connector={<StepConnector sx={stepConnectorSx} />}
    >
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean } = {};
        const labelProps: { optional?: React.ReactNode } = {};

        if (isStepSkipped(index)) {
          stepProps.completed = false;
        }
        return (
          <Step key={label} {...stepProps}>
            <StepLabel
              {...labelProps}
              StepIconProps={{ icon: '\u25CF', sx: stepIconProps }}
              sx={stepLabelSx}
            >
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </MUIStepper>
  );
}

const stepConnectorSx: SxProps = { top: '12px', left: '-60%', right: '50%' };

const stepLabelSx: SxProps<Theme> = ({ palette }) => ({
  '.MuiStepLabel-label': {
    typography: 'body3',
    color: palette.grey[400],
    '&.Mui-active': {
      color: 'primary.main',
    },
    '&.Mui-completed': {
      color: palette.primary.main,
    },
    '&.MuiStepLabel-alternativeLabel': { marginTop: '10px' },
  },
});

const stepIconProps: SxProps<Theme> = ({ palette }) => ({
  color: 'transparent',
  zIndex: 1,
  '&.Mui-active': {
    '.MuiStepIcon-text': {
      fill: palette.common.white,
      fontSize: '10px',
    },
  },
  '.MuiStepIcon-text': {
    fill: palette.grey[400],
    fontSize: '10px',
  },
  '&.Mui-completed': {
    background: 'white',
  },
});
