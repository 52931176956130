import { Alert } from '@mui/material';
import PinmetoPinImage from './PinmetoPinImage';

interface Props {
  margin?: 'none' | 'normal';
  text: string;
}

export default function PinmetoInfoBox({ margin = 'normal', text }: Props) {
  return (
    <Alert severity="info" sx={{ my: margin === 'none' ? '' : 2 }} icon={<PinmetoPinImage />}>
      {text}
    </Alert>
  );
}
