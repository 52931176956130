import { useState } from 'react';
import * as yup from 'yup';
import BrandConfigCard from '../BrandConfigCard/BrandConfigCard';
import { RoleDetailed } from '@dap-admin/types';
import { UniversalFormProvider } from '@dap-common/ui';
import SelectedRoles from '../SelectedRoles';
import { RolesForm } from '../../Forms';

interface RolesSchema {
  selectedRoles: string[];
}

const rolesSchema = yup.object().shape({
  selectedRoles: yup.array(yup.string()).default([]),
});

interface Props {
  selectedRoles: RoleDetailed[];
  availableRoles: RoleDetailed[];
  roleType: 'brandRoles' | 'dataownerRoles' | 'locationRoles';
  header: string;
  handleSave: (input: any) => void;
  hasAdminRights: boolean;
}

function RolesConfig({
  selectedRoles,
  availableRoles,
  roleType,
  header,
  handleSave,
  hasAdminRights,
}: Props) {
  const [isEditing, setIsEditing] = useState(false);

  const save = ({ selectedRoles }: RolesSchema) => {
    handleSave({
      [roleType]: selectedRoles,
    });
    setIsEditing(false);
  };

  const defaultValues: RolesSchema = {
    selectedRoles: availableRoles
      .filter((role) => selectedRoles.some((selectedRole) => selectedRole.key === role.key))
      .map((role) => role.key),
  };

  return (
    <BrandConfigCard
      header={header}
      isEditing={isEditing}
      handleEdit={() => setIsEditing(true)}
      handleCancelEdit={() => setIsEditing(false)}
      hasAdminRights={hasAdminRights}
    >
      {isEditing ? (
        <UniversalFormProvider
          defaultValues={defaultValues}
          schema={rolesSchema}
          shouldUnregister={false}
        >
          <RolesForm roles={availableRoles} save={save} cancelEdit={() => setIsEditing(false)} />
        </UniversalFormProvider>
      ) : (
        <SelectedRoles roles={selectedRoles} />
      )}
    </BrandConfigCard>
  );
}

export default RolesConfig;
