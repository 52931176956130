import { SanityPageHeader } from '@dap-sanity/ui';
import { Straight } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Portal } from '@shared/components';
import { useTranslation } from 'react-i18next';
import { subheaderPortalElementId } from '@dap-common/styles';
import { messages } from '@dap-common/i18n';
import { getDashboardRoute } from '@dap-common/consts';

interface Props {
  title: string;
  message: string;
}

function NotConfiguredPage({ title, message }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Portal elementId={subheaderPortalElementId}>
        <SanityPageHeader
          breadcrumbs={[
            {
              to: getDashboardRoute(),
              text: t(messages.common.goBack),
              leftIcon: <Straight sx={{ transform: 'rotate(-90deg)', mr: 2 }} />,
            },
          ]}
          header={title}
        />
      </Portal>
      <Typography>{message}</Typography>
    </>
  );
}

export default NotConfiguredPage;
