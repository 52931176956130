import { LocationBasic } from '@dap-admin/types';
import { messages, useBrandTranslation, useTranslationWithBrandOptions } from '@dap-common/i18n';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LocationsListCard from './LocationsListCard';

interface Props {
  dataownerLocations: LocationBasic[];
  getLocationRoute: (locationKey: string) => string;
  brandKey: string;
}

const LocationsList: React.FC<Props> = ({ dataownerLocations, getLocationRoute, brandKey }) => {
  const { t: tBrand } = useBrandTranslation(brandKey);
  const { t } = useTranslation('pagesHomeDataowner');
  const { t: tOptions } = useTranslationWithBrandOptions('common', brandKey);
  const { palette } = useTheme();

  return dataownerLocations.length > 0 ? (
    <Stack
      data-testid="DataownerLocationsList"
      spacing={2}
      sx={{
        padding: '20px 15px',
        border: '1px solid rgba(69, 59, 113, 0.1)',
        borderRadius: ' 7px',
        backgroundColor: palette.cardBackgrounds.lightPurple,
      }}
    >
      <Typography variant="h2">
        {t(messages.pagesHomeDataowner.locations.title, {
          locations: tBrand(messages.brand.location.name.plural),
        })}
      </Typography>
      <Stack spacing={1}>
        {dataownerLocations.map((location) => (
          <LocationsListCard
            key={location.key}
            location={location}
            locationRoute={getLocationRoute(location.key)}
          />
        ))}
      </Stack>
    </Stack>
  ) : (
    <Box data-testid="DataownerLocationsList" padding="1rem">
      <Typography>
        {tOptions(messages.common.location.noLocations, {
          locations: messages.brand.location.name.plural,
        })}
      </Typography>
    </Box>
  );
};

export default LocationsList;
