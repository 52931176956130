import { Button, FormControl, FormLabel, IconButton, List, styled } from '@mui/material';
import { AddCircleOutline, DeleteOutline } from '@mui/icons-material';
import { FunctionComponent, ReactNode } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { messages } from '@dap-common/i18n';
import { Buttons } from '../Buttons';
import { FieldType } from './formTypes';

const arrayMessages = messages.common.form.field.array;

type ArrayLayout = 'singleColumn' | 'flexWrap';

interface ChildrenProps {
  addNewFieldButton: (emptyField: any) => ReactNode;
  removeFieldButton: (index: number, label: string) => ReactNode;
  fields: FieldType[];
  fieldName: (name: string, index: number) => string;
}

interface Props {
  children: (props: ChildrenProps) => ReactNode;
  arrayName: string;
  legend?: string;
  layout?: ArrayLayout;
}

const InputArray: FunctionComponent<Props> = ({
  arrayName,
  legend,
  layout = 'singleColumn',
  children,
}) => {
  const { t } = useTranslation('common');
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: arrayName,
  });

  return (
    <FormControl component="fieldset">
      {legend && <FormLabel component="legend">{legend}</FormLabel>}
      <FlexList spacing={'0.5em'} layout={layout}>
        {children({
          addNewFieldButton: (emptyField) => (
            <Buttons align="left">
              <Button
                color="primary"
                onClick={() => append(emptyField)}
                startIcon={<AddCircleOutline />}
                size="small"
              >
                {t(arrayMessages.add)}
              </Button>
            </Buttons>
          ),
          removeFieldButton: (index, label) => (
            <IconButton
              aria-label={t(arrayMessages.remove, { label })}
              onClick={() => remove(index)}
            >
              <DeleteOutline />
            </IconButton>
          ),
          fields,
          fieldName: (name, index) => `${arrayName}[${index}].${name}`,
        })}
      </FlexList>
    </FormControl>
  );
};

const FlexList = styled(List)<{ spacing?: string; layout?: ArrayLayout }>`
  display: flex;
  flex-wrap: wrap;
  & > * {
    ${({ layout }) =>
      layout === 'singleColumn'
        ? `
      width: 100%;
  `
        : `
      flex: 1 1 auto;
  `}
  }
  ${({ spacing }) =>
    spacing &&
    `
    & > * {
      margin: ${spacing};
    }
  `}
`;

export default InputArray;
