import { Link } from '@mui/material';
import { getDashboardRoute } from '@dap-common/consts';
import { Link as ReactRouterLink } from 'react-router-dom';
import { brandStylesConfig } from '@dap-common/styles';
import { RecognizedBrands } from '@dap-common/types';

interface Props {
  resetState?: () => void;
  brandKey?: RecognizedBrands;
}

function SidebarLogo({ resetState, brandKey }: Props) {
  const brandStyle = (brandKey && brandStylesConfig[brandKey]) || brandStylesConfig.default;

  return (
    <Link
      to={getDashboardRoute()}
      component={ReactRouterLink}
      onClick={resetState}
      sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
    >
      <img
        src={brandStyle.icon}
        alt={`${brandKey} logo`}
        height="33px"
        width="33px"
        style={{ margin: 'auto', objectFit: 'contain' }}
      />
    </Link>
  );
}

export default SidebarLogo;
