import React from 'react';
import { alpha, Avatar, Box } from '@mui/material';
import { UserBackground } from '@assets/images';

interface Props {
  username: string;
  userPicture?: string;
}

const FancyAvatar = React.forwardRef(({ username, userPicture }: Props, ref) => (
  <Box
    ref={ref}
    display="flex"
    justifyContent="center"
    borderRadius="50%"
    sx={{ background: `url(${UserBackground}) no-repeat`, padding: '7px' }}
    width={55}
    height={55}
  >
    <Avatar
      alt={username}
      src={userPicture}
      sx={{
        width: 40,
        height: 40,
        border: ({ palette }) => `1px solid ${alpha(palette.grey[900], 0.2)}`,
      }}
    />
  </Box>
));

export default FancyAvatar;
