import { useBrandTFunction } from '@dap-admin/i18n';
import { AvailableLocationFields, BrandV2 } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { CheckboxWithLabel, UniversalFormProvider } from '@dap-common/ui';
import { useMemo, useState } from 'react';
import BrandConfigCard from '../BrandConfigCard/BrandConfigCard';
import SelectedEcomAssortments from '../SelectedEcomAssortments/SelectedEcomAssortments';
import EcommerceConfigForm from './EcommerceConfigForm';
import { EcomConfigSchema, ecomConfigSchema } from './ecommerceConfigSchema';

interface Props {
  brandKey: BrandV2['key'];
  locationFieldConfigs: BrandV2['brandLocationFieldConfigs'];
  ecommerceAssortments: BrandV2['ecommerceAssortments'];
  error?: boolean;
  handleSave: (selectedEcomAssortments: string[], show: boolean) => void;
  hasAdminRights: boolean;
}

function EcommerceConfig({
  brandKey,
  locationFieldConfigs,
  ecommerceAssortments,
  handleSave,
  hasAdminRights,
}: Props) {
  const t = useAppTFunction();
  const tBrand = useBrandTFunction(brandKey);
  const [isEditing, setIsEditing] = useState(false);

  const existingConfig = useMemo(
    () =>
      locationFieldConfigs.find(({ fieldName }) => AvailableLocationFields.Ecommerce === fieldName),
    [locationFieldConfigs]
  );

  const showFields = useMemo(
    () =>
      locationFieldConfigs.some(
        ({ fieldName, show }) => AvailableLocationFields.Ecommerce === fieldName && show
      ),
    [locationFieldConfigs]
  );
  // TODO: fix after MID-909 is done, so that we filter out only selected ecom.
  const defaultValues: EcomConfigSchema = {
    selectedEcomAssortments: ecommerceAssortments.map((x) => x.key),
    show: !!existingConfig?.show,
  };

  const handleSaveForm = (input: EcomConfigSchema) => {
    const ecomAssortmentKeys = input.selectedEcomAssortments;
    handleSave(ecomAssortmentKeys, input.show);
    setIsEditing(false);
  };

  return (
    <BrandConfigCard
      header={t('brandDetailsPage.ecommerceConfig.title', {
        locations: tBrand('brand.location.name.plural'),
      })}
      isEditing={isEditing}
      handleEdit={() => setIsEditing(true)}
      handleCancelEdit={() => setIsEditing(false)}
      hasAdminRights={hasAdminRights}
    >
      {isEditing ? (
        <UniversalFormProvider schema={ecomConfigSchema} defaultValues={{ ...defaultValues }}>
          <EcommerceConfigForm
            cancelEdit={() => setIsEditing(false)}
            save={handleSaveForm}
            brandKey={brandKey}
            ecomAssortments={ecommerceAssortments}
          />
        </UniversalFormProvider>
      ) : (
        <>
          <CheckboxWithLabel
            disabled
            checked={showFields}
            label={t('brandDetailsPage.ecommerceConfig.checkbox', {
              locations: tBrand('brand.location.name.plural'),
            })}
          />
          {!!showFields && (
            <SelectedEcomAssortments
              selectedEcomAssortments={ecommerceAssortments}
              brandKey={brandKey}
            />
          )}
        </>
      )}
    </BrandConfigCard>
  );
}

export default EcommerceConfig;
