import { Location, AvailableLocationFields, BrandV2 } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { Divider, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { LabelValueTable, OutlinedPaper } from '@shared/components';
import { ServicesList } from '../List';
import AddressBlock from './LocationDetails/AddressBlock';
import ContactBlock from './LocationDetails/ContactBlock';
import OpeningHoursBlock from './LocationDetails/OpeningHoursBlock';
import SpecialOpeningHoursBlock from './LocationDetails/SpecialOpeningHoursBlock';

interface Props {
  location: Location;
  brand: BrandV2;
}

function LocationDetails({ location, brand }: Props) {
  const t = useAppTFunction();
  const { breakpoints } = useTheme();
  const isBiggerThanXL = useMediaQuery(breakpoints.up('xl'));
  const isBiggerThan2250 = useMediaQuery(breakpoints.up(2250));

  const hasPinmetoMapping = !!location.integrationDetails.pinmeto.pinmetoId;

  const showPinmetoData =
    brand?.brandLocationFieldConfigs?.some(
      (config) => config.fieldName === AvailableLocationFields.PinMeTo
    ) || false;

  return (
    <Grid container spacing={3}>
      <Grid container item sm={12} xl={isBiggerThan2250 ? 9 : 12} width={'100%'}>
        <OutlinedPaper>
          <Stack
            direction={{ sm: 'column', xl: isBiggerThanXL ? 'row' : 'column' }}
            gap={3}
            divider={<Divider orientation={isBiggerThanXL ? 'vertical' : 'horizontal'} flexItem />}
          >
            <Grid container item flexDirection={'column'} sm={12} md={6} spacing={4}>
              <Grid item data-testid="location-address-block">
                <AddressBlock
                  title={t('location.detail.address.title')}
                  address={location.contactInfo.address}
                  hasPinmetoMapping={hasPinmetoMapping}
                />
                <LabelValueTable
                  labelWidth={120}
                  items={[
                    {
                      label: t('location.detail.coordinates.longitude'),
                      value: location.contactInfo.coordinates?.long,
                    },

                    {
                      label: t('location.detail.coordinates.latitude'),
                      value: location.contactInfo.coordinates?.lat,
                    },
                  ]}
                />
              </Grid>
              {location.contactInfo.postalAddress && (
                <Grid item>
                  <AddressBlock
                    title={t('location.detail.address.postalAddress')}
                    address={location.contactInfo.postalAddress}
                  />
                </Grid>
              )}
            </Grid>

            <Grid container item flexDirection={'column'} sm={12} md={6} spacing={4}>
              {showPinmetoData && (
                <Grid item rowSpacing={2}>
                  <OpeningHoursBlock
                    openingHours={location.integrationDetails.pinmeto.openHours}
                    hasPinmetoMapping={hasPinmetoMapping}
                  />
                  {location.integrationDetails.pinmeto.specialOpenHours && (
                    <SpecialOpeningHoursBlock
                      openingHours={location.integrationDetails.pinmeto.specialOpenHours}
                      hasPinmetoMapping={hasPinmetoMapping}
                    />
                  )}
                </Grid>
              )}
              <Grid item>
                <ContactBlock
                  contact={location.contactInfo.contact}
                  hasPinmetoMapping={hasPinmetoMapping}
                />
              </Grid>
            </Grid>
          </Stack>
        </OutlinedPaper>
      </Grid>

      <Grid container item sm={12} xl={isBiggerThan2250 ? 3 : 6}>
        <OutlinedPaper>
          <ServicesList services={location.apps} />
        </OutlinedPaper>
      </Grid>
    </Grid>
  );
}

export default LocationDetails;
