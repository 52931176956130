import { useTranslation } from 'react-i18next';
import { TOptions } from 'i18next';
import { MessagePaths } from '../utils/messageTypeUtils';

export const useMessagesTranslation = <MessagesT extends Record<string, any>>() => {
  const translation = useTranslation();

  const appT = (key: MessagePaths<MessagesT>, options?: TOptions) => {
    const [keyNs, ...rest] = key.split('.');

    return translation.t(rest.join('.'), {
      ns: keyNs,
      ...options,
    });
  };

  return {
    ...translation,
    t: appT,
  };
};