import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { Person } from '../person/personTypes';
import { Attachment, KeyInfo, SanityBlockContent } from './commonFieldTypes';

export enum ArticleType {
  standard = 'article',
  module = 'moduleArticle',
  event = 'eventArticle',
  service = 'serviceArticle',
}

export interface SanityArticlePreview {
  id: string;
  title: string;
  intro: string;
  url: string;
  image: SanityImageSource;
}

export interface SanityModuleArticle extends SanityArticlePreview {
  body?: SanityBlockContent[];
  attachments?: Attachment[];
  keyInfo?: KeyInfo;
  tags?: string[];
  categoryTitle?: string;
}

export interface SanityStandardArticle extends SanityArticlePreview {
  body?: SanityBlockContent[];
  attachments?: Attachment[];
  keyInfo?: KeyInfo;
  tags?: string[];
  author?: Person[];
  categoryTitle?: string;
  publishAt: string;
  module: string;
}
