import { MailboxCircleIllustration, MailboxIllustration } from '@assets/images';
import { useBrandadminTFunction } from '@dap-admin/i18n';
import { Grid, Typography } from '@mui/material';
import { Paper } from '@shared/components';

export default function HelpCenterBlock() {
  const t = useBrandadminTFunction();

  return (
    <Paper sx={{ paddingBottom: 0 }}>
      <Grid container spacing={2} justifyContent={{ xs: 'center', md: 'right' }} wrap="wrap">
        <Grid
          item
          md
          textAlign={{ xs: 'center', md: 'left' }}
          order={{ xs: 2, md: 1 }}
          paddingBottom={{ xs: 4, md: 0 }}
          marginBottom={{ xs: 0, md: 4 }}
        >
          <Typography paddingBottom={{ xs: 0, md: 2 }} variant="h5">
            {t('brandadmin.help.title')}
          </Typography>
          <Typography component="p" variant="h6" fontWeight={500} color="text.secondary">
            {t('brandadmin.help.description')}
          </Typography>
          <Typography
            paddingTop={1}
            component="p"
            variant="h6"
            fontWeight={600}
            color="text.secondary"
            mb={{ md: 4 }}
          >
            {t('brandadmin.help.email')}
          </Typography>
        </Grid>

        <Grid
          item
          md={'auto'}
          order={{ xs: 1, md: 2 }}
          display={{ xs: 'none', md: 'flex' }}
          justifyContent={'flex-end'}
          alignItems={'flex-end'}
          p={0}
        >
          <img src={MailboxIllustration} height="191" alt="" />
        </Grid>
        <Grid item display={{ md: 'none', xs: 'flex' }}>
          <img src={MailboxCircleIllustration} height="140" alt="" />
        </Grid>
      </Grid>
    </Paper>
  );
}
