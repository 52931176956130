import { datahubConfig } from './datahubConfig';

export const datahubBaseUrl = datahubConfig.url;

const apiPaths = {
  findEvents: 'v1/find/events',
};

// EVENTS
export const DATAHUB_FIND_EVENTS = apiPaths.findEvents;
