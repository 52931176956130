import { EditCard } from '@dap-common/ui';
import { ReactNode } from 'react';

interface Props {
  header: ReactNode;
  isEditing: boolean;
  handleEdit: () => void;
  children: ReactNode;
  isLoading?: boolean;
  handleCancelEdit: () => void;
  hasAdminRights: boolean;
}

function BrandConfigCard({
  header,
  isEditing,
  handleEdit,
  children,
  handleCancelEdit,
  hasAdminRights,
}: Props) {
  return (
    <EditCard
      header={header}
      edit={handleEdit}
      cancel={handleCancelEdit}
      isEditing={isEditing}
      isEditable={hasAdminRights}
    >
      {children}
    </EditCard>
  );
}

export default BrandConfigCard;
