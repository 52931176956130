import { useBrandadminTFunction } from '@dap-admin/i18n';
import { Location, Property, PropertyValueTypes } from '@dap-admin/types';
import { tableHeight } from '@dap-admin/utils';
import { useAppTFunction } from '@dap-common/i18n';
import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import {
  DataGridProProps,
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarProps,
  GridToolbarQuickFilter,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import TreeTable from './TreeTable';
import { formatDate } from 'date-fns';

interface Props {
  properties: Location['properties'];
  showFeatures?: boolean;
  hasAdminRights?: boolean;
  onEdit?: (property: Property) => void;
  onDelete?: (property: Property) => void;
}

interface TransformedProperty extends Property {
  hierarchy: Array<string>;
}

export default function PropertiesTable({
  properties,
  showFeatures = true,
  hasAdminRights = false,
  onEdit,
  onDelete,
}: Props) {
  const t = useAppTFunction();
  const tBrandadmin = useBrandadminTFunction();

  const actionMenuColumn: GridActionsColDef<Property> = useMemo(
    () => ({
      field: 'actions',
      type: 'actions',
      headerName: t('brandadmin.action.title'),
      width: 70,
      align: 'center',
      resizable: false,
      renderHeader: () => null,
      sortable: false,
      filterable: false,
      disableReorder: true,
      hideable: false,

      // TODO: hack to remove action menu for grouped rows, use renderCell instead of getActions
      getActions: () => [],
      renderCell: (params) => {
        if (!params.rowNode.parent) {
          return [];
        }
        return [
          <>
            {/* must include label and placeholder, doc doesnt say what they are for */}
            {onEdit && (
              <GridActionsCellItem
                label={t('common.edit')}
                icon={<EditOutlined fontSize={'small'} />}
                onClick={() => onEdit(params.row)}
                placeholder={t('common.edit')}
              />
            )}
            {onDelete && (
              <GridActionsCellItem
                icon={<DeleteOutlined fontSize={'small'} />}
                onClick={() => onDelete(params.row)}
                placeholder={t('common.delete')}
                label={t('common.delete')}
              />
            )}
          </>,
        ];
      },
    }),
    [onEdit, onDelete, t]
  );

  const columns: Array<GridColDef<Property>> = useMemo(() => {
    const baseColumns = [
      {
        field: 'value',
        headerName: t('brandadmin.properties.value'),
        flex: 1,
        type: 'string',
        valueGetter: ({ row }: GridValueGetterParams<string, Property>) => {
          if (!row.value) return '';

          if (row.valueType === PropertyValueTypes.boolean) {
            return row.value === 'true' ? t('common.yes') : t('common.no');
          }

          if (row.valueType === PropertyValueTypes.date) {
            return formatDate(row.value, 'dd/MM/yyyy');
          }

          return row.value;
        },
      },
    ];

    if (!showFeatures || !hasAdminRights) {
      return baseColumns;
    }

    return [...baseColumns, actionMenuColumn];
  }, [t, showFeatures, hasAdminRights, actionMenuColumn]);

  const rows: GridRowsProp<TransformedProperty> = useMemo(
    () =>
      properties.map((property) => ({
        ...property,
        hierarchy: [
          property.keyGroup ? property.keyGroup : tBrandadmin('brandadmin.properties.unspecified'),
          property.key,
        ],
      })),
    [properties, tBrandadmin]
  );

  const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row: TransformedProperty) =>
    row.hierarchy;

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: tBrandadmin('brandadmin.properties.key'),
    flex: 0.8,
    minWidth: 100,
  };

  return (
    <Box width={'100%'} display="grid" height={tableHeight}>
      <TreeTable
        columns={columns}
        rows={rows}
        getTreeDataPath={getTreeDataPath}
        localeText={{
          noRowsLabel: tBrandadmin('brandadmin.properties.noProperties'),
        }}
        getRowId={(row: Property) => row.key}
        groupingColDef={groupingColDef}
        components={
          showFeatures
            ? {
                Toolbar: CustomGridToolbar,
              }
            : {}
        }
        defaultGroupingExpansionDepth={1} // expands the groups as default
      />
    </Box>
  );
}

const CustomGridToolbar = (props: GridToolbarProps) => {
  return (
    <GridToolbarContainer {...props} sx={{ padding: ({ spacing }) => spacing(0, 0, 1, 0) }}>
      <GridToolbarQuickFilter data-testid="PropertiesTableSearch" />
    </GridToolbarContainer>
  );
};
