import { Stack } from '@mui/material';

import { messages } from '@dap-common/i18n';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { cardInputProps } from './cardInputProps';
import { LocationByggplannleggerSchema } from '@dap-admin/types';
import {
  EditCard,
  LabelValue,
  SaveOrCancelEdit,
  TextInput,
  UniversalFormProvider,
} from '@dap-common/ui';

interface Props {
  hasAdminRights: boolean;
  byggplanleggerId?: string;
  save: (updatedId: LocationByggplannleggerSchema) => void;
  saving: boolean;
}

const schema = yup.object().shape({
  byggplanleggerId: yup.string(),
});

const ByggplanleggerCard = ({ byggplanleggerId, save, saving, hasAdminRights }: Props) => {
  const { t } = useTranslation('pagesHomeLocation');
  const [isEditing, setIsEditing] = useState(false);
  return (
    <EditCard
      header={t(messages.pagesHomeLocation.byggplanleggerId)}
      isEditing={isEditing}
      edit={() => setIsEditing(true)}
      cancel={() => setIsEditing(false)}
      isEditable={hasAdminRights}
      titleTypographyVariant="h6"
      fullHeight
    >
      {isEditing ? (
        <UniversalFormProvider
          defaultValues={{ byggplanleggerId: byggplanleggerId || '' }}
          schema={schema}
        >
          <ByggplanleggerForm save={save} saving={saving} cancel={() => setIsEditing(false)} />
        </UniversalFormProvider>
      ) : (
        <LabelValue
          label={t(messages.pagesHomeLocation.byggplanleggerId)}
          value={byggplanleggerId || '-'}
        />
      )}
    </EditCard>
  );
};

export default ByggplanleggerCard;

interface FromProps extends Omit<Props, 'hasAdminRights'> {
  cancel: () => void;
}

const ByggplanleggerForm = ({ cancel, save, saving }: FromProps) => {
  const { t } = useTranslation('pagesHomeLocation');
  const { handleSubmit } = useFormContext<LocationByggplannleggerSchema>();

  const onSubmit = handleSubmit((input: LocationByggplannleggerSchema) => {
    save(input);
    cancel();
  });
  return (
    <form>
      <Stack spacing={1.5}>
        <TextInput
          name="byggplanleggerId"
          label={t(messages.pagesHomeLocation.byggplanleggerId)}
          {...cardInputProps}
        />
        <SaveOrCancelEdit save={onSubmit} loading={saving} cancel={cancel} align="right" />
      </Stack>
    </form>
  );
};
