import { useAuth0 } from '@auth0/auth0-react';
import { routes } from '@dap-common/consts';
import { useLanguageSelection } from '@dap-common/data-access';
import { Sidebar } from '@dap-common/modules';
import { Header } from '@dap-common/ui';
import { Stack } from '@mui/material';
import { LocalStorage } from '@shared/constants';
import { useDisclosure } from '@shared/hooks';
import { themeV2 } from '@shared/styles';
import { useEffect } from 'react';
import { useLocation, useMatch } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

export default function AppLayout({ children }: Props) {
  const { switchLanguage, language } = useLanguageSelection();
  const { logout } = useAuth0();
  const isSidebarOpen = localStorage.getItem(LocalStorage.SHOW_SIDEBAR);
  const { pathname } = useLocation();

  const { isOpen: openSidebar, onToggle: toggleSidebar } = useDisclosure(isSidebarOpen === 'true');

  const toggle = () => {
    localStorage.setItem(LocalStorage.SHOW_SIDEBAR, (!openSidebar).toString());
    toggleSidebar();
  };

  const location = useLocation();

  const isABrandadminPage = useMatch(`${routes.brandadmin}/*`);
  const isMePage = useMatch(`${routes.me}/*`);
  const isUsingV2Theme = isABrandadminPage || isMePage;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Stack direction="row">
      <Sidebar logoutCallback={logout} openSidebar={openSidebar} toggleSidebar={toggle} />

      <Stack
        bgcolor={isUsingV2Theme ? themeV2.palette.background.default : 'background.default'}
        height="100vh"
        width="100%"
        overflow="hidden auto"
      >
        {!location.pathname.includes('sanity') && (
          <Header switchLanguage={switchLanguage} language={language} />
        )}
        {children}
      </Stack>
    </Stack>
  );
}
