import { Stack, SxProps, Typography } from '@mui/material';
import { PreviewCard } from '@dap-common/ui';
import { FillMinMax } from '@shared/components';
import PreviewSanityImage from '../previewContent/PreviewSanityImage';
import { previewCardHeight, previewCardImageHeight } from './fremdriftConstants';
import { SanityModuleArticle } from '@dap-sanity/types';
import { maxLinesSx } from '@shared/utils';

interface Props {
  articles: Array<SanityModuleArticle>;
  categorySlug: string;
}

export default function FremdriftArticleList({ articles, categorySlug }: Props) {
  return (
    <FillMinMax minWidth="200px" gridGap="1rem">
      {articles.map(({ id, url, image, title, intro }) => (
        <PreviewCard to={`${categorySlug}/${url}`} key={id} sx={previewCardSx}>
          <Stack spacing={1}>
            {image && <PreviewSanityImage height={previewCardImageHeight} image={image} />}
            <Typography variant="h4" sx={max2LinesTextSx}>
              {title}
            </Typography>
            <Typography variant="body3" sx={max3LinesTextSx}>
              {intro}
            </Typography>
          </Stack>
        </PreviewCard>
      ))}
    </FillMinMax>
  );
}

const max2LinesTextSx = maxLinesSx(2);
const max3LinesTextSx = maxLinesSx(3);

const previewCardSx: SxProps = {
  height: previewCardHeight,
};
