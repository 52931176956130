import { Dataowner } from '@dap-admin/types';
import { messages, useTranslationWithBrandOptions } from '@dap-common/i18n';
import { FormDialog } from '@dap-common/ui';

interface Props {
  dataowner: Dataowner;
  cancelDialog: () => void;
  deleteDataowner: (dataownerKey: string) => void;
  loading: boolean;
  brandKey: string;
}

const DeleteDataowner = ({
  dataowner,
  cancelDialog,
  deleteDataowner,
  loading,
  brandKey,
}: Props) => {
  const { t } = useTranslationWithBrandOptions('pagesHomeDataowner', brandKey);

  return (
    <FormDialog
      title={dataowner.name}
      entityName={dataowner.name}
      content={t(messages.pagesHomeDataowner.delete.confirm, {
        dataowner: messages.brand.dataowner.name.singular,
        theDataowner: messages.brand.dataowner.name.definite,
      })}
      handleConfirm={() => deleteDataowner(dataowner.key)}
      handleCancel={() => cancelDialog()}
      loading={loading}
    />
  );
};

export default DeleteDataowner;
