import { RedirectLoginOptions } from '@auth0/auth0-react';

export enum AuthStatus {
  Loading = 'LOADING',
  NotAuthenticated = 'NOT_AUTHENTICATED',
  Authorized = 'Authorized',
  NotAuthorized = 'NOT_AUTHORIZED',
  Error = 'ERROR',
}

export interface AuthContextProps {
  token: string;
  login: (options?: RedirectLoginOptions) => void;
  logout: () => void;
  isAuthenticated: boolean;
  error: Error | undefined;
  isLoading: boolean;
}
