import { Avatar, Box as MuiBox, Stack, styled, Typography } from '@mui/material';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { messages } from '@dap-common/i18n';
import { maxLinesSx } from '@shared/utils';
import dayjs from 'dayjs';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PreviewSanityImage from '../previewContent/PreviewSanityImage';
import { Person } from '@dap-sanity/types';
import { PreviewCard } from '@dap-common/ui';

interface Props {
  url: string;
  image: SanityImageSource;
  imgHeight: number;
  title: string;
  intro: string;
  publishedAt: string;
  author?: Person[];
}

const NewsPreviewCard: React.FC<Props> = ({
  url,
  image,
  imgHeight,
  title,
  intro,
  publishedAt,
  author,
}) => {
  const { t } = useTranslation('newsArticles');

  const publishedString = useMemo(() => {
    const daysPublishedAgo = dayjs().diff(publishedAt, 'day');
    if (daysPublishedAgo === 0) {
      return t(messages.newsArticles.today);
    }
    return t(messages.newsArticles.updatedAgo, {
      amount: daysPublishedAgo,
      timeUnit: 'd',
    });
  }, [publishedAt, t]);

  return (
    <PreviewCard to={url} sx={{ minWidth: '210px', width: '210px', gap: '12px' }}>
      <Stack spacing={1}>
        <PreviewSanityImage height={imgHeight} image={image} />
        <Typography variant="h4" sx={max2LinesTextSx}>
          {title}
        </Typography>
        <Typography variant="body3" sx={max3LinesTextSx}>
          {intro}
        </Typography>
      </Stack>
      <Box>
        <Stack direction="row" spacing={1} alignItems="center">
          {author && author.length > 0 && (
            <>
              <Avatar sx={{ height: 20, width: 20 }} />
              <Typography variant="body5">
                {author.length > 1 ? `${author[0].name} +${author.length - 1}` : author[0].name}
              </Typography>
            </>
          )}
        </Stack>
        <Typography variant="body5">{publishedString}</Typography>
      </Box>
    </PreviewCard>
  );
};

const Box = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0.5rem',
  background: theme.palette.cardBackgrounds.lightPurple,
  borderRadius: '7px',
  height: '36px',
}));

const max2LinesTextSx = maxLinesSx(2);
const max3LinesTextSx = maxLinesSx(3);

export default memo(NewsPreviewCard);
