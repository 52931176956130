import { useBrandadminTFunction } from '@dap-admin/i18n';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { useState } from 'react';

enum Status {
  Active = 'active',
  Deactivated = 'deactivated',
}

export default function FilterOperatorSelect(props: GridFilterInputValueProps) {
  const t = useBrandadminTFunction();

  const { item, applyValue } = props;

  const [option, setOption] = useState<Status | ''>(
    item.value ? Status.Deactivated : Status.Active
  );

  const handleChange = (event: SelectChangeEvent<Status>) => {
    setOption(event.target.value as Status);
    const newFieldItem = {
      ...item,
      value: event.target.value === Status.Deactivated,
    };
    applyValue(newFieldItem);
  };

  return (
    <Select
      variant={'outlined'}
      size={'small'}
      value={option ?? ''}
      onChange={handleChange}
      sx={{
        height: '100%',
      }}
    >
      {Object.values(Status).map((status) => (
        <MenuItem key={'menu-item-' + status} value={status}>
          {t(`brandadmin.filter.status.${status}`)}
        </MenuItem>
      ))}
    </Select>
  );
}
