import { useBrandTFunction } from '@dap-admin/i18n';
import { useAppTFunction } from '@dap-common/i18n';
import { FormDialog } from '@dap-common/ui';

interface Props {
  name: string;
  close: () => void;
  deleteLocation: () => void;
  isDeletingLocation: boolean;
  brandKey: string;
}

const DeleteLocation = ({ name, close, deleteLocation, isDeletingLocation, brandKey }: Props) => {
  const t = useAppTFunction();
  const tBrand = useBrandTFunction(brandKey);

  return (
    <FormDialog
      title={t('location.actionMenu.delete', { location: tBrand('brand.location.name.singular') })}
      entityName={name}
      content={t('location.delete.title', {
        theLocation: tBrand('brand.location.name.definite'),
        locationName: name,
      })}
      handleConfirm={deleteLocation}
      handleCancel={close}
      loading={isDeletingLocation}
    />
  );
};

export default DeleteLocation;
