import { useBrandTFunction } from '@dap-admin/i18n';
import { Dataowner } from '@dap-admin/types';
import { brandStylesConfig } from '@dap-common/styles';
import { RecognizedBrands } from '@dap-common/types';
import { FlexRow } from '@dap-common/ui';
import { Typography } from '@mui/material';
import { Paper } from '@shared/components';
import { EntityButton, EntityChart } from '../Chart';
import { getLocationRoute } from '@dap-common/consts';

interface Props {
  dataowner: Dataowner;
}

export default function DataownerChart({ dataowner }: Props) {
  const t = useBrandTFunction(dataowner.brand.key);
  const brandStyle =
    brandStylesConfig[dataowner.brand.key as RecognizedBrands] || brandStylesConfig.default;

  return (
    <Paper sx={{ height: '100%' }}>
      <FlexRow
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'nowrap'}
        gap={3}
        mb={3}
      >
        <Typography variant="h6" noWrap>
          {t('brand.dataowner.name.singular', 'capitalize')}
        </Typography>
        {brandStyle.icon && (
          <img
            src={brandStyle.icon}
            alt={`${dataowner.brand.name} logo`}
            height="24px"
            width="24px"
            style={{ objectFit: 'contain' }}
          />
        )}
      </FlexRow>

      <EntityChart>
        <EntityButton color="primary" active>
          <Typography component="span" fontWeight="bold" noWrap>
            {dataowner.name}
          </Typography>
          <Typography component="span" variant="body2" color="text.secondary" noWrap>
            {t('brand.dataowner.name.singular', 'capitalize')}
          </Typography>
        </EntityButton>

        {dataowner.locations.length === 1 ? (
          <EntityButton
            to={getLocationRoute({ locationKey: dataowner.locations[0].key })}
            hoverColor="secondary"
          >
            <Typography component="span" fontWeight="bold" noWrap>
              {dataowner.locations[0].name}
            </Typography>
            <Typography component="span" variant="body2" color="text.secondary" noWrap>
              {t('brand.location.name.singular', 'capitalize')}
            </Typography>
          </EntityButton>
        ) : (
          <EntityButton active>
            <Typography fontWeight="bold" noWrap>
              {`${dataowner.locations.length} ${
                dataowner.locations.length === 1
                  ? t('brand.location.name.singular')
                  : t('brand.location.name.plural')
              }`}
            </Typography>
          </EntityButton>
        )}
      </EntityChart>
    </Paper>
  );
}
