import { Check } from '@mui/icons-material';
import { alpha, ListItemButton, ListItemText, styled, Typography, useTheme } from '@mui/material';

interface Props {
  onClick: () => void;
  text: string;
  isSelected: boolean;
}

const StyledListItemButton = styled(ListItemButton)({
  '&.focusedItem': {
    backgroundColor: 'rgba(92, 79, 149, 0.43000000000000005)',
  },
  '&:hover': {
    backgroundColor: '#686361',
  },
});

export default function ExpandMenuListItem({ onClick, text, isSelected }: Props) {
  const { palette } = useTheme();

  return (
    <StyledListItemButton
      onClick={onClick}
      selected={isSelected}
      focusVisibleClassName="focusedItem"
      sx={{
        minWidth: '10rem',
        p: 0,
        '&.Mui-selected': {
          bgcolor: alpha(palette.primary.main, 0.1),
        },
        '&:hover': {
          backgroundColor: alpha(palette.primary.main, 0.2),
        },
      }}
      disableGutters
    >
      {isSelected && (
        <Check fontSize="small" htmlColor={palette.primary.main} sx={{ minWidth: '2rem' }} />
      )}
      <ListItemText
        sx={isSelected ? {} : { padding: '0 2rem' }}
        primary={<Typography variant="body3">{text}</Typography>}
      />
    </StyledListItemButton>
  );
}
