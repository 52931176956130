import { Card, Link, Stack, SxProps, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useTranslation } from 'react-i18next';
import { messages } from '@dap-common/i18n';
import { FancyAvatar } from '@shared/components';

interface Props {
  name: string;
  userPicture?: string;
  jobTitle?: string;
  phoneNumber?: string;
  email: string;
}

function ContactCard({ name, userPicture, jobTitle, phoneNumber, email }: Props) {
  const { t } = useTranslation('common');
  return (
    <Card sx={cardStyle}>
      <Stack direction="row" spacing={2}>
        <FancyAvatar username={name} userPicture={userPicture || name} />
        <Stack spacing={1}>
          <Typography variant="h4">{name}</Typography>
          {jobTitle && <Typography variant="body3Light">{jobTitle}</Typography>}
          {phoneNumber && (
            <Stack direction="row" spacing={0.5} alignItems="center">
              <PhoneIcon color="primary" fontSize="inherit" />
              <Typography variant="body3">{phoneNumber}</Typography>
            </Stack>
          )}
          <Stack direction="row" spacing={0.5} alignItems="center">
            <MailOutlineIcon color="primary" fontSize="inherit" />
            <Typography variant="body3">
              <Link href={`mailto:${email}`} variant="body3">
                {t(messages.common.email.sendEmail)}
              </Link>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}

export default ContactCard;

const cardStyle: SxProps = { padding: '15px 20px', height: '100%', width: '255px' };
