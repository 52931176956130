import { ListItemProps, ListItem as MuiListItem, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

interface Props extends ListItemProps {
  borderRadius?: number;
  marginBottom?: 'normal' | 'none';
}

export default function OutlinedListItem({
  marginBottom = 'normal',
  borderRadius = 3,
  children,
  ...rest
}: PropsWithChildren<Props>) {
  return (
    <StyledListItem marginBottom={marginBottom} borderRadius={borderRadius} {...rest}>
      {children}
    </StyledListItem>
  );
}

const StyledListItem = styled(MuiListItem, {
  shouldForwardProp: (prop) => prop !== 'borderRadius' && prop !== 'marginBottom',
})<Pick<Props, 'borderRadius' | 'marginBottom'>>(
  ({ theme, borderRadius = 1, marginBottom = 'normal' }) => ({
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey.A100}`,
    borderRadius: theme.shape.borderRadius * borderRadius,
    marginBottom: marginBottom === 'none' ? 0 : theme.spacing(1),
    ...(marginBottom === 'none' && {
      borderBottom: 'none',
      borderRadius: 0,
      '&:first-of-type': {
        borderRadius: theme.spacing(borderRadius / 2, borderRadius / 2, 0, 0),
      },
      '&:last-of-type': {
        borderBottom: `1px solid ${theme.palette.grey.A100}`,
        borderRadius: theme.spacing(0, 0, borderRadius / 2, borderRadius / 2),
      },
      '&:only-child': {
        borderRadius: theme.shape.borderRadius * borderRadius,
      },
    }),
  })
);
