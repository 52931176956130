import dataownerEn from '../resources/en/dataowner.json';
import brandadminEn from '../resources/en/brandadmin.json';
import employeeEn from '../resources/en/employee.json';
import locationEn from '../resources/en/location.json';
import errorEn from '../resources/en/error.json';

import dataownerNo from '../resources/no/dataowner.json';
import brandadminNo from '../resources/no/brandadmin.json';
import employeeNo from '../resources/no/employee.json';
import locationNo from '../resources/no/location.json';
import errorNo from '../resources/no/error.json';

import dataownerSv from '../resources/sv/dataowner.json';
import brandadminSv from '../resources/sv/brandadmin.json';
import employeeSv from '../resources/sv/employee.json';
import locationSv from '../resources/sv/location.json';
import errorSv from '../resources/sv/error.json';

import { BrandadminLanguageResources } from '../types/BrandadminResources';

export const brandadminI18nResources: BrandadminLanguageResources = {
  no: {
    brandadmin: brandadminNo,
    dataowner: dataownerNo,
    location: locationNo,
    employee: employeeNo,
    error: errorNo,
  },
  sv: {
    brandadmin: brandadminSv,
    dataowner: dataownerSv,
    location: locationSv,
    employee: employeeSv,
    error: errorSv,
  },
  en: {
    brandadmin: brandadminEn,
    dataowner: dataownerEn,
    location: locationEn,
    employee: employeeEn,
    error: errorEn,
  },
};
