import {
  IntegrationConfigurations,
  LocationPinMeToMappingSchema,
  Location,
  MutationResult,
  UpdatePinMeToMappingRequest,
} from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { FlexRow } from '@dap-common/ui';
import { PinmetoLocationMappingDTO } from '@generated-types';
import { AddLocationOutlined } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { OutlinedPaper } from '@shared/components';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import PinmetoInfo from './PinmetoActions';
import PinmetoDialog, { PinmetoAction } from './PinmetoDialog';

interface Props {
  isAdmin: boolean;
  pinmeto: IntegrationConfigurations['pinmeto'];
  lastPinMeToSync: string;
  syncPinMeTo: () => void;
  syncPinMeToState: MutationResult<Location, Location['key']>;
  updatePinMeToMapping: (mapping: LocationPinMeToMappingSchema) => void;
  updatePinMeToState: MutationResult<PinmetoLocationMappingDTO, UpdatePinMeToMappingRequest>;
  removePinMeToMapping: () => void;
  removePinMeToState: MutationResult<void, Location['key']>;
}

export default function PinMeTo({
  isAdmin,
  pinmeto,
  lastPinMeToSync,
  syncPinMeTo,
  syncPinMeToState,
  updatePinMeToMapping,
  updatePinMeToState,
  removePinMeToMapping,
  removePinMeToState,
}: Props) {
  const t = useAppTFunction();
  const [dialogAction, setDialogAction] = useState<PinmetoAction>('none');
  const { pinmetoId, pinmetoAutomatedSync } = pinmeto;
  const hasAutomatedSync = pinmetoAutomatedSync || false;

  const closeDialog = () => setDialogAction('none');

  const savePinmetoData = (data: LocationPinMeToMappingSchema) => {
    updatePinMeToMapping(data);
  };

  const deleteMapping = () => {
    removePinMeToMapping();
  };

  useEffect(() => {
    if (updatePinMeToState.isSuccess || removePinMeToState.isSuccess) closeDialog();
  });

  const handleAutomatedSync = () => {
    if (pinmetoId) {
      const data: LocationPinMeToMappingSchema = {
        pinMeToId: pinmetoId,
        automatedSync: !hasAutomatedSync,
      };
      updatePinMeToMapping(data);
    }
  };

  return (
    <OutlinedPaper paddingY="dense" sx={{ height: '100%' }}>
      <FlexRow justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant="h6">{t('location.detail.pinmeto.title')}</Typography>
        {pinmetoId ? (
          <PinmetoInfo
            changeId={() => setDialogAction('change')}
            sync={syncPinMeTo}
            isSyncing={syncPinMeToState.isLoading}
            deleteId={() => setDialogAction('delete')}
            hasAutomatedSync={hasAutomatedSync}
            handleAutomatedSync={handleAutomatedSync}
            pinmetoId={pinmetoId}
            hasAdminRights={isAdmin}
          />
        ) : isAdmin ? (
          <Button
            variant="outlined"
            startIcon={<AddLocationOutlined fontSize="small" />}
            size="small"
            onClick={() => setDialogAction('add')}
          >
            {t('location.detail.pinmeto.dialog.add.title')}
          </Button>
        ) : (
          t('location.detail.pinmeto.noConnection')
        )}
      </FlexRow>

      {lastPinMeToSync && (
        <Typography flexGrow={1}>
          {`${t('location.detail.pinmeto.lastUpdated')} ${dayjs(lastPinMeToSync).format('L, LT')}`}
        </Typography>
      )}

      {dialogAction !== 'none' && (
        <PinmetoDialog
          close={closeDialog}
          action={dialogAction}
          confirmDelete={deleteMapping}
          savePinmetoData={savePinmetoData}
          pinmetoId={pinmetoId}
          hasAutomatedSync={hasAutomatedSync}
          updatePinMeToState={updatePinMeToState}
          removePinMeToState={removePinMeToState}
        />
      )}
    </OutlinedPaper>
  );
}
