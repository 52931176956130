import { LocationEditSchema } from '@dap-admin/schemas';
import { useAppTFunction } from '@dap-common/i18n';
import { FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { SelectInputField, TextInputField } from '@shared/components';
import { CountryCode } from '@shared/types';
import { useController, useFormContext } from 'react-hook-form';
import PinmetoInfoBox from '../Pinmeto/PinmetoInfoBox';

interface Props {
  hasPinmetoMapping: boolean;
}

export default function EditAddress({ hasPinmetoMapping }: Props) {
  const t = useAppTFunction();

  const { control, watch } = useFormContext<LocationEditSchema>();

  const addressAndPostalAddressEqualController = useController({
    name: 'address.addressAndPostalAddressEqual',
    control,
  });
  const addressAndPostalAddressEqual = watch('address.addressAndPostalAddressEqual');

  const streetAddressController = useController({
    name: 'address.address.street',
    control,
  });
  const postalCodeController = useController({
    name: 'address.address.postalCode',
    control,
  });
  const cityController = useController({
    name: 'address.address.city',
    control,
  });
  const countryController = useController({
    name: 'address.address.country',
    control,
  });

  const postalStreetAddressController = useController({
    name: 'address.postalAddress.street',
    control,
  });
  const postalPostalCodeController = useController({
    name: 'address.postalAddress.postalCode',
    control,
  });
  const postalCityController = useController({ name: 'address.postalAddress.city', control });
  const postalCountryController = useController({ name: 'address.postalAddress.country', control });

  const latitudeController = useController({ name: 'coordinates.lat', control });
  const longitudeController = useController({ name: 'coordinates.long', control });

  return (
    <>
      {hasPinmetoMapping && (
        <PinmetoInfoBox text={t('location.detail.pinmeto.tooltip.notEditable')} />
      )}

      <SelectInputField
        {...countryController.field}
        disabled={hasPinmetoMapping}
        label={t('brandadmin.country.title')}
        fullWidth
        menuItems={[
          {
            name: t('brandadmin.country.norway'),
            value: CountryCode.Norway,
          },
          {
            name: t('brandadmin.country.sweden'),
            value: CountryCode.Sweden,
          },
          {
            name: t('brandadmin.country.finland'),
            value: CountryCode.Finland,
          },
        ]}
      />

      <TextInputField
        {...streetAddressController.field}
        error={streetAddressController.fieldState.error?.message}
        disabled={hasPinmetoMapping}
        label={t('location.detail.address.street')}
        required
        size="medium"
        fullWidth
      />

      <Stack direction="row" gap={2}>
        <Stack flex="1 1 40%">
          <TextInputField
            {...postalCodeController.field}
            error={postalCodeController.fieldState.error?.message}
            disabled={hasPinmetoMapping}
            label={t('location.detail.address.postalCode')}
            required
            size="medium"
            fullWidth
          />
        </Stack>
        <TextInputField
          {...cityController.field}
          error={cityController.fieldState.error?.message}
          disabled={hasPinmetoMapping}
          label={t('location.detail.address.city')}
          required
          size="medium"
          fullWidth
        />
      </Stack>
      <Stack direction="row" gap={2}>
        <TextInputField
          {...longitudeController.field}
          error={longitudeController.fieldState.error?.message}
          disabled={hasPinmetoMapping}
          label={t('location.detail.coordinates.longitude')}
          size="medium"
          fullWidth
        />
        <TextInputField
          {...latitudeController.field}
          error={latitudeController.fieldState.error?.message}
          disabled={hasPinmetoMapping}
          label={t('location.detail.coordinates.latitude')}
          size="medium"
          fullWidth
        />
      </Stack>

      <Typography variant="h5" mt={2}>
        {t('location.detail.address.postalAddress')}
      </Typography>

      <FormControlLabel
        labelPlacement="start"
        control={
          <Switch
            id={addressAndPostalAddressEqualController.field.name + '-id'}
            {...addressAndPostalAddressEqualController.field}
            checked={addressAndPostalAddressEqualController.field.value}
            size="small"
          />
        }
        label={
          <Typography variant="body2" fontWeight={300} color="text.secondary">
            {t('location.detail.address.sameAddressAsVisiting')}
          </Typography>
        }
        sx={{ ml: 0, gap: 1 }}
      />

      {!addressAndPostalAddressEqual && (
        <Stack mt={1}>
          <SelectInputField
            {...postalCountryController.field}
            label={t('brandadmin.country.title')}
            menuItems={[
              {
                name: t('brandadmin.country.norway'),
                value: CountryCode.Norway,
              },
              {
                name: t('brandadmin.country.sweden'),
                value: CountryCode.Sweden,
              },
              {
                name: t('brandadmin.country.finland'),
                value: CountryCode.Finland,
              },
            ]}
            fullWidth
          />

          <TextInputField
            {...postalStreetAddressController.field}
            error={postalStreetAddressController.fieldState.error?.message}
            label={t('location.detail.address.street')}
            required
            size="medium"
            fullWidth
          />

          <Stack direction="row" gap={2} height="fit-content">
            <Stack flex="1 1 40%">
              <TextInputField
                {...postalPostalCodeController.field}
                error={postalPostalCodeController.fieldState.error?.message}
                label={t('location.detail.address.postalCode')}
                required
                size="medium"
                fullWidth
              />
            </Stack>
            <TextInputField
              {...postalCityController.field}
              error={postalCityController.fieldState.error?.message}
              label={t('location.detail.address.city')}
              required
              size="medium"
              fullWidth
            />
          </Stack>
        </Stack>
      )}
    </>
  );
}
