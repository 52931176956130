import { BrandStyles } from '@dap-common/types';
import { alpha, Avatar, Button, Typography, useTheme } from '@mui/material';
import { memo, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  brandStyle: BrandStyles;
  onClick: () => void;
  disabled?: boolean;
}

function BrandButton({ children, brandStyle, onClick, disabled = false }: Props) {
  const { palette } = useTheme();
  const mainBrandColor = brandStyle.colors?.main;
  const lightBrandColor = brandStyle.colors?.light;

  return (
    <Button
      onClick={onClick}
      role="link"
      disabled={disabled}
      variant="outlined"
      color="grey"
      startIcon={
        <Avatar variant="square" sx={{ bgcolor: 'transparent' }}>
          <img src={brandStyle.icon} alt="" aria-hidden height="33px" width="33px" />
        </Avatar>
      }
      sx={{
        minWidth: '6rem',
        height: '3rem',
        justifyContent: 'space-between',
        border: `1px solid ${palette.divider}`,
        backgroundColor: lightBrandColor || mainBrandColor || alpha(palette.primary.light, 0.1),
        ':hover': {
          '>*': {
            color: palette.common.white,
          },
        },
      }}
    >
      <Typography
        variant="body1"
        fontWeight="500"
        noWrap
        color={({ palette }) => brandStyle.colors?.contrastText || palette.text.primary}
      >
        {children}
      </Typography>
    </Button>
  );
}

export default memo(BrandButton);
