import { AddCircleOutline, DeleteOutline } from '@mui/icons-material';
import { Box, Button, FormControl, FormLabel, IconButton, List, styled } from '@mui/material';
import { messages } from '@dap-common/i18n';
import { FunctionComponent } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldType, Margin, Size } from './formTypes';
import TextInput from './TextInput';
import { FlexRow } from '../Layout';

const arrayMessages = messages.common.form.field.array;

interface FieldConfig {
  name: string;
  inputLabel: string;
  required?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  margin?: Margin;
  size?: Size;
  readOnlyFn?: (field: FieldType) => boolean;
}

interface Props {
  arrayName: string;
  fieldConfigs: FieldConfig[];
  legend?: string;
  fullWidth?: true;
}

/**
 * @deprecated use FieldArray & FieldArrayItem from @shared/components
 */
const TextInputArray: FunctionComponent<Props> = ({
  arrayName,
  legend,
  fieldConfigs,
  fullWidth,
}) => {
  const { t } = useTranslation('common');
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: arrayName,
  });

  const emptyNewField = fieldConfigs.reduce((tmp, { name }) => ({ ...tmp, [name]: '' }), {});
  return (
    <FormControl component="fieldset" fullWidth={fullWidth}>
      {legend && <FormLabel component="legend">{legend}</FormLabel>}
      <SpacingList spacing={'1em'}>
        {(fields as FieldType[]).map((field, index) => (
          <Box component="li" key={field.id}>
            <FlexRow gap={1}>
              {fieldConfigs.map(({ readOnlyFn, readOnly, ...config }) => {
                return (
                  <SingleField
                    {...config}
                    arrayName={arrayName}
                    index={index}
                    defaultValue={field[config.name]}
                    key={config.name}
                    readOnly={readOnly || (readOnlyFn && readOnlyFn(field))}
                  />
                );
              })}
              <IconButton
                aria-label={t(arrayMessages.remove, { label: `${field['key']}` })}
                onClick={() => {
                  remove(index);
                }}
              >
                <DeleteOutline />
              </IconButton>
            </FlexRow>
          </Box>
        ))}
        <Button
          color="primary"
          onClick={() => append(emptyNewField)}
          startIcon={<AddCircleOutline />}
          size="small"
        >
          {t(arrayMessages.add)}
        </Button>
      </SpacingList>
    </FormControl>
  );
};

const SpacingList = styled(List)<{ spacing?: string }>`
  ${({ spacing }) =>
    spacing &&
    `
    & > *:not(:last-child) {
      margin-bottom: ${spacing};
    }
  `}
`;

interface SingleFieldProps extends FieldConfig {
  arrayName: string;
  index: number;
  defaultValue: string;
}

const SingleField: FunctionComponent<SingleFieldProps> = ({
  arrayName,
  index,
  name,
  inputLabel,
  ...props
}) => {
  const label = `${inputLabel} ${index + 1}`;
  return <TextInput {...props} name={`${arrayName}.${index}.${name}`} label={label} size="small" />;
};

export default TextInputArray;
