import { Address } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { FlexRow } from '@dap-common/ui';
import { Typography, Stack } from '@mui/material';
import { Flag, LabelValueTable } from '@shared/components';
import { CountryName } from '@shared/types';
import { formatPostalCode } from '@shared/utils';
import PinmetoPinTooltip from '../PinmetoPinTooltip';

interface Props {
  address: Address;
  hasPinmetoMapping?: boolean;
  title?: string;
}

export default function AddressBlock({ address, hasPinmetoMapping, title }: Props) {
  const t = useAppTFunction();

  return (
    <Stack spacing={2}>
      {title && (
        <FlexRow justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="h6" fontWeight={700}>
            {title}
          </Typography>
          {hasPinmetoMapping && <PinmetoPinTooltip />}
        </FlexRow>
      )}
      <LabelValueTable
        labelWidth={120}
        items={[
          {
            label: t('location.detail.address.street'),
            value: address?.street,
          },
          {
            label: t('location.detail.address.postalCode'),
            value: formatPostalCode(address?.postalCode, address?.country),
          },
          {
            label: t('location.detail.address.city'),
            value: address?.city,
          },
          {
            label: t('location.detail.address.country'),
            value: address.country ? (
              <FlexRow gap={2}>
                {CountryName[address.country]}
                <Flag size="24px" country={address.country} />
              </FlexRow>
            ) : null,
          },
        ]}
      />
    </Stack>
  );
}
