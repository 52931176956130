import { useBrandTFunction } from '@dap-admin/i18n';
import { LocationEnabledSchema } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { ContainedButton, Dialog } from '@shared/components';

interface Props {
  open: boolean;
  cancelEdit: () => void;
  name: string;
  enabled: boolean;
  brandKey: string;
  updateLocation: (formData: LocationEnabledSchema) => void;
  isUpdatingLocation: boolean;
}

const DisableEnableLocation = ({
  open,
  cancelEdit,
  name,
  enabled,
  brandKey,
  updateLocation,
  isUpdatingLocation,
}: Props) => {
  const tApp = useAppTFunction();
  const tBrand = useBrandTFunction(brandKey);

  const onSubmit = () => {
    const enabledLocation = { enabled: !enabled };
    updateLocation(enabledLocation);
    cancelEdit();
  };

  const dialogTitle = enabled
    ? tApp('location.actionMenu.deactivate', {
        location: tBrand('brand.location.name.singular'),
      })
    : tApp('location.actionMenu.activate', {
        location: tBrand('brand.location.name.singular'),
      });

  const dialogContent = enabled ? (
    <Typography>{`${tApp('location.deactivate.info', {
      theLocation: tBrand('brand.location.name.definite'),
    })} ${name}?`}</Typography>
  ) : (
    <Typography>{`${tApp('pagesHomeLocation.activate.info')} ${name}?`}</Typography>
  );

  return (
    <Dialog open={open} onClose={cancelEdit}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <Button variant="text" size="large" color="grey" onClick={cancelEdit} disableElevation>
          {tApp('common.cancel')}
        </Button>
        <ContainedButton
          color={enabled ? 'warning' : 'primary'}
          loading={isUpdatingLocation}
          onClick={onSubmit}
        >
          {enabled
            ? `${tApp('location.deactivate.confirm')}`
            : `${tApp('pagesHomeLocation.activate.confirm')}`}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
};

export default DisableEnableLocation;
