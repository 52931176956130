import { useAppTFunction } from '@dap-common/i18n';
import {
  AccountCircleOutlined,
  DoDisturbAltOutlined,
  EditOutlined,
  NoAccountsOutlined,
  VisibilityOutlined,
  SyncRounded,
} from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import { Paper } from '@shared/components';
import { memo } from 'react';

interface Props {
  isSuperuser: boolean;
  isBrandAdmin: boolean;
  isMgEmployee: boolean;
  isDeactivated: boolean;
  editEmployeeDetails: () => void;
  employeeLogDetails: () => void;
  extendedUserDataDetails: () => void;
  refreshUserData: () => void;
  enableDisableEmployee: () => void;
  removeEmployeeFromBrand: () => void;
  deleteEmployee: () => void;
}

function EmployeeActionMenu({
  isSuperuser,
  isBrandAdmin,
  isMgEmployee,
  isDeactivated,
  editEmployeeDetails,
  employeeLogDetails,
  extendedUserDataDetails,
  refreshUserData,
  enableDisableEmployee,
  removeEmployeeFromBrand,
  deleteEmployee,
}: Props) {
  const t = useAppTFunction();

  return (
    <Paper sx={{ height: '100%' }}>
      <MenuList dense disablePadding>
        <MenuItem disabled={isMgEmployee} onClick={editEmployeeDetails}>
          <ListItemIcon>
            <EditOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('employee.actionMenu.editDetails')}</ListItemText>
        </MenuItem>

        {isSuperuser && (
          <div>
            <MenuItem onClick={employeeLogDetails}>
              <ListItemIcon>
                <VisibilityOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('employee.actionMenu.viewLog')}</ListItemText>
            </MenuItem>

            <MenuItem onClick={extendedUserDataDetails}>
              <ListItemIcon>
                <VisibilityOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('employee.actionMenu.viewUserData')}</ListItemText>
            </MenuItem>

            <MenuItem onClick={refreshUserData}>
              <ListItemIcon>
                <SyncRounded fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('employee.updateUserData.title')}</ListItemText>
            </MenuItem>

            <MenuItem onClick={enableDisableEmployee}>
              <ListItemIcon>
                {isDeactivated ? (
                  <AccountCircleOutlined fontSize="small" />
                ) : (
                  <NoAccountsOutlined
                    fontSize="small"
                    color="warning"
                    sx={{ transform: 'scaleX(-1)' }}
                  />
                )}
              </ListItemIcon>
              <ListItemText>
                {isDeactivated
                  ? t('employee.actionMenu.activate')
                  : t('employee.actionMenu.deactivate')}
              </ListItemText>
            </MenuItem>

            <MenuItem onClick={deleteEmployee}>
              <ListItemIcon>
                <DoDisturbAltOutlined fontSize="small" color="warning" />
              </ListItemIcon>
              <ListItemText>{t('employee.actionMenu.deleteUser')}</ListItemText>
            </MenuItem>
          </div>
        )}

        {(isBrandAdmin || isSuperuser) && (
          <MenuItem onClick={removeEmployeeFromBrand}>
            <ListItemIcon>
              <DoDisturbAltOutlined fontSize="small" color="warning" />
            </ListItemIcon>
            <ListItemText>
              {t('employee.actionMenu.removeConnectionFrom', {
                entity: t('brandadmin.brand.title.definite'),
              })}
            </ListItemText>
          </MenuItem>
        )}
      </MenuList>
    </Paper>
  );
}

export default memo(EmployeeActionMenu);
