import { Button, ButtonProps, Stack } from '@mui/material';
import { messages } from '@dap-common/i18n';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from './LoadingButton';

interface Props {
  save?: () => void;
  cancel: () => void;
  loading?: boolean;
  align?: 'left' | 'right';
  saveText?: string;
  cancelText?: string;
  disabled?: boolean;
  formId?: string;
  primaryButtonColor?: ButtonProps['color'];
}

const SaveOrCancelEdit: FunctionComponent<Props> = ({
  save,
  cancel,
  loading = false,
  align = 'left',
  saveText,
  cancelText,
  disabled,
  formId,
  primaryButtonColor = 'primary',
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent={align === 'left' ? 'flex-start' : 'flex-end'}
    >
      <Button
        variant="text"
        size="small"
        onClick={cancel}
        disabled={loading}
        sx={{
          '&.MuiButton-root': {
            typography: 'body3',
            color: ({ palette }) => palette.common.black,
          },
        }}
      >
        {cancelText || t(messages.common.cancel)}
      </Button>
      <LoadingButton
        size="small"
        onClick={save}
        loading={loading}
        disabled={disabled || loading}
        form={formId}
        type="submit"
        color={primaryButtonColor}
      >
        {saveText || t(messages.common.save)}
      </LoadingButton>
    </Stack>
  );
};

export default SaveOrCancelEdit;
