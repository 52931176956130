import { Typography } from '@mui/material';
import { PortableText, PortableTextReactComponents } from '@portabletext/react';
import { useMemo } from 'react';
import EmbeddedYoutubeVideo from './EmbeddedYoutubeVideo';
import InlineExternalLink from './InlineExternalLink';
import InlineInternalLink from './InlineInternalLink';
import SanityImage from './SanityImage';
import SanityTable from './SanityTable';
import { SanityBlockContent } from '@dap-sanity/types';
import { getHrefValueForLink } from '@dap-sanity/utils';

interface Props {
  body: SanityBlockContent[];
}

const portableComponents: Partial<PortableTextReactComponents> = {
  marks: {
    link: ({ children, value }) => {
      const { href } = value;
      if (!href) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{children}</>;
      }
      if (href.startsWith('/')) {
        return <InlineInternalLink to={href}>{children}</InlineInternalLink>;
      }
      return <InlineExternalLink href={getHrefValueForLink(href)}>{children}</InlineExternalLink>;
    },
  },
  types: {
    image: ({ value }) => <SanityImage imgSrc={value} />,
    table: ({ value }) => <SanityTable {...value} />,
    videoSrc: ({ value }) => <EmbeddedYoutubeVideo url={value.url} />,
    span: ({ value }) => <span>{value.text}</span>,
  },
  block: {
    h2: ({ children }) => <Typography variant="h2">{children}</Typography>,
  },
};

function SanityBody({ body }: Props): JSX.Element {
  const bodyWithoutEmptyParagraphs = useMemo(
    () =>
      body &&
      body.filter(
        ({ children, _type }) =>
          _type !== 'block' || !!children?.some(({ text, _type }) => _type !== 'span' || !!text)
      ),
    [body]
  );

  return (
    <Typography variant="body2" component="section">
      <PortableText value={bodyWithoutEmptyParagraphs} components={portableComponents} />
    </Typography>
  );
}

export default SanityBody;
