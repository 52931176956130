import { useBrandTFunction, useBrandadminTFunction } from '@dap-admin/i18n';
import { DataownerSearchResult } from '@dap-admin/types';
import { getOrgNumberName } from '@dap-admin/utils';
import { ListItem, Stack, Typography, useTheme } from '@mui/material';
import { Chip, ListItemButton } from '@shared/components';
import { formatOrgNo } from '@shared/utils';
import { CSSProperties } from 'react';
import SidebarListItemPrimaryTitle from './SidebarListItemPrimaryTitle';

interface Props {
  style: CSSProperties;
  isSelected: boolean;
  onSelect: () => void;
  dataowner: DataownerSearchResult;
}

export default function DataownerListItem({ style, dataowner, onSelect, isSelected }: Props) {
  const { palette } = useTheme();
  const t = useBrandadminTFunction();
  const tBrand = useBrandTFunction(dataowner.brand.key);

  const handleSelect = () => {
    onSelect();
  };

  return (
    <ListItem disablePadding>
      <ListItemButton
        style={style}
        primaryText={
          <SidebarListItemPrimaryTitle
            title={dataowner.name}
            CTAText={dataowner.hasUnverifiedEmployees ? t('employee.warning.unverified') : ''}
          />
        }
        secondaryText={
          <Typography variant="body2" fontWeight={600} color="text.secondary">
            {getOrgNumberName(dataowner.orgNr, t)} {formatOrgNo(dataowner.orgNr)}
          </Typography>
        }
        handleClick={handleSelect}
        selected={isSelected}
      >
        <Stack direction="row" width="100%" alignItems="center">
          <Stack direction="row" spacing={1}>
            <Chip
              size="small"
              label={`${dataowner.countEnabledLocations} ${tBrand('brand.location.name.plural')}`}
              backgroundColor={palette.success[100]}
            />
            {!dataowner.enabled && (
              <Chip
                size="small"
                label={t('dataowner.deactivated')}
                backgroundColor={palette.warning.main}
              />
            )}
            {!dataowner.isMember && (
              <Chip
                size="small"
                label={t('dataowner.membership.nonMember')}
                backgroundColor={palette.secondary[100]}
              />
            )}
          </Stack>
        </Stack>
      </ListItemButton>
    </ListItem>
  );
}
