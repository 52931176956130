import { useBrandadminTFunction } from '@dap-admin/i18n';
import { EmployeeBasicV2 } from '@dap-admin/types';
import { Box } from '@mui/material';
import { EmployeeCard } from '../Cards';
import { EmployeeActionsMenuActions } from './EmployeeActionsMenu';

interface Props {
  employees: EmployeeBasicV2[];
  brandKey: string;
  menuActions: EmployeeActionsMenuActions;
  isAdmin: boolean;
  isSuperuser: boolean;
}

function EmployeeGrid({ employees, ...rest }: Props) {
  const t = useBrandadminTFunction();

  if (employees.length === 0) return <div>{t('employee.noEmployees')}</div>;

  return (
    <Box
      display="grid"
      gap={3}
      gridTemplateColumns="repeat(auto-fill, minmax(290px, 1fr))"
      sx={{ '> *': { display: 'flex', flexDirection: 'column', flexGrow: 1 } }}
    >
      {employees.map((employee) => (
        <EmployeeCard key={employee.userId} employee={employee} {...rest} />
      ))}
    </Box>
  );
}

export default EmployeeGrid;
