import { EmployeeDetail, UserData } from '@dap-admin/types';

export function hasEditUserPermission(
  me: UserData,
  employee: EmployeeDetail,
  isBrandAdmin: boolean,
  orgUnitKey: string
): boolean {
  return (
    me?.metadata.superuser ||
    isBrandAdmin ||
    me?.userId === employee.userId ||
    isAdmin(me, orgUnitKey)
  );
}

/**
 * @deprecated use isAdmin instead
 */
export function isBrandAdmin(userData: UserData, selectedBrandKey: string) {
  return !!(
    userData.metadata.superuser ||
    userData?.roles.some(({ role, orgUnitKey }) => role.isAdmin && orgUnitKey === selectedBrandKey)
  );
}

/**
 * @deprecated use isAdmin instead
 */
export function isDataownerAdmin(userData: UserData, selectedDataownerKey: string) {
  return !!userData.roles?.some(
    ({ role, orgUnitKey }) => role.isAdmin && orgUnitKey === selectedDataownerKey
  );
}

/**
 * @deprecated use isAdmin instead
 */
export function isLocationAdmin(userData: UserData, selectedLocationKey: string) {
  return !!userData.roles?.some(
    ({ role, orgUnitKey }) => role.isAdmin && orgUnitKey === selectedLocationKey
  );
}

/**
 *
 * @param userData The current userData object
 * @param orgUnitKey The key of the orgUnit
 * @returns true if the user has an admin role in the given OrgUnit
 */
export function isAdmin(userData: UserData, orgUnitKey: string): boolean {
  return (
    userData.metadata.superuser ||
    userData.roles.some((role) => role.role.isAdmin && role.orgUnitKey === orgUnitKey)
  );
}
