import { alpha, ButtonBase, ButtonProps, Stack, Typography } from '@mui/material';
import { Attachment as AttachmentIcon } from '@mui/icons-material';
import PDFObject from 'pdfobject';
import { useMemo } from 'react';
import {
  DOC_FILE_TYPE,
  DOCX_FILE_TYPE,
  PDF_FILE_TYPE,
  PPT_FILE_TYPE,
  PPTX_FILE_TYPE,
  XLSX_FILE_TYPE,
} from './fileTypes';

import { getFileExtension } from '@shared/utils';
import { Attachment } from '@dap-sanity/types';
import { DownloadIcon } from '@shared/custom-icons';
import {
  excelIconUri,
  genericFileIconUri,
  pdfIconUri,
  powerpointIconUri,
  wordIconUri,
} from '@assets/images';

interface Props extends Attachment {
  onClick: () => void;
}

export default function SanityAttachment({ label, url, onClick }: Props) {
  const fileType = useMemo(() => getFileExtension(url), [url]);
  const fileName = `${label}.${fileType}`;
  const downloadUrl = `${url}?dl=${label}.${fileType}`;

  if (fileType === PDF_FILE_TYPE && PDFObject.supportsPDFs) {
    return (
      <AttachmentButton onClick={onClick} endIcon={<FileImg fileType={fileType} />}>
        {fileName}
      </AttachmentButton>
    );
  }

  return (
    <AttachmentButton
      startIcon={<DownloadIcon />}
      endIcon={<FileImg fileType={fileType || ''} />}
      href={downloadUrl}
    >
      {fileName}
    </AttachmentButton>
  );
}

function FileImg({ fileType }: { fileType: string }) {
  const fileIcon = useMemo(() => {
    switch (fileType) {
      case PDF_FILE_TYPE:
        return pdfIconUri;
      case DOC_FILE_TYPE:
      case DOCX_FILE_TYPE:
        return wordIconUri;
      case PPT_FILE_TYPE:
      case PPTX_FILE_TYPE:
        return powerpointIconUri;
      case XLSX_FILE_TYPE:
        return excelIconUri;
      default:
        return genericFileIconUri;
    }
  }, [fileType]);
  return <img src={fileIcon} width="25px" height="25px" aria-hidden alt="" />;
}

function AttachmentButton({
  children,
  startIcon = <AttachmentIcon />,
  endIcon,
  ...props
}: ButtonProps) {
  return (
    <ButtonBase
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        px: 2,
        py: 1,
        '&:hover,:focus': {
          boxShadow: ({ palette }) => `0 0 10px ${alpha(palette.common.black, 0.1)}`,
        },
      }}
      {...props}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        mr={2}
        sx={{
          '& > svg, img': {
            width: '16px',
          },
        }}
      >
        {startIcon}
        <Typography variant="body3" component="span" textAlign="left">
          {children}
        </Typography>
      </Stack>
      {endIcon}
    </ButtonBase>
  );
}
