import { SanityConfig } from '@dap-common/types';

export const DAP_BRAND_URLS = {
  systemhus: ['ansatt.systemhus.no', 'ansatt.systemhus.mestergruppen.cloud'],
  byggtorget: ['ansatt.byggtorget.no', 'ansatt.byggtorget.mestergruppen.cloud'],
  xlbygg: ['ansatt.xl-bygg.no', 'ansatt.xlbygg.mestergruppen.cloud'],
  blinkhus: ['ansatt.blink-hus.no'],
  mesterhus: ['ansatt.mesterhus.no'],
  nordbohus: ['ansatt.nordbohus.no'],
  pretre: ['dap.pretre.no'],
  kuloer: ['medlem.kulornorge.no'],
  bolist: ['anstalld.bolist.se'],
  saltdalshytta: ['dap.saltdalshytta.no'],
  fargerike: ['ansatt.fargerike.no'],
};

const commonSanityConfig: Omit<
  SanityConfig,
  'viewPreviewContent' | 'dataset' | 'projectId' | 'useCdn'
> = {
  apiVersion: '2021-10-21',
};

export const DAP_SANITY_CONFIG: SanityConfig = {
  ...commonSanityConfig,
  projectId: process.env['NX_DAP_SANITY_PROJECT_ID'] ?? 'missing-env-setting',
  // If NX_DAP_SANITY_USE_CDN is a string and either 'true' or 'false', use that value. Otherwise, default to true
  useCdn:
    typeof process.env['NX_DAP_SANITY_USE_CDN'] === 'string'
      ? process.env['NX_DAP_SANITY_USE_CDN'].toLowerCase() === 'true'
      : true,
  viewPreviewContent: false,
  dataset: process.env['NX_DAP_SANITY_DATASET'] ?? 'missing-env-setting',
};
