import { CustomError, QueryError } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { Buttons, InputSelect } from '@dap-common/ui';
import { isCustomError } from '@dap-common/utils';
import {
  Alert,
  AlertTitle,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { ContainedButton, Dialog, TagHeading } from '@shared/components';
import { useState } from 'react';

interface Props {
  options: { value: string; name: string }[];
  isLoading: boolean;
  onClose: () => void;
  onDelete: (val: string) => void;
  error: CustomError | QueryError | SerializedError | undefined;
}

function DeletePropertyWithSelectDialog({ options, onClose, isLoading, onDelete, error }: Props) {
  const t = useAppTFunction();
  const [selectedPropertyKey, setSelectedPropertyKey] = useState('');

  const handleSelect = (event: any) => {
    setSelectedPropertyKey(event.target.value);
  };

  return (
    <Dialog maxWidth="xs" fullWidth onClose={onClose} open>
      <DialogTitle>
        <TagHeading color="warning">
          {`${t('common.delete')} ${t('brandadmin.properties.title').toLowerCase()}`}
        </TagHeading>
      </DialogTitle>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <AlertTitle>{t('brandadmin.properties.delete.error')}</AlertTitle>
          {isCustomError(error) && `${error.status} ${error.data.message}`}
        </Alert>
      )}

      <DialogContent>
        <InputSelect
          margin="normal"
          name="propertySelection"
          fullWidth
          onChange={handleSelect}
          menuItems={options}
          placeholder={''}
        />
        <Typography paddingTop={2}>
          {t('brandadmin.properties.delete.description', {
            property: selectedPropertyKey,
          })}
        </Typography>
        <Typography fontWeight={600} paddingTop={2}>
          {t('brandadmin.properties.delete.warning')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Buttons>
          <Button variant="text" color="grey" onClick={onClose} size="small">
            {t('common.cancel')}
          </Button>
          <ContainedButton
            color="warning"
            size="small"
            loading={isLoading}
            onClick={() => onDelete(selectedPropertyKey)}
            disabled={selectedPropertyKey === ''}
          >
            {t('common.delete')}
          </ContainedButton>
        </Buttons>
      </DialogActions>
    </Dialog>
  );
}

export default DeletePropertyWithSelectDialog;
