import * as yup from 'yup';

export const ecomConfigSchema = yup.object().shape({
  selectedEcomAssortments: yup.array(yup.string()).default([]),
  show: yup.boolean(),
});

export interface EcomConfigSchema {
  selectedEcomAssortments: string[];
  show: boolean;
}
