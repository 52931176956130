import { IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { messages } from '@dap-common/i18n';
import { Cached } from '@mui/icons-material';
import { LoadingErrorComponent } from '../Wrappers';

const ErrorDefaultComponent: LoadingErrorComponent<any> = ({ reload, error }) => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" spacing={2}>
      <Typography variant="h1" component="h3" sx={{ color: ({ palette }) => palette.warning.main }}>
        {t(messages.common.error.oops)}
      </Typography>
      <Typography variant="body1">{t(messages.common.error.fetch)}</Typography>
      {reload && (
        <>
          <IconButton onClick={reload}>
            <Cached sx={{ fontSize: '62px', transform: 'scaleX(-1) rotate(45deg)' }} />
          </IconButton>
          <Typography variant="body1">{t(messages.common.error.tryReloading)}</Typography>
        </>
      )}
    </Stack>
  );
};

export default ErrorDefaultComponent;
