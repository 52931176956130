import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { addUserPicture, useAppDispatch } from '@dap-common/data-access';
import { AuthContextProps } from '@dap-common/types';
import { LocalStorage } from '@shared/constants';
import { removeToken, storeToken } from '@shared/state/auth';
import { hasExpired } from '@shared/utils';
import { createContext, useCallback, useContext, useEffect, useState, ReactNode } from 'react';

const AuthContext = createContext<AuthContextProps | null>(null);

function AuthContextContainer({ children }: { children: ReactNode }) {
  const {
    isLoading: auth0IsLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
    getIdTokenClaims,
    error: auth0Error,
  } = useAuth0();
  const [token, setToken] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [initialPath] = useState(window.location.pathname);

  const dispatch = useAppDispatch();

  const refreshToken = useCallback(() => {
    const fetchToken = async () => {
      return await getAccessTokenSilently()
        .then(setToken)
        .catch((error) => {
          console.error(error);
          loginWithRedirect();
        });
    };

    fetchToken();
  }, [getAccessTokenSilently, setToken, loginWithRedirect]);

  useEffect(() => {
    const checkEveryHour = 60 * 60;
    const checkToken = () => {
      if (isAuthenticated && token) {
        if (hasExpired(token, checkEveryHour)) {
          refreshToken();
        }
      }
    };
    const interval = setInterval(checkToken, checkEveryHour * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [token, refreshToken, isAuthenticated]);

  useEffect(() => {
    // @ts-ignore
    if (window.Cypress) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const auth0 = JSON.parse(localStorage.getItem(LocalStorage.AUTH0_CYPRESS)!);
      dispatch(storeToken(auth0.body.access_token));
      setToken(auth0.body.access_token);
    } else {
      const initAuth = async () => {
        if (isAuthenticated) {
          try {
            const token = await getAccessTokenSilently();
            const idToken = await getIdTokenClaims();
            dispatch(storeToken(token));
            setToken(token);
            dispatch(addUserPicture(idToken?.picture || ''));
          } catch (error) {
            console.error(error);
            loginWithRedirect();
          }
        }
        setIsLoading(false);
      };

      if (auth0IsLoading) {
        return;
      }

      initAuth();
    }
  }, [
    isAuthenticated,
    auth0IsLoading,
    dispatch,
    getAccessTokenSilently,
    getIdTokenClaims,
    loginWithRedirect,
  ]);

  const login = useCallback(
    (options?: RedirectLoginOptions) =>
      loginWithRedirect({
        appState: { returnTo: initialPath },
        ...options,
      }),
    [initialPath, loginWithRedirect]
  );

  const logoutAndRemoveToken = useCallback(() => {
    dispatch(removeToken());
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [dispatch, logout]);

  return (
    <AuthContext.Provider
      value={{
        logout: logoutAndRemoveToken,
        login,
        token,
        isAuthenticated,
        error: auth0Error,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error(`useAuthContext must be used within a AuthContextProvider`);
  }
  return context;
};

export { useAuthContext, AuthContextContainer, AuthContext };
