import { BrandBasic } from '@dap-admin/types';
import { alpha, Box, Stack, Toolbar, Typography } from '@mui/material';
import { messages } from '@dap-common/i18n';
import { useTranslation } from 'react-i18next';
import SidebarLogo from './SidebarLogo';
import { RecognizedBrands } from '@dap-common/types';
import { brandStylesConfig } from '@dap-common/styles';
import { BrandSelectorMenu } from '@dap-common/ui';

interface SidebarHeaderProps {
  openSidebar: boolean;
  brandKey: RecognizedBrands | undefined;
  availableBrands: BrandBasic[];
  selectBrand: (brandKey: string) => void;
}

function SidebarHeader({
  openSidebar,
  brandKey,
  availableBrands,
  selectBrand,
}: SidebarHeaderProps) {
  const { t } = useTranslation();
  const brandStyle = (brandKey && brandStylesConfig[brandKey]) || brandStylesConfig.default;
  const brandColors = brandStyle.colors;
  const mainBrandColor = brandColors?.main;
  const lightBrandColor = brandColors?.light;

  return (
    <Toolbar disableGutters sx={{ padding: '20px 15px 15px' }}>
      <Stack
        direction="row"
        flexGrow={1}
        justifyContent="space-between"
        alignItems="center"
        bgcolor={({ palette }) =>
          lightBrandColor || mainBrandColor || alpha(palette.primary.light, 0.1)
        }
        borderRadius="7px"
        height="48px"
        p="15px"
        spacing={1}
      >
        <SidebarLogo brandKey={brandKey} />
        <Typography
          fontSize={12}
          fontWeight="500"
          width={'100%'}
          noWrap
          color={({ palette }) => brandColors?.contrastText || palette.text.primary}
          sx={{ ...(!openSidebar && { display: 'none' }) }}
        >
          {t(messages.common.digitalArbeidsplass)}
        </Typography>
        {brandKey && availableBrands.length > 1 && (
          <Box
            sx={{
              ...(!openSidebar && { display: 'none' }),
              color: ({ palette }) => brandColors?.contrastText || palette.text.primary,
            }}
          >
            <BrandSelectorMenu
              availableBrands={availableBrands}
              selectBrand={selectBrand}
              brandKey={brandKey}
            />
          </Box>
        )}
      </Stack>
    </Toolbar>
  );
}

export default SidebarHeader;
