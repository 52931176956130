import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthState } from '@shared/state/auth';
import { DATAHUB_FIND_EVENTS, datahubBaseUrl } from './datahubPaths';
import { FindEventsResponse, FindEventsArgs } from '@dap-datahub/types';
import { mapFindEventRequestToDTO, mapFindEventResponseFromDTO } from './mappers';
import { BrandV2, Dataowner, EmployeeDetail, Location } from '@dap-admin/types';

const datahubBaseQuery = fetchBaseQuery({
  baseUrl: datahubBaseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as { auth: AuthState }).auth.token;

    if (!token) {
      throw new Error('No access token');
    }
    headers.set('authorization', `Bearer ${token}`);
    return headers;
  },
});

export const datahubApi = createApi({
  reducerPath: 'datahubApi',
  baseQuery: datahubBaseQuery,
  endpoints: (build) => ({
    findEventsLocation: build.query<FindEventsResponse<Location>, FindEventsArgs>({
      query: (payload) => ({
        url: DATAHUB_FIND_EVENTS,
        method: 'POST',
        body: mapFindEventRequestToDTO(payload),
      }),
      transformResponse: mapFindEventResponseFromDTO,
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        return {
          endpointName: endpointName,
          orgUnitKey: queryArgs.orgUnitKey,
          entity: queryArgs.entity,
        };
      },
      merge: (currentData, { events, scrollId }) => {
        currentData.events.push(...events);
        currentData.scrollId = scrollId;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return !!currentArg?.scrollId && currentArg !== previousArg;
      },
    }),
    findEventsDataowner: build.query<FindEventsResponse<Dataowner>, FindEventsArgs>({
      query: (payload) => ({
        url: DATAHUB_FIND_EVENTS,
        method: 'POST',
        body: mapFindEventRequestToDTO(payload),
      }),
      transformResponse: mapFindEventResponseFromDTO,
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        return {
          endpointName: endpointName,
          orgUnitKey: queryArgs.orgUnitKey,
          entity: queryArgs.entity,
        };
      },
      merge: (currentData, { events, scrollId }) => {
        currentData.events.push(...events);
        currentData.scrollId = scrollId;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return !!currentArg?.scrollId && currentArg !== previousArg;
      },
    }),
    findEventsBrand: build.query<FindEventsResponse<BrandV2>, FindEventsArgs>({
      query: (payload) => ({
        url: DATAHUB_FIND_EVENTS,
        method: 'POST',
        body: mapFindEventRequestToDTO(payload),
      }),
      transformResponse: mapFindEventResponseFromDTO,
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        return {
          endpointName: endpointName,
          orgUnitKey: queryArgs.orgUnitKey,
          entity: queryArgs.entity,
        };
      },
      merge: (currentData, { events, scrollId }) => {
        currentData.events.push(...events);
        currentData.scrollId = scrollId;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return !!currentArg?.scrollId && currentArg !== previousArg;
      },
    }),
    findEventsEmployee: build.query<FindEventsResponse<EmployeeDetail>, FindEventsArgs>({
      query: (payload) => ({
        url: DATAHUB_FIND_EVENTS,
        method: 'POST',
        body: mapFindEventRequestToDTO(payload),
      }),
      transformResponse: mapFindEventResponseFromDTO,
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        return {
          endpointName: endpointName,
          orgUnitKey: queryArgs.orgUnitKey,
          entity: queryArgs.entity,
        };
      },
      merge: (currentData, { events, scrollId }) => {
        currentData.events.push(...events);
        currentData.scrollId = scrollId;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return !!currentArg?.scrollId && currentArg !== previousArg;
      },
    }),
  }),
});

export const {
  useFindEventsLocationQuery,
  useFindEventsDataownerQuery,
  useFindEventsBrandQuery,
  useFindEventsEmployeeQuery,
} = datahubApi;
