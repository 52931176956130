import { Box, BoxProps } from '@mui/material';
import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  id: string;
  labelledById: string;
  show: boolean;
  boxProps?: BoxProps;
}

const TabPanel = ({ children, id, labelledById, show, boxProps }: TabPanelProps) => {
  if (!show) {
    return null;
  }

  return (
    <Box
      role="tabpanel"
      id={id}
      key={id}
      aria-labelledby={labelledById}
      display="flex"
      flexDirection="column"
      minHeight={0} //Firefox special to enable scroll
      padding={0}
      {...boxProps}
    >
      {show && children}
    </Box>
  );
};

export default TabPanel;
