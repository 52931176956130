import { matrixUri } from '@assets/images';
import { useBrandTFunction, useBrandadminTFunction } from '@dap-admin/i18n';
import { routes } from '@dap-common/consts';
import { Grid, Typography } from '@mui/material';
import { ContainedButton, Paper, TagHeading } from '@shared/components';
import { useNavigate } from 'react-router-dom';

interface Props {
  brandKey: string;
}

export default function OrganizationViewBlock({ brandKey }: Props) {
  const t = useBrandadminTFunction();
  const tBrand = useBrandTFunction(brandKey);
  const navigate = useNavigate();

  return (
    <Paper
      sx={{
        backgroundImage: `url('${matrixUri}')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: '100%',
        backgroundPositionY: '100%',
        border: '1px solid #fff',
      }}
    >
      <TagHeading marginBottom={2}>
        <Typography variant="h5">{t('brandadmin.orgView.title')}</Typography>
      </TagHeading>
      <Grid container spacing={2} justifyContent={{ xs: 'center', md: 'flex-start' }} wrap="wrap">
        <Grid
          item
          flexDirection={'column'}
          textAlign={{ xs: 'center', md: 'left' }}
          paddingBottom={{ xs: 0 }}
          display={'flex'}
          justifyContent={{ xs: 'center', md: 'flex-start' }}
        >
          <Typography
            component="p"
            variant="h6"
            fontWeight={500}
            color="text.secondary"
            maxWidth={{ xs: '100%', md: '365px' }}
          >
            {t('brandadmin.orgView.description', {
              locations: tBrand('brand.location.name.plural'),
            })}
          </Typography>
        </Grid>
        <Grid
          item
          width={'100%'}
          display={'flex'}
          justifyContent={{ xs: 'center', md: 'flex-start' }}
        >
          <ContainedButton onClick={() => navigate(routes.organizationView)} size="large">
            {t('brandadmin.orgView.cta')}
          </ContainedButton>
        </Grid>
      </Grid>
    </Paper>
  );
}
