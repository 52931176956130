import { Stack, Typography } from '@mui/material';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import PreviewSanityImage from '../previewContent/PreviewSanityImage';
import { PreviewCard } from '@dap-common/ui';
import { maxLinesSx } from '@shared/utils';

interface Props {
  title: string;
  intro: string;
  image: SanityImageSource;
  imgHeight: number;
  toUrl: string;
}

export default function ServicePreviewCard({
  title,
  intro,
  image,
  imgHeight,
  toUrl,
}: Props): JSX.Element {
  return (
    <PreviewCard to={toUrl}>
      <Stack spacing={1} mb={2}>
        <PreviewSanityImage height={imgHeight} image={image} />
        <Typography variant="h4" sx={max2LinesTextSx}>
          {title}
        </Typography>
        <Typography variant="body3" sx={max3LinesTextSx}>
          {intro}
        </Typography>
      </Stack>
    </PreviewCard>
  );
}

const max2LinesTextSx = maxLinesSx(2);
const max3LinesTextSx = maxLinesSx(3);
