import { brandadminI18nResources, brandI18nResources } from '@dap-admin/i18n';
import addEmployeePageEn from './en/addEmployeePage.json';
import brandAdminEn from './en/brandadmin.json';
import brandDetailsPageEn from './en/brandDetailsPage.json';
import blinkhusEn from './en/brands/blinkhus.json';
import bolistEn from './en/brands/bolist.json';
import defaultEn from './en/brands/default.json';
import fargerikeEn from './en/brands/fargerike.json';
import kuloerEn from './en/brands/kuloer.json';
import mestergruppenEn from './en/brands/mestergruppen.json';
import mesterhusEn from './en/brands/mesterhus.json';
import nordbohusEn from './en/brands/nordbohus.json';
import rotEn from './en/brands/rot.json';
import saltdalshyttaEn from './en/brands/saltdalshytta.json';
import systemhusEn from './en/brands/systemhus.json';
import xlbyggEn from './en/brands/xlbygg.json';
import commonEn from './en/common.json';
import createPageEn from './en/createPage.json';
import employeePageEn from './en/employeePage.json';
import eventsEn from './en/events.json';
import fremdriftEn from './en/fremdrift.json';
import helpCentreEn from './en/helpCentre.json';
import intranetBannerEn from './en/intranetBanner.json';
import mgaEn from './en/mga.json';
import mosaicEn from './en/mosaic.json';
import newsArticlesEn from './en/newsArticles.json';
import orderEn from './en/order.json';
import pagesHomeDataownerEn from './en/pagesHomeDataowner.json';
import pagesHomeEmployeesEn from './en/pagesHomeEmployees.json';
import pagesHomeLocationEn from './en/pagesHomeLocation.json';
import proffEn from './en/proff.json';
import searchPageEn from './en/searchPage.json';
import servicesEn from './en/services.json';
import validationEn from './en/validation.json';
import workplaceEn from './en/workplace.json';
import addEmployeePageNo from './no/addEmployeePage.json';
import brandAdminNo from './no/brandadmin.json';
import brandDetailsPageNo from './no/brandDetailsPage.json';
import blinkhusNo from './no/brands/blinkhus.json';
import bolistNo from './no/brands/bolist.json';
import defaultNo from './no/brands/default.json';
import fargerikeNo from './no/brands/fargerike.json';
import kuloerNo from './no/brands/kuloer.json';
import mestergruppenNo from './no/brands/mestergruppen.json';
import mesterhusNo from './no/brands/mesterhus.json';
import nordbohusNo from './no/brands/nordbohus.json';
import rotNo from './no/brands/rot.json';
import saltdalshyttaNo from './no/brands/saltdalshytta.json';
import systemhusNo from './no/brands/systemhus.json';
import xlbyggNo from './no/brands/xlbygg.json';
import commonNo from './no/common.json';
import createPageNo from './no/createPage.json';
import employeePageNo from './no/employeePage.json';
import eventsNo from './no/events.json';
import fremdriftNo from './no/fremdrift.json';
import helpCentreNo from './no/helpCentre.json';
import intranetBannerNo from './no/intranetBanner.json';
import mgaNo from './no/mga.json';
import mosaicNo from './no/mosaic.json';
import newsArticlesNo from './no/newsArticles.json';
import orderNo from './no/order.json';
import pagesHomeDataownerNo from './no/pagesHomeDataowner.json';
import pagesHomeEmployeesNo from './no/pagesHomeEmployees.json';
import pagesHomeLocationNo from './no/pagesHomeLocation.json';
import proffNo from './no/proff.json';
import searchPageNo from './no/searchPage.json';
import servicesNo from './no/services.json';
import validationNo from './no/validation.json';
import workplaceNo from './no/workplace.json';
import addEmployeePageSv from './sv/addEmployeePage.json';
import brandAdminSv from './sv/brandadmin.json';
import brandDetailsPageSv from './sv/brandDetailsPage.json';
import blinkhusSv from './sv/brands/blinkhus.json';
import bolistSv from './sv/brands/bolist.json';
import defaultSv from './sv/brands/default.json';
import fargerikeSv from './sv/brands/fargerike.json';
import kuloerSv from './sv/brands/kuloer.json';
import mestergruppenSv from './sv/brands/mestergruppen.json';
import mesterhusSv from './sv/brands/mesterhus.json';
import nordbohusSv from './sv/brands/nordbohus.json';
import rotSv from './sv/brands/rot.json';
import saltdalshyttaSv from './sv/brands/saltdalshytta.json';
import systemhusSv from './sv/brands/systemhus.json';
import xlbyggSv from './sv/brands/xlbygg.json';
import commonSv from './sv/common.json';
import createPageSv from './sv/createPage.json';
import employeePageSv from './sv/employeePage.json';
import eventsSv from './sv/events.json';
import fremdriftSv from './sv/fremdrift.json';
import helpCentreSv from './sv/helpCentre.json';
import intranetBannerSv from './sv/intranetBanner.json';
import mgaSv from './sv/mga.json';
import mosaicSv from './sv/mosaic.json';
import newsArticlesSv from './sv/newsArticles.json';
import orderSv from './sv/order.json';
import pagesHomeDataownerSv from './sv/pagesHomeDataowner.json';
import pagesHomeEmployeesSv from './sv/pagesHomeEmployees.json';
import pagesHomeLocationSv from './sv/pagesHomeLocation.json';
import proffSv from './sv/proff.json';
import searchPageSv from './sv/searchPage.json';
import servicesSv from './sv/services.json';
import validationSv from './sv/validation.json';
import workplaceSv from './sv/workplace.json';

export const defaultResources = {
  common: commonNo,
  brandAdmin: brandAdminNo,
  events: eventsNo,
  pagesHomeLocation: pagesHomeLocationNo,
  pagesHomeDataowner: pagesHomeDataownerNo,
  pagesHomeEmployees: pagesHomeEmployeesNo,
  addEmployeePage: addEmployeePageNo,
  createPage: createPageNo,
  brandDetailsPage: brandDetailsPageNo,
  employeePage: employeePageNo,
  searchPage: searchPageNo,
  validation: validationNo,
  newsArticles: newsArticlesNo,
  services: servicesNo,
  xlbygg: { ...xlbyggNo, ...brandI18nResources.no.xlbygg },
  systemhus: { ...systemhusNo, ...brandI18nResources.no.systemhus },
  nordbohus: { ...nordbohusNo, ...brandI18nResources.no.nordbohus },
  mesterhus: { ...mesterhusNo, ...brandI18nResources.no.mesterhus },
  fargerike: fargerikeNo,
  blinkhus: { ...blinkhusNo, ...brandI18nResources.no.blinkhus },
  saltdalshytta: saltdalshyttaNo,
  rot: { ...rotNo, ...brandI18nResources.no.rot },
  mestergruppen: { ...mestergruppenNo, ...brandI18nResources.no.mestergruppen },
  bolist: { ...bolistNo, ...brandI18nResources.no.bolist },
  kuloer: { ...kuloerNo, ...brandI18nResources.no.kuloer },
  workplace: workplaceNo,
  proff: proffNo,
  fremdrift: fremdriftNo,
  helpCentre: helpCentreNo,
  mosaic: mosaicNo,
  order: orderNo,
  brand: {
    ...defaultNo,
    ...brandI18nResources.no.brand,
  },
  default: defaultNo,
  intranetBanner: intranetBannerNo,
  mga: mgaNo,
  ...brandadminI18nResources.no,
};

export type AppResources = typeof defaultResources;

export type AppLanguageResources = {
  no: AppResources;
  sv: AppResources;
  en: AppResources;
};

/**
 * 'Resources' typing makes sure no language files have missing translations, where Norwegian is default
 * 'I18nResource' is present to please TS in init function at the bottom
 **/
export const appLanguageResources: AppLanguageResources = {
  no: defaultResources,
  en: {
    common: commonEn,
    brandAdmin: brandAdminEn,
    events: eventsEn,
    pagesHomeLocation: pagesHomeLocationEn,
    pagesHomeDataowner: pagesHomeDataownerEn,
    pagesHomeEmployees: pagesHomeEmployeesEn,
    addEmployeePage: addEmployeePageEn,
    createPage: createPageEn,
    brandDetailsPage: brandDetailsPageEn,
    employeePage: employeePageEn,
    searchPage: searchPageEn,
    validation: validationEn,
    newsArticles: newsArticlesEn,
    services: servicesEn,
    xlbygg: { ...xlbyggEn, ...brandI18nResources.en.xlbygg },
    systemhus: { ...systemhusEn, ...brandI18nResources.en.systemhus },
    nordbohus: { ...nordbohusEn, ...brandI18nResources.en.nordbohus },
    mesterhus: { ...mesterhusEn, ...brandI18nResources.en.mesterhus },
    fargerike: fargerikeEn,
    blinkhus: { ...blinkhusEn, ...brandI18nResources.en.blinkhus },
    saltdalshytta: saltdalshyttaEn,
    rot: { ...rotEn, ...brandI18nResources.en.rot },
    mestergruppen: { ...mestergruppenEn, ...brandI18nResources.en.mestergruppen },
    bolist: { ...bolistEn, ...brandI18nResources.en.bolist },
    kuloer: { ...kuloerEn, ...brandI18nResources.en.kuloer },
    workplace: workplaceEn,
    proff: proffEn,
    fremdrift: fremdriftEn,
    helpCentre: helpCentreEn,
    mosaic: mosaicEn,
    order: orderEn,
    brand: { ...defaultEn, ...brandI18nResources.en.brand },
    default: defaultEn,
    intranetBanner: intranetBannerEn,
    mga: mgaEn,
    ...brandadminI18nResources.en,
  },
  sv: {
    common: commonSv,
    brandAdmin: brandAdminSv,
    events: eventsSv,
    pagesHomeLocation: pagesHomeLocationSv,
    pagesHomeDataowner: pagesHomeDataownerSv,
    pagesHomeEmployees: pagesHomeEmployeesSv,
    addEmployeePage: addEmployeePageSv,
    createPage: createPageSv,
    brandDetailsPage: brandDetailsPageSv,
    employeePage: employeePageSv,
    searchPage: searchPageSv,
    validation: validationSv,
    newsArticles: newsArticlesSv,
    services: servicesSv,
    xlbygg: { ...xlbyggSv, ...brandI18nResources.sv.xlbygg },
    systemhus: { ...systemhusSv, ...brandI18nResources.sv.systemhus },
    nordbohus: { ...nordbohusSv, ...brandI18nResources.sv.nordbohus },
    mesterhus: { ...mesterhusSv, ...brandI18nResources.sv.mesterhus },
    fargerike: fargerikeSv,
    blinkhus: { ...blinkhusSv, ...brandI18nResources.sv.blinkhus },
    saltdalshytta: saltdalshyttaSv,
    rot: { ...rotSv, ...brandI18nResources.sv.rot },
    mestergruppen: { ...mestergruppenSv, ...brandI18nResources.sv.mestergruppen },
    bolist: { ...bolistSv, ...brandI18nResources.sv.bolist },
    kuloer: { ...kuloerSv, ...brandI18nResources.sv.kuloer },
    workplace: workplaceSv,
    proff: proffSv,
    fremdrift: fremdriftSv,
    helpCentre: helpCentreSv,
    mosaic: mosaicSv,
    order: orderSv,
    brand: { ...defaultSv, ...brandI18nResources.sv.brand },
    default: defaultSv,
    intranetBanner: intranetBannerSv,
    mga: mgaSv,
    ...brandadminI18nResources.sv,
  },
};

export const brandResources = {
  xlbygg: { ...xlbyggNo, ...brandI18nResources.no.xlbygg },
  systemhus: { ...systemhusNo, ...brandI18nResources.no.systemhus },
  nordbohus: { ...nordbohusNo, ...brandI18nResources.no.nordbohus },
  mesterhus: { ...mesterhusNo, ...brandI18nResources.no.mesterhus },
  blinkhus: { ...blinkhusNo, ...brandI18nResources.no.blinkhus },
  saltdalshytta: { ...systemhusNo, ...brandI18nResources.no.systemhus },
  rot: { ...rotNo, ...brandI18nResources.no.rot },
  mestergruppen: { ...mestergruppenNo, ...brandI18nResources.no.mestergruppen },
  bolist: { ...bolistNo, ...brandI18nResources.no.bolist },
  default: { ...defaultNo, ...brandI18nResources.no.brand },
  kuloer: { ...kuloerNo, ...brandI18nResources.no.kuloer },
  fargerike: fargerikeNo,
};

export type NonBrandNamespaces =
  | 'common'
  | 'brandadmin'
  | 'pagesHomeLocation'
  | 'pagesHomeDataowner'
  | 'pagesHomeEmployees'
  | 'addEmployeePage'
  | 'createPage'
  | 'brandDetailsPage'
  | 'employeePage'
  | 'searchPage'
  | 'validation'
  | 'newsArticles'
  | 'services'
  | 'mosaic';
