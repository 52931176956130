import { alpha, Box, Button, Card, Link, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import PreviewSanityImage from '../previewContent/PreviewSanityImage';
import { eventCardImageHeight } from './eventConstants';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { Link as RouterLink } from 'react-router-dom';
import { PropsWithChildren } from 'react';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { capitalizeFirstLetter, maxLinesSx } from '@shared/utils';
import { messages } from '@dap-common/i18n';
import { OverflowingText } from '@shared/components';

interface EventCardProps {
  title: string;
  intro: string;
  date: string;
  registrationUrl?: string;
  registrationEmail?: string;
  time?: string;
  place?: string;
  href?: string;
  image?: SanityImageSource;
}

export default function EventCard({
  image,
  title,
  date,
  intro,
  registrationUrl,
  registrationEmail,
  time,
  place,
  href,
}: EventCardProps) {
  const { t } = useTranslation(['events']);
  const dayjsDate = dayjs(date);

  return (
    <Card sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
      <LinkWrapper href={href}>
        {image ? (
          <PreviewSanityImage height={eventCardImageHeight} image={image} noBorderRadius />
        ) : (
          <Box
            width="100%"
            height={eventCardImageHeight}
            bgcolor={({ palette }) => palette.cardBackgrounds.lightPurple}
          />
        )}
      </LinkWrapper>
      <Stack
        sx={{
          justifyContent: 'space-between',
          flexGrow: '1',
        }}
      >
        <LinkWrapper href={href}>
          <Stack sx={{ p: 2 }}>
            <OverflowingText variant="h4" gutterBottom tooltipText={title}>
              {title}
            </OverflowingText>
            <Typography
              color={({ palette }) => alpha(palette.common.black, 0.4)}
              variant="body3"
              sx={{ ...max2LinesTextSx, m: 0 }}
              paragraph
            >
              {intro}
            </Typography>
          </Stack>
        </LinkWrapper>

        <Box px={2} pb={2}>
          {place && (
            <Typography
              variant="body3"
              color={({ palette }) => alpha(palette.common.black, 0.4)}
              sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
            >
              <PlaceOutlinedIcon fontSize="small" color="primary" />
              {place}
            </Typography>
          )}

          {time && (
            <Typography
              variant="body3"
              color={({ palette }) => alpha(palette.common.black, 0.4)}
              sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
            >
              <AccessTimeOutlinedIcon fontSize="small" color="primary" />
              {time}
            </Typography>
          )}

          <Box display="flex" flex="1 0 auto" justifyContent="flex-end">
            <Box display="flex" flexGrow={1} alignItems="end">
              <Stack alignItems="flex-start">
                {(registrationUrl || registrationEmail) && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    href={registrationUrl || `mailto:${registrationEmail}`}
                    target={registrationUrl ? '_blank' : '_self'}
                    sx={{ mt: 1 }}
                  >
                    {t(messages.events.registration)}
                  </Button>
                )}
              </Stack>
            </Box>

            <Stack
              justifyContent="center"
              alignItems="center"
              px={2}
              py={1}
              sx={({ palette }) => ({
                backgroundColor: palette.cardBackgrounds.lightPurple,
                border: `1px solid ${palette.border.darkPurple}`,
                borderRadius: '7px',
              })}
            >
              <Typography variant="body3" color={({ palette }) => alpha(palette.common.black, 0.4)}>
                {capitalizeFirstLetter(dayjsDate.format('ddd'))}
              </Typography>
              <Typography variant="h1" sx={{ color: ({ palette }) => palette.text.primary }}>
                {dayjsDate.format('D')}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Card>
  );
}

function LinkWrapper({ href, children }: { href: string | undefined } & PropsWithChildren) {
  if (href) {
    return (
      <Link
        component={RouterLink}
        underline="none"
        to={href}
        sx={{
          '&:hover': {
            textDecoration: 'none',
          },
        }}
      >
        {children}
      </Link>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

const max2LinesTextSx = maxLinesSx(2);
