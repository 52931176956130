import { Card } from '@mui/material';
import React from 'react';

export const newsCardPaddingX = '20px';

interface Props {
  withXPadding: boolean;
  children: React.ReactNode;
}

function NewsCarouselCard({ withXPadding, children }: Props) {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        px: withXPadding ? newsCardPaddingX : 0,
        pt: '20px',
        pb: '10px',
        minHeight: '380px',
      }}
    >
      {children}
    </Card>
  );
}

export default NewsCarouselCard;
