import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {
  alpha,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
  Tooltip,
} from '@mui/material';
import { messages } from '@dap-common/i18n';
import { AppType } from '@dap-common/types';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { appLogos } from '@assets/appLogos';
import { BrandV2, CompleteApp, UserData } from '@dap-admin/types';
import { getUserAppsForBrand } from '@dap-admin/utils';
import { Avatar } from '@shared/components';

const VISIBLE_APPS = 8;

interface AppMenuProps {
  openSidebar: boolean;
  brandApps: BrandV2['apps'];
  userApps: UserData['apps'];
}

function AppMenu({ openSidebar, brandApps, userApps }: AppMenuProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const accessibleApps = getUserAppsForBrand(brandApps, userApps)?.filter(
    (app) => app.key !== AppType.BrandAdmin
  );

  const hiddenApps: CompleteApp[] = accessibleApps?.slice(VISIBLE_APPS) || [];

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  if (accessibleApps?.length) {
    return (
      <List sx={listSx}>
        {accessibleApps.slice(0, VISIBLE_APPS).map((app) => (
          <AppLink key={`app-link-${app.key}`} app={app} openSidebar={openSidebar} />
        ))}
        <Collapse id="hidden-apps" in={open} timeout="auto">
          {hiddenApps.map((app) => (
            <AppLink key={`app-link-${app.key}`} app={app} openSidebar={openSidebar} />
          ))}
        </Collapse>

        {hiddenApps?.length > 0 && (
          <Button
            onClick={handleToggle}
            aria-controls="hidden-apps"
            aria-expanded={open}
            variant="contained"
            sx={{ margin: '10px auto -25px' }}
          >
            {openSidebar ? t(messages.common.allApplications) : <AddRoundedIcon />}
          </Button>
        )}
      </List>
    );
  }
  return null;
}

export default memo(AppMenu);

interface AppLinkProps {
  app: CompleteApp;
  openSidebar: boolean;
}

const AppLink = ({ app, openSidebar }: AppLinkProps) => {
  return (
    <ListItem disablePadding>
      <Tooltip title={app.name} arrow placement="right">
        <ListItemButton sx={listItemButtonSx} href={app.url} target="_blank">
          <ListItemIcon sx={{ minWidth: '25px', height: '25px' }}>
            <Avatar name={app.name} imgSrc={appLogos[app.key]} variant="square" sx={avatarSx} />
          </ListItemIcon>
          <ListItemText
            primary={app.name}
            sx={[
              { ...(!openSidebar && { display: 'none' }) },
              ...(Array.isArray(listItemTextSx) ? listItemTextSx : [listItemTextSx]),
            ]}
          />
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
};

const listSx: SxProps<Theme> = ({ palette }) => ({
  background: palette.common.white,
  border: 1,
  borderColor: alpha(palette.primary.main, 0.1),
  borderRadius: '7px',
  margin: '20px 15px 35px 15px',
});

const listItemButtonSx: SxProps<Theme> = ({ palette }) => ({
  px: 2.5,
  py: '5px',
  minHeight: '25px',
  ':hover': { backgroundColor: 'transparent' },
});

const avatarSx: SxProps<Theme> = ({ palette }) => ({
  width: '25px',
  height: '25px',
  borderRadius: '7px',
  backgroundColor: palette.common.white,
  color: palette.grey[900],
  border: 1,
  borderColor: alpha(palette.common.black, 0.05),
  '& img': {
    width: '22px',
    height: '22px',
    padding: '3px',
  },
});

const listItemTextSx: SxProps<Theme> = ({ palette, typography }) => ({
  paddingLeft: '10px',
  alignContent: 'center',
  '.MuiTypography-root': {
    typography: typography.body4,
    fontWeight: 500,
    color: alpha(palette.text.primary, 0.8),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
