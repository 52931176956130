import { AppBasic, BrandV2, Location as LocationType, PatchLocation } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { FlexRow } from '@dap-common/ui';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { ContainedButton } from '@shared/components';
import { useDisclosure } from '@shared/hooks';
import EditLocationDialog from './EditLocationDetails/EditLocationDialog';
import LocationDetails from './LocationDetails';

export type LocationEdit = typeof editInit;

const editInit = {
  enabled: false,
};

export interface Props {
  location: LocationType;
  isDataownerAdmin: boolean;
  isBrandAdmin: boolean;
  availableApps: AppBasic[];
  brand: BrandV2;
  updateLocationDetails: (formData: PatchLocation) => void;
}

function Location({
  location,
  isDataownerAdmin,
  isBrandAdmin,
  availableApps,
  brand,
  updateLocationDetails,
}: Props) {
  const t = useAppTFunction();
  const editDialog = useDisclosure();

  return (
    <>
      {location.isAdmin && (
        <FlexRow justifyContent={'flex-end'} pb={2}>
          <ContainedButton
            size="small"
            startIcon={<EditOutlined />}
            onClick={editDialog.onOpen}
            disableElevation
          >
            {t('common.edit')}
          </ContainedButton>
        </FlexRow>
      )}

      {editDialog.isOpen && (
        <EditLocationDialog
          onClose={editDialog.onClose}
          open={editDialog.isOpen}
          location={location}
          availableRegions={brand.regions}
          availableServices={availableApps}
          updateLocationDetails={updateLocationDetails}
          isDataownerAdmin={isDataownerAdmin}
          isBrandAdmin={isBrandAdmin}
        />
      )}

      <LocationDetails location={location} brand={brand} />
    </>
  );
}

export default Location;
