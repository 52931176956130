import { pinMeToPin } from '@assets/images';
import { useAppTFunction } from '@dap-common/i18n';
import { styled } from '@mui/material';

export default function PinmetoPinImage() {
  const t = useAppTFunction();

  return <Img src={pinMeToPin} alt={t('location.detail.pinmeto.title')} />;
}

const Img = styled('img')`
  height: 24px;
`;
