import { SvgIcon, SvgIconProps } from '@mui/material';

const RemoveCheckboxIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="21" height="21" viewBox="0 0 21 21" fill="none">
      <rect y="0.756836" width="21" height="21" rx="5" fill="currentColor" />
      <rect
        x="0.5"
        y="1.25684"
        width="20"
        height="20"
        rx="4.5"
        stroke="black"
        strokeOpacity="0.5"
      />
      <rect
        x="5.76367"
        y="14.2898"
        width="10.9825"
        height="2.43102"
        rx="0.5"
        transform="rotate(-45.1158 5.76367 14.2898)"
        fill="white"
      />
      <rect
        width="10.9825"
        height="2.43102"
        rx="0.5"
        transform="matrix(0.705676 0.708534 0.708534 -0.705676 5.76367 8.22388)"
        fill="white"
      />
    </SvgIcon>
  );
};

export default RemoveCheckboxIcon;
