import { alpha, Box, Paper, Stack, Typography } from '@mui/material';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { ErrorBoundary } from '@sentry/react';
import { FillMinMax } from '@shared/components';
import { Link } from 'react-router-dom';
import { useSanityContextActions } from '@dap-sanity/utils';
import { SanityBody } from '../pageContent';
import ProffInfoBanner from './ProffInfoBanner';
import { ProffModuleConfig } from '@dap-sanity/types';

interface Props extends ProffModuleConfig {
  brandImageUrl: string;
}

function AllProffCategoriesList({
  frontPageProffImage,
  infoText,
  brandImageUrl,
  categories,
}: Props) {
  return (
    <Stack spacing={4}>
      <ProffInfoBanner brandImageUrl={brandImageUrl} backgroundImage={frontPageProffImage}>
        <SanityBody body={infoText} />
      </ProffInfoBanner>
      <FillMinMax minWidth="160px" gridGap="16px">
        {categories.map((category) => (
          <CategoryLink
            key={category.id}
            title={category.title}
            to={category.slug}
            backgroundImage={category.image}
          />
        ))}
      </FillMinMax>
    </Stack>
  );
}

interface CategoryLinkProps {
  title: string;
  to: string;
  backgroundImage?: SanityImageSource;
}

function CategoryLink({ title, to, backgroundImage }: CategoryLinkProps) {
  const { getImageUrlBuilder } = useSanityContextActions();
  return (
    <Paper
      component={Link}
      to={to}
      sx={{
        aspectRatio: '1 / 1',
        display: 'flex',
        alignItems: 'flex-end',
        p: 0,
        textDecoration: 'none',
        position: 'relative',
        background: ({ palette }) => alpha(palette.common.black, 0.4),
      }}
    >
      <ErrorBoundary>
        <Box
          sx={{
            borderRadius: '7px',
            width: '100%',
            position: 'absolute',
            height: '100%',
            backgroundImage:
              backgroundImage && `url(${getImageUrlBuilder(backgroundImage).height(600).url()})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: 1,
          }}
        />
      </ErrorBoundary>
      <Typography
        variant="h4"
        color="white"
        sx={{
          p: 2,
          background: ({ palette }) =>
            `linear-gradient(180deg, transparent 0%, ${palette.common.black} 100%)`,
          borderRadius: '7px',
          flexGrow: '1',
          textAlign: 'center',
          zIndex: 2,
        }}
      >
        {title}
      </Typography>
    </Paper>
  );
}

export default AllProffCategoriesList;
