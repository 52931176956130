import { pinMeToLogoUri } from '@assets/images';
import { useAppTFunction } from '@dap-common/i18n';
import { FlexRow } from '@dap-common/ui';
import EditOutlined from '@mui/icons-material/EditOutlined';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Sync from '@mui/icons-material/Sync';
import { CircularProgress, IconButton, Switch, Tooltip, styled } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  sync: () => void;
  isSyncing: boolean;
  changeId: () => void;
  deleteId: () => void;
  hasAutomatedSync: boolean;
  handleAutomatedSync: () => void;
  pinmetoId?: string;
  hasAdminRights: boolean;
}

export default function PinMeToActions({
  sync,
  isSyncing,
  changeId,
  deleteId,
  hasAutomatedSync,
  handleAutomatedSync,
  pinmetoId,
  hasAdminRights,
}: Props) {
  const t = useAppTFunction();

  return (
    <FlexRow gap={1} justifyContent={'space-between'} alignItems={'center'} height={'100%'}>
      {hasAdminRights && (
        <FlexRow>
          {pinmetoId && (
            <Tooltip title={`${t('location.detail.pinmeto.automaticSync')}`}>
              <Switch
                type="checkbox"
                color="primary"
                checked={hasAutomatedSync}
                onClick={handleAutomatedSync}
                size="small"
                sx={{ marginRight: 1 }}
              />
            </Tooltip>
          )}

          <Tooltip title={`${t('location.detail.pinmeto.syncronizeData')}`}>
            <IconButton
              aria-label={t('location.detail.pinmeto.syncronizeData')}
              onClick={sync}
              size="small"
              disabled={isSyncing}
            >
              {isSyncing ? <CircularProgress size={24} /> : <Sync />}
            </IconButton>
          </Tooltip>
          <Tooltip title={`${t('location.detail.pinmeto.edit')}`}>
            <IconButton
              aria-label={t('location.detail.pinmeto.edit')}
              onClick={changeId}
              size="small"
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title={`${t('location.detail.pinmeto.delete')}`}>
            <IconButton
              aria-label={t('location.detail.pinmeto.delete')}
              onClick={deleteId}
              size="small"
            >
              <DeleteOutline />
            </IconButton>
          </Tooltip>
        </FlexRow>
      )}
    </FlexRow>
  );
}

export const PinmetoContainer: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('pagesHomeLocation');
  return (
    <FlexRow>
      <Img src={pinMeToLogoUri} alt={t('location.detail.pinmeto.logoAltText')} />
      {children}
    </FlexRow>
  );
};

const Img = styled('img')`
  height: 35px;
  padding: 5px 5px 13px;
`;
