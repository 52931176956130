import { Typography } from '@mui/material';
import { messages, useTranslationWithBrandOptions } from '@dap-common/i18n';
import { useTranslation } from 'react-i18next';
import { SaveOrCancelEdit, StyledDialog } from '@dap-common/ui';
import { UpdateDataownerEnabledSchema } from '@dap-admin/types';

interface Props {
  open: boolean;
  cancelEdit: () => void;
  name: string;
  enabled: boolean;
  brandKey: string;
  updateDataowner: (input: UpdateDataownerEnabledSchema) => void;
  isUpdatingDataowner: boolean;
}

const DisableEnableDataowner = ({
  open,
  cancelEdit,
  name,
  enabled,
  brandKey,
  updateDataowner,
  isUpdatingDataowner,
}: Props) => {
  const { t } = useTranslation(['pagesHomeDataowner', 'common']);
  const { t: tBrand } = useTranslationWithBrandOptions('pagesHomeDataowner', brandKey);

  const onSubmit = () => {
    const enabledDataowner = { enabled: !enabled };
    updateDataowner(enabledDataowner);
  };

  const dialogTitle = enabled
    ? tBrand(messages.pagesHomeDataowner.actionMenu.deactivate, {
        dataowner: messages.brand.dataowner.name.singular,
      })
    : tBrand(messages.pagesHomeDataowner.actionMenu.activate, {
        dataowner: messages.brand.dataowner.name.singular,
      });

  const primaryButtonText = enabled
    ? `${t(messages.pagesHomeDataowner.deactivate.confirm)} ${name}?`
    : `${t(messages.pagesHomeDataowner.activate.confirm)} ${name}?`;

  return (
    <StyledDialog
      open={open}
      title={dialogTitle}
      content={
        enabled ? (
          <Typography>{`${t(messages.pagesHomeDataowner.deactivate.info)} ${name}?`}</Typography>
        ) : (
          <Typography>{`${t(messages.pagesHomeDataowner.activate.info)} ${name}?`}</Typography>
        )
      }
      actionButtons={
        <SaveOrCancelEdit
          cancel={cancelEdit}
          save={onSubmit}
          loading={isUpdatingDataowner}
          saveText={primaryButtonText}
          primaryButtonColor={enabled ? 'warning' : 'primary'}
        />
      }
      handleClose={cancelEdit}
    />
  );
};

export default DisableEnableDataowner;
