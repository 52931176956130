import { EmployeeBasicV2, EmployeeStatus } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { MenuWithPopup } from '@dap-common/ui';
import {
  AccountCircleOutlined,
  NoAccountsOutlined,
  PersonRemoveOutlined,
} from '@mui/icons-material';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import { Divider, MenuItem, Typography } from '@mui/material';

export interface EmployeeActionsMenuProps {
  employee: EmployeeBasicV2;
  isSuperuser: boolean;
  actions: EmployeeActionsMenuActions;
}

export interface EmployeeActionsMenuActions {
  onMenuClose: () => void;
  onEdit: (employee: EmployeeBasicV2) => void;
  onRemove?: (employee: EmployeeBasicV2) => void;
  onViewLog: (employee: EmployeeBasicV2) => void;
  onViewUserData: (employee: EmployeeBasicV2) => void;
  onActivate: (employee: EmployeeBasicV2) => void;
  onDeactivate: (employee: EmployeeBasicV2) => void;
  onDeleteUser: (employee: EmployeeBasicV2) => void;
  onRemoveFromBrand: (employee: EmployeeBasicV2) => void;
}

export default function EmployeeActionsMenu({
  employee,
  isSuperuser,
  actions,
}: EmployeeActionsMenuProps) {
  const t = useAppTFunction();
  return (
    <MenuWithPopup onMenuClose={actions.onMenuClose}>
      <MenuItem onClick={() => actions.onEdit(employee)}>
        <EditOutlined fontSize={'small'} />
        <Typography variant="body2" fontWeight={500} ml={1}>
          {t('employee.actionMenu.editDetails')}
        </Typography>
      </MenuItem>

      {actions.onRemove && (
        <MenuItem onClick={() => actions.onRemove?.(employee)}>
          <DeleteOutlined fontSize={'small'} />
          <Typography variant="body2" fontWeight={500} ml={1}>
            {t('employee.remove.action')}
          </Typography>
        </MenuItem>
      )}

      {isSuperuser && <Divider />}

      {isSuperuser && (
        <MenuItem onClick={() => actions.onViewLog(employee)}>
          <VisibilityOutlined fontSize={'small'} />
          <Typography variant="body2" fontWeight={500} ml={1}>
            {t('employee.actionMenu.viewLog')}
          </Typography>
        </MenuItem>
      )}

      {isSuperuser && (
        <MenuItem onClick={() => actions.onViewUserData(employee)}>
          <VisibilityOutlined fontSize={'small'} />
          <Typography variant="body2" fontWeight={500} ml={1}>
            {t('employee.actionMenu.viewUserData')}
          </Typography>
        </MenuItem>
      )}

      {isSuperuser && <Divider />}

      {isSuperuser && employee.status !== (EmployeeStatus.DISABLED || EmployeeStatus.BLOCKED) && (
        <MenuItem onClick={() => actions.onDeactivate(employee)}>
          <NoAccountsOutlined sx={{ transform: 'scaleX(-1)' }} fontSize="small" />
          <Typography variant="body2" fontWeight={500} ml={1}>
            {t('employee.actionMenu.deactivate')}
          </Typography>
        </MenuItem>
      )}

      {isSuperuser && employee.status === EmployeeStatus.DISABLED && (
        <MenuItem onClick={() => actions.onActivate(employee)}>
          <AccountCircleOutlined fontSize="small" />
          <Typography variant="body2" fontWeight={500} ml={1}>
            {t('employee.actionMenu.activate')}
          </Typography>
        </MenuItem>
      )}

      {isSuperuser && (
        <MenuItem onClick={() => actions.onDeleteUser(employee)}>
          <PersonRemoveOutlined fontSize="small" />
          <Typography variant="body2" fontWeight={500} ml={1}>
            {t('employee.actionMenu.deleteUser')}
          </Typography>
        </MenuItem>
      )}

      {isSuperuser && (
        <MenuItem onClick={() => actions.onRemoveFromBrand(employee)}>
          <DeleteOutlined fontSize="small" />
          <Typography variant="body2" fontWeight={500} ml={1}>
            {t('employee.actionMenu.removeConnectionFrom', {
              entity: t('brandadmin.brand.title.definite'),
            })}
          </Typography>
        </MenuItem>
      )}
    </MenuWithPopup>
  );
}
