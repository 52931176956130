import { useBrandadminTFunction } from '@dap-admin/i18n';
import { EmployeeBasicV2 } from '@dap-admin/types';
import { ListItemIcon, ListItemText } from '@mui/material';
import { LimitedList } from '@shared/components';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { AdministratorIcon } from '@shared/custom-icons';

interface Props {
  roles: EmployeeBasicV2['roles'];
  max?: number;
  itemWidth?: string;
}

export default function RolesList({ roles, max = 1, itemWidth = 'auto' }: Props) {
  const tAdmin = useBrandadminTFunction();

  if (!roles) return null;

  return (
    <LimitedList
      row
      itemWidth={itemWidth}
      title={tAdmin('employee.details.roles')}
      items={roles.map(({ role }) => (
        <>
          <ListItemIcon sx={{ minWidth: '24px' }}>
            {role.isAdmin ? (
              <AdministratorIcon sx={{ fontSize: '18px' }} />
            ) : (
              <PersonOutlineOutlinedIcon sx={{ fontSize: '18px' }} />
            )}
          </ListItemIcon>
          <ListItemText primary={role.title} primaryTypographyProps={{ variant: 'body2' }} />
        </>
      ))}
      max={max}
      disablePadding
      dense
    />
  );
}
