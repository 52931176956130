import { Buttons, FlexColumn } from '@dap-common/ui';
import { Alert, Typography } from '@mui/material';
import { ContainedButton, PaginationArrows, TagHeading } from '@shared/components';
import { useState } from 'react';

export interface EmployeeCTAProps {
  ctas: Array<CTA>;
}

interface CTA {
  title: string;
  description: string;
  success?: string;
  error?: string;
  primaryButtonLabel: string;
  onClickPrimaryButton: () => void;
}

export default function EmployeeCTA({ ctas }: EmployeeCTAProps) {
  const [activeCTA, setActiveCTA] = useState(0);
  const maxCTA = ctas.length;

  const handleNext = () => {
    setActiveCTA((previousActiveCTA) => previousActiveCTA + 1);
  };

  const handleBack = () => {
    setActiveCTA((previousActiveCTA) => previousActiveCTA - 1);
  };

  if (ctas.length === 0) return null;

  return (
    <div>
      <TagHeading marginBottom={2}>
        <Typography variant="h6">{ctas?.[activeCTA]?.title}</Typography>
        <PaginationArrows
          disableNext={activeCTA === maxCTA - 1}
          disablePrevious={activeCTA === 0}
          handleNext={handleNext}
          handlePrevious={handleBack}
        />
      </TagHeading>

      {ctas.map(
        (
          { title, description, success, error, primaryButtonLabel, onClickPrimaryButton },
          index
        ) => (
          <div key={`${title}-${index}`}>
            {activeCTA === index && (
              <FlexColumn flexGrow={1} gap={2}>
                {success && <Alert severity="success">{success}</Alert>}
                {error && <Alert severity="warning">{error}</Alert>}

                <Typography color="text.secondary">{description}</Typography>

                <Buttons>
                  <ContainedButton onClick={onClickPrimaryButton} disableElevation>
                    {primaryButtonLabel}
                  </ContainedButton>
                </Buttons>
              </FlexColumn>
            )}
          </div>
        )
      )}
    </div>
  );
}
