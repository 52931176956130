import {
  DataGridPro,
  DataGridProProps,
  nbNO,
  svSE,
  enUS,
  GridLocaleText,
  GridSlotsComponentsProps,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';

const DataGridLocale: { [key: string]: Partial<GridLocaleText> } = {
  nb: nbNO.components.MuiDataGrid.defaultProps.localeText,
  sv: svSE.components.MuiDataGrid.defaultProps.localeText,
  en: enUS.components.MuiDataGrid.defaultProps.localeText,
};

const componentProps: GridSlotsComponentsProps = {
  baseTextField: {
    variant: 'outlined',
    size: 'small',
    InputLabelProps: { shrink: true },
  },
  filterPanel: {
    filterFormProps: {
      linkOperatorInputProps: {
        variant: 'outlined',
        size: 'small',
      },
      columnInputProps: {
        variant: 'outlined',
        size: 'small',
        sx: { mt: 'auto', mr: 1 },
      },
      operatorInputProps: {
        variant: 'outlined',
        size: 'small',
        sx: { mt: 'auto', mr: 1 },
      },
      valueInputProps: {
        InputComponentProps: {
          variant: 'outlined',
          size: 'small',
        },
      },
    },
  },
};

function BaseTable({
  columns,
  rows,
  getRowId,
  checkboxSelection,
  hideFooterRowCount = false,
  localeText,
  ...rest
}: DataGridProProps) {
  const { i18n } = useTranslation();

  return (
    <DataGridPro
      columns={columns}
      rows={rows}
      localeText={{ ...DataGridLocale[i18n.language], ...localeText }}
      getRowId={getRowId}
      headerHeight={45}
      rowHeight={60}
      disableSelectionOnClick
      hideFooterPagination
      checkboxSelection={checkboxSelection}
      hideFooterRowCount={hideFooterRowCount}
      componentsProps={{ ...componentProps, ...rest.componentsProps }}
      {...rest}
    />
  );
}

export default BaseTable;
