import { ButtonBase, IconButton as MuiIconButton, styled, Typography } from '@mui/material';
import { FlexRow } from '@dap-common/ui';
import { useTranslation } from 'react-i18next';
import { SnapItem, SnapList } from 'react-snaplist-carousel';
import NewsCarouselCard, { newsCardPaddingX } from './NewsCarouselCard';
import { previewCardImageHeight } from './newsConstants';
import NewsPreviewCard from './NewsPreviewCard';
import { useCarousel } from '@shared/hooks';
import { messages } from '@dap-common/i18n';
import { CustomChevronLeft, CustomChevronRight } from '@shared/custom-icons';
import { SanityNewsArticlePreview } from '@dap-sanity/types';

interface Props {
  newsArticles: SanityNewsArticlePreview[];
  newsUrl: string;
}

export default function NewsCarousel({ newsArticles, newsUrl }: Props) {
  const { t } = useTranslation(['newsArticles', 'common']);
  const {
    snapListRef,
    firstItemIsVisible,
    lastItemIsVisible,
    visibleElements,
    goToSnapItem,
    scrollForward,
    scrollBack,
  } = useCarousel(newsArticles.length);

  if (newsArticles.length === 0) {
    return (
      <NewsCarouselCard withXPadding>
        <Typography variant="h2">{t(`newsArticles:${messages.newsArticles.latest}`)}</Typography>
        <Typography>{t(`newsArticles:${messages.newsArticles.emptyList}`)}</Typography>
      </NewsCarouselCard>
    );
  }

  return (
    <NewsCarouselCard withXPadding={false}>
      <FlexRow justifyContent="space-between" px={newsCardPaddingX} mb="16px">
        <Typography variant="h2">{t(`newsArticles:${messages.newsArticles.latest}`)}</Typography>
        <FlexRow gap="2px">
          <IconButton
            size="small"
            disabled={firstItemIsVisible}
            onClick={scrollBack}
            aria-label={t(`common:${messages.common.goToPrevious}`)}
          >
            <CustomChevronLeft
              fontSize="inherit"
              color={firstItemIsVisible ? 'disabled' : 'primary'}
            />
          </IconButton>
          <IconButton
            size="small"
            disabled={lastItemIsVisible}
            onClick={scrollForward}
            aria-label={t(`common:${messages.common.goToNext}`)}
          >
            <CustomChevronRight
              fontSize="inherit"
              color={lastItemIsVisible ? 'disabled' : 'primary'}
            />
          </IconButton>
        </FlexRow>
      </FlexRow>
      <div>
        <SnapList ref={snapListRef} direction="horizontal">
          {newsArticles.map(({ image, title, intro, url, id, author, publishAt }, index) => (
            <SnapItem
              snapAlign="center"
              key={id}
              margin={{
                left: index === 0 ? newsCardPaddingX : '10px',
                right: index === newsArticles.length - 1 ? newsCardPaddingX : '10px',
              }}
            >
              <NewsPreviewCard
                image={image}
                imgHeight={previewCardImageHeight}
                title={title}
                intro={intro}
                url={`${newsUrl}/${url}`}
                author={author}
                publishedAt={publishAt}
              />
            </SnapItem>
          ))}
        </SnapList>
      </div>
      <FlexRow gap="5px" alignSelf="center" mt="10px">
        {newsArticles.map((_, index) => (
          <ButtonBase
            onClick={() => goToSnapItem(index)}
            sx={{
              height: '10px',
              width: '10px',
              borderRadius: '50%',
              bgcolor: ({ palette }) =>
                visibleElements.includes(index) ? palette.primary.dark : palette.grey[300],
              '&:focus': {
                outline: ({ palette }) => `1px solid ${palette.primary.main}`,
              },
            }}
            aria-label={t(`common:${messages.common.goToNumber}`, { nr: index + 1 })}
            key={index}
          />
        ))}
      </FlexRow>
    </NewsCarouselCard>
  );
}

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  padding: '5px 7px 5px 3px', // center chevron in button
  '&:hover': {
    bgcolor: theme.palette.grey[100],
  },
}));
