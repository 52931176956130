import { AppBasic } from '@dap-admin/types';
import { FormControlLabel, List, ListItem, Typography } from '@mui/material';
import { CheckboxIconChecked, RemoveCheckboxIcon } from '@shared/custom-icons';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox } from '../Form';

interface Props {
  availableApps: AppBasic[];
  apps: AppBasic[];
  saveApps: (appKeys: string[], add: boolean) => void;
  saving?: boolean;
  addMode?: boolean;
  formId: string;
}

const EditApps = ({ availableApps, saveApps, saving, apps, addMode = true, formId }: Props) => {
  const defaultValue = apps.map((app) => app.key);

  const { handleSubmit, control } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { apps: defaultValue },
  });
  const [checkedValues, setCheckedValues] = useState<string[]>(defaultValue);

  const handleSelect = (value: string) => {
    if (checkedValues.includes(value)) {
      setCheckedValues(checkedValues.filter((app) => app !== value));
    } else {
      setCheckedValues((prevState) => [...prevState, value]);
    }
  };

  return (
    <form id={formId} onSubmit={handleSubmit(() => saveApps(checkedValues, addMode))}>
      <List disablePadding>
        {availableApps.map(({ key, name }) => (
          <ListItem key={key} disablePadding>
            <FormControlLabel
              control={
                <Controller
                  name="apps"
                  control={control}
                  render={() => (
                    <Checkbox
                      color="primary"
                      id={key}
                      checkedIcon={
                        addMode ? <CheckboxIconChecked /> : <RemoveCheckboxIcon color="error" />
                      }
                      checked={checkedValues.includes(key)}
                      onChange={() => handleSelect(key)}
                      size="small"
                    />
                  )}
                />
              }
              label={<Typography variant="body2">{name}</Typography>}
              disabled={saving}
            />
          </ListItem>
        ))}
      </List>
    </form>
  );
};

export default EditApps;
