import { List } from '@mui/material';
import NavLinkItem, { NavRoute } from './NavLinkItem';

interface Props {
  navRoutes: NavRoute[];
  expandedView: boolean;
}

function Navigation({ navRoutes, expandedView }: Props) {
  return (
    <List sx={{ padding: '10px 15px' }} component="nav">
      {navRoutes.map(({ title, icon, to, isActive, subRoutes }) => (
        <NavLinkItem
          title={title}
          icon={icon}
          to={to}
          isActive={isActive}
          subRoutes={subRoutes}
          expandedView={expandedView}
          key={to}
        />
      ))}
    </List>
  );
}

export default Navigation;
