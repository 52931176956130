import { Cancel, CheckCircle } from '@mui/icons-material';
import { Stack } from '@mui/material';

import { messages } from '@dap-common/i18n';
import { FunctionComponent, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { cardInputProps } from './cardInputProps';
import { EcomAssortment, Ecommerce, LocationEcommerceSchema } from '@dap-admin/types';
import {
  CheckboxWithLabel,
  EditCard,
  InputSelect,
  LabelValue,
  SaveOrCancelEdit,
  TextInput,
  UniversalFormProvider,
} from '@dap-common/ui';
import { pruneObject } from '@shared/utils';

const ecommerceSchema = yup.object().shape({
  assortment: yup.string(),
  papirflyStoreId: yup.string(),
  hasInventoryManagement: yup.boolean(),
});

interface PostBody {
  ecomAssortmentKey?: string;
  papirflyStoreId?: string;
  hasInventoryManagement?: boolean;
}

const mapToPostBody = (updatedEcom: Ecommerce): PostBody => ({
  ecomAssortmentKey: updatedEcom.assortment,
  papirflyStoreId: updatedEcom.papirflyStoreId,
  hasInventoryManagement: updatedEcom.hasInventoryManagement,
});

interface Props {
  ecommerce?: Ecommerce;
  selectedEcommerceAssortments: EcomAssortment[];
  showOmniumData?: boolean;
  publiclyAvailable?: boolean;
  hasInventoryManagement?: boolean;
  save: (updatedEcommerce: LocationEcommerceSchema) => void;
  saving: boolean;
  hasAdminRights: boolean;
}

interface FormProps extends Omit<Props, 'hasAdminRights' | 'save'> {
  cancel: () => void;
  save: (updatedEcommerce: PostBody) => void;
}

const EcommerceForm: FunctionComponent<FormProps> = ({
  save,
  cancel,
  selectedEcommerceAssortments,
  saving,
}) => {
  const { t } = useTranslation('pagesHomeLocation');
  const { assortment, papirflyStoreId, hasInventoryManagement } =
    messages.pagesHomeLocation.ecommerce;
  const { handleSubmit, control } = useFormContext<Ecommerce>();

  const onSubmit = handleSubmit((updatedEcom) => {
    save(mapToPostBody(updatedEcom));
  });

  const assortmentOptions = useMemo(
    () =>
      selectedEcommerceAssortments.map(({ key, title }) => ({
        value: key,
        name: title,
      })),
    [selectedEcommerceAssortments]
  );

  const assortmentController = useController({ name: 'assortment', control });
  const hasInventoryManagementController = useController({
    name: 'hasInventoryManagement',
    control,
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={1.5}>
        <InputSelect
          {...assortmentController.field}
          value={assortmentController.field.value || ''}
          label={t(assortment)}
          placeholder={t(assortment)}
          menuItems={assortmentOptions}
          margin="dense"
        />
        <TextInput name="papirflyStoreId" label={t(papirflyStoreId)} {...cardInputProps} />
        <CheckboxWithLabel
          {...hasInventoryManagementController.field}
          label={t(hasInventoryManagement)}
          margin="dense"
        />
        <SaveOrCancelEdit save={onSubmit} cancel={cancel} align="right" loading={saving} />
      </Stack>
    </form>
  );
};

const EcommerceCard: FunctionComponent<Props> = ({
  ecommerce,
  selectedEcommerceAssortments,
  showOmniumData,
  publiclyAvailable,
  save,
  saving,
  hasAdminRights,
}) => {
  const { t } = useTranslation('pagesHomeLocation');
  const {
    assortment,
    papirflyStoreId,
    hasInventoryManagement: hasInventoryManagementText,
  } = messages.pagesHomeLocation.ecommerce;
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const saveFn = (updated: PostBody) => {
    save({ ecommerce: pruneObject(updated, null) });
    setIsEditing(false);
  };

  return (
    <EditCard
      header={t(messages.pagesHomeLocation.ecommerce.name)}
      isEditing={isEditing}
      edit={() => setIsEditing(true)}
      cancel={() => setIsEditing(false)}
      isEditable={hasAdminRights}
      titleTypographyVariant="h6"
      fullHeight
    >
      {isEditing ? (
        <UniversalFormProvider defaultValues={ecommerce} schema={ecommerceSchema}>
          <EcommerceForm
            save={saveFn}
            cancel={() => setIsEditing(false)}
            selectedEcommerceAssortments={selectedEcommerceAssortments}
            ecommerce={ecommerce}
            saving={saving}
          />
        </UniversalFormProvider>
      ) : (
        <Stack spacing={1}>
          <LabelValue
            labelPlacement={ecommerce?.ecomAssortment?.title ? 'top' : 'start'}
            label={t(assortment)}
            value={ecommerce?.ecomAssortment?.title || '-'}
            sx={{ alignItems: 'start' }}
          />

          <LabelValue label={t(papirflyStoreId)} value={ecommerce?.papirflyStoreId || '-'} />
          <LabelValue
            label={t(hasInventoryManagementText)}
            alignItems="center"
            value={
              ecommerce?.hasInventoryManagement ? (
                <CheckCircle color="success" sx={{ fontSize: '15px' }} />
              ) : (
                <Cancel color="error" sx={{ fontSize: '15px' }} />
              )
            }
            valueTypographyProps={{ sx: { display: 'inline-flex' } }}
          />
          {showOmniumData && (
            <LabelValue
              label={t(messages.pagesHomeLocation.publiclyAvailable)}
              value={
                publiclyAvailable ? (
                  <CheckCircle color="success" sx={{ fontSize: '15px' }} />
                ) : (
                  <Cancel color="error" sx={{ fontSize: '15px' }} />
                )
              }
            />
          )}
        </Stack>
      )}
    </EditCard>
  );
};

export default EcommerceCard;
