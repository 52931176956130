import { Cancel } from '@mui/icons-material';
import {
  BoxProps,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  SxProps,
  Typography,
} from '@mui/material';
import { messages } from '@dap-common/i18n';
import { FunctionComponent, ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends BoxProps {
  header: ReactNode;
  isEditing: boolean;
  edit: () => void;
  cancel: () => void;
  altEditIcon?: ReactElement;
  isEditable: boolean;
  fullHeight?: boolean;
  titleTypographyVariant?: 'h6' | 'h4' | 'h2';
}

const EditCard: FunctionComponent<Props> = ({
  header,
  children,
  edit,
  isEditing,
  altEditIcon,
  isEditable,
  cancel,
  fullHeight,
  titleTypographyVariant = 'h4',
}) => {
  const { t } = useTranslation();

  return (
    <Card variant="outlined" color="primary" sx={cardSx(fullHeight)}>
      <CardHeader
        title={
          <Typography component="h4" variant={titleTypographyVariant} fontWeight="700" noWrap>
            {header}
          </Typography>
        }
        sx={cardHeaderSx}
        action={
          !isEditing ? (
            isEditable &&
            (altEditIcon ? (
              altEditIcon
            ) : (
              <Button
                aria-label={t(messages.common.form.edit, { label: header })}
                onClick={edit}
                size="small"
                variant="text"
                color="inherit"
                sx={{ typography: 'body3' }}
              >
                {t(messages.common.edit)}
              </Button>
            ))
          ) : altEditIcon ? (
            <IconButton
              aria-label={t(messages.common.form.cancel, { label: header })}
              onClick={cancel}
              size="small"
            >
              <Cancel color="action" />
            </IconButton>
          ) : (
            <Button
              aria-label={t(messages.common.form.cancel, { label: header })}
              onClick={cancel}
              size="small"
              variant="text"
              color="inherit"
              sx={{ typography: 'body3' }}
            >
              {t(messages.common.cancel)}
            </Button>
          )
        }
      />
      <CardContent sx={{ py: 0 }}>{children}</CardContent>
    </Card>
  );
};

const cardSx = (fullHeight: boolean | undefined): SxProps => ({
  padding: 0,
  ...(fullHeight && { height: '100%' }),
});

const cardHeaderSx: SxProps = {
  '& .MuiCardHeader-content': {
    width: '100%',
    overflow: 'hidden',
  },
};

export default EditCard;
