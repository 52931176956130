import { CheckCircle } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import LabelValue from '../Form/LabelValue';
import { FlexRow } from '../Layout';
import { useAppTFunction } from '@dap-common/i18n';
import { CopyIcon } from '@shared/custom-icons';

interface Props {
  value: string;
  label?: string;
  justifyContent?: 'baseline' | 'space-between';
}

const CopyValueToClipboard = ({ value, label, justifyContent = 'baseline' }: Props) => {
  const t = useAppTFunction();
  const [copied, setCopied] = useState<boolean>(false);

  const copy = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 700);
  };
  return (
    <FlexRow justifyContent={'space-between'}>
      {label && (
        <LabelValue
          label={`${label}: `}
          labelTypographyProps={{ variant: 'h3' }}
          value={value}
          justifyContent={justifyContent}
        />
      )}
      <Tooltip title={`${t('common.copy.copyToClipboard')} ${label ?? ''}`}>
        <IconButton
          aria-label={`${t('common.copy.copyToClipboard')} ${label ?? ''}`}
          size="small"
          onClick={copy}
        >
          {copied ? (
            <CheckCircle fontSize="small" color="success" />
          ) : (
            <CopyIcon fontSize="small" />
          )}
        </IconButton>
      </Tooltip>
    </FlexRow>
  );
};

export default CopyValueToClipboard;
