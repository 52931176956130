import { appLogos } from '@assets/appLogos';
import { AppDetailed, BrandV2, CompleteApp, UserData } from '@dap-admin/types';

export const mapAppToCompleteApp = ({
  key,
  name,
  url,
  config,
  ...rest
}: AppDetailed): CompleteApp => ({
  key,
  name: config?.name || name,
  url: config?.url || url || '',
  logoUrl: appLogos[key],
  default: !!rest.default,
});

export function getUserAppsForBrand(brandApps: BrandV2['apps'], userApps: UserData['apps']) {
  return brandApps
    .filter((app) => userApps.some((userApp) => userApp.key === app.key))
    .map(mapAppToCompleteApp);
}
