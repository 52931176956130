import { useBrandTFunction, useBrandadminTFunction } from '@dap-admin/i18n';
import { BrandV2, Dataowner, Location } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { FlexColumn, FlexRow, LabelValue } from '@dap-common/ui';
import { Divider, Stack, useTheme } from '@mui/material';
import {
  Breadcrumbs,
  Chip,
  LabelValueTable,
  LimitedList,
  Paper,
  TagHeading,
} from '@shared/components';
import LocationDetailsBlockActionMenu, {
  LocationDetailsMenuActions,
} from './LocationDetailsBlockActionMenu';

interface Props {
  location: Location;
  actions: LocationDetailsMenuActions;
  isDataownerAdmin: boolean;
  isBrandAdmin: boolean;
  isSuperuser: boolean;
  availableRegions: BrandV2['regions'];
  availableServices: Dataowner['apps'];
  availableLocationProperties: BrandV2['locationProperties'];
}

export default function LocationDetailsBlock({
  location,
  actions,
  isDataownerAdmin,
  isBrandAdmin,
  isSuperuser,
  availableRegions,
  availableServices,
  availableLocationProperties,
}: Props) {
  const t = useBrandadminTFunction();
  const tApp = useAppTFunction();
  const tBrand = useBrandTFunction(location.dataowner.brand.key);

  const { palette } = useTheme();

  return (
    <Paper sx={{ height: '100%' }} data-testid="location-details-block">
      <TagHeading marginBottom={3} alignItems="flex-start">
        <FlexRow justifyContent="space-between" alignItems="flex-start">
          <FlexColumn>
            <Breadcrumbs
              items={[{ href: '../..', label: tApp('common.home') }, { label: location.name }]}
              fontSize="sm"
            />
            <LabelValue
              label={t('location.id')}
              sx={{ padding: '4px 0' }}
              value={<Chip label={location.key} backgroundColor={palette.info.main} />}
              labelTypographyProps={{
                color: 'text.secondary',
                variant: 'body2',
                fontWeight: 400,
              }}
            />
          </FlexColumn>

          {!location.enabled && (
            <Chip
              label={t('location.properties.deactivated')}
              backgroundColor={palette.warning.main}
            />
          )}
        </FlexRow>

        {location.isAdmin && (
          <LocationDetailsBlockActionMenu
            location={location}
            actions={actions}
            isDataownerAdmin={isDataownerAdmin}
            isBrandAdmin={isBrandAdmin}
            isSuperuser={isSuperuser}
            availableRegions={availableRegions}
            availableServices={availableServices}
            availableLocationProperties={availableLocationProperties}
          />
        )}
      </TagHeading>

      <LabelValueTable
        items={[
          {
            label: tBrand('brand.location.locationProperties.name.plural', 'capitalize'),
            value: location.locationProperties && (
              <LimitedList
                row
                max={5}
                items={location.locationProperties.map((property) => (
                  <Chip
                    key={property.key}
                    label={property.title}
                    rounded
                    backgroundColor={palette.secondary[100]}
                  />
                ))}
              />
            ),
          },
          {
            label: t('location.region.name'),
            value: location.regionName && (
              <Chip label={location.regionName} rounded backgroundColor={palette.success[100]} />
            ),
          },
          {
            label: t('location.gln.title'),
            value: location.glns && (
              <Stack
                direction="row"
                flexWrap="wrap"
                rowGap={1.5}
                columnGap={1}
                divider={<Divider orientation="vertical" flexItem />}
              >
                {location.glns.map(({ id }) => (
                  <div key={id}>{id}</div>
                ))}
              </Stack>
            ),
          },
        ]}
      />
    </Paper>
  );
}
