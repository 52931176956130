import {
  DATAOWNER,
  EMPLOYEE_V2,
  LOCATION_V2,
  LOCATION_V2_EMPLOYEES,
  LOCATION_V2_KEY,
} from '@dap-admin/config';
import { Dataowner, InformationModelType } from '@dap-admin/types';
import { messages, useTranslationWithBrandOptions } from '@dap-common/i18n';
import { mapOrgUnitRolesToOldRoles, roleBasicMapper } from '@dap-common/utils';
import { EmployeeBasicDTO, EmployeeDetailDTO, LocationDetailDTO } from '@generated-types';
import { bearerToken, patchRequest, postRequest } from '@shared/fetch-utils';
import { catchAndLogError, reportReduxActionException } from '@shared/reporting';
import { AuthState } from '@shared/state/auth';
import { t } from 'i18next';
import { Epic, StateObservable, combineEpics, ofType } from 'redux-observable';
import { Observable, catchError, concatMap, forkJoin, iif, map, of, switchMap } from 'rxjs';
import { brandApi } from '../../api/brandApi';
import { dataownerApi } from '../../api/dataownerApi';
import { locationApi } from '../../api/locationApi';
import { BrandTags, DataownerTags, LocationTags } from '../../api/tags';
import { BrandState } from '../brand/brandReducer';
import {
  LocationState,
  createNewLocationAddAdminFailure,
  createNewLocationAddAppsFailure,
  createNewLocationFailure,
  createNewLocationRequest,
  createNewLocationSuccess,
} from './locationReducer';

interface LocationRootState {
  location: LocationState;
  auth: AuthState;
  brand: BrandState;
}

const createLocationWizardEpic: Epic = (
  actions$: Observable<ReturnType<typeof createNewLocationRequest>>,
  state$: StateObservable<LocationRootState>
) =>
  actions$.pipe(
    ofType(createNewLocationRequest.type),
    // either use exisiting d.o or create new
    concatMap((action) =>
      iif(
        () => !!action.payload.dataowner.key, // is either existing d.o with key or not
        of(null),
        postRequest<Dataowner>(
          DATAOWNER,
          bearerToken(state$.value.auth.token),
          action.payload.dataowner
        ).pipe(map(({ response }) => response)) // exposes the response in an observable
      ).pipe(
        // use either new d.o or exisisting for new location
        concatMap((newDataowner) =>
          postRequest<LocationDetailDTO>(LOCATION_V2, bearerToken(state$.value.auth.token), {
            ...action.payload.location,
            dataowner: { key: newDataowner?.key || action.payload.dataowner.key },
          }).pipe(
            concatMap(({ response: newLocation }) => {
              // if apps are set
              const appsRequest$ = iif(
                () => !!action.payload.location.apps && action.payload.location.apps.length > 0,
                patchRequest<LocationDetailDTO>(
                  LOCATION_V2_KEY(newLocation.key),
                  bearerToken(state$.value.auth.token),
                  { apps: action.payload.location.apps }
                ),
                of(null)
              ).pipe(
                // error, adding apps. Returns new observable with action of failure
                catchError((error) => {
                  const message = t(`brandAdmin:${messages.brandAdmin.error.location.apps}`);
                  reportReduxActionException(action, {
                    ...error,
                    message,
                  });
                  return of(createNewLocationAddAppsFailure({ error: message }));
                })
              );

              // if admin is set
              const adminRole =
                newLocation.availableRoles?.find(
                  (role) => role.type === InformationModelType.Location && role.role?.isAdmin
                ) || newLocation.availableRoles?.[0];

              const mappedRole = adminRole
                ? mapOrgUnitRolesToOldRoles(roleBasicMapper([adminRole]))
                : [];

              const adminPayload = {
                ...action.payload.admin,
                locations: [newLocation.key],
                roles: mappedRole,
              };

              const existingUserPayload = {
                userId: action.payload.admin.id,
                apps: action.payload.admin.apps,
                roles: mappedRole,
              };
              // if not skipped, add admin by either using existing user or create new
              const adminRequest$ = iif(
                () => !!action.payload.admin.givenName,
                iif(
                  () => !!action.payload.admin.id,
                  postRequest<EmployeeBasicDTO[]>(
                    LOCATION_V2_EMPLOYEES(newLocation.key),
                    bearerToken(state$.value.auth.token),
                    [existingUserPayload]
                  ),
                  postRequest<EmployeeDetailDTO>(
                    EMPLOYEE_V2,
                    bearerToken(state$.value.auth.token),
                    adminPayload
                  )
                ),
                of(null)
              ).pipe(
                // error, adding admin. Returns new observable with action of failure
                catchError((error) => {
                  const message = t(`brandAdmin:${messages.brandAdmin.error.location.admin}`);
                  reportReduxActionException(action, {
                    ...error,
                    message,
                  });
                  return of(createNewLocationAddAdminFailure({ error: message }));
                })
              );
              return forkJoin([appsRequest$, adminRequest$]).pipe(
                // result of forkJoin, and success create location
                switchMap(([appsResponse, adminResponse]) => {
                  const errors = [];
                  if (appsResponse?.type === 'location/createNewLocationAddAppsFailure') {
                    errors.push(appsResponse.payload.error);
                  }
                  if (adminResponse?.type === 'location/createNewLocationAddAdminFailure') {
                    errors.push(adminResponse.payload.error);
                  }
                  return of(
                    createNewLocationSuccess({ location: newLocation, chainedErrors: errors }),
                    brandApi.util.invalidateTags([BrandTags.BRAND_LOCATIONS]),
                    dataownerApi.util.invalidateTags([DataownerTags.SEARCH_DATAOWNERS]),
                    locationApi.util.invalidateTags([LocationTags.SEARCH_LOCATIONS]),
                    brandApi.util.invalidateTags([BrandTags.BRAND_DATAOWNERS]),
                    dataownerApi.util.invalidateTags([DataownerTags.DATAOWNER])
                  );
                })
              );
            }),

            // error, could not create location
            catchError((error) => {
              reportReduxActionException(action, {
                ...error,
                message: useTranslationWithBrandOptions(
                  'brandadmin',
                  action.payload.dataowner.brandKey || ''
                ).t(messages.brandAdmin.error.location.create, {
                  entity: messages.brand.location.name.definite,
                }),
              });
              return of(createNewLocationFailure(error));
            })
          )
        ),

        // error, create d.o
        catchAndLogError(action, [
          createNewLocationFailure,
          () => brandApi.util.invalidateTags([BrandTags.BRAND_DATAOWNERS]),
          () => dataownerApi.util.invalidateTags([DataownerTags.SEARCH_DATAOWNERS]),
        ])
      )
    )
  );

export default combineEpics(createLocationWizardEpic);
