import { DAP_BRAND_URLS, DAP_SANITY_CONFIG } from '@dap-common/consts';
import { brandStylesConfig } from '@dap-common/styles';
import { Env, EnvConfigs } from '@dap-common/types';
import {
  bademiljoeFeaturesDev,
  bademiljoeFeaturesStage,
  bademiljoeFeaturesProd,
  blinkhusFeaturesDev,
  blinkhusFeaturesProd,
  blinkhusFeaturesStage,
  bolistFeaturesDev,
  bolistFeaturesStage,
  bolistFeaturesProd,
  byggtorgetFeaturesDev,
  byggtorgetFeaturesProd,
  byggtorgetFeaturesStage,
  defaultBrandFeatures,
  kuloerBrandFeaturesDev,
  kuloerBrandFeaturesProd,
  kuloerBrandFeaturesStage,
  mestergruppenFeaturesDev,
  mestergruppenFeaturesProd,
  mestergruppenFeaturesStage,
  mesterhusFeaturesDev,
  mesterhusFeaturesProd,
  mesterhusFeaturesStage,
  nordbohusFeaturesDev,
  nordbohusFeaturesProd,
  nordbohusFeaturesStage,
  pretreFeaturesDev,
  pretreFeaturesProd,
  pretreFeaturesStage,
  systemhusFeaturesDev,
  systemhusFeaturesProd,
  systemhusFeaturesStage,
  xlbyggFeaturesDev,
  xlbyggFeaturesProd,
  xlbyggFeaturesStage,
  xlbyggSverigeFeaturesDev,
  xlbyggSverigeFeaturesProd,
  xlbyggSverigeFeaturesStage,
  fargerikeBrandFeaturesDev,
  fargerikeBrandFeaturesStage,
  fargerikeBrandFeaturesProd,
  saltdalshyttaBrandFeaturesDev,
  saltdalshyttaBrandFeaturesStage,
  saltdalshyttaBrandFeaturesProd,
} from '@dap-dashboard/config';

export const env: Env = (process.env['NX_REACT_APP_ENV'] as 'dev' | 'stage' | 'prod') || 'local';

const sentryDsn = 'https://5d8fa7a1af194ea9bef381dc2a8c5350@o344472.ingest.sentry.io/6642861';

const muiLicenseKey =
  '37d24028aa82caa294effbc034e42797T1JERVI6MzY5NTEsRVhQSVJZPTE2NzUyNTIzMDgwMDAsS0VZVkVSU0lPTj0x';

const appConfig = (version: string): EnvConfigs => ({
  dev: {
    features: {
      brandFeatures: {
        defaultFeatures: defaultBrandFeatures,
        systemhus: systemhusFeaturesDev,
        nordbohus: nordbohusFeaturesDev,
        mesterhus: mesterhusFeaturesDev,
        blinkhus: blinkhusFeaturesDev,
        byggtorget: byggtorgetFeaturesDev,
        xlbygg: xlbyggFeaturesDev,
        xlbyggsverige: xlbyggSverigeFeaturesDev,
        pretre: pretreFeaturesDev,
        mestergruppen: mestergruppenFeaturesDev,
        kuloer: kuloerBrandFeaturesDev,
        bolist: bolistFeaturesDev,
        fargerike: fargerikeBrandFeaturesDev,
        saltdalshytta: saltdalshyttaBrandFeaturesDev,
        bademiljoe: bademiljoeFeaturesDev,
      },
    },
    styles: brandStylesConfig,
    auth0: {
      clientId: 'mcsF7fJ3Ir5F2zH1W6Y5jGL7Vtpx9sH4',
      domain: 'dev-mestergruppen.eu.auth0.com',
      audience: 'mg.iam',
    },
    iam: { url: 'https://iam.datahub-dev.mestergruppen.cloud' },
    version,
    gaMeasurementId: 'G-96K1JKSD1P',
    oldAppLink: 'https://dev.kjedeadmin.mestergruppen.cloud',
    sanity: {
      ...DAP_SANITY_CONFIG,
    },
    sentryDsn,
    muiLicenseKey,
  },
  stage: {
    features: {
      brandFeatures: {
        defaultFeatures: defaultBrandFeatures,
        systemhus: systemhusFeaturesStage,
        nordbohus: nordbohusFeaturesStage,
        mesterhus: mesterhusFeaturesStage,
        blinkhus: blinkhusFeaturesStage,
        byggtorget: byggtorgetFeaturesStage,
        xlbygg: xlbyggFeaturesStage,
        xlbyggsverige: xlbyggSverigeFeaturesStage,
        pretre: pretreFeaturesStage,
        mestergruppen: mestergruppenFeaturesStage,
        kuloer: kuloerBrandFeaturesStage,
        bolist: bolistFeaturesStage,
        fargerike: fargerikeBrandFeaturesStage,
        saltdalshytta: saltdalshyttaBrandFeaturesStage,
        bademiljoe: bademiljoeFeaturesStage,
      },
    },
    styles: brandStylesConfig,
    auth0: {
      clientId: 'TdYKUtMDSKB1mI02IfbHkF26LmZm06HL',
      domain: 'stage-mestergruppen.eu.auth0.com',
      audience: 'mg.iam',
    },
    iam: { url: 'https://iam.datahub-stage.mestergruppen.cloud' },
    version,
    gaMeasurementId: 'G-2JZYLTEESB',
    oldAppLink: 'https://stage.kjedeadmin.mestergruppen.cloud',
    sanity: DAP_SANITY_CONFIG,
    sentryDsn,
    muiLicenseKey,
  },
  prod: {
    features: {
      brandFeatures: {
        defaultFeatures: {
          ...defaultBrandFeatures,
          completeRegistrationEmailInfo: { resolveToggle: () => 'off' },
        },
        systemhus: systemhusFeaturesProd,
        nordbohus: nordbohusFeaturesProd,
        mesterhus: mesterhusFeaturesProd,
        blinkhus: blinkhusFeaturesProd,
        byggtorget: byggtorgetFeaturesProd,
        xlbygg: xlbyggFeaturesProd,
        xlbyggsverige: xlbyggSverigeFeaturesProd,
        pretre: pretreFeaturesProd,
        mestergruppen: mestergruppenFeaturesProd,
        kuloer: kuloerBrandFeaturesProd,
        bolist: bolistFeaturesProd,
        fargerike: fargerikeBrandFeaturesProd,
        saltdalshytta: saltdalshyttaBrandFeaturesProd,
        bademiljoe: bademiljoeFeaturesProd,
      },
    },
    styles: brandStylesConfig,
    auth0: {
      clientId: 'viR4pX7HxSMhstP8Y5KohMBpYf225AEG',
      domain: 'mestergruppen.eu.auth0.com',
      audience: 'mg.iam',
    },
    iam: { url: 'https://iam.datahub-prod.mestergruppen.cloud' },
    version,
    gaMeasurementId: 'G-T16PFG2JFQ',
    // hotjar: {
    //   id: 3217717,
    //   version: 6,
    // },
    clarity: {
      id: 'jdh67vcatk',
    },
    oldAppLink: 'https://kjedeadmin.mestergruppen.cloud',
    sanity: DAP_SANITY_CONFIG,
    sentryDsn,
    dapBrandUrls: DAP_BRAND_URLS,
    muiLicenseKey,
  },
});

const getConfig = (env: Env) => {
  const version = process.env['NX_REACT_APP_VERSION'] || 'local';
  return env === 'local' ? appConfig(version).dev : appConfig(version)[env];
};

export const config = getConfig(env);
