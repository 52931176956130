import { RecognizedBrands } from '@dap-common/types';
import { MainContent, Subheader } from '@dap-common/ui';
import { PropsWithChildren } from 'react';

interface Props {
  brandKey: RecognizedBrands;
}

export default function DefaultPageTemplate({ brandKey, children }: PropsWithChildren<Props>) {
  return (
    <>
      <Subheader brandKey={brandKey} />
      <MainContent>{children}</MainContent>
    </>
  );
}
