import { useBrandadminTFunction } from '@dap-admin/i18n';
import { LocationOverview, Property, PropertyValueTypes } from '@dap-admin/types';
import { getLocationRoute } from '@dap-common/consts';
import { Buttons, FlexRow } from '@dap-common/ui';
import { Box, useTheme } from '@mui/material';
import {
  GridColDef,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilter,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { ContainedButton } from '@shared/components';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseTable from './BaseTable';
import { locationColumnDefs } from './ColumnDefinitions';
import { useAppTFunction } from '@dap-common/i18n';
import { formatDate } from 'date-fns';

interface Props {
  locations: LocationOverview[];
  handleNewProperty: () => void;
  handleDeleteProperty: () => void;
  canAdministrateProperties: boolean;
}

function OrganizationViewTable({
  locations,
  handleNewProperty,
  handleDeleteProperty,
  canAdministrateProperties,
}: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const tBrandadmin = useBrandadminTFunction();
  const t = useAppTFunction();

  // Note: Future functionality: get all properties from an endpoint. No parsing required.
  const allActivePropertyKeys = useMemo(
    () => [
      ...new Set(
        locations
          .flatMap(({ properties }) => properties?.flatMap((prop) => prop.key))
          .filter((it) => it)
      ),
    ],
    [locations]
  );

  const colDefs: GridColDef[] = useMemo(
    () => [
      ...locationColumnDefs(theme, tBrandadmin),
      ...allActivePropertyKeys.map<GridColDef>((propertyKey) => ({
        field: `${propertyKey}`,
        headerName: `${propertyKey}`,
        minWidth: 200,
        valueGetter: ({ row: location }: GridValueGetterParams<LocationOverview>) => {
          if (location['properties']) {
            const property = location['properties'].find(
              (prop: Property) => prop.key === propertyKey
            );
            if (property) {
              if (!property.value) return null;
              switch (property.valueType) {
                case PropertyValueTypes.boolean:
                  return property.value === 'true' ? t('common.yes') : t('common.no');
                case PropertyValueTypes.date:
                  return formatDate(property.value, 'dd/MM/yyyy');
                default:
                  return property.value;
              }
            }
            return null;
          }
          return null;
        },
      })),
    ],
    [theme, tBrandadmin, allActivePropertyKeys, t]
  );

  const onRowClick = (params: GridRowParams) => {
    navigate(getLocationRoute({ locationKey: params.row.key }));
  };

  const locationRows = useMemo(
    () => locations.map((location) => ({ id: location.uuid, ...location })),
    [locations]
  );

  const CustomGridToolbar = (props: GridToolbarProps) => {
    return (
      <GridToolbarContainer {...props}>
        <FlexRow justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
          <FlexRow gap={1}>
            <GridToolbarQuickFilter data-testid="EmployeeTableSearch" />
            <GridToolbarColumnsButton data-testid="EmployeeTableColumns" placeholder={''} />
            <GridToolbarFilterButton data-testid="EmployeeTableFilters" placeholder={''} />
            <GridToolbarDensitySelector data-testid="EmployeeTableDensity" placeholder={''} />
          </FlexRow>

          {canAdministrateProperties && (
            <FlexRow gap={1}>
              <Buttons>
                <ContainedButton
                  size="small"
                  color="warning"
                  onClick={handleDeleteProperty}
                  disabled={!allActivePropertyKeys.length}
                >
                  {tBrandadmin('brandadmin.properties.remove')}
                </ContainedButton>
                <ContainedButton size="small" onClick={handleNewProperty}>
                  {tBrandadmin('brandadmin.properties.new')}
                </ContainedButton>
              </Buttons>
            </FlexRow>
          )}
        </FlexRow>
      </GridToolbarContainer>
    );
  };

  // Note: Styling boolean fields is functional, but we need to receive types from backend to set the type of the property-columns correctly.
  return (
    <Box
      height="calc(100vh - 250px)"
      paddingTop={2}
      sx={{
        '.MuiDataGrid-root .MuiDataGrid-booleanCell[data-value="true"]': {
          color: theme.palette.success.main,
        },
        '.MuiDataGrid-root .MuiDataGrid-booleanCell[data-value="false"]': {
          color: theme.palette.error.main,
        },
      }}
    >
      <BaseTable
        columns={colDefs}
        style={{ cursor: 'pointer' }}
        rows={locationRows}
        onRowClick={onRowClick}
        pinnedColumns={{ left: ['name'] }}
        components={{
          Toolbar: (props) => CustomGridToolbar(props),
        }}
      />
    </Box>
  );
}
export default OrganizationViewTable;
