import { useBrandTFunction } from '@dap-admin/i18n';
import { AppBasic, BrandV2, LocationOverview } from '@dap-admin/types';
import { getSelectedRoles } from '@dap-admin/utils';
import { useAppTFunction } from '@dap-common/i18n';
import { Box } from '@mui/material';
import { FillMinMax } from '@shared/components';
import { RegionSchema } from '../../Forms';
import BrandApps from '../BrandApps/BrandApps';
import BrandRegions from '../BrandRegions/BrandRegions';
import RolesConfig from '../RolesConfig/RolesConfig';

interface Props {
  brand: BrandV2;
  handleUpdateBrand: (input: any) => void;
  handleCreateBrandRegion: (input: RegionSchema) => void;
  handleUpdateBrandRegion: (id: string, input: RegionSchema) => void;
  handleDeleteBrandRegion: (id: string) => void;
  handleUpdateApps: () => void;
  brandLocations: LocationOverview[];
  availableServices: Array<AppBasic>;
}

function BrandConfig({
  brand,
  handleUpdateBrand,
  handleCreateBrandRegion,
  handleUpdateBrandRegion,
  handleDeleteBrandRegion,
  handleUpdateApps,
  brandLocations,
  availableServices,
}: Props) {
  const t = useAppTFunction();
  const tBrand = useBrandTFunction(brand.key);

  return (
    <Box component="section">
      <FillMinMax minWidth="400px" gridGap="1.25rem">
        <BrandApps
          availableApps={availableServices}
          currentApps={brand.apps}
          hasAdminRights={brand.isAdmin}
          handleEdit={handleUpdateApps}
        />
        <BrandRegions
          regions={brand.regions}
          handleCreate={handleCreateBrandRegion}
          handleUpdate={handleUpdateBrandRegion}
          handleDelete={handleDeleteBrandRegion}
          brandLocations={brandLocations}
          hasAdminRights={brand.isAdmin}
        />
        <RolesConfig
          roleType="brandRoles"
          selectedRoles={getSelectedRoles(brand.brandRoles)}
          handleSave={handleUpdateBrand}
          availableRoles={brand.brandRoles}
          header={t('brandDetailsPage.availableRoles.brand.title')}
          hasAdminRights={brand.isAdmin}
        />
        <RolesConfig
          roleType="dataownerRoles"
          selectedRoles={getSelectedRoles(brand.dataownerRoles)}
          handleSave={handleUpdateBrand}
          availableRoles={brand.dataownerRoles}
          header={t('brandDetailsPage.availableRoles.dataowner.title', {
            dataownerRoles: tBrand('brand.dataowner.dataownerRoles.name.plural'),
          })}
          hasAdminRights={brand.isAdmin}
        />
      </FillMinMax>
      {}
    </Box>
  );
}

export default BrandConfig;
