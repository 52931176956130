import { Typography } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MaxWidthWrapper from './MaxWidthWrapper';
import { messages } from '@dap-common/i18n';

interface Props {
  children: React.ReactNode;
}

export default function MainContent({ children }: Props) {
  const { t } = useTranslation('common');

  return (
    <MaxWidthWrapper component="main" sx={{ my: 4 }}>
      <ErrorBoundary
        fallback={<Typography>{t(messages.common.error.generic)}</Typography>}
        beforeCapture={(scope) => {
          scope.setTag('component', 'main-content');
        }}
      >
        {children}
      </ErrorBoundary>
    </MaxWidthWrapper>
  );
}
