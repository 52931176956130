import { useBrandadminTFunction } from '@dap-admin/i18n';
import { maxHeightPopover } from '@dap-admin/utils';
import { Badge, PopoverActions, Typography, styled } from '@mui/material';
import { Popover, ToggleButton } from '@shared/components';
import { FilterIcon } from '@shared/custom-icons';
import { usePopupElement } from '@shared/hooks';
import { throttle } from 'lodash';
import { PropsWithChildren, useCallback, useRef } from 'react';

interface Props {
  showBadge: boolean;
  disabled?: boolean;
}

export default function FilterPopover({ children, showBadge, disabled }: PropsWithChildren<Props>) {
  const t = useBrandadminTFunction();
  const { menuElementProps, triggerElementProps } = usePopupElement();
  const popoverActions = useRef<PopoverActions>(null);

  // Reposition popover if the height changes
  const paperRef = useCallback((node: HTMLDivElement) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(
      throttle(() => {
        if (popoverActions.current) {
          popoverActions.current.updatePosition();
        }
      }, 20)
    );
    resizeObserver.observe(node);
  }, []);

  return (
    <>
      <StyledBadge badgeContent=" " color="primary" invisible={!showBadge}>
        <StyledToggleButton
          {...triggerElementProps}
          value="selected"
          selected={showBadge}
          disabled={disabled}
        >
          <FilterIcon />
        </StyledToggleButton>
      </StyledBadge>

      <StyledPopover
        {...menuElementProps}
        handleClose={menuElementProps.onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transitionDuration={100}
        title={<Typography variant="h4">{t('brandadmin.filter.title')}</Typography>}
        action={popoverActions}
        slotProps={{ paper: { ref: paperRef } }}
      >
        {children}
      </StyledPopover>
    </>
  );
}

const StyledBadge = styled(Badge)(({ theme: { shape } }) => ({
  '.MuiBadge-badge': { height: '15px', minWidth: '15px' },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme: { shape } }) => ({
  borderRadius: shape.borderRadius * 2,
  padding: 5,
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    minWidth: theme.spacing(45),
    maxHeight: maxHeightPopover,
    boxShadow: theme.shadows[5],
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  marginLeft: theme.spacing(1),
}));
