import { Chip, List, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { messages } from '@dap-common/i18n';
import { RoleDetailed } from '@dap-admin/types';

interface Props {
  roles: RoleDetailed[];
}

function SelectedRoles({ roles }: Props) {
  const { t } = useTranslation('brandDetailsPage');

  return roles.length > 0 ? (
    <List disablePadding sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
      {roles.map(({ key, title }) => (
        <li key={key}>
          <Chip label={title} variant="large" />
        </li>
      ))}
    </List>
  ) : (
    <Typography variant="body2">{t(messages.brandDetailsPage.availableRoles.noRoles)}</Typography>
  );
}

export default SelectedRoles;
