import { useBrandadminTFunction } from '@dap-admin/i18n';
import { EmployeeDetail, EmployeeStatus, UserData } from '@dap-admin/types';
import { hasEditUserPermission } from '@dap-admin/utils';
import { routes } from '@dap-common/consts';
import { Grid } from '@mui/material';
import { TabPanel, Tabs } from '@shared/components';
import { useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import EmployeeActionMenu from './EmployeeActionMenu';
import EmployeeDetailsBlock from './EmployeeDetailsBlock';
import EmployeeWarnings from './EmployeeWarnings';

interface Props {
  employeeData: EmployeeDetail;
  selectedBrandKey: string;
  isBrandAdmin: boolean;
  openEditDetailsDialog: () => void;
  openLogDialog: () => void;
  openExtendedUserDataDialog: () => void;
  openEnableDisableEmployeeDialog: () => void;
  openRemoveEmployeeFromBrandDialog: () => void;
  openDeleteEmployeeDialog: () => void;
  userData: UserData;
  sendCompleteRegistrationEmail: (userId: string) => void;
  completeRegistrationEmailSentAt?: string;
  refreshUserData: () => void;
}

const tabId = 'employee-tab';

function FullEmployeeView({
  employeeData,
  selectedBrandKey,
  isBrandAdmin,
  openEditDetailsDialog,
  openLogDialog,
  openExtendedUserDataDialog,
  openEnableDisableEmployeeDialog,
  openRemoveEmployeeFromBrandDialog,
  openDeleteEmployeeDialog,
  userData,
  sendCompleteRegistrationEmail,
  completeRegistrationEmailSentAt,
  refreshUserData,
}: Props) {
  const tAdmin = useBrandadminTFunction();
  const { pathname } = useLocation();

  const numberOfWarnings = [
    employeeData.status.includes(EmployeeStatus.UNVERIFIED),
    employeeData.status.includes(EmployeeStatus.TEMP),
    employeeData.status.includes(EmployeeStatus.DISABLED),
    employeeData.status.includes(EmployeeStatus.BLOCKED),
  ].filter((x) => x).length;

  const tabs = useMemo(
    () => [
      {
        label: tAdmin('dataowner.details'),
        value: routes.details,
        to: routes.details,
      },

      ...(userData.metadata.superuser
        ? [
            {
              label: tAdmin('brandadmin.changelog.title'),
              value: routes.changelog,
              to: routes.changelog,
            },
          ]
        : []),
    ],
    [tAdmin, userData.metadata.superuser]
  );
  const currentTab = pathname.includes(routes.changelog) ? routes.changelog : routes.details;

  return (
    <Grid container spacing={3}>
      <Grid item container spacing={3}>
        <Grid item xs={12} xl>
          <EmployeeDetailsBlock employee={employeeData} />
        </Grid>
        {hasEditUserPermission(userData, employeeData, isBrandAdmin, selectedBrandKey) && (
          <Grid item xs={12} xl={4}>
            <EmployeeActionMenu
              isSuperuser={!!userData && userData.metadata.superuser}
              isBrandAdmin={isBrandAdmin}
              isMgEmployee={employeeData.mgEmployee}
              isDeactivated={employeeData.status.includes(EmployeeStatus.DISABLED)}
              editEmployeeDetails={openEditDetailsDialog}
              employeeLogDetails={openLogDialog}
              extendedUserDataDetails={openExtendedUserDataDialog}
              enableDisableEmployee={openEnableDisableEmployeeDialog}
              removeEmployeeFromBrand={openRemoveEmployeeFromBrandDialog}
              deleteEmployee={openDeleteEmployeeDialog}
              refreshUserData={refreshUserData}
            />
          </Grid>
        )}
      </Grid>

      {numberOfWarnings > 0 && (
        <Grid item xs={12}>
          <EmployeeWarnings
            isBlocked={employeeData.status.includes(EmployeeStatus.BLOCKED)}
            isDeactivated={employeeData.status.includes(EmployeeStatus.DISABLED)}
            isTempAccount={employeeData.status.includes(EmployeeStatus.TEMP)}
            isUnverified={employeeData.status.includes(EmployeeStatus.UNVERIFIED)}
            sendCompleteRegistrationEmail={() => sendCompleteRegistrationEmail(employeeData.userId)}
            completeRegistrationEmailSentAt={completeRegistrationEmailSentAt}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Tabs prefixId={tabId} currentTab={currentTab} tabs={tabs} />

        {tabs.map((tab, index) => (
          <TabPanel
            key={'tab-panel-' + index}
            prefixId={tabId}
            value={tab.value}
            hidden={currentTab !== tab.value}
          >
            {currentTab === tab.value && <Outlet context={{ selectedEmployee: employeeData }} />}
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  );
}

export default FullEmployeeView;
