import { KeyInfo } from '@dap-sanity/types';
import { Card, Divider, Stack, SxProps, Theme, Typography } from '@mui/material';
import { messages } from '@dap-common/i18n';
import { useTranslation } from 'react-i18next';
import KeyInfoEmail from './KeyInfoEmail';
import KeyInfoLink from './KeyInfoLink';

interface Props {
  keyInfo: KeyInfo;
}

const cardStyling: SxProps<Theme> = {
  p: 2,
};

function KeyInfoCard({ keyInfo }: Props) {
  const { t } = useTranslation(['common', 'services']);
  return (
    <Card color="primary" elevation={1} sx={cardStyling}>
      <Stack spacing={1} divider={<Divider />}>
        <Typography variant="h1" component="h2">
          {t(messages.services.keyInfo, { ns: 'services' })}
        </Typography>
        {keyInfo.email.map((email) => (
          <KeyInfoEmail key={email.value} email={email} />
        ))}
        {keyInfo.link.map((link) => (
          <KeyInfoLink key={link.url} link={link} />
        ))}
        {keyInfo.text.map((text) => (
          <Stack key={text.value}>
            <Typography variant="h4" component="h3">
              {text.title}
            </Typography>
            <Typography key={text.value} variant="body2">
              {text.value}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Card>
  );
}

export default KeyInfoCard;
