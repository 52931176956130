import * as yup from 'yup';
import { AppTFunction } from '@dap-common/i18n';
import { useMemo } from 'react';

export const usePropertiesSchema = (t: AppTFunction) => {
  const schema = useMemo(
    () =>
      yup.object({
        properties: yup
          .array(
            yup.object({
              key: yup.string().required(t('validation.required')).default(''),
              title: yup.string().required(t('validation.required')).default(''),
              description: yup.string().default(''),
            })
          )
          .default([]),
      }),
    [t]
  );
  return schema;
};

export type LocationPropertiesSchema = yup.InferType<ReturnType<typeof usePropertiesSchema>>;
