import { Typography } from '@mui/material';
import { messages } from '@dap-common/i18n';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BrandConfigCard from '../BrandConfigCard/BrandConfigCard';
import EditDapClientId from './EditDapClientId';
import { UniversalFormProvider } from '@dap-common/ui';
import { clientIdSchema, DapClientIdSchema } from '@dap-admin/types';

interface Props {
  clientId: string;
  handleSave: (input: DapClientIdSchema) => void;
  isUpdating: boolean;
}

function DapClientId({ clientId, handleSave, isUpdating }: Props) {
  const { t } = useTranslation('brandDetailsPage');
  const [isEditing, setIsEditing] = useState(false);

  const updateClientId = (input: DapClientIdSchema) => {
    if (input.employeePortalClientId !== clientId) {
      handleSave(input);
    }
    setIsEditing(false);
  };

  return (
    <BrandConfigCard
      header={t(messages.brandDetailsPage.dapAuth0ClientId.title)}
      isEditing={isEditing}
      handleEdit={() => setIsEditing(true)}
      handleCancelEdit={() => setIsEditing(false)}
      isLoading={isUpdating}
      hasAdminRights={true}
    >
      {isEditing ? (
        <UniversalFormProvider
          schema={clientIdSchema}
          defaultValues={{ employeePortalClientId: clientId || '' }}
        >
          <EditDapClientId cancelEdit={() => setIsEditing(false)} handleSave={updateClientId} />
        </UniversalFormProvider>
      ) : (
        <Typography variant="body2">
          {clientId ? clientId : t(messages.brandDetailsPage.dapAuth0ClientId.empty)}
        </Typography>
      )}
    </BrandConfigCard>
  );
}

export default DapClientId;
