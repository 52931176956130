import { useBrandadminTFunction } from '@dap-admin/i18n';
import { EmployeeDetail, EmployeeStatus } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { CopyValueToClipboard, FlexColumn, FlexRow, LabelValue } from '@dap-common/ui';
import { Stack, Typography, useTheme } from '@mui/material';
import { Breadcrumbs, Chip, LabelValueTable, Paper, TagHeading } from '@shared/components';

interface Props {
  employee: EmployeeDetail;
}

export default function EmployeeDetailsBlock({ employee }: Props) {
  const t = useBrandadminTFunction();
  const tApp = useAppTFunction();
  const { palette } = useTheme();

  return (
    <Paper sx={{ height: '100%' }} data-testid="employee-details-block">
      <TagHeading marginBottom={3}>
        <FlexRow justifyContent="space-between" alignItems="flex-start">
          <FlexColumn>
            <Breadcrumbs
              items={[{ href: '../..', label: tApp('common.home') }, { label: employee.name }]}
              fontSize="sm"
            />
            <LabelValue
              label={t('location.id')}
              sx={{ padding: '4px 0' }}
              value={<Chip label={employee.userId} backgroundColor={palette.info.main} />}
              labelTypographyProps={{
                color: 'text.secondary',
                variant: 'body2',
                fontWeight: 400,
              }}
            />
          </FlexColumn>
        </FlexRow>

        {employee.status.includes(EmployeeStatus.DISABLED) && (
          <Chip
            label={t('location.properties.deactivated')}
            backgroundColor={palette.warning.main}
          />
        )}
      </TagHeading>

      <LabelValueTable
        items={[
          {
            label: t('employee.details.email'),
            value: (
              <Stack direction="row" alignItems="center">
                <Typography>{employee.email}</Typography>
                <CopyValueToClipboard value={employee.email} label="" />
              </Stack>
            ),
          },
          {
            label: t('employee.details.phone'),
            value: employee.phoneNumber,
          },
          {
            label: t('employee.details.jobTitle'),
            value: employee.jobTitle,
          },
        ]}
      />
    </Paper>
  );
}
