import { BlockType } from './blockTypes';
import { DashboardBlock } from './../documents/dashboardPage';
import { SanityAsset } from '@sanity/image-url/lib/types/types';

export enum LegacyBannerType {
  Image = 'legacyImageBanner',
  MGA = 'legacyMGABanner',
  Intranet = 'legacyIntranetBanner',
  Proff = 'legacyProffBanner',
}

export interface LegacyBannerImage {
  _type: LegacyBannerType.Image;
  image: SanityAsset;
}

export interface LegacyBannerMGA {
  _type: LegacyBannerType.MGA;
}

export interface LegacyBannerIntranet {
  _type: LegacyBannerType.Intranet;
}

export interface LegacyBannerProff {
  _type: LegacyBannerType.Proff;
}

export interface BlockLegacyBanner extends DashboardBlock<BlockType.LegacyBanner> {
  variant: (LegacyBannerImage | LegacyBannerMGA | LegacyBannerProff | LegacyBannerIntranet)[];
}
